import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isYes(inputUser, UserInput.types.hotFlashes.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputUserKeys.push(UserInput.types.hotFlashes.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/* if hot flashes
		mild - increase value by100
		moderate - increase 150
		severe increase by 200 */

		if(inputUser[UserInput.types.hormoneProblems.key] === "severe" || inputUser[UserInput.types.hormoneProblems.key] === "extremely severe"){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputUserKeys.push(UserInput.types.hormoneProblems.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}

	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(isYes(inputUser, UserInput.types.hotFlashes.key)){
		sortValue -= 150;
	}/* if hot flashes
	mild - increase value by100
	moderate - increase 150
	severe increase by 200 */
	// Craig Todo: Convert to Hot Flash Severity
	return sortValue;
};
const getPrompts = (inputUser, inputCalculated) => {
	var toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.maleOrFemale.key)){
		toReturn.push(UserInput.types.maleOrFemale);
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isUnanswered(inputUser, UserInput.types.hormoneProblems.key)){
			toReturn.push(UserInput.types.hormoneProblems);
		}
		if(isUnanswered(inputUser, UserInput.types.hotFlashes.key)){
			toReturn.push(UserInput.types.hotFlashes);
		}
	}
	return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.hormoneProblems.key] === "severe"){
		return {
			description : "1 serving (1/2 tsp or 2.5 mL), twice per day.",
			servingsPerDay : 2
		};
	}
	if(inputUser[UserInput.types.hormoneProblems.key] === "extremely severe"){
		return {
			description : "2 servings (1 tsp or 5 mL), twice per day.",
			servingsPerDay : 4
		};
	}
	if(isYes(inputUser, UserInput.types.hotFlashes.key)){
		return {
			description : "2 servings (1 tsp or 5 mL) per day.",
			servingsPerDay : 2
		};
	}
	return {
		description : "1 serving (1/2 tsp or 2.5 mL) per day.",
		servingsPerDay : 1
	};
	
};
// const getDescription = (inputUser, inputCalculated) => {
// 	return `
// 	Aller-Calm incorporates bioflavonoids, micronutrients, proteolytic enzymes, and herbs 
// 	into a comprehensive formula that provides multifaceted support for individuals with 
// 	immune imbalances. Dihydroquercetin (DHQvital™), a key component in Aller-Calm, inhibits 
// 	oxidation, is bioactive, and is highly absorbable. Aller-Calm supports the body’s regulating 
// 	function in addressing an overactive or distressed histamine response that are sometimes 
// 	brought on by the environment.*`;
// };
const title = "Dong Quai";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "DONG-QUAI", price: 49}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getPrices	
};