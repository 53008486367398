import React from 'react';
import { InputGroup, InputGroupAddon, CustomInput, Button, Form, FormGroup } from 'reactstrap';
import serializer from './serializer.js';
import { getDateString } from './utilities.js';
import FileSaver from 'file-saver';

const File = (props) => {
	const fileInput = React.createRef();

	const saveToFile = () => {
		var blob = new Blob([serializer.serialize(props.inputUser)], {type: "text/plain;charset=utf-8"});
		FileSaver.saveAs(blob, (props.persisterName || "FunctionalMedicineCalculator-" + getDateString()) + ".txt");
	};
	const fileUpload = (event) => {
		event.preventDefault();
		let reader = new FileReader();
		reader.onload = (onLoadEvent) => {
			props.updateInputUser(serializer.deserialize(onLoadEvent.target.result));
		}
		reader.readAsText(fileInput.current.files[0]);
	};
  	
	return (
		<div>
			<h4>Save a progress checkpoint to a file on your machine:</h4>
			<Form>
				<FormGroup>
					<InputGroup>
						<Button color="primary" onClick={saveToFile}>
							Save progress to a file
						</Button>
					</InputGroup>
				</FormGroup>
			</Form>
			<h4>Load a progress checkpoint from a file on your machine:</h4>
			<Form onSubmit={fileUpload}>
				<FormGroup>
					<InputGroup>
						<CustomInput type="file" id="snapshotUpload" name="snapshot" label="Pick a file to load" innerRef={fileInput} />
						<InputGroupAddon addonType="prepend">
							<Button color="primary" type="submit">Upload</Button>
						</InputGroupAddon>
					</InputGroup>
				</FormGroup>
			</Form>
		</div>
	);
}
export default File;