import React from 'react';
import { InputGroup, Form, FormGroup, Label } from 'reactstrap';
import serializer from './serializer.js';
import UserInput from '../inputs/user/index.js';
import queryString from 'query-string';

export const getLink = (inputUser, activeTab, debug, selectedOutputs, firstName, email, verifiedEmail) => {
	const serializedInputUser = encodeURIComponent(serializer.serialize(UserInput.getMinifiedInputUser(inputUser))).replace(/[!'()*]/g, function(c) {
		return '%' + c.charCodeAt(0).toString(16);
	  });
	return "/calculator/" 
		+ encodeURIComponent(activeTab || "questionaire") 
		+ "?inputUser=" + serializedInputUser 
		+ (selectedOutputs && selectedOutputs.length > 0 ? "&selectedOutputs=" + encodeURIComponent(serializer.serialize(selectedOutputs)) : "") 
		+ (debug ? "&debug=true" : "")
		+ (firstName && firstName.length > 0 ? "&firstName=" + encodeURIComponent(firstName) : "")
		+ (email && email.length > 0 ? "&email=" + encodeURIComponent(email) : "")
		+ (verifiedEmail ? "&verifiedEmail=true" : "")
};
export const getInputUserFromQueryString = (rawQueryString) => {
	const queryStringParams = queryString.parse(rawQueryString);
	return UserInput.getRestoredInputUser(serializer.deserialize(queryStringParams.inputUser));
};
const QueryString = (props) => {
	return (
		<div>
			<h1>URL Storage</h1>
			<Form>
			<FormGroup>
				<InputGroup>
				<a href={getLink(props.inputUser, props.activeTab, props.debug)}>
					<Label>Bookmark this link</Label>
				</a>
				</InputGroup>
			</FormGroup>
			</Form>
		</div>
	);
};
export default QueryString;