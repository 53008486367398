import React, { useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import Prompt from './Prompt.js';
import CalculatedInput from './inputs/calculated/index.js';
import UserInput from './inputs/user/index.js';
import outputs from './outputs/index.js';
import Browser from './persister/Browser.js';
import File from './persister/File.js';
import { getLink, getInputUserFromQueryString } from './persister/QueryString.js';
import queryString from 'query-string';
import { UncontrolledAlert, Row, Col, Container, ListGroup, Button, Form, FormGroup, Label, InputGroup, Input, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './Recommender.css';
import serializer from './persister/serializer.js';
import Cart from '../Cart/index.js';
import OutputCategoryStoreView from './outputs/OutputCategoryStoreView.js';
import ReactGA from "react-ga";
import LayoutConstants from '../LayoutConstants.js';


//https://reactstrap.github.io/components/form/
//https://bootswatch.com/sandstone/




const getDefaultInputUser = (inputUser) => {
	inputUser = inputUser || {};
	let defaultInputUser = {};
	Object.keys(UserInput.types).map((key) => defaultInputUser[key] = inputUser[key] || "");
	return defaultInputUser;
};


const Recommender = (props) => {
	let history = useHistory();
	const queryStringParams = queryString.parse(props.location.search);
	const { tab } = useParams();

	const [busy, setBusy] = useState(false);

	const [inputUser, setInputUser] = useState(getDefaultInputUser(getInputUserFromQueryString(props.location.search)));
	const [selectedOutputs, setSelectedOutputs] = useState(serializer.deserialize(queryStringParams.selectedOutputs) || undefined);
	
	const [verifiedEmail, setVerifiedEmail] = useState(queryStringParams.verifiedEmail);
	const [understandDisclaimer, setUnderstandDisclaimer] = useState(queryStringParams.verifiedEmail);
	const [firstName, setFirstName] = useState(queryStringParams.firstName || localStorage.getItem("firstName"));
	const [email, setEmail] = useState(queryStringParams.email || localStorage.getItem("email"));

	const [emailSent, setEmailSent] = useState(false);
	const [inputFilter, setInputFilter] = useState("");
	
	const inputCalculated = CalculatedInput.getInputCalculated(inputUser);
	const [outputPrompts, setOutputPrompts] = useState(outputs.getPrompts(inputUser, inputCalculated, selectedOutputs));
	const outputRecommendations = outputs.getRecommended(inputUser, inputCalculated, selectedOutputs);

	const selectPage = (requestedTab, updatedInputUser, updatedSelectedOutputs, skipPageView) => {
		requestedTab = requestedTab || tab;
		
		setInputFilter("");
		setInputUser(updatedInputUser);
		setSelectedOutputs(updatedSelectedOutputs);
		var nextOutputPrompts = outputs.getPrompts(updatedInputUser, CalculatedInput.getInputCalculated(updatedInputUser), updatedSelectedOutputs);
		// if((requestedTab === "questionaire") && (nextOutputPrompts === undefined || nextOutputPrompts === null || nextOutputPrompts.length === 0)){
			
		// 	console.log("Skipping to recommendations");
		// 	requestedTab = "recommendations";
		// }
		setOutputPrompts(nextOutputPrompts);
		if(!skipPageView){
			history.push(getLink(updatedInputUser,requestedTab,props.debug, updatedSelectedOutputs));
		}
	};
	const selectTab = (requestedTab) => {
		selectPage(requestedTab, inputUser, selectedOutputs);
	};
    const setInputUserProperty = (key, value, calculate) => {
		setUnderstandDisclaimer(false);
		let inputUserModification = {};
		inputUserModification[key] = value;
		const newInputUser = {...inputUser, ...inputUserModification};
		setEmailSent(false);
		//newInputUser[key] = value;
		if(calculate){
			selectPage(undefined, newInputUser, selectedOutputs, true);
		}else{
			setInputUser(newInputUser);
		}
	};
	const setOutputPromptsToUnknown = (outputPrompts) => {
		const newInputUser = {
			...inputUser,
			...outputPrompts
				.filter((op) => outputs.isUnanswered(inputUser, op.key))
				.reduce((acc, cur) => {acc[cur.key] = "unknown"; return acc; },{})
		};
		
		window.scrollTo(0,0);
		selectPage(tab, newInputUser, selectedOutputs);
	};
	if(tab === undefined){
		history.push(getLink(inputUser,"questionaire",props.debug, selectedOutputs));
	}
	if(tab === "inputs" && ! (outputPrompts.length === 0)){
		history.push(getLink(inputUser,"questionaire",props.debug, selectedOutputs));
	}
	if(tab === "questionaire" && ! (outputPrompts.length > 0)){
		history.push(getLink(inputUser,"recommendations",props.debug, selectedOutputs));
	}

	let allTab, outputFilteringTab, resetUserInputTab, resetOutputFiltersTab, allOutputsTab;
	if(props.debug){
		allTab = <NavItem><NavLink onClick={() => {selectTab("all")}} active={tab === "all"}>All</NavLink></NavItem>;
		outputFilteringTab = <NavItem><NavLink onClick={() => {selectTab("outputfiltering")}} active={tab === "outputfiltering"}>Output Filtering</NavLink></NavItem>;
		resetUserInputTab = <NavItem><Button onClick={() => selectPage(tab, getDefaultInputUser(), selectedOutputs) }>Reset User Inputs</Button></NavItem>;
		resetOutputFiltersTab = <NavItem><Button onClick={() => selectPage(tab,inputUser,undefined)}>Reset Output Filters</Button></NavItem>;
		allOutputsTab = <NavItem><NavLink onClick={() => {selectTab("alloutputs")}} active={tab === "alloutputs"}>All Outputs</NavLink></NavItem>;
	}

	
	const behaviors = outputRecommendations.filter((v) => v.output.category === outputs.categories.behaviors);
	const supplements = outputRecommendations.filter((v) => v.output.category === outputs.categories.supplements);
	const tests = outputRecommendations.filter((v) => v.output.category === outputs.categories.tests);
	const notifications = outputRecommendations.filter((v) => v.output.category === outputs.categories.notifications);
	const others = outputRecommendations.filter((v) => 
		v.output.category !== outputs.categories.behaviors 
		&& v.output.category !== outputs.categories.supplements 
		&& v.output.category !== outputs.categories.tests
		&& v.output.category !== outputs.categories.notifications );
	
	let minimumOutputPromptGroup = Math.min(...outputPrompts.map((value) => value.group > -1 ? value.group : 1000));

	const onUnderstandDisclaimer = () => {
		ReactGA.event({
			category: "Calculator",
			action: "Understand Disclaimer"
		});
		if(isFirstNameAndEmailValid()){
			localStorage.setItem("firstName", firstName);
			localStorage.setItem("email", email);
			emailResults(() => setEmailSent(true));
		}
	};
	const emailResults = (successCallback) => {
		if(!props.debug && !busy){
			setBusy(true);
			fetch("https://uixkjepjvf.execute-api.us-east-1.amazonaws.com/default/emailResults",{
				method: "POST",
				//credentials: "omit",
				//mode: "no-cors",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": "6t3BxXOlzU8F5c6XK0nG9adzU0rZOp128loyKhed"
				},
				body: JSON.stringify(
					{
						email,
						firstName,
						resultsHref: window.location.origin + getLink(inputUser, "recommendations", props.debug, selectedOutputs, firstName, email, true)
					}
				)
			})
			.then(response => response.text())
			.then(result => {
				console.log(result);
				setBusy(false);
				successCallback();})
			.catch((error) => {
				console.log(error);
				setBusy(false);
			});
		}else{
			successCallback();
		}

	};
	const isFirstNameAndEmailValid = () => {
		return firstName && firstName.length > 0 && email && email.length > 0 && email.indexOf("@") > 0
	};
	const onGoHome = () => {
		ReactGA.event({
			category: "Calculator",
			action: "Refuses Disclaimer"
		});
		history.push("/");
	};

	const filterOutputPromptsForQuestionaire = (outputPrompt) => {
		return outputPrompt.group === minimumOutputPromptGroup
			&& (minimumOutputPromptGroup < 100 ||
			filterOnInputFilter(outputPrompt.prompt))
			;
	};
	const filterOnInputFilter = (prompt) => {
		return inputFilter === undefined
		|| inputFilter.length === 0
		|| prompt.toLowerCase().includes(inputFilter.toLowerCase());
	};
	const tabPanes = {
		"questionaire" :
			<TabPane tabId="questionaire">
				<Row>
					<Col>
						{ (minimumOutputPromptGroup > 99) ? <Button onClick={() => setOutputPromptsToUnknown(outputPrompts.filter(filterOutputPromptsForQuestionaire))} block className="mb-3">Skip labs</Button> : "" }
						{ (minimumOutputPromptGroup > 99) ? <InputGroup className="p-4 mb-3"><Input type="text" id="inputFilter" placeholder="Type here to filter list" value={inputFilter} onChange={(event) => setInputFilter(event.target.value)} /></InputGroup> : "" }
						<Form className="mb-5">
						{ outputPrompts.filter(filterOutputPromptsForQuestionaire).map((value) => 
								<Prompt
								key={value.key}
								inputUserKey={value.key}
								prompt={value.prompt} 
								value={inputUser[value.key]} 
								possible={value.possible}
								units={value.units}
								setInputUser={(key,value) => setInputUserProperty(key,value) }
								type={value.type}
								images={value.images}
								choiceDescription={value.choiceDescription}
								range={inputCalculated[value.key + "Range"]}
								/>)}
						</Form>
						<Button onClick={() => setOutputPromptsToUnknown(outputPrompts.filter(filterOutputPromptsForQuestionaire))} block className="p-3" color="info">Next</Button>
						<p className="p-5">
							<center>
								<Button onClick={() => {selectTab("recommendations")}}>See recommendations so far</Button>
							</center>
						</p>
					</Col>
				</Row>
			</TabPane>,
		"inputs":
			<TabPane tabId="inputs">
				<Row>
					<Col>
						<Button onClick={() => selectTab("recommendations")} block className="mb-3">View Recommendations</Button>
						<InputGroup className="p-4 mb-3"><Input type="text" id="inputFilter" placeholder="Type here to filter list" value={inputFilter} onChange={(event) => setInputFilter(event.target.value)} /></InputGroup>
						<Form className="mb-5">
						{ Object
							.keys(UserInput.types)
							.filter((key) => !outputs.isUnanswered(inputUser, key) && filterOnInputFilter(UserInput.types[key].prompt))
							.map((key) =>
								<Prompt
								key={key}
								inputUserKey={key}
								prompt={UserInput.types[key].prompt}
								value={inputUser[key]}
								possible={UserInput.types[key].possible}
								units={UserInput.types[key].units}
								setInputUser={(k,v) => setInputUserProperty(k,v,true) }
								type={UserInput.types[key].type}
								images={UserInput.types[key].images}
								choiceDescription={UserInput.types[key].choiceDescription}
								range={inputCalculated[key + "Range"]} />
							)
						}
						</Form>
						{ outputPrompts.length > 0 ? <Button onClick={() => selectTab("questionaire")} block>Questionaire</Button> : "" }
						<Button onClick={() => selectTab("recommendations")} block className="p-3" color="info">View Recommendations</Button>						
					</Col>
				</Row>
			</TabPane>,
		"all":
			<TabPane tabId="all">
				<Row>
					<Col>
						{ Object.keys(UserInput.types).map((key) => 
							<Prompt
							key={key}
							inputUserKey={key}
							prompt={UserInput.types[key].prompt}
							value={inputUser[key]}
							possible={UserInput.types[key].possible}
							units={UserInput.types[key].units}
							setInputUser={(k,v) => setInputUserProperty(k,v,true) }
							type={UserInput.types[key].type}
							images={UserInput.types[key].images}
							choiceDescription={UserInput.types[key].choiceDescription}
							range={inputCalculated[key + "Range"]} />)}
					</Col>
				</Row>
			</TabPane>,
		"recommendations":
			<TabPane tabId="recommendations">
				<Modal isOpen={!understandDisclaimer && !emailSent}>
					<ModalHeader>
						Disclaimer
					</ModalHeader>
					<ModalBody>
					I understand the products, services and claims made about specific products or procedures on
					this website and it's subsidiaries have not been evaluated by the United Sates Food and Drug
					Administration and are not provided to diagnose, treat, cure or prevent disease. The information
					provided on this website and subsidiaries is not intended as a substitute for advice from your
					physician or other health care provider or any information contained on or in product label or
					packaging. As always, please consult with a healthcare professional before starting any diet,
					exercise or supplementation program, before taking any medication, supplement, or if you have
					or might suspect you might have a health problem.
					</ModalBody>
					<ModalHeader>
						Medication Contraindications and supplement cautions based on questionnaire and labs
					</ModalHeader>
					<ModalBody>
					Congratulations on taking the first step towards better health. Certain vitamins and herbs can
					have profound effects on the body, all of which may still not be known. Based on your
					questionnaire and labs provided we have provided you with a few things that may be
					contraindicated for you to take. In addition, any time you are on a prescription medications there
					is always the chance of some unknown interaction. If you are on medication we recommend that
					you use caution and it’s never a bad idea to consult with a physician familiar with potential
					interactions.
					</ModalBody>
					<ModalFooter>
						<Container>
					<FormGroup row>
						<Label htmlFor="firstNameTextBox" md={4} xs={12}>First Name</Label>			
						<Col md={8} sm={12}>
							<Input 
								id="firstNameTextBox"
								type="textbox" 
								value={firstName} 
								onChange={(event) => setFirstName(event.target.value)} 
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label htmlFor="emailTextBox" md={4} xs={12}>Email</Label>			
						<Col md={8} sm={12}>
							<Input 
								id="emailTextBox"
								type="email" 
								value={email} 
								onChange={(event) => setEmail(event.target.value)} 
							/>
							<small>Recommendations will be emailed</small>
						</Col>						
					</FormGroup>
						<Button color="primary" className="mr-3" onClick={onUnderstandDisclaimer} disabled={busy}>I Understand</Button>
						<Button color="secondary" onClick={onGoHome}>Go Home</Button>
						</Container>
					</ModalFooter>
				</Modal>
				{ emailSent ?
					<Row>
						<Col xs="12">
							<h3>Please check <strong>{email}</strong> for your recommendations.</h3>
							<br />
							<h4>Congratulations on taking the first step towards better health.</h4>
						</Col>
						<Col md="7">
							<p>Dr. Mortensen created the Functional Medicine Calculator as a way to reach more people at a lower cost. There are certain foundational things that each body needs. Every person is different, different lifestyle choices, eating habits, and DNA that has an impact on our bodies and how we feel.</p>
							<p>Functional medicine's main goal is to improve function and health from a root or ground level perspective. At the very basic initial phase of functional medicine we want to see how the body is functioning. In Dr. Mortensen's office we always start with basic body function by looking at symptoms and labs. Let us first get the body functioning better before we move on to later steps.</p>
							<p>In the functional medicine world we know that food is medicine, however, if your body has become so dysfunctional that you are showing symptoms, we cannot heal by food alone. We need a boost with high quality nutraceuticals.</p>
							Your results are grouped into 3 main categories. 
							<ol><li>Future Testing</li>
							<li>Supplement Recommendation</li>
							<li>Behavioral Changes</li></ol>
						</Col>
						<Col md="5">
					<div className="aspect-ratio-wrapper mb-5">
								<iframe title="Understanding these recommendations" width="100%" src="https://www.youtube.com/embed/vdDc4iAWNIM?controls=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
							</div>
					</Col>
						<Col xs="12">
						<h4>Future Testing:</h4>
							<p>Based on the input you gave on the questionnaire and any labs (if available), we use our algorithm to determine if any additional testing is suggested in order to further investigate you internal health. They are ordered from the highest priority to the least priority. If you didnt have any recent labs to input, consider ordering our basic panel. You will be surprised at how much information we can get from just that and amazed at how much more accurate your supplement recommendations will be.</p>
							<h4>Supplement recommendations:</h4>
							<p>The recommendations given are again listed in order from the most important to the least important. Although the list of recommendations may be very long, we usually recommend starting with the top 5-10 supplements. This gives you the best chances of using the supplements that will give you the best "bang for your buck" based off of your symptoms and health concerns.</p>
							<p><em>Hint</em> - If you have any labs within the last year go back and input those into your questionnaire. It can significantly change your results. </p>
							<h4>Behaviors:</h4>
							<p>This category includes anything not covered in the future testing or supplement categories. It can cover anything from seeing a functional medicine or conventional doctor, avoiding certain foods, certain types of exercises, sleep, meditation, or other intervention and/or modalities.</p>
							<p>We hope you find this information helpful and if you have any feedback please let us know so we can continue to improve.</p>
							<br/>
							<p>Sincerely,</p>
							<p><em>FM Calculator team</em></p>
						</Col>
					</Row> 
					: "" }
				{ understandDisclaimer && verifiedEmail ?
				<Row className="text-white bg-primary p-4">
					<Col xs="12" className="m-3"><h3><i>Congratulations on taking the first step towards better health.</i></h3></Col>
					<Col md="7">
							
					<p>Dr. Mortensen created the Functional Medicine Calculator as a way to reach more people at a lower cost. There are certain foundational things that each body needs. Every person is different, different lifestyle choices, eating habits, and DNA that has an impact on our bodies and how we feel.</p>
							<p>Functional medicine's main goal is to improve function and health from a root or ground level perspective. At the very basic initial phase of functional medicine we want to see how the body is functioning. In Dr. Mortensen's office we always start with basic body function by looking at symptoms and labs. Let us first get the body functioning better before we move on to later steps.</p>
							<p>In the functional medicine world we know that food is medicine, however, if your body has become so dysfunctional that you are showing symptoms, we cannot heal by food alone. We need a boost with high quality nutraceuticals.</p>
							<ol><li><a href={"#Future Testing"}>Future Testing</a></li>
							<li><a href={"#Supplement Recommendation"}>Supplement Recommendation</a></li>
							<li><a href={"#Behavioral Changes"}>Behavioral Changes</a></li></ol>
					</Col>
					<Col md="5">
					<div className="aspect-ratio-wrapper mb-5">
								<iframe title="Understanding these recommendations" width="100%" src="https://www.youtube.com/embed/vdDc4iAWNIM?controls=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
							</div>
					</Col>
				</Row> : "" }
				{ understandDisclaimer && verifiedEmail ?
				<Row>
					<Col>
						<outputs.OutputCategoryView title="Future Testing" debug={props.debug} outputCategoryItems={tests} inputUser={inputUser} inputCalculated={inputCalculated} cart={props.cart} getQtyAndPrice={props.getQtyAndPrice} addToCart={props.addToCart} description="Based on the input you gave on the questionnaire and any labs (if available), we use our algorithm to determine if any additional testing is suggested in order to further investigate you internal health. They are ordered from the highest priority to the least priority. If you didnt have any recent labs to input, consider ordering our basic panel. You will be surprised at how much information we can get from just that and amazed at how much more accurate your supplement recommendations will be." />
						<outputs.OutputCategoryView title="Supplement Recommendation" debug={props.debug} outputCategoryItems={supplements} inputUser={inputUser} inputCalculated={inputCalculated} cart={props.cart} getQtyAndPrice={props.getQtyAndPrice} addToCart={props.addToCart} description={"The recommendations given are again listed in order from the most important to the least important. Although the list of recommendations may be very long, we usually recommend starting with the top 5-10 supplements. This gives you the best chances of using the supplements that will give you the best \"bang for your buck\" based off of your symptoms and health concerns.  Hint - If you have any labs within the last year go back and input those into your questionnaire. It can significantly change your results."}  />
						<outputs.OutputCategoryView title="Behavioral Changes" debug={props.debug} outputCategoryItems={behaviors} inputUser={inputUser} inputCalculated={inputCalculated} description="This category includes anything not covered in the future testing or supplement categories. It can cover anything from seeing a functional medicine or conventional doctor, avoiding certain foods, certain types of exercises, sleep, meditation, or other intervention and/or modalities."/>
						{others.length > 0 ? <outputs.OutputCategoryView title="Other" outputCategoryItems={others} inputUser={inputUser} inputCalculated={inputCalculated} /> : null }
						<Cart.View cart={props.cart} getQtyAndPrice={props.getQtyAndPrice} addToCart={props.addToCart} clearCart={props.clearCart} />
						<p>
							<center>
								{ outputPrompts.length > 0 ?
								<Button onClick={() => selectTab("questionaire")} className="p-3 m-3" color="info">Continue questionaire</Button> : 
								<Button onClick={() => selectTab("inputs")} className="p-3 m-3" color="info">View/Change inputs</Button>
								}
							</center>
						</p>
						
						<center className="m-3"><small>*These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.</small></center>
						{notifications.length > 0 ?
							<div style={{position : "fixed", bottom: 0, left: "1em", right: "1em", zIndex: 1000 }}>
								{notifications.map((rec) => <UncontrolledAlert color="danger" >
									{rec.output.getDescription()}
								</UncontrolledAlert> )}
							</div> 
							: null }
					</Col>
					
				</Row>				
				: "" }
			</TabPane>,
		"alloutputs":
			<TabPane tabId="alloutputs">
				<Row>
					<Col>
						<OutputCategoryStoreView title="All" debug={props.debug} outputCategoryItems={outputs.allOutputs} inputUser={inputUser} inputCalculated={inputCalculated} cart={props.cart} getQtyAndPrice={props.getQtyAndPrice} addToCart={props.addToCart} />
						
					</Col>
				</Row>
			</TabPane>,
		"outputfiltering":
			<TabPane tabId="outputfiltering">
				
				<Row>
					<Col>
						<Form>											
							{ outputs.allOutputs.map((output) => <FormGroup key={output.title}>
								<Label check xs="12" md="6">
									<Input 
										type="checkbox" 
										checked={selectedOutputs !== undefined ? selectedOutputs.indexOf(output.title) > -1 : false } 
										onChange={selectedOutputs !== undefined && selectedOutputs.indexOf(output.title) > -1 ? () => setSelectedOutputs(selectedOutputs.filter(x => x !== output.title)) : () => setSelectedOutputs([...(selectedOutputs || []), output.title])}
										/>{' '}
									{output.title}
								</Label>
								
								{/* {output.getPrices !== undefined ? <Label check xs="10" md="4" align="right">{output.getPrices(inputUser, inputCalculated).map((price) => <Price price={price} />)}</Label> : null}
								{output.images !== undefined ? <Div xs="2"><ImageViewer images={output.images} /></Div> : null } */}
							</FormGroup>) }
						</Form>
					</Col>
				</Row>
			</TabPane>,
		"persist":
			<PersistTab 
				inputUser={inputUser} 
				updateInputUser={(updatedInputUser) => selectPage("inputs", updatedInputUser, selectedOutputs)} 
				storageUpdated={() => selectPage(tab,inputUser, selectedOutputs)}
				activeTab={tab}
				debug={props.debug}
				link={getLink(inputUser,"questionaire",props.debug, selectedOutputs)}/>
	};

	return (
		
		<Container>
			<h2 className="mt-5 m-2">Calculator</h2>
			{ props.debug ? 
			<Nav tabs>
				<NavItem>
					<NavLink disabled={outputPrompts.length <= 0} onClick={() => {selectTab("questionaire")}} active={tab === "questionaire"}>Questionaire</NavLink>
				</NavItem>
				<NavItem>
					<NavLink onClick={() => {selectTab("recommendations")}} active={tab === "recommendations"}>Recommendations 
						{outputRecommendations !== undefined && outputRecommendations.length > 0 ? "(" + outputRecommendations.length + ")" : "" }</NavLink>
				</NavItem>
				{allTab}
				{outputFilteringTab}
				{allOutputsTab}
				{resetUserInputTab}
				{resetOutputFiltersTab}
				<NavItem>
					<NavLink onClick={() => {selectTab("persist")}} active={tab === "persist"}>Save Progress or Load</NavLink>
				</NavItem>
			</Nav> : "" }
			<TabContent activeTab={tab} className="pt-4 pb-5 pl-1 pr-1">
				{ tabPanes[tab] } 
			</TabContent>
		</Container>
	);
};
const PersistTab = (props) => {
	return (
	<TabPane tabId="persist">
		<Row>
			<Col>
				{props.link && <h4 className="mb-5 mt-2"><a href={props.link}>Save this link to come back later</a></h4> }
				<Browser persisterName="fm2" inputUser={props.inputUser} updateInputUser={props.updateInputUser} onStorageUpdated={props.storageUpdated} />
				<div className="mt-5">
					<File inputUser={props.inputUser} updateInputUser={props.updateInputUser} />
				</div>
			</Col>
		</Row>
	</TabPane>);
};
export default withRouter(Recommender);