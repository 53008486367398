import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isBelowOptimal, isBelowStandard, getDefaultDosage} from '../utilities';


import image1 from './gabarestore1.jpg';
//import doc1 from './gabarestore1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.anxiety.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.anxiety.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if anxiety increase value 75 */
	if(isYes(inputUser, UserInput.types.stress.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.stress.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if stress increase value 50 */
	if(isYes(inputUser, UserInput.types.cantFallAsleep.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.cantFallAsleep.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if cant fall asleep increase value 100 */
	if(inputUser[UserInput.types.fatigue.key] === "mild" || inputUser[UserInput.types.fatigue.key] === "moderate" || inputUser[UserInput.types.fatigue.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.fatigue.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if fatigue increase value
	mild - increase value 50
	moderate - increase 75
	severe - increase 100 */
	if(isBelowOptimal(inputCalculated, CalculatedInput.types.lithiumRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.lithiumRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/*  if lithium is below optimal increase value 100
	if below standard increaes value 150*/
	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(isYes(inputUser, UserInput.types.anxiety.key)){
		sortValue -= 75;
	}/* if anxiety increase value 75 */
	if(isYes(inputUser, UserInput.types.stress.key)){
		sortValue -= 50;
	}/* if stress increase value 50 */
	if(isYes(inputUser, UserInput.types.cantFallAsleep.key)){
		sortValue -= 100;
	}/* if cant fall asleep increase value 100 */
	if(inputUser[UserInput.types.fatigue.key] === "mild"){
		sortValue -= 50;
	}else if(inputUser[UserInput.types.fatigue.key] === "moderate"){
		sortValue -= 75;
	}else if(inputUser[UserInput.types.fatigue.key] === "severe"){
		sortValue -= 100;
	}/* if fatigue increase value
	mild - increase value 50
	moderate - increase 75
	severe - increase 100 */

	if(isBelowStandard(inputCalculated, CalculatedInput.types.lithiumRange.key)){
		sortValue -= 150;
	}else if(isBelowOptimal(inputCalculated, CalculatedInput.types.lithiumRange.key)){
		sortValue -= 100;
	}/*  if lithium is below optimal increase value 100
	if below standard increaes value 150*/
	return sortValue;
};
const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.anxiety.key)){ toReturn.push(UserInput.types.anxiety); }
	if(isUnanswered(inputUser,UserInput.types.stress.key)){ toReturn.push(UserInput.types.stress); }
	if(isUnanswered(inputUser,UserInput.types.cantFallAsleep.key)){ toReturn.push(UserInput.types.cantFallAsleep); }
	if(isUnanswered(inputUser,UserInput.types.fatigue.key)){ toReturn.push(UserInput.types.fatigue); }
	if(isUnanswered(inputUser,UserInput.types.lithium.key)){ toReturn.push(UserInput.types.lithium); }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	return {
		description : "1 serving, 30-60 minutes before bed.",
		servingsPerDay : 1
	};
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	Gaba Restore is designed to support the GABAergic system using specially selected nutrients, amino acids, and cofactors.* Key 
	ingredients include L-taurine and high-quality, standardized valerian root and passion flower extracts. It incorporates 
	high-potency vitamin B6 and manganese, as well as a high amount of zinc.*`;
};
const title = "Gaba Restore";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "GABA-RESTORE", price: 31, servings: 90}]
};


export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	//docs: [{ src: doc1 }]
	
};