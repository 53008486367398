export default {
    maleOrFemale : {
		key: "maleOrFemale",
		prompt : "Are you male or female?",
		possible: ["male","female"],
		group : 10, 
		priority : 10
	},
	africanAmerican : {
		key: "africanAmerican",
		prompt : "Are you African American?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	femaleCycle : {
		key: "femaleCycle",
		prompt : "Do you still have a cycle/period?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	age : {
		key: "age",
		prompt : "Age",
		units : "years",
		type : "number",
		group : 10, 
		priority : 10
	},
	heartDiseaseFamilyHistory : {
		key: "heartDiseaseFamilyHistory",
		prompt: "Family history of heart disease?",
		possible: ["no","yes"],
		group : 3,
		priority : 10
	}
}