import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(inputUser[UserInput.types.yellowEyesSeverity.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.yellowEyesSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
    if(isYes(inputUser, UserInput.types.difficultySwallowing.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.difficultySwallowing.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
    if(isYes(inputUser, UserInput.types.throatHoarsness.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.throatHoarsness.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
    if(inputUser[UserInput.types.verticalNailRidges.key] === "moderate"
	|| inputUser[UserInput.types.verticalNailRidges.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.verticalNailRidges.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.yellowEyesSeverity.key)){
		toReturn.push(UserInput.types.yellowEyesSeverity);
    }
    if(isUnanswered(inputUser, UserInput.types.difficultySwallowing.key)){
		toReturn.push(UserInput.types.difficultySwallowing);
    }
    if(isUnanswered(inputUser, UserInput.types.throatHoarsness.key)){
		toReturn.push(UserInput.types.throatHoarsness);
    }
    if(isUnanswered(inputUser, UserInput.types.verticalNailRidges.key)){
        toReturn.push(UserInput.types.verticalNailRidges);
	}
	return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "Talk To Your Doctor",
	getDosage,
	category : categories.behaviors,
	getSortValue : getDefaultSortValue
};