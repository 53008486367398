import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isBelowOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';



const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isBelowOptimal(inputCalculated, CalculatedInput.types.dheasMaleRange.key)
		//|| isBelowOptimal(inputCalculated, CalculatedInput.types.dheasFemaleRange.key)
	){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.dheasMaleRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.maleOrFemale.key)){
        toReturn.push(UserInput.types.maleOrFemale);
    }else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
        if(isUnanswered(inputUser, UserInput.types.dheasMale.key)){
            toReturn.push(UserInput.types.dheasMale);
        }
    }else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
        // if(isUnanswered(inputUser, UserInput.types.dheasFemale.key)){
        //     toReturn.push(UserInput.types.dheasFemale);
        // }
    }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(isBelowOptimal(inputCalculated, CalculatedInput.types.dheasMaleRange.key)
		//|| isBelowOptimal(inputCalculated, CalculatedInput.types.dheasFemaleRange.key)
	){
		return "Retest in 2 months";
	}else{
		return "";
	}
};

export default {
	getEvaluation,
	getPrompts,
	title : "DHEA Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};