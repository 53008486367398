import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { getUnknownKeys, isUnanswered, isOptimal, isYes, isNo, isAboveOptimalButInStandard, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';
import { getEvaluation as getComprehensiveTestEvaluation } from './fullbloodpanel.js';

const getIsCase1 = (inputUser, inputCalculated) => {
	if(isNo(inputUser, UserInput.types.takeCholesterolLoweringMeds.key) && isAboveOptimalButInStandard(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
		return{
            recommended : true,
            inputUserKeys : [UserInput.types.takeCholesterolLoweringMeds.key],
            inputCalculatedKeys : [CalculatedInput.types.cholesterolTotalRange.key]
        }
    }else{
        return {
            recommended : false
        }
    }
};
const getIsCase2 = (inputUser, inputCalculated) => {
	if(isYes(inputUser, UserInput.types.takeCholesterolLoweringMeds.key) && isOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key) && isYes(inputUser, UserInput.types.preferStoppingCholesterolLoweringMeds.key)){
		return{
            recommended : true,
            inputUserKeys : [UserInput.types.takeCholesterolLoweringMeds.key, UserInput.types.preferStoppingCholesterolLoweringMeds.key],
            inputCalculatedKeys : [CalculatedInput.types.cholesterolTotalRange.key]
        }
    }else{
        return {
            recommended : false
        }
    }
}
const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(getComprehensiveTestEvaluation(inputUser, inputCalculated).recommended){
		return toReturn;
	}

	const isCase1 = getIsCase1(inputUser, inputCalculated);
    if(isCase1.recommended){
        toReturn.recommended = true;
		toReturn.inputUserKeys.push(...isCase1.inputUserKeys);
		toReturn.inputCalculatedKeys.push(...isCase1.inputCalculatedKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
    const isCase2 = getIsCase2(inputUser, inputCalculated);
    if(isCase2.recommended){
        toReturn.recommended = true;
		toReturn.inputUserKeys.push(...isCase2.inputUserKeys);
		toReturn.inputCalculatedKeys.push(...isCase2.inputCalculatedKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
	const unknownKeys = getUnknownKeys(inputUser, [
		UserInput.types.cholesterolTotal.key,
		UserInput.types.triglycerides.key,
		UserInput.types.ldlCholesterol.key,
		UserInput.types.hdlCholesterol.key,
		UserInput.types.vldlCholesterol.key]);
    if(unknownKeys.length > 0){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(...unknownKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.takeCholesterolLoweringMeds.key)){ toReturn.push(UserInput.types.takeCholesterolLoweringMeds); }
	if(isUnanswered(inputUser, UserInput.types.cholesterolTotal.key)){ toReturn.push(UserInput.types.cholesterolTotal); }
	if(isYes(inputUser, UserInput.types.takeCholesterolLoweringMeds.key) && isOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
		if(isUnanswered(inputUser, UserInput.types.preferStoppingCholesterolLoweringMeds.key)){
			toReturn.push(UserInput.types.preferStoppingCholesterolLoweringMeds);
		}
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	const isCase2 = getIsCase2(inputUser, inputCalculated);
    if(isCase2.recommended){
		return "Test in 30 days if reducing cholesterol lowering meds.  Talk to your doctor about reducing meds.";
	}else{
		return "Test in 60 days after starting supplement program.";
	}
};

export default {
	getEvaluation,
	getPrompts,
	title : "Lipids Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};