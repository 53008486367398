import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, isNo} from '../utilities';

const isHashimotos = (inputUser, inputCalculated) => {
    if(isYes(inputUser, UserInput.types.lowTemperature.key)){
        if(isYes(inputUser, UserInput.types.lowThyroid.key)){
            if(isYes(inputUser, UserInput.types.hashimotos.key)){
                return true;
            }
        }
    }
    return false;
};
const isNonHashimotos = (inputUser, inputCalculated) => {
    if(isNo(inputUser, UserInput.types.lowTemperature.key)){
        return true;
    } else if(isYes(inputUser, UserInput.types.lowTemperature.key)){
        if(isNo(inputUser, UserInput.types.lowThyroid.key)){
            return true;
        }if(isYes(inputUser, UserInput.types.lowThyroid.key)){
            if(isNo(inputUser, UserInput.types.hashimotos.key)){
                return true;
            }
        }
    }
    return false;
};
const getHashimotosPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.lowTemperature.key)){
        toReturn.push(UserInput.types.lowTemperature);
    }else if(isYes(inputUser,UserInput.types.lowTemperature.key)){
        if(isUnanswered(inputUser, UserInput.types.lowThyroid.key)){
            toReturn.push(UserInput.types.lowThyroid);
        }else if(isYes(inputUser,UserInput.types.lowThyroid.key)){
            if(isUnanswered(inputUser, UserInput.types.hashimotos.key)){
                toReturn.push(UserInput.types.hashimotos);
            }
        }
    }
    return toReturn;
};
const getNonHashimotosPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.lowTemperature.key)){
        toReturn.push(UserInput.types.lowTemperature);
    }else if(isYes(inputUser,UserInput.types.lowTemperature.key)){
        if(isUnanswered(inputUser, UserInput.types.lowThyroid.key)){
            toReturn.push(UserInput.types.lowThyroid);
        }else if(isYes(inputUser,UserInput.types.lowThyroid.key)){
            if(isUnanswered(inputUser, UserInput.types.hashimotos.key)){
                toReturn.push(UserInput.types.hashimotos);
            }
        }
    }
    return toReturn;
};

export default {
    isHashimotos,
    isNonHashimotos,
    getHashimotosPrompts,
    getNonHashimotosPrompts
};