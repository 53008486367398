import drinkmorewater from './drinkmorewater.js';
import talkpotassium from './talkpotassium.js';
import talkiron from './talkiron.js';
import reduceiron from './reduceiron.js';
import increasehealthyfats from './increasehealthyfats.js';
import talkcholesterolmeds from './talkcholesterolmeds.js';
import talkthyroidmeds from './talkthyroidmeds.js';
import reducestress from './reducestress.js';
import talkthyroidresults from './talkthyroidresults.js';
import talkfunctionalmedicine from './talkfunctionalmedicine.js';
import reducevitamind from './reducevitamind.js';
import talkoptometrist from './talkoptometrist.js';
import talkdoctor from './talkdoctor.js';
import talkentdoctor from './talkentdoctor.js';
import sinusrinses from './sinusrinses.js';
import infraredsauna from './infraredsauna.js';
import saltwaterrinses from './saltwaterrinses.js';
import dentalcheckup from './dentalcheckup.js';
import oilpulling from './oilpulling.js';
import teatreeoilonetoes from './teatreeoilontoes.js';

//import  from './.js';


export default [
	drinkmorewater,
	talkpotassium,
	talkiron,
	reduceiron,
	increasehealthyfats,
	talkcholesterolmeds,
	talkthyroidmeds,
	reducestress,
	talkthyroidresults,
	talkfunctionalmedicine,
	reducevitamind,
	talkoptometrist,
	talkdoctor,
	talkentdoctor,
	sinusrinses,
	infraredsauna,
	saltwaterrinses,
	dentalcheckup,
	oilpulling,
	teatreeoilonetoes
];