import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import thyroid from '../helpers/thyroid.js';
import { isUnanswered, isAboveOptimal, isNo, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isBelowStandard } from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
  let toReturn = getDefaultEvaluation();
  if (isYes(inputUser, UserInput.types.nausea.key)) {
    toReturn.recommended = true;
    toReturn.inputUserKeys.push(UserInput.types.nausea.key);
    toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
  }
  if (isYes(inputUser, UserInput.types.vomiting.key)) {
    toReturn.recommended = true;
    toReturn.inputUserKeys.push(UserInput.types.vomiting.key);
    toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
  }
  if (inputUser[UserInput.types.bloatingAfterMeals.key] === "after mostly sugar or carbohydrate meals") {
    toReturn.recommended = true;
    toReturn.inputUserKeys.push(UserInput.types.bloatingAfterMeals.key);
    toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
  }

  if (thyroid.isHashimotos(inputUser, inputCalculated)) {
    if (isAboveOptimal(inputCalculated, CalculatedInput.types.thyroidPeroxidaseTPOAbsLABCORPRange.key)) {
      toReturn.recommended = true;
      toReturn.inputCalculatedKeys.push(CalculatedInput.types.thyroidPeroxidaseTPOAbsLABCORPRange.key);
      toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat(["Hashimotos: true"]);
    } else if (isAboveOptimal(inputCalculated, CalculatedInput.types.thyroglobulinAbsRange.key)) {
      toReturn.recommended = true;
      toReturn.inputCalculatedKeys.push(CalculatedInput.types.thyroglobulinAbsRange.key);
      toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat(["Hashimotos: true"]);
    }
  }
  if (isBelowStandard(inputCalculated, CalculatedInput.types.potassiumRange.key)) {
    toReturn.recommended = true;
    toReturn.inputCalculatedKeys.push(CalculatedInput.types.potassiumRange.key);
    toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
  }

  if (isNo(inputUser, UserInput.types.allergies.key) && isYes(inputUser, UserInput.types.pluggedEars.key)) {
    toReturn.recommended = true;
    toReturn.inputUserKeys.push(UserInput.types.allergies.key);
    toReturn.inputUserKeys.push(UserInput.types.pluggedEars.key);
    toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
  }

  if (isYes(inputUser, UserInput.types.difficultySwallowing.key)) {
    toReturn.recommended = true;
    toReturn.inputUserKeys.push(UserInput.types.difficultySwallowing.key);
    toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
  }
  if (isYes(inputUser, UserInput.types.throatHoarsness.key)) {
    toReturn.recommended = true;
    toReturn.inputUserKeys.push(UserInput.types.throatHoarsness.key);
    toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
  }
  if (inputUser[UserInput.types.verticalNailRidges.key] === "moderate"
    || inputUser[UserInput.types.verticalNailRidges.key] === "severe") {
    toReturn.recommended = true;
    toReturn.inputUserKeys.push(UserInput.types.verticalNailRidges.key);
    toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
  }
  return toReturn;
}

const getPrompts = (inputUser, inputCalculated) => {
  let toReturn = [];
  toReturn.push(...thyroid.getHashimotosPrompts(inputUser, inputCalculated));
  if (thyroid.isHashimotos(inputUser, inputCalculated)) {
    if (isUnanswered(inputUser, UserInput.types.thyroidPeroxidaseTPOAbsLABCORP.key)) {
      toReturn.push(UserInput.types.thyroidPeroxidaseTPOAbsLABCORP);
    }
    if (isUnanswered(inputUser, UserInput.types.thyroglobulinAbs.key)) {
      toReturn.push(UserInput.types.thyroglobulinAbs);
    }
  }

  if (isUnanswered(inputUser, UserInput.types.nausea.key)) {
    toReturn.push(UserInput.types.nausea);
  }
  if (isUnanswered(inputUser, UserInput.types.vomiting.key)) {
    toReturn.push(UserInput.types.vomiting);
  }
  if (isUnanswered(inputUser, UserInput.types.bloatingAfterMeals.key)) {
    toReturn.push(UserInput.types.bloatingAfterMeals);
  }
  if (isUnanswered(inputUser, UserInput.types.potassium.key)) {
    toReturn.push(UserInput.types.potassium);
  }
  if (isUnanswered(inputUser, UserInput.types.allergies.key)) {
    toReturn.push(UserInput.types.allergies);
  } else if (isNo(inputUser, UserInput.types.allergies.key) && isUnanswered(inputUser, UserInput.types.pluggedEars.key)) {
    toReturn.push(UserInput.types.pluggedEars);
  }
  if (isUnanswered(inputUser, UserInput.types.difficultySwallowing.key)) {
    toReturn.push(UserInput.types.difficultySwallowing);
  }
  if (isUnanswered(inputUser, UserInput.types.throatHoarsness.key)) {
    toReturn.push(UserInput.types.throatHoarsness);
  }
  if (isUnanswered(inputUser, UserInput.types.verticalNailRidges.key)) {
    toReturn.push(UserInput.types.verticalNailRidges);
  }
  return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
  let toReturn = "";
  return toReturn;
};

export default {
  getEvaluation,
  getPrompts,
  title: "Follow Up With A Functional Medicine Doctor",
  getDosage,
  category: categories.behaviors,
  getSortValue: () => getDefaultSortValue() - 1
};