import React, { useState } from 'react';
import View from './View';
import { ListGroup, ListGroupItem, Button } from 'reactstrap';



const OutputCategoryView = (props) => {

	const [lowPriority] = useState(props.lowPriority || 0);
	const [targetRecommendations] = useState(props.targetRecommendations || 5);
	const [showAllRecommendations, setShowAllRecRecommendations] = useState(props.showAllRecommendations, false);

	const getRelation = (reasons, reasonsToCompare) => {
		const intersection = (reasons || []).filter(x => reasonsToCompare.some(y => y.prompt === x.prompt && y.answer === x.answer));
		if(intersection.length === 0){
			return "NONE";
		}
		if(intersection.length === reasons.length){
			if(intersection.length === reasonsToCompare.length) {
				return "EQUAL";
			}else if(intersection.length < reasonsToCompare.length){
				return "MORE";
			}
		}
		if(intersection.length === reasonsToCompare.length){
			return "LESS";
		}
		return "SOME";
	};
	const clarifyOutputRecommendations = (allOutputRecommendations) => {
		return  allOutputRecommendations
			//.filter((outRec) => outRec.evaluation !== undefined && outRec.output !== undefined)
			.map(outRec => {
				const related = allOutputRecommendations
				.map((rel, i) => {
					return {
					...rel,
					relation : getRelation(outRec.evaluation?.reasons, rel.evaluation?.reasons)}
				})
				.filter(posRel => 
					posRel.output?.title !== outRec.output?.title &&
					posRel.relation !== "NONE"
					);
				return {
					...outRec,
					sortValue: outRec.output?.getSortValue(props.inputUser, props.inputCalculated) + getSortValueModifier(outRec, related),
					related
				};
			});
	};

	const getSortValueModifier = (item, related) => {
		let relatedModifier = 0;
		if(related !== undefined){
			relatedModifier += related.some(x => x.relation==="MORE") ? 300 : 0;
			/*
			(related === undefined ? 0 : (related.some(x => x.relation==="MORE") ? 300 : 0) + 
			(related === undefined ? 0 : (related.some(x => x.relation==="EQUAL") ? 300 : 0)
			*/
		}


		return (item.output.getPrices !== undefined ? -10 : 10) +
			(item.output.images !== undefined ? -3 : 3) +
			(item.output.docs !== undefined ? -2 : 2) +
			(item.evaluation.reasons !== undefined ? item.evaluation.reasons.length * -5 : 0) + 
			relatedModifier;
	};

	//props.outputCategoryItems;

	
	const filteredOutputCategoryItems = props.inputUser === undefined ? props.outputCategoryItems : clarifyOutputRecommendations(props.outputCategoryItems)
		.sort((a,b) => (a.sortValue - b.sortValue));


	if(filteredOutputCategoryItems !== undefined && filteredOutputCategoryItems.length > 0){
		return(
			<ListGroup id={props.title}>
				<h2 className="mt-5 m-2 p-3 text-success border-success" style={{ borderWidth : '1px', borderStyle : 'solid'}}>{props.title}</h2>
				{props.description !== undefined ? <p className="m-3 p-3">{props.description}</p> : null }
				{filteredOutputCategoryItems.map((rec, i) => {
						// let subTitle = "", dosageTotalPerDay = 1;
						// if(rec.output.getDosage !== undefined){
						// 	const dosage = rec.output.getDosage(props.inputUser, props.inputCalculated);
						// 	if(dosage!==undefined && dosage.description !== undefined){
						// 		subTitle = dosage.description;
						// 	}
						// }
						const dosage = rec.output.getDosage !== undefined ? rec.output.getDosage(props.inputUser, props.inputCalculated) : {};
						return (
							<View 
							key={i}
							title={rec.output.title}
							description={rec.output.getDescription === undefined ? "" : rec.output.getDescription(props.inputUser, props.inputCalculated)}
							prices={rec.output.getPrices === undefined ? [] : rec.output.getPrices(props.inputUser, props.inputCalculated)}
							reasons={rec.evaluation.reasons} 
							related={rec.related}
							images={rec.output.images}
							docs={rec.output.docs}
							priority={rec.sortValue}
							subtitle={dosage?.description}
							servingsPerDay={dosage?.servingsPerDay}
							cart={props.cart}
							getQtyAndPrice={props.getQtyAndPrice}
							addToCart={props.addToCart}
							debug={props.debug}
							minimized={!(showAllRecommendations || i < targetRecommendations || rec.sortValue < lowPriority)}
							/>
							);
				})}
				{/* <p className="m-5">
					<center>
				{!showAllRecommendations && filteredOutputCategoryItems.length > targetRecommendations ? 
					<Button color="info" className="p-3" onClick={() => setShowAllRecRecommendations(true)} >See all lower priority/alternative recommendations</Button> : 
					filteredOutputCategoryItems.length > targetRecommendations ?
						<Button color="info" className="p-3" onClick={() => setShowAllRecRecommendations(false)} >Hide all lower priority/alternative recommendations</Button> :
						null }
					</center>
				</p> */}
			</ListGroup>
		);
	}else{
		return null;
		// (
		// 	<ListGroup className="mb-5">
		// 		<h2>{props.title}</h2>
		// 		<ListGroupItem>No Recommendations.</ListGroupItem>
		// 	</ListGroup>
		// );
	}
};
export default OutputCategoryView;