import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.highBloodPressure.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.highBloodPressure.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(isYes(inputUser, UserInput.types.highBloodPressure.key)){
		sortValue -= 150;
	}
	return sortValue;
};
// Craig Todo: Need to discuss blood pressure question.  Should we go straight to ranges instead of 2 questions?
/* if yes to high blood pressure add in question to ask about what typical reading is
1. above 140/90 - rec 1 capsule per day, if yes increase value by 100
2. above 160/100 -rec 1 capsuel 2 times per day, if yes increase value by 200, also recommend follow up with primary dr
or functional medicine dr*/
const getPrompts = (inputUser, inputCalculated) => {
	var toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.highBloodPressure.key)){
		toReturn.push(UserInput.types.highBloodPressure);
	}
	return toReturn;
};

const getDosage = getDefaultDosage;
const getDescription = (inputUser, inputCalculated) => {
	return `
	Nattokinase is an all-natural, potent enzyme that is extracted from natto and highly purified. Natto is a fermented soybean 
	food that has been consumed in Japan for over 1000 years. Nattokinase has been the subject of many studies, including human 
	and animal trials. In 1980, researchers discovered that nattokinase demonstrated a positive effect on blood flow in vitro. 
	Nattokinase may also support cardiovascular health.*`;
};
const title = "NattoKinase";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "NATTOKINASE", price: 29}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices
	
};