import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isYes, isBelowOptimalButInStandard, isBelowStandard, isBelowOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isAboveStandard, isAboveOptimal, getDefaultDosage} from '../utilities';

import image1 from './testobalance1.png';
import doc1 from './testobalance1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		let reasonInputUserKeys = [];
		let reasonInputCalculatedKeys = [];
		if(inputUser[UserInput.types.hormoneProblems.key] === "mild"
			|| inputUser[UserInput.types.hormoneProblems.key] === "moderate"
			|| inputUser[UserInput.types.hormoneProblems.key] === "severe"){
			reasonInputUserKeys.push(UserInput.types.hormoneProblems.key);
		}
		
		if(isYes(inputUser, UserInput.types.lackOfSexDrive.key)){
			reasonInputUserKeys.push(UserInput.types.lackOfSexDrive.key);
		}
		if(isYes(inputUser, UserInput.types.troubleGainingMuscle.key)){
			reasonInputUserKeys.push(UserInput.types.troubleGainingMuscle.key);
		}
		if(isYes(inputUser, UserInput.types.troubleLosingWeight.key)){
			reasonInputUserKeys.push(UserInput.types.troubleLosingWeight.key);
		}
		if(isBelowOptimal(inputCalculated, CalculatedInput.types.testosteroneTotalMaleRange.key)){
			reasonInputCalculatedKeys.push(CalculatedInput.types.testosteroneTotalMaleRange.key);
		}
		if(isBelowOptimal(inputCalculated, CalculatedInput.types.testosteroneFreeMaleRange.key)){
			reasonInputCalculatedKeys.push(CalculatedInput.types.testosteroneFreeMaleRange.key);
		}
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.sexHormoneBindingGlobulinMaleRange.key)){
			reasonInputCalculatedKeys.push(CalculatedInput.types.sexHormoneBindingGlobulinMaleRange.key);
		}


		if(reasonInputUserKeys.length > 0 || reasonInputCalculatedKeys.length > 0){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputUserKeys.push(...reasonInputUserKeys);
			toReturn.inputCalculatedKeys.push(...reasonInputCalculatedKeys);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}


	} else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isYes(inputUser, UserInput.types.lackOfSexDrive.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputUserKeys.push(UserInput.types.lackOfSexDrive.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.sexHormoneBindingGlobulinFemaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.sexHormoneBindingGlobulinFemaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}
	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(inputUser[UserInput.types.hormoneProblems.key] === "mild"){
			sortValue -= 50;
		}else if (inputUser[UserInput.types.hormoneProblems.key] === "moderate"){
			sortValue -= 100;
		}else if (inputUser[UserInput.types.hormoneProblems.key] === "severe"){
			sortValue -= 150;
		}

			/*  if male and yes to hormone problems
			mild - increaes value 50
			moderate - increase value 100
			severe - increase value 150*/
		
		
		if(isYes(inputUser, UserInput.types.lackOfSexDrive.key)){
			sortValue -= 150;
			/* if lack of sex drive/lowlibido increase value
			for males - 150
			females - 75 */
		}
		if(isYes(inputUser, UserInput.types.troubleGainingMuscle.key)){
			sortValue -= 100;
			/* if male and yes to trouble gaining weight increase value 100
			if female and yes to trouble gaining weight increase value 50 */
		}
		if(isYes(inputUser, UserInput.types.troubleLosingWeight.key)){
			sortValue -= 100;
		}/* if male and trouble losing weight increase value 100 */


		/*
		if male labs Total testosterone or free testosterone below optimal range increaes value 150
		if below standard range increase value 300 */
		if(isBelowStandard(inputCalculated, CalculatedInput.types.testosteroneTotalMaleRange.key)){
			sortValue -= 300;
		} else if(isBelowOptimal(inputCalculated, CalculatedInput.types.testosteroneTotalMaleRange.key)){
			sortValue -= 150;
		}
		if(isBelowStandard(inputCalculated, CalculatedInput.types.testosteroneFreeMaleRange.key)){
			sortValue -= 300;
		} else if(isBelowOptimal(inputCalculated, CalculatedInput.types.testosteroneFreeMaleRange.key)){
			sortValue -= 150;
		}
		
		/*if Male sex hormone binding globulin above optimal increase value 50, if above standard increase value 100 */
		if(isAboveStandard(inputCalculated, CalculatedInput.types.sexHormoneBindingGlobulinMaleRange.key)){
			sortValue -= 100;
		} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.sexHormoneBindingGlobulinMaleRange.key)){
			sortValue -= 50;
		}
		
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isYes(inputUser, UserInput.types.lackOfSexDrive.key)){
			sortValue -= 75;
			/* if lack of sex drive/lowlibido increase value
			for males - 150
			females - 75 */
		}
		if(isYes(inputUser, UserInput.types.troubleGainingMuscle.key)){
			sortValue -= 50;
			/* if male and yes to trouble gaining weight increase value 100
			if female and yes to trouble gaining weight increase value 50 */
		}
		/*
		if female and yes to decreased libido/sex drive and sex hormone binding globulin above optimal or standard increase value 50*/
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.sexHormoneBindingGlobulinFemaleRange.key)){
			sortValue -= 50;
		}
	}
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
	var toReturn = [];

	

	if(isUnanswered(inputUser,UserInput.types.maleOrFemale.key)){
		toReturn.push(UserInput.types.maleOrFemale);
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isUnanswered(inputUser, UserInput.types.hormoneProblems.key)){
			toReturn.push(UserInput.types.hormoneProblems);
		}
		if(isUnanswered(inputUser,UserInput.types.testosteroneTotalMale.key)){
			toReturn.push(UserInput.types.testosteroneTotalMale);
		}
		if(isUnanswered(inputUser, UserInput.types.lackOfSexDrive.key)){
			toReturn.push(UserInput.types.lackOfSexDrive);
		}
		if(isUnanswered(inputUser, UserInput.types.troubleGainingMuscle.key)){
			toReturn.push(UserInput.types.troubleGainingMuscle);
		}
		if(isUnanswered(inputUser, UserInput.types.troubleLosingWeight.key)){
			toReturn.push(UserInput.types.troubleLosingWeight);
		}
		if(isUnanswered(inputUser, UserInput.types.testosteroneTotalMale.key)){
			toReturn.push(UserInput.types.testosteroneTotalMale);
		}
		if(isUnanswered(inputUser, UserInput.types.testosteroneFreeMale.key)){
			toReturn.push(UserInput.types.testosteroneFreeMale);
		}
		if(isUnanswered(inputUser, UserInput.types.sexHormoneBindingGlobulinMale.key)){
			toReturn.push(UserInput.types.sexHormoneBindingGlobulinMale);
		}
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isUnanswered(inputUser, UserInput.types.lackOfSexDrive.key)){
			toReturn.push(UserInput.types.lackOfSexDrive);
		}
		if(isUnanswered(inputUser, UserInput.types.troubleGainingMuscle.key)){
			toReturn.push(UserInput.types.troubleGainingMuscle);
		}
		if(isUnanswered(inputUser, UserInput.types.sexHormoneBindingGlobulinFemale.key)){
			toReturn.push(UserInput.types.sexHormoneBindingGlobulinFemale);
		}
	}
	return toReturn;
};
const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isBelowStandard(inputCalculated, CalculatedInput.types.testosteroneTotalMaleRange.key)){
			return {
				description : "2 servings in the morning.",
				servingsPerDay : 2
			};
		}else if(isYes(inputUser, UserInput.types.lackOfSexDrive.key)){
			return {
				description : "2 servings in the morning.",
				servingsPerDay : 2
			};
		}else if(isYes(inputUser, UserInput.types.troubleGainingMuscle.key)){
			return {
				description : "2 servings in the morning.",
				servingsPerDay : 2
			};
		}else if(isYes(inputUser, UserInput.types.troubleLosingWeight.key)){
			return {
				description : "2 servings in the morning.",
				servingsPerDay : 2
			};
		}else if(isBelowOptimalButInStandard(inputCalculated, CalculatedInput.types.testosteroneTotalMaleRange.key)){
			return {
				description : "1 serving in the morning.",
				servingsPerDay : 1
			};
		}
	}else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isYes(inputUser, UserInput.types.lackOfSexDrive.key)){
			return {
				description : "1 serving in the morning.",
				servingsPerDay : 1
			};
		}
	}
	return {
		description : "1 serving in the morning.",
		servingsPerDay : 1
	};
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	Testo Balance features two safe, clinically-tested, standardized, and patented ingredients 
	designed to support vitality and general physical and mental well-being in men and women. 
	Numerous studies have demonstrated that PrimaVie® shilajit and LJ100® Eurycoma longifolia 
	support healthy androgen biosynthesis, which includes modulating the influence of sex 
	hormone-binding globulin.*`;
};
const title = "Testo Balance";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00017", price: 59, servings: 60}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }],
};