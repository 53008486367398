//import efa from './efa.js'; see eveneng primrose oil
import neurostream from './neurostream.js';
import pmsease from './pmsease.js';
import testobalance  from './testobalance.js';
import magneuro3 from './magneuro3';
import glucosupport from './glucosupport.js';
import kidneykorect from './kidneykorrect.js';
import digestright from './digestright.js';
import curcumax from './curcumax.js';
import essentialnutrientswithoutiron from './essentialnutrientswithoutiron.js';
import ironglycinate from './ironglycinate.js';
import coq10 from './coq10.js';
import redyeastrice from './redyeastrice.js';
import omegamax from './omegamax.js';
import niacinsr from './niacinsr.js';
import selenium from './selenium.js';
import thyroidbalance from './thyroidbalance.js';
//import vitamindliquid from './vitamindliquid.js';
import vitamindcapsules from './d35000.js';
import bcomplete from './bcomplete.js';
import dhea from './dhea.js';
import immunobase from './immunobase.js';
import garlix from './garlic.js';
import allercalm from './allercalm.js';
import gabarestore from './gabarestore.js';
import inositol from './inositol.js';
import seropromote from './seropromote.js';
import acetylblend from './acetylblend.js';
import doparefresh from './doparefresh.js';
import llysine from './llysine.js';
import fungalformula from './fungalformula.js';
import utiflowclear from './utiflowclear.js';
import prostateguard from './prostateguard.js';
import dim3supreme from './dim3supreme.js';
import chastetree from './chastetree.js';
import dongquai from './dongquai.js';
import nattokinase from './nattokinase.js';
import sleepez from './sleepez.js';
import cortisolbalancer from './cortisolbalancer.js';
import dailycalm from './dailycalm.js';
import activatedcharcoal from './activatedcharcoal.js';
import bmassist from './bmassist.js';
import fiberformula from './fiberformula.js';
import girenew from './girenew.js';
import gimicrobx from './biomebalance.js';
import inflamaway from './inflamaway.js';
//import probiotics from './probiotics.js';
import candidaformula from './candidaformula.js';
import neurosupport from './neurosupport.js';
import bilemin from './bilebooster.js';
import biozyme from './biozyme.js';
import probiocomplete from './probiocomplete.js';
import probio100 from './probio100.js';
import probio30 from './probio30.js';
import vitaminc from './vitaminc.js'
import calciumdglucarate from './calciumdglucarate.js';
import eveningprimroseoil from './eveningprimroseoil.js';
import zinc from './zinc.js';
//import  from './.js';


export default [
	//efa,
	neurostream,
	pmsease,
	testobalance,
	magneuro3,
	glucosupport,
	kidneykorect,
	digestright,
	curcumax,
	essentialnutrientswithoutiron,
	ironglycinate,
	coq10,
	redyeastrice,
	omegamax,
	niacinsr,
	selenium,
	thyroidbalance,
//	vitamindliquid,
	vitamindcapsules,
	bcomplete,
	dhea,
	immunobase,
	garlix,
	allercalm,
	gabarestore,
	inositol,
	seropromote,
	acetylblend,
	doparefresh,
	llysine,
	utiflowclear,
	fungalformula,
	prostateguard,
	dim3supreme,
	chastetree,
	dongquai,
	nattokinase,
	sleepez,
	cortisolbalancer,
	dailycalm,
	activatedcharcoal,
	bmassist,
	fiberformula,
	girenew,
// probiotics,
	gimicrobx,
	inflamaway,
	candidaformula,
	neurosupport,
	bilemin,
	biozyme,
	probiocomplete,
	probio100,
	probio30,
	vitaminc,
	calciumdglucarate,
	eveningprimroseoil,
	zinc
];