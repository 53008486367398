// import UserInput from '../../inputs/user/index.js';
// import CalculatedInput from '../../inputs/calculated/index.js';
import { getDefaultSortValue, getDefaultEvaluation, categories, getDefaultDosage} from '../utilities';

import image1 from './probiocomplete1.png';
import doc1 from './probiocomplete1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	return toReturn;
};


const getDosage = getDefaultDosage;

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	return sortValue;
};

const getDescription = (inputUser, inputCalculated) => {
	return `Probio Complete DF is a vegetarian, gluten- and dairy-free blend of diverse, well-researched, 
	identity-certified HOWARU® and FloraFit® probiotic strains totaling 45 billion CFUs per dose. 
	Delivered in gastro-resistant capsules, this formula is targeted to support a healthy microbiome. 
	Each strain is well-researched and identity-verified and has been genetically characterized and 
	properly classified for safety and assurance. Nitrogen-purged blister packaging further assures 
	the viability of these strains, which were not only selected for their health benefits and 
	complementary actions but also for their viability and stability.*`;
};
const title = "Probio Complete DF";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00044", price: 57, servings: 30}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};