import serializer from './serializer.js';

export const saveToLocalStorage = (storageNamespace, key, jsonObject) => {
	localStorage.setItem(storageNamespace + key, serializer.serialize(jsonObject));
	addKey(storageNamespace, key);
	return true;
};
export const loadFromLocalStorage = (storageNamespace, key) => {
	return serializer.deserialize(localStorage.getItem(storageNamespace + key));
};
export const getKeys = (storageNamespace) => {
	return new Set(serializer.deserialize(localStorage.getItem(storageNamespace + "Keys")));
};
export const saveKeys = (storageNamespace, keys) => {
	localStorage.setItem(storageNamespace + "Keys", serializer.serialize([...keys]));
};
export const addKey = (storageNamespace, key) => {
	let keys = getKeys(storageNamespace);
	keys.add(key);
	saveKeys(storageNamespace, keys);
};
export const deleteKey = (storageNamespace, key) => {
	let keys = getKeys(storageNamespace);
	keys.delete(key);
	saveKeys(storageNamespace, keys);
};

export const deleteItemFromLocalStorage = (storageNamespace, key) => {
	localStorage.removeItem(storageNamespace + key);
	deleteKey(storageNamespace, key);
	return true;
};
export const deleteFromLocalStorage = (storageNamespace) => {
	let keys = getKeys(storageNamespace);
	keys.forEach((key) => deleteItemFromLocalStorage(storageNamespace, key));
}
export default {
	saveToLocalStorage,
	loadFromLocalStorage,
	getKeys,
	deleteItemFromLocalStorage,
	deleteFromLocalStorage
};