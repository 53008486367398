import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isBelowOptimal, isBelowStandard, isAboveOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isOutsideOptimal, isOutsideStandard, isYes, isAboveStandard, getDefaultDosage} from '../utilities';


import image1 from './omegamax6501.jpg';
import image2 from './omegamax13001.jpg';

import doc1 from './omegamax6501.pdf';
import doc2 from './omegamax13001.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	// if(isNo(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
	// 	if(isBelowOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
	// 		toReturn.recommended = true;
	// 		toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
	// 		toReturn.inputCalculatedKeys.push(CalculatedInput.types.cholesterolTotalRange.key);
	// 		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	// 	}
	// }
	
	

	if(isYes(inputUser,UserInput.types.swellingInflammation.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.swellingInflammation.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser,UserInput.types.bodyPain.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bodyPain.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser,UserInput.types.skinIssues.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.skinIssues.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser,UserInput.types.heartDiseaseFamilyHistory.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.heartDiseaseFamilyHistory.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.brainFog.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.brainFog.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPFemaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.hsCRPFemale.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.esrFemaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.esrFemale.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.hsCRPMale.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.esrMaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.esrMale.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.homocysteine.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}


	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.cholesterolTotalRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.ldlCholesterolRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.ldlCholesterolRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isBelowOptimal(inputCalculated, CalculatedInput.types.hdlCholesterolRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.hdlCholesterolRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.vldlCholesterolRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.vldlCholesterolRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser,UserInput.types.dryCrackedSkin.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.dryCrackedSkin.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.eczemaPsoriasisRosacea.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	return toReturn;
};

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue() - 250;
	/* Overall increase value of Omega max by 250 */

	// if(isNo(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
	// 	if(isBelowOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
	// 		toReturn.recommended = true;
	// 		toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
	// 		toReturn.inputCalculatedKeys.push(CalculatedInput.types.cholesterolTotalRange.key);
	// 		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	// 	}
	// }

	/*
		
		add the following triggers to recommend OmegaMax
		if any of the following complaints or yes answer trigger Omega max with corresponding increase in value
		complaint of inflammation - increase value 100
		complaint of pain increase value by 50
		complaint or symptoms of skin issues(any) - increase value 50
		history or family history of heart disease increase value 100
		complaint or symptoms of brain fog or mental fatigue increase value 100
		If CRP on labs is above optimal -rec 1 omegamax epr day - increase value 150
		if CRP on labs is above standard - rec 1 capsule 2 times per day - increase value 300
		if ESR or Homocysteine on labs above optimal or standard rec 1 capsule per day - increase value 100
	*/

	if(isYes(inputUser,UserInput.types.swellingInflammation.key)){
		sortValue -= 100;
	}
	if(isYes(inputUser,UserInput.types.bodyPain.key)){
		sortValue -= 50;
	}
	if(isYes(inputUser,UserInput.types.skinIssues.key)){
		sortValue -= 50;
	}
	if(isYes(inputUser,UserInput.types.heartDiseaseFamilyHistory.key)){
		sortValue -= 100;
	}
	if(isYes(inputUser, UserInput.types.brainFog.key)){
		sortValue -= 100
	}
	if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isAboveStandard(inputCalculated, CalculatedInput.types.hsCRPFemaleRange.key)){
			sortValue -= 300;
		} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPFemaleRange.key)){
			sortValue -= 150;
		}
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.esrFemaleRange.key)){
			sortValue -= 100;
		}
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isAboveStandard(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key)){
			sortValue -= 300;
		} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key)){
			sortValue -= 150;
		}
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.esrMaleRange.key)){
			sortValue -= 100;
		}
	}
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		sortValue -= 100;
	}

	/*
	For labs
		Total cholesterol above or below optimal range increase value 150
		Total cholesterol above or below standard range increase value 250
		LDL cholesterol above optimal increase value 100
		LDL cholesterol above standard increase value 200
		HDL cholesterol below optimal increase value 150
		HDL cholesterol below optimal increase value 300
		VLDL above optimal increase value 100
		VLDL above standard range increase value 200*/

	if(isOutsideStandard(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
		sortValue -= 250;
	} else if(isOutsideOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
		sortValue -= 150;
	}
	if(isAboveStandard(inputCalculated, CalculatedInput.types.ldlCholesterolRange.key)){
		sortValue -= 200;
	} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.ldlCholesterolRange.key)){
		sortValue -= 100;
	}
	if(isBelowStandard(inputCalculated, CalculatedInput.types.hdlCholesterolRange.key)){
		sortValue -= 300;
	} else if(isBelowOptimal(inputCalculated, CalculatedInput.types.hdlCholesterolRange.key)){
		sortValue -= 150;
	}
	if(isAboveStandard(inputCalculated, CalculatedInput.types.vldlCholesterolRange.key)){
		sortValue -= 200;
	} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.vldlCholesterolRange.key)){
		sortValue -= 100;
	}

	if(isYes(inputUser,UserInput.types.dryCrackedSkin.key)){
		sortValue -= 100;
	}
	if(isYes(inputUser,UserInput.types.eczemaPsoriasisRosacea.key)){
		sortValue -= 150;
	}
	return sortValue;
};
/* Change name to Omega Max */
const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.takeCholesterolLoweringMeds.key)){
        toReturn.push(UserInput.types.takeCholesterolLoweringMeds);
    // }else if(isNo(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){		
		// if(isUnanswered(inputUser, UserInput.types.cholesterolTotal.key)){
		// 	toReturn.push(UserInput.types.cholesterolTotal);
		// }
	}
	
	if(isUnanswered(inputUser, UserInput.types.bodyPain.key)){
		toReturn.push(UserInput.types.bodyPain);
	}
	if(isUnanswered(inputUser, UserInput.types.swellingInflammation.key)){
		toReturn.push(UserInput.types.swellingInflammation);
	}
	if(isUnanswered(inputUser, UserInput.types.skinIssues.key)){
		toReturn.push(UserInput.types.skinIssues);
	}
	if(isUnanswered(inputUser, UserInput.types.heartDiseaseFamilyHistory.key)){
		toReturn.push(UserInput.types.heartDiseaseFamilyHistory);
	}
	if(isUnanswered(inputUser, UserInput.types.brainFog.key)){
		toReturn.push(UserInput.types.brainFog);
	}
	if(isUnanswered(inputUser,UserInput.types.maleOrFemale.key)){
		toReturn.push(UserInput.types.maleOrFemale);
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isUnanswered(inputUser, UserInput.types.hsCRPFemale.key)){ toReturn.push(UserInput.types.hsCRPFemale); }
		if(isUnanswered(inputUser, UserInput.types.esrFemale.key)){ toReturn.push(UserInput.types.esrFemale); }
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isUnanswered(inputUser, UserInput.types.hsCRPMale.key)){ toReturn.push(UserInput.types.hsCRPMale); }
		if(isUnanswered(inputUser, UserInput.types.esrMale.key)){ toReturn.push(UserInput.types.esrMale); }
	}	
	if(isUnanswered(inputUser, UserInput.types.homocysteine.key)){ toReturn.push(UserInput.types.homocysteine); }

	if(isUnanswered(inputUser, UserInput.types.cholesterolTotal.key)){
		toReturn.push(UserInput.types.cholesterolTotal);
	}
	if(isUnanswered(inputUser, UserInput.types.ldlCholesterol.key)){
		toReturn.push(UserInput.types.ldlCholesterol);
	}
	if(isUnanswered(inputUser, UserInput.types.hdlCholesterol.key)){
		toReturn.push(UserInput.types.hdlCholesterol);
	}
	if(isUnanswered(inputUser, UserInput.types.vldlCholesterol.key)){
		toReturn.push(UserInput.types.vldlCholesterol);
	}
	if(isUnanswered(inputUser, UserInput.types.dryCrackedSkin.key)){
		toReturn.push(UserInput.types.dryCrackedSkin);
	}
	if(isUnanswered(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){
		toReturn.push(UserInput.types.eczemaPsoriasisRosacea);
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(isBelowStandard(inputCalculated, CalculatedInput.types.hdlCholesterolRange.key) 
		|| isAboveStandard(inputCalculated, CalculatedInput.types.hsCRPFemaleRange.key)
		|| isAboveStandard(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key)){
			return {
				description : "2 servings (1300 mg), twice per day.",
				servingsPerDay : 4
			};
	}else{
		return {
			description : "2 servings (1300 mg) per day.",
			servingsPerDay : 2
		};
	}
	return getDefaultDosage();
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Omega Max features natural enzymatically enhanced MaxSimil® monoglyceride fish oil that has a 
	three times greater EPA/DHA absorption rate than an equivalent dose of ethyl ester fish oil. 
	Basically this means that the fish oil is pre-digested. So for those with pretty much any type 
	of health condition where you need higher absoption rates or increased needs, such as those 
	with digestive issues, this is the absolute BEST fish oil you can take. This IFOS five-star 
	certified fish oil outperforms other fish oil supplements through MaxSimil patented lipid 
	absorption enhancement technology (PLATform).*
	Our Omega Max 650 is a much smaller softgel then our Omega Max for easier swallowing.`;
};
const title = "Omega Max";
const getPrices = (inputUser, inputCalculated) => {
	return [{title: title + " 1300", sku: "00013", price: 52, servings: 120}, 
	{title: title + " 650 (smaller)", sku: "00014", price: 33, servings: 60}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 },{ src: image2 }],
	docs: [{ src: doc1 }, { src: doc2}]
};