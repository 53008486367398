import { getDefaultSortValue, categories, getDefaultEvaluation, getDefaultDosage} from '../utilities';


import image1 from './essentialnutrientswithoutiron1.jpg';
import doc1 from './essentialnutrientswithoutiron1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	toReturn.recommended = true;
	return toReturn;
};
/* increaes value 500 */
const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    return toReturn;
};

const getDosage = getDefaultDosage;
/* Change name to Essential Nutrients w/o iron*/
const getDescription = (inputUser, inputCalculated) => {
	return `
	This high-quality, hypoallergenic, multivitamin/mineral blend includes activated vitamins; 
	folate as Quatrefolic® (5-MTHF) for optimal utilization; and patented Albion® TRAACS®chelated 
	mineral complexes in vegetarian capsules. The comprehensive nutrient profile in Essential 
	Nutrients supports foundational wellness; antioxidant activity with vitamins C and E, selenium, 
	and beta-carotene; and phase I detoxification. Essential Nutrients does NOT contain Iron.*`;
};
const title = "Essential Nutrients without Iron";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00011", price: 29, servings: 120}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue : () => getDefaultSortValue() - 500,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};