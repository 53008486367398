import UserInput from '../../inputs/user/index.js';
import { isYes, isUnanswered, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(inputUser[UserInput.types.depression.key] === "mild"
		|| inputUser[UserInput.types.depression.key] === "moderate"
		|| inputUser[UserInput.types.depression.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.depression.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);		
	}

	if(isYes(inputUser, UserInput.types.anxiety.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.anxiety.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if anxiety
	mild incrase value by 50
	moderate increase value by 100
	severe increase value by 150*/

	if(isYes(inputUser, UserInput.types.stress.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.stress.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if yes to stress increase value by 25*/

	if(inputUser[UserInput.types.fatigue.key] === "mild" || inputUser[UserInput.types.fatigue.key] === "moderate" || inputUser[UserInput.types.fatigue.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.fatigue.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};/* if fatigue
mild increase value by 25
if moderate increase value by 50
severe increase valeu by 75 */


const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(inputUser[UserInput.types.depression.key] === "mild"){
		sortValue -= 50;
	}else if(inputUser[UserInput.types.depression.key] === "moderate"){
		sortValue -= 100;
	}else if(inputUser[UserInput.types.depression.key] === "severe"){
		sortValue -= 150;
	}/* if depression
	mild increase value 50
	moderate increase value 100
	severe increase value 150*/


	// Craig Todo: Convert anxiety to levels
	if(isYes(inputUser, UserInput.types.anxiety.key)){
		sortValue -= 100;
	}/* if anxiety
	mild incrase value by 50
	moderate increase value by 100
	severe increase value by 150*/

	if(isYes(inputUser, UserInput.types.stress.key)){
		sortValue -= 25
	}/* if yes to stress increase value by 25*/

	if(inputUser[UserInput.types.fatigue.key] === "mild"){
		sortValue -= 25;
	}else if(inputUser[UserInput.types.fatigue.key] === "moderate"){
		sortValue -= 50;
	}else if(inputUser[UserInput.types.fatigue.key] === "severe"){
		sortValue -= 75;
	}/* if fatigue
mild increase value by 25
if moderate increase value by 50
severe increase valeu by 75 */

	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.depression.key)){ toReturn.push(UserInput.types.depression); }
	if(isUnanswered(inputUser,UserInput.types.anxiety.key)){ toReturn.push(UserInput.types.anxiety); }
	if(isUnanswered(inputUser,UserInput.types.stress.key)){ toReturn.push(UserInput.types.stress); }
	if(isUnanswered(inputUser,UserInput.types.fatigue.key)){ toReturn.push(UserInput.types.fatigue); }
	
    return toReturn;
};

const getDosage = getDefaultDosage;

/* Also recommend this one if the patient marks anxiety, stress, adrenal fatigue */
const getDescription = (inputUser, inputCalculated) => {
	return `
	Inositol supports overall relaxation and helps maintain the proper metabolism of serotonin. It is used for the nutritional 
	support of brain wellness and female hormonal health through its role in healthy liver function. It may also aid in attaining 
	a restful night’s sleep.*`;
};
const title = "Inositol";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "INOSITOL", price: 20}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices
	
};