import Range from './Range';
import UserInput from '../../user/index';

var getRange = (inputUserKey,standardMin,optimalMin,optimalMax,standardMax,getinputUserValue) => {
    return new Range(
        inputUserKey,
        UserInput.types[inputUserKey].prompt,
        standardMin,
        optimalMin,
        optimalMax,
        standardMax,
        getinputUserValue
    )
};
const biomarkerRanges = {
    glucoseRange : getRange(UserInput.types.glucose.key,65,75,86,99,(inputUser) => inputUser[UserInput.types.glucose.key]),
    hemoglobinA1CRange : getRange(UserInput.types.hemoglobinA1C.key,0,4.6,5.5,5.7,(inputUser) => inputUser[UserInput.types.hemoglobinA1C.key]),
    insulinFastingRange : getRange(UserInput.types.insulinFasting.key,2,2,5,19,(inputUser) => inputUser[UserInput.types.insulinFasting.key]),
    fructosamineRange : getRange(UserInput.types.fructosamine.key,190,190,228,270,(inputUser) => inputUser[UserInput.types.fructosamine.key]),
    cPeptideRange : getRange(UserInput.types.cPeptide.key,.8,1.1,1.6,3.1,(inputUser) => inputUser[UserInput.types.cPeptide.key]),
    bunRange : getRange(UserInput.types.bun.key,7,10,16,25,(inputUser) => inputUser[UserInput.types.bun.key]),
    creatinineRange : getRange(UserInput.types.creatinine.key,0.4,0.8,1.1,1.5,(inputUser) => inputUser[UserInput.types.creatinine.key]),
    eGFRNonAfricanAmericanRange : getRange(UserInput.types.eGFRNonAfricanAmerican.key,60,90,120,120,(inputUser) => inputUser[UserInput.types.eGFRNonAfricanAmerican.key]),
    eGFRAfricanAmericanRange : getRange(UserInput.types.eGFRAfricanAmerican.key,60,90,120,120,(inputUser) => inputUser[UserInput.types.eGFRAfricanAmerican.key]),
    sodiumRange : getRange(UserInput.types.sodium.key,135,135,142,146,(inputUser) => inputUser[UserInput.types.sodium.key]),
    potassiumRange : getRange(UserInput.types.potassium.key,3.5,4,4.5,5.3,(inputUser) => inputUser[UserInput.types.potassium.key]),
    chlorideRange : getRange(UserInput.types.chloride.key,98,100,106,110,(inputUser) => inputUser[UserInput.types.chloride.key]),
    cO2Range : getRange(UserInput.types.cO2.key,19,25,30,30,(inputUser) => inputUser[UserInput.types.cO2.key]),
    anionGapRange : getRange(UserInput.types.anionGap.key,6,7,12,16,(inputUser) => inputUser[UserInput.types.anionGap.key]),
    uricAcidMaleRange : getRange(UserInput.types.uricAcidMale.key,3.45,3.5,5.9,8,(inputUser) => inputUser[UserInput.types.uricAcidMale.key]),
    uricAcidFemaleRange : getRange(UserInput.types.uricAcidFemale.key,2.5,3,5.5,7,(inputUser) => inputUser[UserInput.types.uricAcidFemale.key]),
    proteinTotalRange : getRange(UserInput.types.proteinTotal.key,6.1,6.9,7.4,8.1,(inputUser) => inputUser[UserInput.types.proteinTotal.key]),
    albuminRange : getRange(UserInput.types.albumin.key,3.6,4,5,5.1,(inputUser) => inputUser[UserInput.types.albumin.key]),
    globulinTotalRange : getRange(UserInput.types.globulinTotal.key,1.9,2.4,2.8,3.7,(inputUser) => inputUser[UserInput.types.globulinTotal.key]),
    calciumRange : getRange(UserInput.types.calcium.key,8.6,9.2,10,10.4,(inputUser) => inputUser[UserInput.types.calcium.key]),
    phosphorusRange : getRange(UserInput.types.phosphorus.key,2.5,3,4,4.5,(inputUser) => inputUser[UserInput.types.phosphorus.key]),
    magnesiumRange : getRange(UserInput.types.magnesium.key,1.5,2.2,2.5,2.5,(inputUser) => inputUser[UserInput.types.magnesium.key]),
    alkPhosRange : getRange(UserInput.types.alkPhos.key,35,70,100,115,(inputUser) => inputUser[UserInput.types.alkPhos.key]),
    astSGOTRange : getRange(UserInput.types.astSGOT.key,10,10,26,35,(inputUser) => inputUser[UserInput.types.astSGOT.key]),
    altSGPTRange : getRange(UserInput.types.altSGPT.key,6,10,26,29,(inputUser) => inputUser[UserInput.types.altSGPT.key]),
    ldhRange : getRange(UserInput.types.ldh.key,120,140,200,250,(inputUser) => inputUser[UserInput.types.ldh.key]),
    // documentation showed optimal min should be .1, however, Craig suggested to have it coincide with standard min
    bilirubinTotalRange : getRange(UserInput.types.bilirubinTotal.key,.2,.2,.9,1.2,(inputUser) => inputUser[UserInput.types.bilirubinTotal.key]),
    bilirubinDirectRange : getRange(UserInput.types.bilirubinDirect.key,0,0,.19,.2,(inputUser) => inputUser[UserInput.types.bilirubinDirect.key]),
    bilirubinIndirectRange : getRange(UserInput.types.bilirubinIndirect.key,.1,.1,.7,1.2,(inputUser) => inputUser[UserInput.types.bilirubinIndirect.key]),
    ggtRange : getRange(UserInput.types.ggt.key,3,10,30,70,(inputUser) => inputUser[UserInput.types.ggt.key]),
    ironSerumRange : getRange(UserInput.types.ironSerum.key,40,85,130,160,(inputUser) => inputUser[UserInput.types.ironSerum.key]),
    ferritinRange : getRange(UserInput.types.ferritin.key,10,30,70,232,(inputUser) => inputUser[UserInput.types.ferritin.key]),
    tibcRange : getRange(UserInput.types.tibc.key,250,250,350,425,(inputUser) => inputUser[UserInput.types.tibc.key]),
    transferrinSaturationRange : getRange(UserInput.types.transferrinSaturation.key,15,20,35,50,(inputUser) => inputUser[UserInput.types.transferrinSaturation.key]),
    cholesterolTotalRange : getRange(UserInput.types.cholesterolTotal.key,125,160,180,200,(inputUser) => inputUser[UserInput.types.cholesterolTotal.key]),
    triglyceridesRange : getRange(UserInput.types.triglycerides.key,0,70,80,150,(inputUser) => inputUser[UserInput.types.triglycerides.key]),
    ldlCholesterolRange : getRange(UserInput.types.ldlCholesterol.key,0,0,120,130,(inputUser) => inputUser[UserInput.types.ldlCholesterol.key]),
    hdlCholesterolRange : getRange(UserInput.types.hdlCholesterol.key,46,55,70,100,(inputUser) => inputUser[UserInput.types.hdlCholesterol.key]),
    vldlCholesterolRange : getRange(UserInput.types.vldlCholesterol.key,0,0,10,29,(inputUser) => inputUser[UserInput.types.vldlCholesterol.key]),
    tshRange : getRange(UserInput.types.tsh.key,.4,1.3,3,4.5,(inputUser) => inputUser[UserInput.types.tsh.key]),
    freeT3Range : getRange(UserInput.types.freeT3.key,2.3,3,3.5,4.2,(inputUser) => inputUser[UserInput.types.freeT3.key]),
    totalT3Range : getRange(UserInput.types.totalT3.key,76,90,168,181,(inputUser) => inputUser[UserInput.types.totalT3.key]),
    freeT4Range : getRange(UserInput.types.freeT4.key,.8,1,1.5,1.8,(inputUser) => inputUser[UserInput.types.freeT4.key]),
    totalT4Range : getRange(UserInput.types.totalT4.key,4.5,6,11.9,12,(inputUser) => inputUser[UserInput.types.totalT4.key]),
    t3UptakeRange : getRange(UserInput.types.t3Uptake.key,22,27,35,37,(inputUser) => inputUser[UserInput.types.t3Uptake.key]),
    freeThyroxineIndexT7Range : getRange(UserInput.types.freeThyroxineIndexT7.key,1.4,1.7,3.8,4.6,(inputUser) => inputUser[UserInput.types.freeThyroxineIndexT7.key]),
    reverseT3Range : getRange(UserInput.types.reverseT3.key,8,10,25,25,(inputUser) => inputUser[UserInput.types.reverseT3.key]),
    thyroidPeroxidaseTPOAbsRange : getRange(UserInput.types.thyroidPeroxidaseTPOAbs.key,0,0,6.8,9,(inputUser) => inputUser[UserInput.types.thyroidPeroxidaseTPOAbs.key]),
    thyroidPeroxidaseTPOAbsLABCORPRange : getRange(UserInput.types.thyroidPeroxidaseTPOAbsLABCORP.key,0,0,34,34,(inputUser) => inputUser[UserInput.types.thyroidPeroxidaseTPOAbsLABCORP.key]),
    thyroglobulinAbsRange : getRange(UserInput.types.thyroglobulinAbs.key,0,0,1,1,(inputUser) => inputUser[UserInput.types.thyroglobulinAbs.key]),
    hsCRPMaleRange : getRange(UserInput.types.hsCRPMale.key,0,0,0.55,2.9,(inputUser) => inputUser[UserInput.types.hsCRPMale.key]),
    hsCRPFemaleRange : getRange(UserInput.types.hsCRPFemale.key,0,0,1.5,2.9,(inputUser) => inputUser[UserInput.types.hsCRPFemale.key]),
    cReactiveProteinRange : getRange(UserInput.types.cReactiveProtein.key,0,0,1.5,2.9,(inputUser) => inputUser[UserInput.types.cReactiveProtein.key]),
    esrMaleRange : getRange(UserInput.types.esrMale.key,0,0,5,15,(inputUser) => inputUser[UserInput.types.esrMale.key]),
    esrFemaleRange : getRange(UserInput.types.esrFemale.key,0,0,10,20,(inputUser) => inputUser[UserInput.types.esrFemale.key]),
    homocysteineRange : getRange(UserInput.types.homocysteine.key,0,0,7.2,10.3,(inputUser) => inputUser[UserInput.types.homocysteine.key]),
    fibrinogenRange : getRange(UserInput.types.fibrinogen.key,175,200,300,425,(inputUser) => inputUser[UserInput.types.fibrinogen.key]),
    creatineKinaseRange : getRange(UserInput.types.creatineKinase.key,44,65,135,196,(inputUser) => inputUser[UserInput.types.creatineKinase.key]),
    vitaminD25OHRange : getRange(UserInput.types.vitaminD25OH.key,20,50,100,100,(inputUser) => inputUser[UserInput.types.vitaminD25OH.key]),
    vitaminB12Range : getRange(UserInput.types.vitaminB12.key,200,450,800,1100,(inputUser) => inputUser[UserInput.types.vitaminB12.key]),
    folateRange : getRange(UserInput.types.folate.key,5.5,15,25,27,(inputUser) => inputUser[UserInput.types.folate.key]),
    dheasMaleRange : getRange(UserInput.types.dheasMale.key,65,350,490,510,(inputUser) => inputUser[UserInput.types.dheasMale.key]),
    dheasFemaleRange : getRange(UserInput.types.dheasFemale.key,18,275,390,390,(inputUser) => inputUser[UserInput.types.dheasFemale.key]),
    testosteroneTotalMaleRange : getRange(UserInput.types.testosteroneTotalMale.key,250,700,900,1100,(inputUser) => inputUser[UserInput.types.testosteroneTotalMale.key]),
    testosteroneTotalFemaleRange : getRange(UserInput.types.testosteroneTotalFemale.key,2,35,45,45,(inputUser) => inputUser[UserInput.types.testosteroneTotalFemale.key]),
    testosteroneFreeMaleRange : getRange(UserInput.types.testosteroneFreeMale.key,46,150,224,224,(inputUser) => inputUser[UserInput.types.testosteroneFreeMale.key]),
    testosteroneFreeFemaleRange : getRange(UserInput.types.testosteroneFreeFemale.key,.2,1,2.2,5,(inputUser) => inputUser[UserInput.types.testosteroneFreeFemale.key]),
    testosteroneBioavailableMaleRange : getRange(UserInput.types.testosteroneBioavailableMale.key,110,375,575,575,(inputUser) => inputUser[UserInput.types.testosteroneBioavailableMale.key]),
    testosteroneBioavailableFemaleRange : getRange(UserInput.types.testosteroneBioavailableFemale.key,.5,5.5,8.5,8.5,(inputUser) => inputUser[UserInput.types.testosteroneBioavailableFemale.key]),
    testosteroneFreeMaleLABCORPRange : getRange(UserInput.types.testosteroneFreeMaleLABCORP.key,9.3,20,25,26.5,(inputUser) => inputUser[UserInput.types.testosteroneFreeMaleLABCORP.key]),
    sexHormoneBindingGlobulinMaleRange : getRange(UserInput.types.sexHormoneBindingGlobulinMale.key,10,30,40,50,(inputUser) => inputUser[UserInput.types.sexHormoneBindingGlobulinMale.key]),
    sexHormoneBindingGlobulinFemaleRange : getRange(UserInput.types.sexHormoneBindingGlobulinFemale.key,17,25,122,124,(inputUser) => inputUser[UserInput.types.sexHormoneBindingGlobulinFemale.key]),
    estradiolMaleRange : getRange(UserInput.types.estradiolMale.key,0,20,30,39,(inputUser) => inputUser[UserInput.types.estradiolMale.key]),
    estradiolFemaleRange : getRange(UserInput.types.estradiolFemale.key,19,150,350,357,(inputUser) => inputUser[UserInput.types.estradiolFemale.key]),
    progesteroneMaleRange : getRange(UserInput.types.progesteroneMale.key,.2,1,1.2,1.3,(inputUser) => inputUser[UserInput.types.progesteroneMale.key]),
    progesteroneFemaleRange : getRange(UserInput.types.progesteroneFemale.key,2.6,18,27,27,(inputUser) => inputUser[UserInput.types.progesteroneFemale.key]),
    totalWBCsRange : getRange(UserInput.types.totalWBCs.key,3.8,5.5,7.5,10.8,(inputUser) => inputUser[UserInput.types.totalWBCs.key]),
    rbcMaleRange : getRange(UserInput.types.rbcMale.key,4.2,4.2,4.9,5.8,(inputUser) => inputUser[UserInput.types.rbcMale.key]),
    rbcFemaleRange : getRange(UserInput.types.rbcFemale.key,3.8,3.9,4.5,5.1,(inputUser) => inputUser[UserInput.types.rbcFemale.key]),
    hemoglobinMaleRange : getRange(UserInput.types.hemoglobinMale.key,13.20,14,15,17.1,(inputUser) => inputUser[UserInput.types.hemoglobinMale.key]),
    hemoglobinFemaleRange : getRange(UserInput.types.hemoglobinFemale.key,11.7,13.5,14.5,15.5,(inputUser) => inputUser[UserInput.types.hemoglobinFemale.key]),
    hematocritMaleRange : getRange(UserInput.types.hematocritMale.key,38.5,40,48,50,(inputUser) => inputUser[UserInput.types.hematocritMale.key]),
    hematocritFemaleRange : getRange(UserInput.types.hematocritFemale.key,35,37,44,45,(inputUser) => inputUser[UserInput.types.hematocritFemale.key]),
    mcvRange : getRange(UserInput.types.mcv.key,80,82,89.9,100,(inputUser) => inputUser[UserInput.types.mcv.key]),
    mchRange : getRange(UserInput.types.mch.key,27,28,31.9,33,(inputUser) => inputUser[UserInput.types.mch.key]),
    mchcRange : getRange(UserInput.types.mchc.key,32,32,35,36,(inputUser) => inputUser[UserInput.types.mchc.key]),
    plateletsRange : getRange(UserInput.types.platelets.key,140,155,385,400,(inputUser) => inputUser[UserInput.types.platelets.key]),
    rdwRange : getRange(UserInput.types.rdw.key,11,11.7,13,15,(inputUser) => inputUser[UserInput.types.rdw.key]),
    neutrophilsRange : getRange(UserInput.types.neutrophils.key,38,40,60,74,(inputUser) => inputUser[UserInput.types.neutrophils.key]),
    bandsRange : getRange(UserInput.types.bands.key,38,40,60,74,(inputUser) => inputUser[UserInput.types.bands.key]),
    lymphocytesRange : getRange(UserInput.types.lymphocytes.key,14,24,44,46,(inputUser) => inputUser[UserInput.types.lymphocytes.key]),
    monocytesRange : getRange(UserInput.types.monocytes.key,0,4,7,13,(inputUser) => inputUser[UserInput.types.monocytes.key]),
    eosinophilsRange : getRange(UserInput.types.eosinophils.key,0,0,2,4,(inputUser) => inputUser[UserInput.types.eosinophils.key]),
    basophilsRange : getRange(UserInput.types.basophils.key,0,0,1,1,(inputUser) => inputUser[UserInput.types.basophils.key]),
    collagenCrossLinkedNTxRange : getRange(UserInput.types.collagenCrossLinkedNTx.key,5,5,79,79,(inputUser) => inputUser[UserInput.types.collagenCrossLinkedNTx.key]),
    cortisolAMRange : getRange(UserInput.types.cortisolAM.key,4,10,15,22,(inputUser) => inputUser[UserInput.types.cortisolAM.key]),
    cortisolPMRange : getRange(UserInput.types.cortisolPM.key,3,6,10,17,(inputUser) => inputUser[UserInput.types.cortisolPM.key]),
    creatinineClearanceRange : getRange(UserInput.types.creatinineClearance.key,75,85,115,125,(inputUser) => inputUser[UserInput.types.creatinineClearance.key]),
    leptinMaleRange : getRange(UserInput.types.leptinMale.key,.3,1.2,9.5,13.4,(inputUser) => inputUser[UserInput.types.leptinMale.key]),
    leptinFemaleRange : getRange(UserInput.types.leptinFemale.key,4.1,4.7,23.7,25,(inputUser) => inputUser[UserInput.types.leptinFemale.key]),
    gastrinRange : getRange(UserInput.types.gastrin.key,0,45,90,100,(inputUser) => inputUser[UserInput.types.gastrin.key]),
    reticulocyteCountRange : getRange(UserInput.types.reticulocyteCount.key,.05,.5,1.5,1.5,(inputUser) => inputUser[UserInput.types.reticulocyteCount.key]),
    lithiumRange : getRange(UserInput.types.lithium.key,.5,.6,1.2,1.3,(inputUser) => inputUser[UserInput.types.lithium.key]),

};

var getRatioRange = (numeratorKey, denomenatorKey,standardMin,optimalMin,optimalMax,standardMax) => {
    return new Range(
        UserInput.types[numeratorKey].key + "_" + UserInput.types[denomenatorKey].key + "Ratio",
        "Ratio of " + UserInput.types[numeratorKey].prompt + " and " + UserInput.types[denomenatorKey].prompt,
        standardMin,
        optimalMin,
        optimalMax,
        standardMax,
        (inputUser) => {
            var numerator = inputUser[UserInput.types[numeratorKey].key];
            var denomenator = inputUser[UserInput.types[denomenatorKey].key];
            
            if(numerator !== null && numerator !== undefined && denomenator !== null && denomenator !== undefined){
                //throw Error(numerator + "/" + denomenator);
                if(denomenator !== 0){
                    return numerator / denomenator;
                }else{
                    return 0;
                }
            }else {
                return null;
            }
        }
    )
}
var biomarkerRatioRanges = {
    bun_creatinineRatioRange : getRatioRange(UserInput.types.bun.key,UserInput.types.creatinine.key,6,10,16,22),
    sodium_potassiumRatioRange : getRatioRange(UserInput.types.sodium.key,UserInput.types.potassium.key,30,30,35,35),
    albumin_globulinTotalRatioRange : getRatioRange(UserInput.types.albumin.key,UserInput.types.globulinTotal.key,1,1.4,2.1,2.5),
    calcium_albuminRatioRange : getRatioRange(UserInput.types.calcium.key,UserInput.types.albumin.key,0,0,2.6,2.6),
    calcium_phosphorusRatioRange : getRatioRange(UserInput.types.calcium.key,UserInput.types.phosphorus.key,2.3,2.3,2.7,2.7),
    cholesterolTotal_hdlCholesterolRatioRange : getRatioRange(UserInput.types.cholesterolTotal.key,UserInput.types.hdlCholesterol.key,0,0,3,5),
    triglycerides_hdlCholesterolRatioRange : getRatioRange(UserInput.types.triglycerides.key,UserInput.types.hdlCholesterol.key,0,0,2,2)
};

export default {
    ...biomarkerRanges,
    ...biomarkerRatioRanges
};