import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isAboveOptimal, isAboveStandard, getDefaultDosage} from '../utilities';

import image1 from './inflamaway1.png';
import doc1 from './inflamaway1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();
    
    if(inputUser[UserInput.types.bodyPain.key] === "mild"
        || inputUser[UserInput.types.bodyPain.key] === "moderate"
        || inputUser[UserInput.types.bodyPain.key] === "severe"){
        toReturn.recommended = true;
        toReturn.inputUserKeys.push(UserInput.types.bodyPain.key);
        toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPFemaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.hsCRPFemale.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.hsCRPMale.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}

	if(isAboveOptimal(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.homocysteine.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	

	return toReturn;
};
/* if pain
mild increase value by 50
moderate increase value by 100
severe increase value by 150*/
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(inputUser[UserInput.types.bodyPain.key] === "mild"){
		sortValue -= 50;
	} else if(inputUser[UserInput.types.bodyPain.key] === "moderate"){
		sortValue -= 100;
	} else if(inputUser[UserInput.types.bodyPain.key] === "severe"){
		sortValue -= 150;
	}

	if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isAboveStandard(inputCalculated, CalculatedInput.types.hsCRPFemaleRange.key)){
			sortValue -= 200;
		} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPFemaleRange.key)){
			sortValue -= 100;
		}
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isAboveStandard(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key)){
			sortValue -= 200;
		} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key)){
			sortValue -= 100;
		}
	}

	if(isAboveStandard(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		sortValue -= 150;
	} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		sortValue -= 75;
	}


	return sortValue;
};


const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.bodyPain.key)){ toReturn.push(UserInput.types.bodyPain); }
	if(isUnanswered(inputUser,UserInput.types.maleOrFemale.key)){
		toReturn.push(UserInput.types.maleOrFemale);
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isUnanswered(inputUser, UserInput.types.hsCRPFemale.key)){ toReturn.push(UserInput.types.hsCRPFemale); }
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isUnanswered(inputUser, UserInput.types.hsCRPMale.key)){ toReturn.push(UserInput.types.hsCRPMale); }
	}
	if(isUnanswered(inputUser, UserInput.types.homocysteine.key)){ toReturn.push(UserInput.types.homocysteine); }
    return toReturn;
};
/* if CRP lab is above optimal recommend inflamaway 1 capsule 2 times per day
is CRP above standard recommend inflamaway 2 capsules 2 times per day
if CRP above optiaml invrease value 100
if CRP is above standard increase value 200

if homocysteine is above optimal or standard range recommend inflamAway
if above optimal rec 1xd
if above standard rec 1 cap 2 times per day

if homocysteine is above optimal increase value by 75
if homocystein is above standard increase value by 150*/



/*  // updated bodyPain symptoms // for the trigger of body pain put in parenthesis (including but not limited to - headaches, Rheumatoid arthritis, PMS, 
	back and neck pain, headaches, muscle tenstion, etc.) */

const getDosage = (inputUser, inputCalculated) => {	

	if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isAboveStandard(inputCalculated, CalculatedInput.types.hsCRPFemaleRange.key)){
			return {
				description : "2 servings, twice per day.",
				servingsPerDay : 4
			};
		} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPFemaleRange.key)){
			return {
				description : "1 serving, twice per day.",
				servingsPerDay : 2
			};
		}
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isAboveStandard(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key)){
			return {
				description : "2 servings, twice per day.",
				servingsPerDay : 4
			};
		} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key)){
			return {
				description : "1 serving, twice per day.",
				servingsPerDay : 2
			};
		}
	}

	if(isAboveStandard(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		return {
			description : "1 serving, twice per day.",
			servingsPerDay : 2
		};
	} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	}

	if(inputUser[UserInput.types.bodyPain.key] === "severe"){
		return {
			description : "2 to 3 servings per day.",
			servingsPerDay : 2.5
		};
	} else if(inputUser[UserInput.types.bodyPain.key] === "moderate"){
		return {
			description : "2 servings per day.",
			servingsPerDay : 2
		};
	}else if(inputUser[UserInput.types.bodyPain.key] === "mild"){
		return {
			description : "1 to 2 servings per day.",
			servingsPerDay : 1.5
		};
	}
	return getDefaultDosage();
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	Inflam-Away is formulated to support eicosanoid and cytokine balance and provide support for 
	joint comfort. 5-LOXIN®, a patented Boswellia extract yielding concentrated 
	3-O-acetyl-11-keto-ß-boswellic acid (AKBA), is found to inhibit the 5-lipoxygenase enzyme. 
	Salicin from white willow bark is a natural inhibitor of both the COX-2 and 5-lipoxygenase 
	enzymes. Bioflavonoid-rich BerryVin™ provides additional support for eicosanoid balance and 
	antioxidant activity.*`;
};
const title = "Inflam Away";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00024", price: 29, servings: 120}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};