import Ranges from "./ranges/";

// var orderedToCalculate = [
// 	Ranges.glucoseRange,
//     Ranges.hemoglobinA1CRange, 
//     Ranges.bunRange, 
//     Ranges.creatinineRange, 
//     Ranges.eGFRNonAfricanAmericanRange, 
//     Ranges.eGFRAfricanAmericanRange, 
//     Ranges.sodiumRange, 
// 	Ranges.potassiumRange, 
// 	Ranges.chlorideRange, 
// 	Ranges.cO2Range, 
// 	Ranges.anionGapRange, 
// 	Ranges.uricAcidMaleRange, 
// 	Ranges.proteinTotalRange, 
// 	Ranges.albuminRange, 
// 	Ranges.globulinTotalRange, 
// 	Ranges.calciumRange, 
// 	Ranges.phosphorusRange, 
// 	Ranges.magnesiumRange, 
// 	Ranges.alkPhosRange, 
// 	Ranges.astSGOTRange, 
// 	Ranges.altSGPTRange, 
// 	Ranges.ldhRange, 
// 	Ranges.bilirubinTotalRange, 
// 	Ranges.ggtRange, 
// 	Ranges.ironSerumRange, 
// 	Ranges.ferritinRange, 
// 	Ranges.tibcRange, 
// 	Ranges.transferrinSaturationRange, 
// 	Ranges.cholesterolTotalRange, 
// 	Ranges.triglyceridesRange, 
// 	Ranges.ldlCholesterolRange, 
// 	Ranges.hdlCholesterolRange, 
// 	Ranges.vldlCholesterolRange, 
// 	Ranges.tshRange, 
// 	Ranges.freeT3Range, 
// 	Ranges.totalT3Range, 
// 	Ranges.freeT4Range, 
// 	Ranges.totalT4Range, 
// 	Ranges.t3UptakeRange, 
// 	Ranges.reverseT3Range, 
// 	Ranges.freeThyroxineIndexT7Range, 
// 	Ranges.thyroidPeroxidaseTPOAbsLABCORPRange, 
// 	Ranges.thyroglobulinAbsRange, 
// 	Ranges.hsCRPMaleRange, 
// 	Ranges.homocysteineRange, 
// 	Ranges.fibrinogenRange, 
// 	Ranges.vitaminD25OHRange, 
// 	Ranges.dheasMaleRange, 
// 	Ranges.testosteroneTotalMaleRange, 
// 	Ranges.sexHormoneBindingGlobulinMaleRange, 
// 	Ranges.estradiolMaleRange,
// 	Ranges.estradiolFemaleRange,
// 	Ranges.totalWBCsRange, 
// 	Ranges.rbcMaleRange, 
// 	Ranges.hemoglobinMaleRange, 
// 	Ranges.hematocritMaleRange, 
// 	Ranges.mcvRange, 
// 	Ranges.mchRange, 
// 	Ranges.mchcRange, 
// 	Ranges.plateletsRange, 
// 	Ranges.rdwRange, 
// 	Ranges.neutrophilsRange, 
// 	Ranges.lymphocytesRange, 
// 	Ranges.monocytesRange, 
// 	Ranges.eosinophilsRange, 
// 	Ranges.basophilsRange, 
// 	Ranges.cortisolAMRange,
// 	Ranges.progesteroneMaleRange,
// 	Ranges.progesteroneFemaleRange,
// 	Ranges.lithiumRange,
// ];

const orderedToCalculate = Object.values(Ranges);


var getInputCalculated = (inputUser) => {
	var inputCalculated = {};
	for(var i=0;i<orderedToCalculate.length;i++){
		inputCalculated[orderedToCalculate[i].key] = orderedToCalculate[i].getValue(inputUser, inputCalculated);
	}
	return inputCalculated;
};
export default {
	orderedToCalculate,
    getInputCalculated,
    types: Ranges
}
