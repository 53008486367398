import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { getUnknownKeys, isUnanswered, isOptimalOrBelow, isAboveOptimal, isNo, isBelowStandard, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';
import { getEvaluation as getComprehensiveTestEvaluation } from './fullbloodpanel.js';


const getIsCase1 = (inputUser, inputCalculated) => {
    if (isOptimalOrBelow(inputCalculated,CalculatedInput.types.ironSerumRange.key)
        && isAboveOptimal(inputCalculated,CalculatedInput.types.ferritinRange.key)
        && isNo(inputUser, UserInput.types.bodyPain.key) && isNo(inputUser,UserInput.types.swellingInflammation.key)){
        return{
            recommended : true,
            inputUserKeys : [UserInput.types.bodyPain.key, UserInput.types.swellingInflammation.key],
            inputCalculatedKeys : [CalculatedInput.types.ironSerumRange.key, CalculatedInput.types.ferritinRange.key]
        }
    }else{
        return {
            recommended : false
        }
    }
};
const getIsCase2 = (inputUser, inputCalculated) => {
    if(isBelowStandard(inputCalculated,CalculatedInput.types.transferrinSaturationRange.key)
        && inputUser[UserInput.types.maleOrFemale.key] === "female"
        && isNo(inputUser, UserInput.types.irregularPeriods.key)){
        return{
            recommended : true,
            inputUserKeys : [UserInput.types.maleOrFemale.key, UserInput.types.irregularPeriods.key],
            inputCalculatedKeys : [CalculatedInput.types.transferrinSaturationRange.key]
        }
    }else{
        return {
            recommended : false
        }
    }
};

const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();

    if(getComprehensiveTestEvaluation(inputUser, inputCalculated).recommended){
		return toReturn;
	}
    
    const isCase1 = getIsCase1(inputUser, inputCalculated);
    if(isCase1.recommended){
        toReturn.recommended = true;
		toReturn.inputUserKeys.push(...isCase1.inputUserKeys);
		toReturn.inputCalculatedKeys.push(...isCase1.inputCalculatedKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
    const isCase2 = getIsCase2(inputUser, inputCalculated);
    if(isCase2.recommended){
        toReturn.recommended = true;
		toReturn.inputUserKeys.push(...isCase2.inputUserKeys);
		toReturn.inputCalculatedKeys.push(...isCase2.inputCalculatedKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
    const unknownKeys = getUnknownKeys(inputUser, [
        UserInput.types.ironSerum.key,
		UserInput.types.ferritin.key,
		UserInput.types.tibc.key,
        UserInput.types.transferrinSaturation.key]);
        
    if(unknownKeys.length > 0){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(...unknownKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.ironSerum.key)){ toReturn.push(UserInput.types.ironSerum); }
    if(isUnanswered(inputUser, UserInput.types.ferritin.key)){ toReturn.push(UserInput.types.ferritin); }
    if(isUnanswered(inputUser, UserInput.types.transferrinSaturation.key)){ toReturn.push(UserInput.types.transferrinSaturation); }
    
    if(isOptimalOrBelow(inputCalculated,CalculatedInput.types.ironSerumRange.key) && isAboveOptimal(inputCalculated,CalculatedInput.types.ferritinRange.key)){
        if(isUnanswered(inputUser, UserInput.types.bodyPain.key)){
            toReturn.push(UserInput.types.bodyPain);
        }
        if(isUnanswered(inputUser, UserInput.types.swellingInflammation.key)){
            toReturn.push(UserInput.types.swellingInflammation);
        }
    }
    if(isUnanswered(inputUser,UserInput.types.maleOrFemale.key)){
        toReturn.push(UserInput.types.maleOrFemale);
    }else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
        if(isBelowStandard(inputCalculated,CalculatedInput.types.transferrinSaturationRange.key)){
            if(isUnanswered(inputUser, UserInput.types.irregularPeriods.key)){
                toReturn.push(UserInput.types.irregularPeriods);
            }
        }
    }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
    const isCase2 = getIsCase2(inputUser, inputCalculated);
    if(isCase2.recommended){
        return "in 1 month";
    }else{
        return "in 2 months";
    }
};

export default {
	getEvaluation,
	getPrompts,
	title : "Iron Panel Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};