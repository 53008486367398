import UserInput from '../../inputs/user/index.js';
import { isYes, isUnanswered, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './eveningprimroseoil1.jpg';
//import doc1 from './eveningprimroseoil1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(isYes(inputUser, UserInput.types.acne.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.acne.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.backOfArmBumps.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.backOfArmBumps.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	// Craig Todo: Verify below
	if(isYes(inputUser,UserInput.types.dryCrackedSkin.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.dryCrackedSkin.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.eczemaPsoriasisRosacea.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};


const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(isYes(inputUser,UserInput.types.acne.key)){
		sortValue -= 100;	
	}
	if(isYes(inputUser,UserInput.types.backOfArmBumps.key)){
		sortValue -= 100;	
	}
	if(isYes(inputUser,UserInput.types.dryCrackedSkin.key)){
		sortValue -= 150;
	}
	if(isYes(inputUser,UserInput.types.eczemaPsoriasisRosacea.key)){
		sortValue -= 200;
	}
	return sortValue;
};



const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.acne.key)){
        toReturn.push(UserInput.types.acne);
	}
	if(isUnanswered(inputUser, UserInput.types.backOfArmBumps.key)){
        toReturn.push(UserInput.types.backOfArmBumps);
	}
	if(isUnanswered(inputUser, UserInput.types.dryCrackedSkin.key)){
		toReturn.push(UserInput.types.dryCrackedSkin);
	}
	if(isUnanswered(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){
		toReturn.push(UserInput.types.eczemaPsoriasisRosacea);
	}
	return toReturn;
};
const getDosage = getDefaultDosage;
const getDescription = (inputUser, inputCalculated) => {
	return `
	Evening primrose oil (E.P.O.) is cold-pressed and produced without the use of any solvents. Used for centuries as nutritional 
	support for a wide range of physiological functions, several studies have indicated that E.P.O. may help support the look and 
	feel of healthy skin. A clinical trial reported that E.P.O. supported healthy skin through a modulation of IFN-gamma. Another 
	study revealed that E.P.O. promoted skin health and led to a more favorable shift in eicosanoid metabolism. Other studies 
	suggest that E.P.O. may support healthy lipid metabolism and may provide oxidative protection by promoting glutathione 
	reductase and transferase activities. A clinical trial reported that GLA in combination with EPA/DHA significantly promoted 
	healthy cardiovascular function in women. Additionally, E.P.O. may potentially be supportive of premenstrual comfort.*`;
};
const title = "Evening Primrose Oil";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "EPO", price: 31, servings: 100}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	// docs: [{ src: doc1 }]	
};