import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isAboveOptimal, isAboveOptimalButInStandard, isAboveStandard , getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './glucosupport1.jpg';
import doc1 from './glucosupport1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.hemoglobinA1CRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.hemoglobinA1CRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.triglyceridesRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.triglyceridesRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.glucoseRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.glucoseRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};/* if glucose is above optimal range increase value 25
if glucose above standard range increase value 50
if HA1C above optimal increase value 150
If HA1C above standard range increase value 250*/

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(isAboveStandard(inputCalculated, CalculatedInput.types.hemoglobinA1CRange.key)){
		sortValue -= 250;
	}else if(isAboveOptimal(inputCalculated, CalculatedInput.types.hemoglobinA1CRange.key)){
		sortValue -= 150;
	}
	// if(isAboveOptimal(inputCalculated, CalculatedInput.types.triglyceridesRange.key)){
	// }
	if(isAboveStandard(inputCalculated, CalculatedInput.types.glucoseRange.key)){
		sortValue -= 50;
	}else if(isAboveOptimal(inputCalculated, CalculatedInput.types.glucoseRange.key)){
		sortValue -= 25;
	}
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.glucose.key)){
		toReturn.push(UserInput.types.glucose);
	} 
	if(isUnanswered(inputUser, UserInput.types.hemoglobinA1C.key)){
		toReturn.push(UserInput.types.hemoglobinA1C);
	} 
	if(isUnanswered(inputUser, UserInput.types.triglycerides.key)){
		toReturn.push(UserInput.types.triglycerides);
	}
	return toReturn;
};

/* Other symptoms that will trigger recommending glucosupport - Feeling of HYPERglycemic (blood sugar too high) 
or HYPOglycemic (blood sugar too low) */

const getDosage = (inputUser, inputCalculated) => {
	if(isAboveStandard(inputCalculated, CalculatedInput.types.hemoglobinA1CRange.key)){
		return {
			description : "1 serving, 3 times per day.",
			servingsPerDay : 3
		};
	} else if(isAboveStandard(inputCalculated, CalculatedInput.types.triglyceridesRange.key) || isAboveOptimalButInStandard(inputCalculated, CalculatedInput.types.hemoglobinA1CRange.key)){
		return {
			description : "1 serving, twice per day.",
			servingsPerDay : 2
		};
	}else if(isAboveOptimalButInStandard(inputCalculated, CalculatedInput.types.triglyceridesRange.key)){
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	}else{
		return getDefaultDosage();
	}
}

const getDescription = (inputUser, inputCalculated) => {
	return `
	Gluco Support features significant quantities of the key ingredients that support insulin 
	utilization and glucose metabolism. CinSulin® is a safe, patented, 100% water-soluble, 10:1 
	concentrated form of cinnamon that provides polyphenol polymers. Standardized American ginseng, 
	green tea, gymnema, and alpha-lipoic acid help protect pancreatic cells, support insulin 
	sensitivity, and provide antioxidant activity. Albion®’s TRAACS® patented chromium is added for 
	its role in enhancing insulin activity.*`;
};
const title = "Gluco-Support";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00021", price: 51, servings: 120}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};