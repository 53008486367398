import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isAboveOptimal, isOptimal, isYes, isNo, isAboveStandard, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './redyeastrice1.png';
//import doc1 from './redyeastrice1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(isAboveStandard(inputCalculated, CalculatedInput.types.ldlCholesterolRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.ldlCholesterolRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}else if(isNo(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if(isAboveStandard(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.cholesterolTotalRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	} else if(isYes(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if(isOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
			if(isYes(inputUser, UserInput.types.preferStoppingCholesterolLoweringMeds.key)){
				toReturn.recommended = true;
				toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
				toReturn.inputUserKeys.push(UserInput.types.preferStoppingCholesterolLoweringMeds.key);
				toReturn.inputCalculatedKeys.push(CalculatedInput.types.cholesterolTotalRange.key);
				toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
			}
		}else if(isAboveOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.cholesterolTotalRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}
	// Craig Todo: same recommendation as above?  :confused: , above contains total cholesterol and LDL?
/* add in the same recommendation as above for Total cholesterol, LDL or triglycerides when above optimal or standard ref range
if on meds for cholesterol increase value 50
if cholesterol , ldl or triglycerides are above optimal increase value by 50 for each one
if cholesterol, ldl or triglycerides are above standard range increase value by 100 for each one*/
	
	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(isYes(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		sortValue -= 50;
	}
	if(isAboveStandard(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
		sortValue -= 50;
	} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
		sortValue -= 100;
	}
	if(isAboveStandard(inputCalculated, CalculatedInput.types.ldlCholesterolRange.key)){
		sortValue -= 50;
	} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.ldlCholesterolRange.key)){
		sortValue -= 100;
	}
	if(isAboveStandard(inputCalculated, CalculatedInput.types.triglyceridesRange.key)){
		sortValue -= 50;
	} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.triglyceridesRange.key)){
		sortValue -= 100;
	}
	
	return sortValue;
};
const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.takeCholesterolLoweringMeds.key)){ toReturn.push(UserInput.types.takeCholesterolLoweringMeds); }
	if(isUnanswered(inputUser, UserInput.types.cholesterolTotal.key)){ toReturn.push(UserInput.types.cholesterolTotal); }
	if(isUnanswered(inputUser, UserInput.types.ldlCholesterol.key)){ toReturn.push(UserInput.types.ldlCholesterol); }
	if(isUnanswered(inputUser, UserInput.types.triglycerides.key)){ toReturn.push(UserInput.types.triglycerides); }


	if(isOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
		if(isUnanswered(inputUser, UserInput.types.preferStoppingCholesterolLoweringMeds.key)){
			console.log("Pushing prefer stopping");
			toReturn.push(UserInput.types.preferStoppingCholesterolLoweringMeds);
		}
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(isAboveStandard(inputCalculated, CalculatedInput.types.ldlCholesterolRange.key)){
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	}if(isNo(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if(isAboveStandard(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
			if(inputUser[UserInput.types.cholesterolTotal.key] >= 220){
				return {
					description : "1 serving, twice per day.",
					servingsPerDay : 2
				};
			}else{
				return {
					description : "1 serving per day.",
					servingsPerDay : 1
				};
			}
		}
	} else if(isYes(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
			return {
				description : "1 serving per day.",
				servingsPerDay : 1
			};
		}else if(
			isOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key) &&
			isYes(inputUser, UserInput.types.preferStoppingCholesterolLoweringMeds.key)
		){
			return {
				description : "1 serving per day.  Consult with your doctor about reducing medications.",
				servingsPerDay : 1
			};
		}
	}
	return {
		description : "Consult with your doctor.",
		servingsPerDay : 1
	};
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Red yeast rice is the product of yeast (Monascus purpureus) containing several compounds collectively known as monacolins, 
	substances that have been shown by clinical studies to support healthy blood lipids.*

	The red yeast rice in this product is USDA certified organic and grown in the US. Designs for Health takes great care to 
	assay our red yeast rice to assure that there are undetectable levels of citrinin, as well as substantial levels of 
	naturally-occurring monacolin compounds, especially monacolin K.*
	
	Red Yeast Rice may support the health of the arterial lining.*`;
};
const title = "Red Yeast Rice";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "RED-YEAST-RICE", price: 35, servings: 150}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	// docs: [{ src: doc1 }]
	
};