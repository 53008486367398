import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Alert } from "reactstrap";

const Tracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const [acceptedTracking, setAcceptedTracking ] = useState(localStorage.getItem("acceptedTracking") || false);

  useEffect(() => {
	  if(acceptedTracking){
		console.debug("Initializing GA.");
		if (!window.location.href.includes("localhost")) {
			ReactGA.initialize("UA-176002054-1");
		}
		setInitialized(true);
	  }
  }, [acceptedTracking]);

  useEffect(() => {
    if (initialized && acceptedTracking) {
		console.debug("GA Pageview:" + location.pathname);
      	ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location, acceptedTracking]);

  const acceptTracking = () => {
	setAcceptedTracking(true);
	localStorage.setItem("acceptedTracking", true);
	// console.debug("Accepted Tracking");
	// ReactGA.event({
	// 	category: "Global",
	// 	action: "Accepted Tracking"
	// });
  };
  
  if(acceptedTracking){
	return null;
  }else{
	return(
		<Alert color="info" toggle={acceptTracking} style={{position : "fixed", bottom: 0, left: "1em", right: "1em" }}>
			We use cookies and browser activity to analyze how our site is used.  
			For more information, please review our <a href="/PrivacyPolicy">privacy policy</a>.
		</Alert>
	);
  }
};

export default Tracking;