import React, { useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import Prompt from './Prompt.js';
import UserInput from './inputs/user/index.js';
import CalculatedInput from './inputs/calculated/index.js';
import { getLink, getInputUserFromQueryString } from './persister/QueryString.js';
import { UncontrolledAlert, Row, Col, Container, ListGroup, Button, Form, FormGroup, Label, InputGroup, Input, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const SingleSymptom = (props) => {
	
    let history = useHistory();
	const [inputUser, setInputUser] = useState({});
	const [inputFilter, setInputFilter] = useState("");
    const setInputUserProperty = (key, value, calculate) => {
		let inputUserModification = {};
		inputUserModification[key] = value;
		const newInputUser = {...inputUser, ...inputUserModification};
		setInputUser(newInputUser);
	};
    const inputCalculated = CalculatedInput.getInputCalculated(inputUser);
	
    const filterOnInputFilter = (prompt) => {
		return inputFilter !== undefined
		&& inputFilter.length !== 0
		&& prompt.toLowerCase().includes(inputFilter.toLowerCase());
	};

    

    const prompts = Object
        .keys(UserInput.types)
        .filter((key) => filterOnInputFilter(UserInput.types[key].prompt))
        .map((key) =>
            <Prompt
            key={key}
            inputUserKey={key}
            prompt={UserInput.types[key].prompt}
            value={inputUser[key]}
            possible={UserInput.types[key].possible}
            units={UserInput.types[key].units}
            setInputUser={(k,v) => setInputUserProperty(k,v,true) }
            type={UserInput.types[key].type}
            images={UserInput.types[key].images}
            range={inputCalculated[key + "Range"]} 
            />
        );
	return (
        <Container>
            <InputGroup className="p-4 mb-3">
                <Input type="text" id="inputFilter" placeholder="Type here to search for specific symptoms" value={inputFilter} onChange={(event) => setInputFilter(event.target.value)} />
            </InputGroup>
            { prompts }
            { Object.keys(inputUser).length > 0 || prompts.length > 0 ? 
                <p className="p-5">
                    <center>
                        <Button onClick={() => history.push(getLink(inputUser,null, props.debug, null))}>Continue with the calcultor</Button>
                    </center>
                </p>
             : "" }
        </Container>
	);
};

export default SingleSymptom;