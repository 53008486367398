export default {
	stress : {
		key: "stress",
		prompt : "Do you have a lot of stress?",
		possible: ["no","yes"],
		group : 4, 
		priority : 10
	},
	eatALotOfSalt : {
		key: "eatALotOfSalt",
		prompt : "Do you eat a lot of salt or packaged food?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	takePotassium : {
		key: "takePotassium",
		prompt : "Do you currently take potassium?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	takeIron : {
		key: "takeIron",
		prompt : "Do you currently take iron or a multi with iron?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	takeCholesterolLoweringMeds : {
		key: "takeCholesterolLoweringMeds",
		prompt : "Do you currently take cholesterol lowering medication?",
		possible: ["no","yes"],
		group : 7, 
		priority : 10
	},
	preferStoppingCholesterolLoweringMeds : {
		key: "preferStoppingCholesterolLoweringMeds",
		prompt : "Would you like to stop taking cholesterol lowering medication?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	takeDepressionMedication : {
		key: "takeDepressionMedication",
		prompt : "Do you take medication for depression?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	// Antibiotics
	takeGeneralAntibiotics : {
		key: "takeGeneralAntibiotics",
		prompt : "Do you currently take any General Antibiotics (amoxicillin, penicillin, keflex)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeTetracyclineAntibiotics : {
		key: "takeTetracyclineAntibiotics",
		prompt : "Do you currently take any Tetracycline Antibiotics?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeTuberculosisAntibiotics : {
		key: "takeTuberculosisAntibiotics",
		prompt : "Do you currently take any Tuberculosis drugs (Isoniazid)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeNeomycinAntibiotics : {
		key: "takeNeomycinAntibiotics",
		prompt : "Do you currently take Neomycin?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeTrimethoprimBactrimSeptraAntibiotics : {
		key: "takeTrimethoprimBactrimSeptraAntibiotics",
		prompt : "Do you currently take Trimethoprim, Bactrim, or Septra?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	// Anticonvulsants
	takePhenobarbitolBarbituatesAnticonvulsants : {
		key: "takePhenobarbitolBarbituatesAnticonvulsants",
		prompt : "Do you currently take Phenobarbitol or barbituates?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takePhenytoinDilantinAnticonvulsants : {
		key: "takePhenytoinDilantinAnticonvulsants",
		prompt : "Do you currently take Phenytoin or Dilantin?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	// Antidepressants
	takeTricyclicAntidepressants : {
		key: "takeTricyclicAntidepressants",
		prompt : "Do you currently take any Tricyclic antidepressants (Clomipramine, dioxepin, elavil, Tofranil)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeSSRIAntidepressants : {
		key: "takeSSRIAntidepressants",
		prompt : "Do you currently take any SSRIs (Prozac, Zoloft)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	// Antidiabetics
	takeSulfonylureasMicronaseDiabetaBiguanidesAntidiabetics : {
		key: "takeSulfonylureasMicronaseDiabetaBiguanidesAntidiabetics",
		prompt : "Do you currently take Sulfonylureas/Micronase, Diabeta, or Biguanides?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeGlucophageAntidiabetics : {
		key: "takeGlucophageAntidiabetics",
		prompt : "Do you currently take Glucophage?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	// Antiinflammatories
	takeCarbamazepineTegretolAntiinflammatories : {
		key: "takeCarbamazepineTegretolAntiinflammatories",
		prompt : "Do you currently take Carbamazepine or Tegretol?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takePrimidoneAntiinflammatories : {
		key: "takePrimidoneAntiinflammatories",
		prompt : "Do you currently take Primidone?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeValproicAcidAntiinflammatories : {
		key: "takeValproicAcidAntiinflammatories",
		prompt : "Do you currently take Valproic Acid?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeCorticosteroidsAntiinflammatories : {
		key: "takeCorticosteroidsAntiinflammatories",
		prompt : "Do you currently take any Corticosteroids (Prednisone or Cortef)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeGoutMedicationsAntiinflammatories : {
		key: "takeGoutMedicationsAntiinflammatories",
		prompt : "Do you currently take any Gout medications (Colchicine)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeSulfasalazineAzulfidineAntiinflammatories : {
		key: "takeSulfasalazineAzulfidineAntiinflammatories",
		prompt : "Do you currently take Sulfasalazine or Azulfidine?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeIndomethacinIndocinAntiinflammatories : {
		key: "takeIndomethacinIndocinAntiinflammatories",
		prompt : "Do you currently take Indomethacin or Indocin?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeOtherNonSteroidalAntiinflammatories : {
		key: "takeOtherNonSteroidalAntiinflammatories",
		prompt : "Do you currently take any non steroidal anti inflammatory drugs (Ibuprofen or Naproxen)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	// Blood Pressure Regulators
	takeCalciumChannelBlockersBloodPressureRegulators : {
		key: "takeCalciumChannelBlockersBloodPressureRegulators",
		prompt : "Do you currently take any calcium channel blockers (Norvasc, Plendil, Procardia, Nimolop, Sular)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeHydralazineDiureticsBloodPressureRegulators : {
		key: "takeHydralazineDiureticsBloodPressureRegulators",
		prompt : "Do you currently take any hydralazine diuretics (Apresoline or Apresazide)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeLoopDiureticsBloodPressureRegulators : {
		key: "takeLoopDiureticsBloodPressureRegulators",
		prompt : "Do you currently take any loop diuretics (Lasix, Bumex, Edecrin)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeThiazideDiureticsBloodPressureRegulators : {
		key: "takeThiazideDiureticsBloodPressureRegulators",
		prompt : "Do you currently take any thiazide diuretics (Diuril, Enduron, Lozol)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takePotassiumSparingDiureticsBloodPressureRegulators : {
		key: "takePotassiumSparingDiureticsBloodPressureRegulators",
		prompt : "Do you currently take any potassium-sparing diuretics (Dyazide)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeACEInhibitorsBloodPressureRegulators : {
		key: "takeACEInhibitorsBloodPressureRegulators",
		prompt : "Do you currently take any ACE inhibitors (Lisinopril, Altace, Accupril, Capoten, Prinivil, Zestril, Vasolec)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	// Cardiovascular Medications
	takeBetaBlockersCardiovascularMedications : {
		key: "takeBetaBlockersCardiovascularMedications",
		prompt : "Do you currently take any beta blockers (Corgard, Lopressor, Betaspace, Sectral, Indiral)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeCardiacGycosidesCardiovascularMedications : {
		key: "takeCardiacGycosidesCardiovascularMedications",
		prompt : "Do you currently take any cardiac gycosides (Digoxin, Digitek, Lanoxin)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeAntiAnginalsCardiovascularMedications : {
		key: "takeAntiAnginalsCardiovascularMedications",
		prompt : "Do you currently take any anti-anginal medications (Norvasc, Procardia, Adalat)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	// Cholesterol-lowering Medication
	takeHydroxymethylglutarylCoenzymeAReductaseInhibitorsCholesterolLoweringMedications : {
		key: "takeHydroxymethylglutarylCoenzymeAReductaseInhibitorsCholesterolLoweringMedications",
		prompt : "Do you currently take any Statins/hydroxymethylglutaryl coenzyme A reductase inhibitors (Mevacor, Lipitor, Zocor, Crestor)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeBileAcidSequestrantsCholesterolLoweringMedications : {
		key: "takeBileAcidSequestrantsCholesterolLoweringMedications",
		prompt : "Do you currently take any bile acid sequestrants (Questran or Colestid)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeGemfibrozilCholesterolLoweringMedications : {
		key: "takeGemfibrozilCholesterolLoweringMedications",
		prompt : "Do you currently take Gemfibrozil?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	// Female Hormone Medication
	takeEstrogenReplacementTherapyFemaleHormoneMedications : {
		key: "takeEstrogenReplacementTherapyFemaleHormoneMedications",
		prompt : "Do you currently take estrogen replacement therapies (Premarin, Enjuvia, Estrace, Climara)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeOralContraceptivesFemaleHormoneMedications : {
		key: "takeOralContraceptivesFemaleHormoneMedications",
		prompt : "Do you currently take oral contraceptives (Alesse, Ovral, Mercette, Triphasil)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	// Other Medication
	takePhenothiazinesOtherMedications : {
		key: "takePhenothiazinesOtherMedications",
		prompt : "Do you currently take phenothiazines?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takePotassiumChlorideSupplementationOtherMedications : {
		key: "takePotassiumChlorideSupplementationOtherMedications",
		prompt : "Do you currently take potassium chloride supplements?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeChemotherapeuticOtherMedications : {
		key: "takeChemotherapeuticOtherMedications",
		prompt : "Do you currently take cancer/chemotherapeutic drugs?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeTheophylineOtherMedications : {
		key: "takeTheophylineOtherMedications",
		prompt : "Do you currently take theophyline drugs?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeAnticoagulantsOtherMedications : {
		key: "takeAnticoagulantsOtherMedications",
		prompt : "Do you currently take anticoagulants (Warfarin or Coamadin) or antiplatelets?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeReverseTranscriptaseInhibitorsOtherMedications : {
		key: "takeReverseTranscriptaseInhibitorsOtherMedications",
		prompt : "Do you currently take reverse transcriptase inhibitors?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeBronchodilatorAlbuterolOtherMedications : {
		key: "takeBronchodilatorAlbuterolOtherMedications",
		prompt : "Do you currently take bronchodilator (asthma) albuterol (Proventil or Ventolin)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	// Over The Counter Medication
	takeAspirinOverTheCounterMedications : {
		key: "takeAspirinOverTheCounterMedications",
		prompt : "Do you currently take aspirin?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeNSAIDsOverTheCounterMedications : {
		key: "takeNSAIDsOverTheCounterMedications",
		prompt : "Do you currently take **NSAIDs?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeLaxativesMineralOilOverTheCounterMedications : {
		key: "takeLaxativesMineralOilOverTheCounterMedications",
		prompt : "Do you currently take laxatives - mineral oil?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeLaxativesBisacodylOverTheCounterMedications : {
		key: "takeLaxativesBisacodylOverTheCounterMedications",
		prompt : "Do you currently take laxatives - bisacodyl?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeMagnesiumAluminumAntacidsOverTheCounterMedications : {
		key: "takeMagnesiumAluminumAntacidsOverTheCounterMedications",
		prompt : "Do you currently take magnesium and aluminum antacids?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeSodiumBicarbonateOverTheCounterMedications : {
		key: "takeSodiumBicarbonateOverTheCounterMedications",
		prompt : "Do you currently take sodium bicorbonate?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeUlcerOverTheCounterMedications : {
		key: "takeUlcerOverTheCounterMedications",
		prompt : "Do you currently take OTC ulcer medications (Pepcid or Tagamet)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	// Ulcer Medication
	takeH2ReceptorAntagonistsUlcerMedications : {
		key: "takeH2ReceptorAntagonistsUlcerMedications",
		prompt : "Do you currently take H2-receptor antagonists (Axid, Pepcid, Tagament, Zantac)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeProtonPumpInhibitorsUlcerMedications : {
		key: "takeProtonPumpInhibitorsUlcerMedications",
		prompt : "Do you currently take proton pump inhibitors (Prilosec or Prevacid)?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeImmunosuppressantMedications : {
		key: "takeImmunosuppressantMedications",
		prompt : "Do you currently take immunosuppressants?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeNifedipineMedications : {
		key: "takeNifedipineMedications",
		prompt : "Do you currently take nifedipine medication?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeAntihypertensiveMedications : {
		key: "takeAntihypertensiveMedications",
		prompt : "Do you currently take antihypertensive medication?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takePhenprocoumonMedications : {
		key: "takePhenprocoumonMedications",
		prompt : "Do you currently take phenprocoumon medication?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeClozapineMedications : {
		key: "takeClozapineMedications",
		prompt : "Do you currently take clozapine medication?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeDocetaxelMedications : {
		key: "takeDocetaxelMedications",
		prompt : "Do you currently take docetaxel medication?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeBoronicAcidBasedProteaseInhibitorMedications : {
		key: "takeBoronicAcidBasedProteaseInhibitorMedications",
		prompt : "Do you currently take boronic acid-based protease inhibitors?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
	takeSunitinibMedications : {
		key: "takeSunitinibMedications",
		prompt : "Do you currently take sunitinib medications?",
		possible: ["no", "yes"],
		group : 10, 
		priority : 10
	},
}