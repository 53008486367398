import UserInput from '../../inputs/user/index.js';
import { isUnanswered, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './acetylblend1.jpg';
//import doc1 from './acetylblend1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	
	if(inputUser[UserInput.types.memoryProblems.key] === "mild"
	|| inputUser[UserInput.types.memoryProblems.key] === "moderate"
	|| inputUser[UserInput.types.memoryProblems.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.memoryProblems.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
}

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.memoryProblems.key)){
        toReturn.push(UserInput.types.memoryProblems);
	}
    return toReturn;
};

/* dosage insturctions problems
Mild - Dosage 1 capsule in Am or during time of day when memory is at its worse
Moderate - dosage 1 capsule 2 times per day
Severe - 1 Capsule 3 times per day */
const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.memoryProblems.key] === "mild"){
		return {
			description : "1 serving per day, in Am or during time of day when memory is at its worse",
			servingsPerDay : 1
		};
	}else if(inputUser[UserInput.types.memoryProblems.key] === "moderate"){
		return {
			description : "1 serving, 2 times per day",
			servingsPerDay : 2
		};
	}else if(inputUser[UserInput.types.memoryProblems.key] === "severe"){
		return {
			description : "1 serving, 3 times per day",
			servingsPerDay : 3
		};
	}
	return getDefaultDosage();
};

/* if input symptoms of memory probs
mild - increase priority by 150
moderate - increase by 300
severe - increase by 450 - total amount */

const getSortValue = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.memoryProblems.key] === "mild"){
		return getDefaultSortValue() - 150;
	}else if(inputUser[UserInput.types.memoryProblems.key] === "moderate"){
		return getDefaultSortValue() - 300;
	}else if(inputUser[UserInput.types.memoryProblems.key] === "severe"){
		return getDefaultSortValue() - 450;
	}
	return getDefaultSortValue();
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Acetyl Blend Active supports the cholinergic system and acetylcholine biosynthesis through select botanicals, enzyme cofactors, 
	and acetylcholine precursors.* Acetylcholine (ACh) is an important neurotransmitter synthesized in both the central and 
	peripheral nervous systems and is involved in many neurological activities. Its primary role in the central nervous system 
	is to promote excitatory actions for cognition and memory. Peripherally, ACh plays an important role in autonomic nervous 
	function, including both the sympathetic and parasympathetic systems.*`;
};

const title = "Acetyl Blend";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "ACETYL-BLEND", price: 47, servings: 90}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	//docs: [{ src: doc1 }]
};