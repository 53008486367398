import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isOptimalOrBelow, isAboveOptimal, isAboveStandard, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './curcamax1.jpg';
import doc1 from './curcumax1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();
    
    if( isOptimalOrBelow(inputCalculated,CalculatedInput.types.ironSerumRange.key)
        && isAboveOptimal(inputCalculated,CalculatedInput.types.ferritinRange.key))
    {
        if(inputUser[UserInput.types.bodyPain.key] === "mild" 
            || inputUser[UserInput.types.bodyPain.key] === "moderate" 
            || inputUser[UserInput.types.bodyPain.key] === "severe"){ 
            toReturn.recommended = true;
            toReturn.inputUserKeys.push(UserInput.types.bodyPain.key);
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.ironSerumRange.key);
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.ferritinRange.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
        }
        if(inputUser[UserInput.types.swellingInflammation.key] === "mild" 
            || inputUser[UserInput.types.swellingInflammation.key] === "moderate" 
            || inputUser[UserInput.types.swellingInflammation.key] === "severe"){ 
            toReturn.recommended = true;
            toReturn.inputUserKeys.push(UserInput.types.swellingInflammation.key);
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.ironSerumRange.key);
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.ferritinRange.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
        }
    }

    let aboveOptimalRangeKeys = [];
    if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key)){
        aboveOptimalRangeKeys.push(CalculatedInput.types.hsCRPMaleRange.key);
    }
    if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPFemaleRange.key)){
        aboveOptimalRangeKeys.push(CalculatedInput.types.hsCRPFemaleRange.key);
    }
    if(isAboveOptimal(inputCalculated, CalculatedInput.types.cReactiveProteinRange.key)){
        aboveOptimalRangeKeys.push(CalculatedInput.types.cReactiveProteinRange.key);
    }
    if(isAboveOptimal(inputCalculated, CalculatedInput.types.esrFemaleRange.key)){
        aboveOptimalRangeKeys.push(CalculatedInput.types.esrFemaleRange.key);
    }
    if(isAboveOptimal(inputCalculated, CalculatedInput.types.esrMaleRange.key)){
        aboveOptimalRangeKeys.push(CalculatedInput.types.esrMaleRange.key);
    }
    if(isAboveOptimal(inputCalculated, CalculatedInput.types.fibrinogenRange.key)){
        aboveOptimalRangeKeys.push(CalculatedInput.types.fibrinogenRange.key);

    }
    if(aboveOptimalRangeKeys.length > 0){
        toReturn.recommended = true;
        toReturn.inputCalculatedKeys.push(...aboveOptimalRangeKeys);
        toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.ironSerum.key)){ 
        toReturn.push(UserInput.types.ironSerum);
    }else if(isOptimalOrBelow(inputCalculated,CalculatedInput.types.ironSerumRange.key)){
        if(isUnanswered(inputUser, UserInput.types.ferritin.key)){
            toReturn.push(UserInput.types.ferritin);
        } else if(isAboveOptimal(inputCalculated,CalculatedInput.types.ferritinRange.key)){
            if(isUnanswered(inputUser, UserInput.types.bodyPain.key)){
                toReturn.push(UserInput.types.bodyPain);
            }
        }
    }

    if(isUnanswered(inputUser, UserInput.types.bodyPain.key)){
        toReturn.push(UserInput.types.bodyPain);
    }

    if(isUnanswered(inputUser, UserInput.types.swellingInflammation.key)){
        toReturn.push(UserInput.types.swellingInflammation);
    }

    if(isUnanswered(inputUser, UserInput.types.maleOrFemale.key)){
        toReturn.push(UserInput.types.maleOrFemale);
    }else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
        if(isUnanswered(inputUser, UserInput.types.hsCRPMale.key)){
            toReturn.push(UserInput.types.hsCRPMale);
        }
        if(isUnanswered(inputUser, UserInput.types.esrMale.key)){
            toReturn.push(UserInput.types.esrMale);
        }
    }else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
        if(isUnanswered(inputUser, UserInput.types.hsCRPFemale.key)){
            toReturn.push(UserInput.types.hsCRPFemale);
        }
        if(isUnanswered(inputUser, UserInput.types.esrFemale.key)){
            toReturn.push(UserInput.types.esrFemale);
        }
    }

    if(isUnanswered(inputUser, UserInput.types.cReactiveProtein.key)){
        toReturn.push(UserInput.types.cReactiveProtein);
    }

    if(isUnanswered(inputUser, UserInput.types.fibrinogen.key)){
        toReturn.push(UserInput.types.fibrinogen);
    }

    

    return toReturn;
};

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

    if(inputUser[UserInput.types.bodyPain.key] === "mild"){ /* increase value by 100 */
        sortValue -= 100;
    } else if(inputUser[UserInput.types.bodyPain.key] === "moderate"){ /* increase value by 200 */
        sortValue -= 200;
    } else if(inputUser[UserInput.types.bodyPain.key] === "severe"){  /* increase value by 300 */
        sortValue -= 300;
    }

    if(inputUser[UserInput.types.swellingInflammation.key] === "mild"){ /* increase value by 150 */
        sortValue -= 150;
    }else if(inputUser[UserInput.types.swellingInflammation.key] === "moderate"){ /* increase value by 250 */
        sortValue -= 250;
    }else if(inputUser[UserInput.types.swellingInflammation.key] === "severe"){ /* increase value by 350 */
        sortValue -= 350;
    }

    if(isAboveStandard(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key)){
        sortValue -= 300;
    }else if(isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key)){
        sortValue -= 150;
        /* CRP above optimal increase value by 150
        CRP above standard increase value by 300 */
    }

    if(isAboveStandard(inputCalculated, CalculatedInput.types.esrFemaleRange.key)){
        sortValue -= 75;
    }else if(isAboveOptimal(inputCalculated, CalculatedInput.types.esrFemaleRange.key)){
        sortValue -= 25;
        /* if esr above optimal increase value by 25
        if esr above standard increase value by 75 */
    }

    if(isAboveOptimal(inputCalculated, CalculatedInput.types.fibrinogenRange.key)){
        sortValue -= 50;
    }else if(isAboveOptimal(inputCalculated, CalculatedInput.types.fibrinogenRange.key)){
        sortValue -= 25;
        /* if fibinogen
        above optimal increase value by 25
        if above standard increase value by 50 */
        
    }
    
    if(isAboveStandard(inputCalculated,CalculatedInput.types.ironSerumRange.key)){
        sortValue -= 50;
    }else if(isAboveOptimal(inputCalculated,CalculatedInput.types.ironSerumRange.key)){
        sortValue -= 25;
        /* Iron serom above optimal increase by 25, above standard increase by 50 */
    }

    if(isAboveStandard(inputCalculated,CalculatedInput.types.ferritinRange.key)){
        sortValue -= 200;
    }else if(isAboveOptimal(inputCalculated,CalculatedInput.types.ferritinRange.key)){
        sortValue -= 100;
        /* If ferritin is above optimal increase value by 100
    if ferritin is above standard increase value by 200 */
    }
    
	return sortValue;
};


const getDosage = (inputUser, inputCalculated) => {

    if(inputUser[UserInput.types.swellingInflammation.key] === "mild"){
        return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	}else if(inputUser[UserInput.types.swellingInflammation.key] === "moderate"){
		return {
			description : "1 or 2 servings per day.",
			servingsPerDay : 1.5
		};
	}else if(inputUser[UserInput.types.swellingInflammation.key] === "severe"){
		return {
			description : "1 to 3 servings per day.",
			servingsPerDay : 2
		};
	}

    let aboveStandardCount = 0;
    aboveStandardCount += isAboveStandard(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key) ? 1 : 0;
    aboveStandardCount += isAboveStandard(inputCalculated, CalculatedInput.types.hsCRPFemaleRange.key) ? 1 : 0;
    aboveStandardCount += isAboveStandard(inputCalculated, CalculatedInput.types.esrMaleRange.key) ? 1 : 0;
    aboveStandardCount += isAboveStandard(inputCalculated, CalculatedInput.types.esrFemaleRange.key) ? 1 : 0;
    aboveStandardCount += isAboveOptimal(inputCalculated, CalculatedInput.types.fibrinogenRange.key) ? 1 : 0;
    
    let aboveOptimalCount = 0;
    aboveOptimalCount += isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPMaleRange.key) ? 1 : 0;
    aboveStandardCount += isAboveOptimal(inputCalculated, CalculatedInput.types.hsCRPFemaleRange.key) ? 1 : 0;
    aboveStandardCount += isAboveOptimal(inputCalculated, CalculatedInput.types.esrMaleRange.key) ? 1 : 0;
    aboveStandardCount += isAboveOptimal(inputCalculated, CalculatedInput.types.esrFemaleRange.key) ? 1 : 0;
    
    aboveOptimalCount += isAboveOptimal(inputCalculated, CalculatedInput.types.fibrinogenRange.key) ? 1 : 0;
    
    if(aboveStandardCount >= 3){
        return {
			description : "3 servings per day.",
			servingsPerDay : 3
		};
    }else if (aboveOptimalCount >= 3 || aboveStandardCount >= 2 || (aboveStandardCount >= 1 && aboveOptimalCount >= 1)){
        return {
			description : "2 servings per day.",
			servingsPerDay : 2
		};
    }else if (aboveOptimalCount >= 1){
        return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
    }
	return getDefaultDosage();
};
const getDescription = (inputUser, inputCalculated) => {
	return `
    Curcu-Max features BCM-95®—a 100% pure turmeric extract standardized to curcumin, demethoxycurcumin, 
    bisdemethoxycurcumin, and essential oils of turmeric rhizome. This natural composition optimizes 
    bioavailability and reflects true turmeric identity to deliver optimal health benefits. BCM-95 has 
    been extensively studied and shows broad efficacy without the use of phospholipids, excipients, 
    additives, carriers, nanotechnology, or bioenhancers.*`;
};
const title = "Curcumax";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00007", price: 41, servings: 60}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
    getSortValue,
    getDescription,
    getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};