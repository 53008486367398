import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isYes, isUnanswered, isOutsideOptimal, isOutsideStandard , getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

const getRangeKeysOutsideStandard = (inputCalculated) => {
	let rangeKeysOutsideStandard = [];
	if(isOutsideStandard(inputCalculated, CalculatedInput.types.bunRange.key)){ rangeKeysOutsideStandard.push(CalculatedInput.types.bunRange.key); }
	if(isOutsideStandard(inputCalculated, CalculatedInput.types.creatinineRange.key)){ rangeKeysOutsideStandard.push(CalculatedInput.types.creatinineRange.key); }
	if(isOutsideStandard(inputCalculated, CalculatedInput.types.bun_creatinineRatioRange.key)){ rangeKeysOutsideStandard.push(CalculatedInput.types.bun_creatinineRatioRange.key); }
	if(isOutsideStandard(inputCalculated, CalculatedInput.types.eGFRNonAfricanAmericanRange.key)){ rangeKeysOutsideStandard.push(CalculatedInput.types.eGFRNonAfricanAmericanRange.key); }
	if(isOutsideStandard(inputCalculated, CalculatedInput.types.eGFRAfricanAmericanRange.key)){ rangeKeysOutsideStandard.push(CalculatedInput.types.eGFRAfricanAmericanRange.key); }
	if(isOutsideStandard(inputCalculated, CalculatedInput.types.creatinineRange.key)){ rangeKeysOutsideStandard.push(CalculatedInput.types.creatinineRange.key); }
	return rangeKeysOutsideStandard;
};
const getRangeKeysNotOptimal = (inputCalculated) => {
	let rangeKeysNotOptimal = [];
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.bunRange.key)){ rangeKeysNotOptimal.push(CalculatedInput.types.bunRange.key); }
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.creatinineRange.key)){ rangeKeysNotOptimal.push(CalculatedInput.types.creatinineRange.key); }
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.bun_creatinineRatioRange.key)){ rangeKeysNotOptimal.push(CalculatedInput.types.bun_creatinineRatioRange.key); }
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.eGFRNonAfricanAmericanRange.key)){ rangeKeysNotOptimal.push(CalculatedInput.types.eGFRNonAfricanAmericanRange.key); }
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.eGFRAfricanAmericanRange.key)){ rangeKeysNotOptimal.push(CalculatedInput.types.eGFRAfricanAmericanRange.key); }
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.creatinineRange.key)){ rangeKeysNotOptimal.push(CalculatedInput.types.creatinineRange.key); }
	return rangeKeysNotOptimal;
};

const getExtraFactorYesKeys = (inputUser) => {
	let extraFactorYesKeys = [];
	if(inputUser[UserInput.types.kidneyProblems.key] === "yes"){ extraFactorYesKeys.push(UserInput.types.kidneyProblems.key); }
	//if(inputUser[UserInput.types.urinateFrequently.key] === "yes"){ extraFactorYesKeys.push(UserInput.types.urinateFrequently.key); }
	//if(inputUser[UserInput.types.utiHistory.key] === "yes"){ extraFactorYesKeys.push(UserInput.types.utiHistory.key); }
	return extraFactorYesKeys;
};

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	const rangeKeysOutsideStandard = getRangeKeysOutsideStandard(inputCalculated);
	if(rangeKeysOutsideStandard.length > 0){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(...rangeKeysOutsideStandard);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	const rangeKeysNotOptimal = getRangeKeysNotOptimal(inputCalculated);
	const extraFactorYesKeys = getExtraFactorYesKeys(inputUser);
	if(rangeKeysNotOptimal.length > 0 && extraFactorYesKeys.length > 0){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(...extraFactorYesKeys);
		toReturn.inputCalculatedKeys.push(...rangeKeysNotOptimal);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if(isYes(inputUser, UserInput.types.utiHistory.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.utiHistory.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if yes increase value 50 */
	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "mild" || inputUser[UserInput.types.yeastInfectionSeverity.key] === "moderate" || inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.yeastInfectionSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* is yes
	mild increase value 50
	moderate increase value 100
	severe increase value 150 */
	if(isYes(inputUser, UserInput.types.bladderInfections.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bladderInfections.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* is yes
	mild increase value 50
	moderate increase value 100
	severe increase value 150 */
	if(isYes(inputUser, UserInput.types.urinateFrequently.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.urinateFrequently.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* is yes
	mild increase value 50
	moderate increase value 100
	severe increase value 150 */

	return toReturn;
};

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	sortValue -= 20 * getRangeKeysOutsideStandard(inputCalculated).length;
	sortValue -= 10 * getRangeKeysNotOptimal(inputCalculated).length;
	/* for lab values
for any of the above kidney markers out of optimal increase value by 10 for each one
for each lab value out of standard range increase value 20
Need to add in question about being African American or not. eGFR African American only applies to that nationality, not to other races
The Non African American applies to all other races */

	if(isYes(inputUser, UserInput.types.utiHistory.key)){
		sortValue -= 10;
	}/* if yes increase value 50 */


	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "mild"){
		sortValue -= 50;
	}else if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "moderate"){
		sortValue -= 100;
	}else if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"){
		sortValue -= 150;
	}/* is yes
	mild increase value 50
	moderate increase value 100
	severe increase value 150 */

	// Craig Todo: Convert bladder infections to severity?
	if(isYes(inputUser, UserInput.types.bladderInfections.key)){
		sortValue -= 100;
	}/* is yes
	mild increase value 50
	moderate increase value 100
	severe increase value 150 */

	// Craig Todo: Convert urinate frquently to severity?
	if(isYes(inputUser, UserInput.types.urinateFrequently.key)){
		sortValue -= 100;
	}/* is yes
	mild increase value 50
	moderate increase value 100
	severe increase value 150 */

	return sortValue;
};


const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.bun.key)){ toReturn.push(UserInput.types.bun); }
	if(isUnanswered(inputUser, UserInput.types.creatinine.key)){ toReturn.push(UserInput.types.creatinine); }
	if(isUnanswered(inputUser, UserInput.types.africanAmerican.key)){ 
		toReturn.push(UserInput.types.africanAmerican); 
	}else if(isYes(inputUser, UserInput.types.africanAmerican.key)){
		if(isUnanswered(inputUser, UserInput.types.eGFRAfricanAmerican.key)){ toReturn.push(UserInput.types.eGFRAfricanAmerican); } 
	}else{
		if(isUnanswered(inputUser, UserInput.types.eGFRNonAfricanAmerican.key)){ toReturn.push(UserInput.types.eGFRNonAfricanAmerican); } 
	}
	
	
	if(isUnanswered(inputUser, UserInput.types.creatinine.key)){ toReturn.push(UserInput.types.creatinine); }
	
	if(isUnanswered(inputUser,UserInput.types.utiHistory.key)){ toReturn.push(UserInput.types.utiHistory); }
	if(isUnanswered(inputUser,UserInput.types.yeastInfectionSeverity.key)){ toReturn.push(UserInput.types.yeastInfectionSeverity); }
	if(isUnanswered(inputUser,UserInput.types.bladderInfections.key)){ toReturn.push(UserInput.types.bladderInfections); }
	if(isUnanswered(inputUser, UserInput.types.urinateFrequently.key)){ toReturn.push(UserInput.types.urinateFrequently); }
	
	const rangeKeysNotOptimal = getRangeKeysNotOptimal(inputCalculated);
	if(rangeKeysNotOptimal.length > 0){
		if(isUnanswered(inputUser, UserInput.types.kidneyProblems.key)){
			toReturn.push(UserInput.types.kidneyProblems)
		}
		// no longer need ranges out of range in order to prompt?

		// if(isUnanswered(inputUser, UserInput.types.urinateFrequently.key)){
		// 	toReturn.push(UserInput.types.urinateFrequently);
		// }
		// if(isUnanswered(inputUser, UserInput.types.utiHistory.key)){
		// 	toReturn.push(UserInput.types.utiHistory);
		// }
	}
	return toReturn;
};

const getDosage = getDefaultDosage;

/* Also recommend if the patient has a history or complaint of frequent urination, Urinary tract infections, Yeast infections, 
or bladder infections */
const getDescription = (inputUser, inputCalculated) => {
	return `
	The ingredients in Kidney Korrect™ have been shown to support healthy kidney function. Champignon is an extract of the 
	Agaricus bisporus mushroom, also known as the white or button mushroom. It has been shown to help the body maintain a 
	healthy ratio of creatinine in the blood versus the urine. Red ginseng, a specially processed form of Panax ginseng, 
	also supports healthy kidney function and displays kidney-protective and antioxidative properties. Astragalus provides 
	support for healthy fasting glucose levels, along with healthy albumin levels in the urine.*`;
};
const title = "Kidney Korrect";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "KIDNEY-KORRECT", price: 58}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices
	
};