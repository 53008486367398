import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isOutsideOptimal, isUnknown , getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';
import { getEvaluation as getComprehensiveTestEvaluation } from './fullbloodpanel.js';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	
	if(getComprehensiveTestEvaluation(inputUser, inputCalculated).recommended){
		return toReturn;
	}

	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.glucoseRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.glucoseRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.hemoglobinA1CRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.hemoglobinA1CRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isUnknown(inputUser, UserInput.types.glucose.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.glucose.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isUnknown(inputUser, UserInput.types.hemoglobinA1C.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.hemoglobinA1C.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser, UserInput.types.hemoglobinA1C.key)){
        toReturn.push(UserInput.types.hemoglobinA1C);
	}
	if(isUnanswered(inputUser, UserInput.types.glucose.key)){
        toReturn.push(UserInput.types.glucose)
    }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	return "";
};

export default {
	getEvaluation,
	getPrompts,
	title : "Insulin Level Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};