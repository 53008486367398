import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isOutsideOptimal, isAboveOptimal, isYes, isNo, isUnknown, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.proteinTotalRange.key)
	&& isYes(inputUser, UserInput.types.digestiveProblems.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.digestiveProblems.key);
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.proteinTotalRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.eosinophilsRange.key) 
    && (isNo(inputUser, UserInput.types.allergies.key) || isUnknown(inputUser, UserInput.types.allergies.key))){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.allergies.key);
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.eosinophilsRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if(isNo(inputUser, UserInput.types.allergies.key) && isYes(inputUser, UserInput.types.pluggedEars.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.allergies.key);
		toReturn.inputUserKeys.push(UserInput.types.pluggedEars.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if(isYes(inputUser, UserInput.types.utiHistory.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.utiHistory.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.badBreath.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.badBreath.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "mild" || inputUser[UserInput.types.yeastInfectionSeverity.key] === "moderate" || inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.yeastInfectionSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.bladderInfections.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bladderInfections.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if(isYes(inputUser, UserInput.types.nausea.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nausea.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.vomiting.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.vomiting.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.digestiveProblems.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.digestiveProblems.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	
	if(inputUser[UserInput.types.yellowEyesSeverity.key] === "moderate"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.yellowEyesSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if(isYes(inputUser, UserInput.types.itchyEars.key) && isYes(inputUser, UserInput.types.candidaHistory.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.itchyEars.key);
		toReturn.inputUserKeys.push(UserInput.types.candidaHistory.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if(isYes(inputUser, UserInput.types.whiteTongueCoating.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.whiteTongueCoating.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if(isYes(inputUser, UserInput.types.acne.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.acne.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser,UserInput.types.dryCrackedSkin.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.dryCrackedSkin.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if(isYes(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.eczemaPsoriasisRosacea.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(inputUser[UserInput.types.horizontalNailRidges.key] === "mild"
	|| inputUser[UserInput.types.horizontalNailRidges.key] === "moderate"
	|| inputUser[UserInput.types.horizontalNailRidges.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.horizontalNailRidges.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.nailPitting.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nailPitting.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.nailFungus.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nailFungus.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};
/* if patient answers yes to nausea, vomiting, leaky gut or other any other digestive issue recommed the GI renew and Stool analysis testing */
const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.proteinTotal.key)){
		toReturn.push(UserInput.types.proteinTotal);
	} else if (isOutsideOptimal(inputCalculated, CalculatedInput.types.proteinTotalRange.key) && isUnanswered(inputUser, UserInput.types.digestiveProblems.key)){
		toReturn.push(UserInput.types.digestiveProblems);
	}

	if(isUnanswered(inputUser, UserInput.types.nausea.key)){ toReturn.push(UserInput.types.nausea); }
	if(isUnanswered(inputUser, UserInput.types.vomiting.key)){ toReturn.push(UserInput.types.vomiting); }
	if(isUnanswered(inputUser, UserInput.types.digestiveProblems.key)){ toReturn.push(UserInput.types.digestiveProblems); }

	if(isUnanswered(inputUser,UserInput.types.eosinophils.key)){
        toReturn.push(UserInput.types.eosinophils);
	// } else  if(isAboveOptimal(inputCalculated, CalculatedInput.types.eosinophilsRange.key)){
	// 	if(isUnanswered(inputUser,UserInput.types.allergies.key)){
	// 		toReturn.push(UserInput.types.allergies);
	// 	}
	}

	if(isUnanswered(inputUser,UserInput.types.allergies.key)){
		toReturn.push(UserInput.types.allergies);
	}else if(isNo(inputUser, UserInput.types.allergies.key) && isUnanswered(inputUser, UserInput.types.pluggedEars.key)){
		toReturn.push(UserInput.types.pluggedEars);
	}

	if(isUnanswered(inputUser,UserInput.types.utiHistory.key)){ toReturn.push(UserInput.types.utiHistory); }
	if(isUnanswered(inputUser,UserInput.types.yeastInfectionSeverity.key)){ toReturn.push(UserInput.types.yeastInfectionSeverity); }
	if(isUnanswered(inputUser,UserInput.types.bladderInfections.key)){ toReturn.push(UserInput.types.bladderInfections); }
	if(isUnanswered(inputUser, UserInput.types.yellowEyesSeverity.key)){toReturn.push(UserInput.types.yellowEyesSeverity);}
	
	if(isUnanswered(inputUser,UserInput.types.candidaHistory.key)){ 
		toReturn.push(UserInput.types.candidaHistory);
	}else if(isYes(inputUser, UserInput.types.candidaHistory.key) && isUnanswered(inputUser, UserInput.types.itchyEars.key)){
		toReturn.push(UserInput.types.itchyEars);
	}

	if(isUnanswered(inputUser,UserInput.types.badBreath.key)){ toReturn.push(UserInput.types.badBreath); }
	if(isUnanswered(inputUser, UserInput.types.whiteTongueCoating.key)){ toReturn.push(UserInput.types.whiteTongueCoating); }

	if(isUnanswered(inputUser, UserInput.types.acne.key)){ toReturn.push(UserInput.types.acne); }
	if(isUnanswered(inputUser, UserInput.types.dryCrackedSkin.key)){ toReturn.push(UserInput.types.dryCrackedSkin); }
	if(isUnanswered(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){ toReturn.push(UserInput.types.eczemaPsoriasisRosacea); }

	if(isUnanswered(inputUser, UserInput.types.horizontalNailRidges.key)){
        toReturn.push(UserInput.types.horizontalNailRidges);
	}
	if(isUnanswered(inputUser, UserInput.types.nailPitting.key)){ toReturn.push(UserInput.types.nailPitting); }
	if(isUnanswered(inputUser, UserInput.types.nailFungus.key)){ toReturn.push(UserInput.types.nailFungus); }	
	return toReturn;
};
const getDosage = (inputUser, inputCalculated) => {
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.eosinophilsRange.key) 
	&& (isNo(inputUser, UserInput.types.allergies.key) || isUnknown(inputUser, UserInput.types.allergies.key))){
		return "Possible unknown parasites";
	}
	return "";
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	sortValue -= 190;	
	return sortValue;
};
export default {
	getEvaluation,
	getPrompts,
	title : "Stool Analysis Test",
	getDosage,
	category : categories.tests,
	getSortValue
	
};