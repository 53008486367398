import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isAboveOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.vitaminD25OHRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.vitaminD25OHRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.vitaminD25OH.key)){
        toReturn.push(UserInput.types.vitaminD25OH);
    }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
    return "";
};

export default {
	getEvaluation,
	getPrompts,
	title : "Reduce Vitamin D Dosage",
	getDosage,
	category : categories.behaviors,
	getSortValue : getDefaultSortValue
};