import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isYes, isNo, isAboveStandard, isOutsideOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isAboveOptimal, getDefaultDosage} from '../utilities';
import image1 from './coq101.jpg';
import doc1 from './coq101.pdf';
/* Here are some other symptoms that would trigger the patient taking CoQ10. Heart disease or family history of heart disease. 
glucose or HA1c above optimal levels. migraines. low energy or chronic fatigue */

const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();
    if(isYes(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
        toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    } else if(isNo(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if(isAboveStandard(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
			toReturn.recommended = true;
            toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.cholesterolTotalRange.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
        }
    }



    if(isYes(inputUser, UserInput.types.heartDisease.key)){
        toReturn.recommended = true;
        toReturn.inputUserKeys.push(UserInput.types.heartDisease.key);
        toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
    if(isYes(inputUser, UserInput.types.heartDiseaseFamilyHistory.key)){
        toReturn.recommended = true;
        toReturn.inputUserKeys.push(UserInput.types.heartDiseaseFamilyHistory.key);
        toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }

    if(isAboveOptimal(inputCalculated, CalculatedInput.types.glucoseRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.glucoseRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.hemoglobinA1CRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.hemoglobinA1CRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

    if(inputUser[UserInput.types.migraines.key] === "yes"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.migraines.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }

    if(inputUser[UserInput.types.fatigue.key] === "mild" || inputUser[UserInput.types.fatigue.key] === "moderate" || inputUser[UserInput.types.fatigue.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.fatigue.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
    
	return toReturn;
};

/* if taking cholesterol meds increase value by 500
If family history of heart disease increase value by 200
if personal history or current complaints of heart disease increase value by 350
chronic fatigue or low energy increase value by 200
glucose levels above or below standard or optimal range increase value by 75

Migraines
Frequency
1-2 per month increase value by by 50
3-4 per month increase value by 100
5-6 per month increase value by 150
7 or more increase value by 200

 */

 // Craig Todo: Need to further research scope of change to Migraines from Yes/No to monthly

const getSortValue = (inputUser, inputCalculated) => {
    let sortValue = getDefaultSortValue();
    
    if(isYes(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
        sortValue -= 500;
    }  
    if(isYes(inputUser, UserInput.types.heartDiseaseFamilyHistory.key)){
        sortValue -= 200;
    }
    if(isYes(inputUser, UserInput.types.heartDisease.key)){
        sortValue -= 350;
    }
    if(inputUser[UserInput.types.fatigue.key] === "mild" || inputUser[UserInput.types.fatigue.key] === "moderate" || inputUser[UserInput.types.fatigue.key] === "severe"){
		sortValue -= 200;
	}
    if(isOutsideOptimal(inputCalculated, CalculatedInput.types.glucoseRange.key)){
		sortValue -= 75;
	}
    if(inputUser[UserInput.types.migraines.key] === "yes"){
		sortValue -= 50;
    }
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.takeCholesterolLoweringMeds.key)){
        toReturn.push(UserInput.types.takeCholesterolLoweringMeds);
    }else if(isNo(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
        if(isUnanswered(inputUser, UserInput.types.cholesterolTotal.key)){
            toReturn.push(UserInput.types.cholesterolTotal);
        }
    }
    if(isUnanswered(inputUser,UserInput.types.heartDisease.key)){ toReturn.push(UserInput.types.heartDisease); }
    if(isUnanswered(inputUser,UserInput.types.heartDiseaseFamilyHistory.key)){ toReturn.push(UserInput.types.heartDiseaseFamilyHistory); }
    if(isUnanswered(inputUser, UserInput.types.hemoglobinA1C.key)){ toReturn.push(UserInput.types.hemoglobinA1C); }
    if(isUnanswered(inputUser, UserInput.types.glucose.key)){ toReturn.push(UserInput.types.glucose); }
    if(isUnanswered(inputUser, UserInput.types.migraines.key)){ toReturn.push(UserInput.types.migraines); }
    if(isUnanswered(inputUser, UserInput.types.fatigue.key)){ toReturn.push(UserInput.types.fatigue); } 
    
    return toReturn;
};

const getDosage = getDefaultDosage;
const getDescription = (inputUser, inputCalculated) => {
	return `
    Coq10 Supreme formulations provide dual-action cardiovascular support with patented ingredients 
    selected for their individual roles in promoting heart health. MaxSimil® is a highly absorbable 
    monoglyceride fish oil. Kaneka Q10®, a naturally pure form of bioidentical CoQ10 (available in 
    50 mg and 100 mg), is well-researched for boosting energy production in the heart and protecting 
    the cardiovascular system from oxidative stress.*`;
};
const title = "CoQ10";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00033", price: 50, servings: 60}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
    getSortValue,
    getDescription,
    getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};