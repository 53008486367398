import UserInput from '../../inputs/user/index.js';
import { isUnanswered, getDefaultSortValue, isNo, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

 import image1 from './bilebooster1.jpg';
// import doc1 from './bilebooster1.pdf';

const getEvaluationWithoutContrainsdications = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(inputUser[UserInput.types.yellowEyesSeverity.key] === "mild" || inputUser[UserInput.types.yellowEyesSeverity.key] === "moderate" 
	//|| inputUser[UserInput.types.yellowEyesSeverity.key] === "severe"
	){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.yellowEyesSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
}
const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getEvaluationWithoutContrainsdications(inputUser, inputCalculated);
	if(toReturn.recommended
		&& isNo(inputUser, UserInput.types.takeAnticoagulantsOtherMedications.key)
		&& isNo(inputUser, UserInput.types.takeNifedipineMedications.key)){
		toReturn.inputUserKeys.push(UserInput.types.takeAnticoagulantsOtherMedications.key);
		toReturn.inputUserKeys.push(UserInput.types.takeNifedipineMedications.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}else{
		toReturn = getDefaultEvaluation();
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.yellowEyesSeverity.key)){
		toReturn.push(UserInput.types.yellowEyesSeverity);
	}
	if(toReturn.length === 0 && getEvaluationWithoutContrainsdications(inputUser, inputCalculated).recommended){
		if(isUnanswered(inputUser,UserInput.types.takeAnticoagulantsOtherMedications.key)){
			toReturn.push(UserInput.types.takeAnticoagulantsOtherMedications);
		}
		if(isUnanswered(inputUser,UserInput.types.takeNifedipineMedications.key)){
			toReturn.push(UserInput.types.takeNifedipineMedications);
		}
	}
	return toReturn;
};

const getDosage = getDefaultDosage;

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(inputUser[UserInput.types.yellowEyesSeverity.key] === "mild"){
		sortValue -= 100;
	}else if(inputUser[UserInput.types.yellowEyesSeverity.key] === "moderate"){
		sortValue -= 200;
	// }else if(inputUser[UserInput.types.yellowEyesSeverity.key] === "severe"){
	// 	sortValue -= 300;
	}
	return sortValue;
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	Bile Booster helps provide support for multiple aspects of the biliary system by incorporating a special blend of phytonutrients 
	and plant-based enzymes.* Each capsule includes over 90 mg of standardized phospholipids and 325 mg of high-quality, 
	standardized dandelion root extract. This formula can also be useful as part of a detoxification program.*
	`;
};
const title = "Bile Booster";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "BILE-BOOSTER", price: 21, servings: 90}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	// docs: [{ src: doc1 }]
};