import React from 'react';
import { Jumbotron } from 'reactstrap';
import './Home.css';
import { Button, Col, Row, Card, CardText, CardBody, Link, CardTitle, CardSubtitle, CardFooter, CardImg, CardImgOverlay, CardLink, CardGroup, Container } from 'reactstrap';
import { useHistory } from "react-router-dom";
import {ReactComponent as OptimizingYou} from './optimizingyou.svg';
import {ReactComponent as ButtonCarat} from '../Components/button-carat.svg';
import SingleSymptom from '../Recommender/SingleSymptom.js';


const headerBackgroundStyle = {
	backgroundImage : 'url(\'./images/compound.jpg\')',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center',
	backgroundSize: 'cover',
	minHeight: '450px',
}
const headerStepsSquare = {
	position: "absolute", 
	bottom: "10%", 
	right: "13%",
	padding: "8%",
	backgroundImage: 'linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF22 10%, #FFFFFF22 90%, #FFFFFF00 100%),linear-gradient(#FFFFFF00 0%, #FFFFFF22 10%, #FFFFFF22 90%, #FFFFFF00 100%)',
	backgroundRepeat: 'no-repeat',
	fontFamily: "'Yellowtail', cursive",
	color:'#505862',
	fontSize: '26pt',
}
const headerSteps = {
	position: "absolute", 
	bottom: "2%", 
	right: "6%",
	padding: "8%",
	backgroundImage: 'radial-gradient(closest-side at 50% 50%, #FFFFFF00 0%, #FFFFFF88 30% 70%, #FFFFFF00 100%)',
	backgroundRepeat: 'no-repeat',
	fontFamily: "'Yellowtail', cursive",
	color:'#505862',
	fontSize: '26pt',
	//visibility: 'hidden'
}
const headerStepsWhiteText = {
	position: "absolute", 
	bottom: "10%", 
	right: "13%",
	padding: "8%",
	backgroundImage: 'linear-gradient(90deg, #727E8A00 0%, #727E8A66 10%, #727E8A66 90%, #727E8A00 100%),linear-gradient(#727E8A00 0%, #727E8A66 10%, #727E8A66 90%, #727E8A00 100%)',
	backgroundRepeat: 'no-repeat',
	fontFamily: "'Yellowtail', cursive",
	color:'#FFFFFF',
	fontSize: '26pt',
}

const Home = (props) => {
	let history = useHistory();
	return(
		<div>
			<Container>
				<Row style={headerBackgroundStyle}>
				<Col lg={{ size: 7, offset: 5}} className="align-bottom" style={{ position: "relative"}}>
					<div style={headerSteps} id='headerSteps'>
						<ol>
							<li style={{ cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0); history.push("/calculator")}}>Complete Questionaire</li>
							<li>Input Labs (optional)</li>
							<li>Optimizing You: results</li>
						</ol>
					</div>
					</Col>
				</Row>				
			<Container>
				<Row><Col className="pt-5 pl-5 pr-5 mx-auto">
				<h2>Have specific symptoms you'd like to address?</h2>
				<SingleSymptom />
				</Col></Row>
				</Container>
			<Jumbotron fluid>
				<Row>
					<Col lg={{ size: 6, offset: 0}}>
						<Card className="p-5 mx-auto">
							<CardSubtitle><h5>Discover</h5></CardSubtitle>
							<CardTitle><h3>How do you know which supplements to take?</h3></CardTitle>
							<CardBody>Watch this video or read in our <a href="/faq#WhyFunctionalMedicineCalculator">FAQ</a></CardBody>
							<CardLink>
							<Button color="link" onClick={() => {window.scrollTo(0, 0); history.push("/calculator")}}>Get Started<ButtonCarat/></Button>
							</CardLink>
						</Card>
					</Col>
					<Col lg={{ size: 6, offset: 0}}>
						<Card>
							<div className="aspect-ratio-wrapper">
								<iframe title="How do you know which supplements to take?" width="100%" src="https://www.powtoon.com/embed/eJfCVCVZTM5/" frameBorder="0" allowFullScreen></iframe>
							</div>
						</Card>
					</Col>
				</Row>
			</Jumbotron>
			<Jumbotron fluid>
				<Row>
					<Col lg={{ size: 6, offset: 0, order: 2}}  md="12">
						<Card className="p-5 mx-auto">
							<CardSubtitle><h5>Learn</h5></CardSubtitle>
							<CardTitle><h3>Why do I feel sick if my labs are normal?</h3></CardTitle>
							<CardBody>Watch this video or read in our <a href="/faq#OptimalRange">FAQ</a></CardBody>
							<CardLink>
							<Button color="link" onClick={() => {window.scrollTo(0, 0); history.push("/calculator")}}>Get Started<ButtonCarat/></Button>
							</CardLink>
						</Card>
					</Col>
					<Col lg={{ size: 6, offset: 0, order: 1}} md="12">
						<Card>
							<div className="aspect-ratio-wrapper">
							<iframe title="why do I have symptoms if my labs are normal?" width="100%" src="https://www.youtube.com/embed/DrKdb0wNSbc?controls=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
							</div>
						</Card>
					</Col>
				</Row>
			</Jumbotron>

			<Jumbotron fluid>
				<Row>
					<Col lg={{ size: 6, offset: 0}}  md="12">
						<Card className="p-5 mx-auto">
							<CardSubtitle><h5>Take Action</h5></CardSubtitle>
							<CardTitle><h3>Why should I use your supplements?</h3></CardTitle>
							<CardBody>Watch this video or read in our <a href="/faq#WhyOurSupplements">FAQ</a></CardBody>
							<CardLink>
							<Button color="link" onClick={() => {window.scrollTo(0, 0); history.push("/calculator")}}>Get Started<ButtonCarat/></Button>
							</CardLink>
						</Card>
					</Col>
					<Col lg={{ size: 6, offset: 0}} md="12">
						<Card>
							<div className="aspect-ratio-wrapper">
							<iframe title="Why should I use your supplements?" width="100%" src="https://www.youtube.com/embed/hAz_v1sU2HI?controls=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
							</div>
						</Card>
					</Col>
				</Row>
			</Jumbotron>
			</Container>
		</div>
	);
};
export default Home;