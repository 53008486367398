import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './biozyme1.jpg';
import doc1 from './biozyme1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.sinusPressure.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.sinusPressure.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.sinusPressure.key)){
		toReturn.push(UserInput.types.sinusPressure);
	}
	return toReturn;
};


const getDosage = (inputUser, inputCalculated) => {
	return {
		description : "2 servings, 2 times per day.",
		servingsPerDay : 4
	};
	return "2 capsules twice a day";
};

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	return sortValue;
};

const getDescription = (inputUser, inputCalculated) => {
	return `BioZyme affects cytokine and eicosanoid balance, promotes joint comfort, 
	and supports the body’s ability to maintain tissue integrity. Proteolytic enzymes in 
	BioZyme may break down proteins and complexes that can be produced as a result of 
	injury and tissue damage. This activity is believed to aid nutrient and oxygen 
	delivery and may help speed the body’s ability to recover and heal. Acid-resistant 
	capsules facilitate systemic delivery of enzymes.*`;
};
const title = "BioZyme";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00020", price: 41, servings: 100}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};