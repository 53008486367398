import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import thyroid from '../helpers/thyroid.js';
import { isUnanswered, isOptimal, isBelowOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isBelowStandard, getDefaultDosage} from '../utilities';

import image1 from './selenium1.jpg';
//import doc1 from './selenium1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();
    
    if(thyroid.isHashimotos(inputUser, inputCalculated)){
        let t4RangeOptimalKeys = [];
        if(isOptimal(inputCalculated,CalculatedInput.types.freeT4Range.key)){
            t4RangeOptimalKeys.push(CalculatedInput.types.freeT4Range.key);
        }
        if(isOptimal(inputCalculated,CalculatedInput.types.totalT4Range.key)){
            t4RangeOptimalKeys.push(CalculatedInput.types.totalT4Range.key);
            /* if T3, T4, TT4 or TT3 below optimal range increase value 150
            if any of the above are below standard range increaes value 150
            if on thyroid meds increase value 150
            if complaints of tiredness or energy issues incrase value 50
            */
        }

        let t3RangeBelowOptimalKeys = [];
        if(isBelowOptimal(inputCalculated,CalculatedInput.types.freeT3Range.key)){
            t3RangeBelowOptimalKeys.push(CalculatedInput.types.freeT3Range.key);
        }
        if(isBelowOptimal(inputCalculated,CalculatedInput.types.totalT3Range.key)){
            t3RangeBelowOptimalKeys.push(CalculatedInput.types.totalT3Range.key);
        }

        if(t4RangeOptimalKeys.length > 0 && t3RangeBelowOptimalKeys.length > 0){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(...t4RangeOptimalKeys);
            toReturn.inputCalculatedKeys.push(...t3RangeBelowOptimalKeys);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
        }
    }
	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
    /* if T3, T4, TT4 or TT3 below optimal range increase value 150
            if any of the above are below standard range increaes value 150
            if on thyroid meds increase value 150
            if complaints of tiredness or energy issues incrase value 50
            */
    // Craig Todo: Thyroid med question?  What tiredness or energy issue questions specificall?
    let belowOptCount = 0;
    if(isBelowStandard(inputCalculated,CalculatedInput.types.freeT3Range.key)){
        sortValue -= 150;
    }else if(isBelowOptimal(inputCalculated,CalculatedInput.types.freeT3Range.key)){
        belowOptCount ++;
    }
    if(isBelowStandard(inputCalculated,CalculatedInput.types.totalT3Range.key)){
        sortValue -= 150;
    }else if(isBelowOptimal(inputCalculated,CalculatedInput.types.totalT3Range.key)){
        belowOptCount ++;
    }
    if(isBelowStandard(inputCalculated,CalculatedInput.types.freeT4Range.key)){
        sortValue -= 150;
    }else if(isBelowOptimal(inputCalculated,CalculatedInput.types.freeT4Range.key)){
        belowOptCount ++;
    }
    if(isBelowStandard(inputCalculated,CalculatedInput.types.totalT4Range.key)){
        sortValue -= 150;
    }else if(isBelowOptimal(inputCalculated,CalculatedInput.types.totalT4Range.key)){
        belowOptCount ++;
    }

    if(belowOptCount > 0){
        sortValue -= 150;
    }
	
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    toReturn.push(...thyroid.getHashimotosPrompts(inputUser, inputCalculated));
    
    if(thyroid.isHashimotos(inputUser, inputCalculated)){
        if(isUnanswered(inputUser,UserInput.types.freeT3.key)){
            toReturn.push(UserInput.types.freeT3);
        }
        if(isUnanswered(inputUser,UserInput.types.totalT3.key)){
            toReturn.push(UserInput.types.totalT3);
        }
        if(isUnanswered(inputUser,UserInput.types.freeT4.key)){
            toReturn.push(UserInput.types.freeT4);
        }
        if(isUnanswered(inputUser,UserInput.types.totalT4.key)){
            toReturn.push(UserInput.types.totalT4);
        }
    }
    return toReturn;
};

const getDosage = getDefaultDosage;
const getDescription = (inputUser, inputCalculated) => {
	return `
    Selenomethionine serves as a storage form of selenium. It is incorporated into 
    proteins and released as the proteins are catabolized.*`;
};
const title = "Selenium";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "SELENIUM", price: 11, servings: 60}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
    getSortValue,
    getDescription,
    getPrices,
	images: [{ src: image1 }],
	// docs: [{ src: doc1 }]	
	
};