import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './biomebalance1.png';
//import doc1 from './allercalm1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(inputUser[UserInput.types.bloatingAfterMeals.key] === "after mostly sugar or carbohydrate meals"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bloatingAfterMeals.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(inputUser[UserInput.types.sinusInfections.key] === "mild" 
		|| inputUser[UserInput.types.sinusInfections.key] === "moderate"
		|| inputUser[UserInput.types.sinusInfections.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.sinusInfections.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.nailFungus.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nailFungus.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

/* if bloating avter meals increase value 150*/
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(inputUser[UserInput.types.bloatingAfterMeals.key] === "after mostly sugar or carbohydrate meals"){
		sortValue -= 75;
		if(isYes(inputUser,UserInput.types.acne.key)){
			sortValue -= 100;	
		}
	}
	if(inputUser[UserInput.types.sinusInfections.key] === "mild" 
		|| inputUser[UserInput.types.sinusInfections.key] === "moderate"
		|| inputUser[UserInput.types.sinusInfections.key] === "severe"){
		sortValue -= 100;
	}
	if(isYes(inputUser, UserInput.types.whiteTongueCoating.key)){
		sortValue -= 100;
	}
	if(isYes(inputUser, UserInput.types.nailFungus.key)){
		sortValue -= 200;
	}
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.bloatingAfterMeals.key)){
		toReturn.push(UserInput.types.bloatingAfterMeals);
	}else if (isYes(inputUser, UserInput.types.bloatingAfterMeals.key) && isUnanswered(inputUser, UserInput.types.acne.key)){
        toReturn.push(UserInput.types.acne);
    }
	if(isUnanswered(inputUser, UserInput.types.sinusInfections.key)){
		toReturn.push(UserInput.types.sinusInfections);
	}
	if(isUnanswered(inputUser, UserInput.types.whiteTongueCoating.key)){
		toReturn.push(UserInput.types.whiteTongueCoating);
	}
	if(isUnanswered(inputUser, UserInput.types.nailFungus.key)){ toReturn.push(UserInput.types.nailFungus); }	
	return toReturn;
};
const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.bloatingAfterMeals.key] === "after mostly sugar or carbohydrate meals"){
		return {
			description : "1 serving, 3 times per day.",
			servingsPerDay : 3
		};
		return "Three times a day";
	}
	return getDefaultDosage();
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Biome Balance is a blend of botanical extracts with a long history of use for supporting a healthy gastrointestinal microbial 
	balance.* The botanical substances in Biome Balance™ are commonly represented on sensitivity testing provided by the major 
	functional laboratories performing stool analysis.*`;
};
const title = "Biome Balance";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "BIOME-BALANCE", price: 43, servings: 60}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	images: [{ src: image1 }],
	// docs: [{ src: doc1 }],
	getDescription,
	getPrices
};