import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import thyroid from '../helpers/thyroid.js';
import { isUnanswered, isBelowStandard, isAboveStandard, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(thyroid.isHashimotos(inputUser, inputCalculated)){
        if(isAboveStandard(inputCalculated,CalculatedInput.types.freeT4Range.key)){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.freeT4Range.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat(["Hashimotos: true"]);
        } else if (isAboveStandard(inputCalculated,CalculatedInput.types.totalT4Range.key)){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.freeT4Range.key);
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.totalT4Range.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat(["Hashimotos: true"]);
        } else if (isAboveStandard(inputCalculated,CalculatedInput.types.freeT3Range.key)){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.freeT3Range.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat(["Hashimotos: true"]);
        } else if (isAboveStandard(inputCalculated,CalculatedInput.types.totalT3Range.key)){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.totalT3Range.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat(["Hashimotos: true"]);
        }
    }

    if(thyroid.isNonHashimotos(inputUser, inputCalculated)){
        let keysAboveStandard = [];
        if (isAboveStandard(inputCalculated, CalculatedInput.types.freeT3Range.key)){
            keysAboveStandard.push(CalculatedInput.types.freeT3Range.key);
        }
        if (isAboveStandard(inputCalculated, CalculatedInput.types.totalT3Range.key)){
            keysAboveStandard.push(CalculatedInput.types.totalT3Range.key);
        }
        if (isAboveStandard(inputCalculated, CalculatedInput.types.freeT4Range.key)){
            keysAboveStandard.push(CalculatedInput.types.freeT4Range.key);
        }
        if (isAboveStandard(inputCalculated, CalculatedInput.types.totalT4Range.key)){
            keysAboveStandard.push(CalculatedInput.types.totalT4Range.key);
        }
        if(keysAboveStandard.length >= 1){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(...keysAboveStandard);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat(["Hashimotos: false"]);
        }
        let keysBelowStandard = [];
        if (isBelowStandard(inputCalculated, CalculatedInput.types.freeT3Range.key)){
            keysBelowStandard.push(CalculatedInput.types.freeT3Range.key);
        }
        if (isBelowStandard(inputCalculated, CalculatedInput.types.totalT3Range.key)){
            keysBelowStandard.push(CalculatedInput.types.totalT3Range.key);
        }
        if (isBelowStandard(inputCalculated, CalculatedInput.types.freeT4Range.key)){
            keysBelowStandard.push(CalculatedInput.types.freeT4Range.key);
        }
        if (isBelowStandard(inputCalculated, CalculatedInput.types.totalT4Range.key)){
            keysBelowStandard.push(CalculatedInput.types.totalT4Range.key);
        }
        if(keysBelowStandard.length >= 1){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(...keysBelowStandard);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat(["Hashimotos: false"]);
        }
    }

	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    toReturn.push(...thyroid.getHashimotosPrompts(inputUser, inputCalculated));
    if(thyroid.isHashimotos(inputUser, inputCalculated)){
        if(isUnanswered(inputUser,UserInput.types.freeT3.key)){
            toReturn.push(UserInput.types.freeT3);
        }
        if(isUnanswered(inputUser,UserInput.types.totalT3.key)){
            toReturn.push(UserInput.types.totalT3);
        }
        if(isUnanswered(inputUser,UserInput.types.freeT4.key)){
            toReturn.push(UserInput.types.freeT4);
        }
        if(isUnanswered(inputUser,UserInput.types.totalT4.key)){
            toReturn.push(UserInput.types.totalT4);
        }
    }
    toReturn.push(...thyroid.getNonHashimotosPrompts(inputUser, inputCalculated));
    if(thyroid.isNonHashimotos(inputUser, inputCalculated)){
        if(isUnanswered(inputUser,UserInput.types.freeT3.key)){
            toReturn.push(UserInput.types.freeT3);
        }
        if(isUnanswered(inputUser,UserInput.types.totalT3.key)){
            toReturn.push(UserInput.types.totalT3);
        }
        if(isUnanswered(inputUser,UserInput.types.freeT4.key)){
            toReturn.push(UserInput.types.freeT4);
        }
        if(isUnanswered(inputUser,UserInput.types.totalT4.key)){
            toReturn.push(UserInput.types.totalT4);
        }
    }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "Talk To Your Doctor About Your Thyroid Medication Dosage",
	getDosage,
	category : categories.behaviors,
	getSortValue : getDefaultSortValue
};