import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isOutsideOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';

const getRangeNotOptimalKeys = (inputCalculated) => {
	let rangeNotOptimalKeys = [];
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.sodiumRange.key)){
		rangeNotOptimalKeys.push(CalculatedInput.types.sodiumRange.key);
	}
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.potassiumRange.key)){
		rangeNotOptimalKeys.push(CalculatedInput.types.potassiumRange.key);
	}
	return rangeNotOptimalKeys;
};
const getExtraFactorYesKeys = (inputUser) => {
	let extraFactorYesKeys = [];
	if(inputUser[UserInput.types.craveSalt.key] === "yes"){
		extraFactorYesKeys.push(UserInput.types.craveSalt.key);
	}
	if(inputUser[UserInput.types.fatigue.key] === "mild" || inputUser[UserInput.types.fatigue.key] === "moderate" || inputUser[UserInput.types.fatigue.key] === "severe"){
		extraFactorYesKeys.push(UserInput.types.fatigue.key);
	}
	if(inputUser[UserInput.types.stress.key] === "yes"){
		extraFactorYesKeys.push(UserInput.types.stress.key);
	}
	if(inputUser[UserInput.types.eatALotOfSalt.key] === "yes"){
		extraFactorYesKeys.push(UserInput.types.eatALotOfSalt.key);
	}
	return extraFactorYesKeys;
};

/* if fatigue is Severe = Recommend additional testing of Adrenocortex Saliva test*/
const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(inputUser[UserInput.types.fatigue.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.fatigue.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	const rangeNotOptimalKeys = getRangeNotOptimalKeys(inputCalculated);
	const extraFactorYesKeys = getExtraFactorYesKeys(inputUser);
	if(rangeNotOptimalKeys.length > 0 && extraFactorYesKeys.length > 0){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(...extraFactorYesKeys);
		toReturn.inputCalculatedKeys.push(...rangeNotOptimalKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.sodium.key)){ toReturn.push(UserInput.types.sodium); } 
	if(isUnanswered(inputUser, UserInput.types.potassium.key)){ toReturn.push(UserInput.types.potassium); } 
	if(isUnanswered(inputUser, UserInput.types.fatigue.key)){ toReturn.push(UserInput.types.fatigue); }
	
	const rangeNotOptimalKeys = getRangeNotOptimalKeys(inputCalculated);
	if(rangeNotOptimalKeys.length > 0){
		if(isUnanswered(inputUser, UserInput.types.craveSalt.key)){
			toReturn.push(UserInput.types.craveSalt)
		}
		// Moved this answer our of "extra factor", since just fatigue == severe can prompt testing
		// if(isUnanswered(inputUser, UserInput.types.fatigue.key)){
		// 	toReturn.push(UserInput.types.fatigue);
		// }
		if(isUnanswered(inputUser, UserInput.types.stress.key)){
			toReturn.push(UserInput.types.stress);
		}
		if(isUnanswered(inputUser, UserInput.types.eatALotOfSalt.key)){
			toReturn.push(UserInput.types.eatALotOfSalt);
		}
	}
	return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	return "";
};

export default {
	getEvaluation,
	getPrompts,
	title : "Adreno Cortex Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};