import serializer from './../Recommender/persister/serializer.js';
import { allOutputs } from '../Recommender/outputs/index.js';
/*
	{
		allerCalm : {
			123: {
				qty : 3,
				price: 33,
				title: "Aller Calm 1400"
			}
		},
		dailyCalm : {
			321 : {
				qty : 2,
				price : 12,
				title: "Daily Calm Cherry"
			}
		}
	}

*/


export const addToCart = (cart, key, sku, qty, price, title) => {
	let toReturn = { ...cart };
	toReturn[key] = toReturn[key] || {};
	if(qty > 0 && price > 0){
		toReturn[key][sku] = {
			qty,
			price,
			title
		};
	}else{
		delete toReturn[key][sku];
	}
	if(Object.keys(toReturn[key]).length === 0){
		delete toReturn[key];
	}
	return toReturn;
};
export const getQtyAndPrice = (cart, key, sku) => {
	if(cart === undefined || cart[key] === undefined || cart[key][sku] === undefined){
		return {
			qty : 0,
			price : 0,
			title : ""
		};
	}else{
		return cart[key][sku];
	}
};
export const getCount = (cart) => {
	let count = 0;
	Object.keys(cart).forEach((outKey) => {
		count += Object.keys(cart[outKey]).length;
	});
	return count;
};
export const deserializeCartToJson = (cart) => {
	let cartJson = {};
	Object.keys(cart).forEach((itemKey) => {
		let itemValue = "";
		Object.keys(cart[itemKey]).forEach((skuKey) => {
			itemValue += (itemValue.length > 0 ? "|" : "") + skuKey + ":" + cart[itemKey][skuKey].qty;
		});
		cartJson[itemKey] = itemValue
	});
	return cartJson;
};
export const serializeJsonToCart = (cartJson) => {
	let cart = {};
	Object.keys(cartJson).forEach((itemKey) => {
		const prices = allOutputs.filter(item => item.title === itemKey)[0].getPrices();
		cartJson[itemKey].split('|').forEach((skuValues) => {
			const [ sku, qty ] = skuValues.split(':');
			const price = prices.filter(skuPrice => skuPrice.sku === sku || (sku === "undefined" && skuPrice.sku === undefined))[0];
			cart[itemKey] = cart[itemKey] || {};
			cart[itemKey][sku] = {
				qty : Number(qty),
				price: price.price,
				title: price.title
			};
		});
	});
	return cart;
};
export default {
	addToCart,
	getQtyAndPrice,
	getCount,
	deserializeCartToJson,
	serializeJsonToCart
};