import UserInput from '../../inputs/user/index.js';
import { isUnanswered, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(inputUser[UserInput.types.diarrheaSeverity.key] === "mild"
		|| inputUser[UserInput.types.diarrheaSeverity.key] === "moderate"
		|| inputUser[UserInput.types.diarrheaSeverity.key] === "severe"
	){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.diarrheaSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};
/* if diarrhea
mild - increase 200
moderate - increase 300
severe increase 400 */
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(inputUser[UserInput.types.diarrheaSeverity.key] === "mild"){
		sortValue -= 200;
	}else if (inputUser[UserInput.types.diarrheaSeverity.key] === "moderate"){
		sortValue -= 300;
	}else if (inputUser[UserInput.types.diarrheaSeverity.key] === "severe"){
		sortValue -= 400;
	}
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.diarrheaSeverity.key)){
        toReturn.push(UserInput.types.diarrheaSeverity);
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.diarrheaSeverity.key] === "severe"){
		return {
			description : "1 serving, twice per day.",
			servingsPerDay : 2
		};
	}
	if(inputUser[UserInput.types.diarrheaSeverity.key] === "moderate"){
		return {
			description : "1 serving, twice per day.",
			servingsPerDay : 2
		};
	}
	if(inputUser[UserInput.types.diarrheaSeverity.key] === "mild"){
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	}
	return getDefaultDosage();
};

export default {
	getEvaluation,
	getPrompts,
	title : "Fiber Formula",
	getDosage,
	category : categories.supplements,
	getSortValue
	
};