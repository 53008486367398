import symptoms from './symptoms.js';
import diagnosis from './diagnosis.js';
import biomarkers from './biomarkers.js';
import demographic from './demographic.js';
import behaviors from './behaviors.js';

export const groups = [
    "Neurological",
    "Digestive",
    "Immune/Inflammatory",
    "Cardiovascular",
    "Energy",
    "Hormone",
    "Skin",
    "Medications",
    "Head",//8
    "Body",
    "Basic",//10
];

// Changing these "shorten..." settings will break existing links saved in other systems like user emails and mailchimp audience contacts!!!
const shortenTakeFirst = 2;
const shortenSkip = 5;
const shortenTakeLast = 1;

const types = {
    ...symptoms,
    ...diagnosis,
    ...demographic,
    ...biomarkers,
    ...behaviors
};

export const shortenKey = (key) => {
    let ret = key.substring(0,shortenTakeFirst);
    for(let i=0;shortenTakeFirst + (i*shortenSkip) + shortenTakeLast<key.length;i++){
        ret += key[shortenTakeFirst + (i*shortenSkip)];
    }
    return ret + key.substring(key.length - shortenTakeLast -1);
};

const getMinifiedInputUser = (inputUser) => {
    let minimized = {};
    Object.keys(inputUser).forEach((key) => {
        minimized[shortenKey(key)] = inputUser[key];
    });
    return minimized;
};

const getRestoredInputUser = (minimized) => {
    minimized = minimized || {};
    const inputUserKeys = Object.keys(types);
    let restored = {};
    Object.keys(minimized).forEach((key) => {
        const matchedKey = inputUserKeys.find(k => shortenKey(k) === key);
        if(matchedKey !== undefined){
            restored[matchedKey] = minimized[key];
        }else{
            restored[key] = minimized[key];//Apparently not shortened
        }
    });
    return restored;
};

export default {
    types,
    shortenKey,
    getMinifiedInputUser,
    getRestoredInputUser    
}