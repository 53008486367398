import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.dryCrackedSkin.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.dryCrackedSkin.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.eczemaPsoriasisRosacea.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser, UserInput.types.dryCrackedSkin.key)){ toReturn.push(UserInput.types.dryCrackedSkin); }
    if(isUnanswered(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){ toReturn.push(UserInput.types.eczemaPsoriasisRosacea); }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	sortValue -= 130;	
	return sortValue;
};

export default {
	getEvaluation,
	getPrompts,
	title : "Essential Fatty Acids Test",
	getDosage,
	category : categories.tests,
	getSortValue
	
};