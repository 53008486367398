import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isYes, isAboveOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isAboveStandard, getDefaultDosage} from '../utilities';

import image1 from './bcomplete1.jpg';
import doc1 from './bcomplete1.pdf';

// Craig Todo: Evaluate getEvaluation, getPrompts, and getDosage.  I expanded them based on Sort value comments (fatigue & B12).

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.homocysteineRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.vitaminB12Range.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.vitaminB12Range.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(inputUser[UserInput.types.fatigue.key] === "mild" || inputUser[UserInput.types.fatigue.key] === "moderate" || inputUser[UserInput.types.fatigue.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.fatigue.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.nailPitting.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nailPitting.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.takeGeneralAntibiotics.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.takeGeneralAntibiotics.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

/* 
Symptoms of fatigue increase value by 100
homocysteine above optimal increase by 100
homocystein above standard increase by 200
if B12 labs below optimal increase by 100
if b12 below standard increase by 300 */

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(inputUser[UserInput.types.fatigue.key] === "mild"){
		sortValue -= 100;
	}else if(inputUser[UserInput.types.fatigue.key] === "moderate"){
		sortValue -= 100;
	}else if(inputUser[UserInput.types.fatigue.key] === "severe"){
		sortValue -= 100;
	}

	if(isYes(inputUser, UserInput.types.nailPitting.key)){
		sortValue -= 150;
	}

	if(isAboveStandard(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		sortValue -= 200;
	} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		sortValue -= 100;
	}

	if(isAboveStandard(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		sortValue -= 200;
	} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		sortValue -= 100;
	}

	if(isAboveStandard(inputCalculated, CalculatedInput.types.vitaminB12Range.key)){
		sortValue -= 300;
	} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.vitaminB12Range.key)){
		sortValue -= 100;
	}

	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	
	if(isUnanswered(inputUser, UserInput.types.fatigue.key)){ toReturn.push(UserInput.types.fatigue); } 

    if(isUnanswered(inputUser,UserInput.types.homocysteine.key)){
        toReturn.push(UserInput.types.homocysteine);
	}
	if(isUnanswered(inputUser,UserInput.types.vitaminB12.key)){
        toReturn.push(UserInput.types.vitaminB12);
	}
	if(isUnanswered(inputUser, UserInput.types.nailPitting.key)){ toReturn.push(UserInput.types.nailPitting); }
	if(isUnanswered(inputUser,UserInput.types.takeGeneralAntibiotics.key)){ toReturn.push(UserInput.types.takeGeneralAntibiotics); }
    return toReturn;
};
/* Change name to B complete */
const getDosage = getDefaultDosage;
const getDescription = (inputUser, inputCalculated) => {
	return `
	Our B-Complete contains the entire spectrum of B vitamins to support adrenal, neurological, 
	and stress-related functions. It features activated forms of vitamins B2, B6, and B12; 
	benfotiamine, a fat soluble, more physiologically active form of thiamin; and folate as 
	Quatrefolic®, which is proven to have greater stability, solubility, and bioavailability 
	over calcium salt forms of 5-MTHF.*`;
};
const title = "B Complete";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00004", price: 39, servings: 90}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};