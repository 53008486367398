import React, { useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Button, Row, Col, ListGroup, ListGroupItem, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Container} from 'reactstrap';
import ReactGA from "react-ga";
import { getPercentage, getCode } from './disc.js';
import queryString from 'query-string';
import LayoutConstants from '../LayoutConstants.js';
import { deserializeCartToJson, serializeJsonToCart } from '../Cart/Cart.js'
import serializer from './../Recommender/persister/serializer.js';

//paypal reference: https://developer.paypal.com/docs/api/orders/v2/
//async script loader: https://github.com/thinhvo0108/react-paypal-express-checkout/blob/master/src/index.js
// test CC: n

export const Qty = (props) => {
	if(props.qty === 0){
		return(
			<Button className="ml-3" size="sm" color="primary" onClick={() => props.setQty(props.qty + 1)}>Add to Cart</Button>
		);
	}else if (props.qty > 0){
		//{props.qty} <Button onClick={() => props.setQty(props.qty - 1)}>-</Button><Button onClick={() => props.setQty(props.qty + 1)}>+</Button>
		return(
			<ButtonGroup className="ml-3">
				<Button color="primary" size="sm" onClick={() => props.setQty(props.qty - 1)}>-</Button>
				<Button disabled={true} size="sm">{props.qty}</Button>
				<Button color="primary" size="sm" onClick={() => props.setQty(props.qty + 1)}>+</Button>
			</ButtonGroup>
		);
	}else{
		return null;
	}
};

const View = (props) => {
	let history = useHistory();

	const [completedOrder, setCompletedOrder] = useState(false);

	if(props.cart !== undefined && Object.keys(props.cart).length > 0){
		let total = 0.00;
		let totalDiscount = 0.00;
		let paypalOrderItems = [];
		Object.keys(props.cart).forEach((outKey) => {
			Object.keys(props.cart[outKey]).forEach((skuKey) => {
				const unitDiscount = (props.cart[outKey][skuKey].price * getPercentage(skuKey) / 100.00).toFixed(2);
				const discountedUnitPrice = (props.cart[outKey][skuKey].price - unitDiscount).toFixed(2);
				let itemTotal = props.cart[outKey][skuKey].qty * discountedUnitPrice;
				total += itemTotal ;
				totalDiscount += props.cart[outKey][skuKey].qty * unitDiscount;
				paypalOrderItems.push({
					name: props.cart[outKey][skuKey].title.substr(0,126), //Todo: limit length to 127 characters
					unit_amount : {
						currency_code: "USD",
						value: discountedUnitPrice
					},
					quantity : props.cart[outKey][skuKey].qty,
					// description : ""
					sku : skuKey
				})
			})
		});
		// if(totalDiscount > 0){
		// 	paypalOrderItems.push({
		// 		name: "Discount",
		// 		unit_amount : {
		// 			currency_code: "USD",
		// 			value: (-totalDiscount.toFixed(2))
		// 		},
		// 		quantity: 1,
		// 		sku: getCode()
		// 	});
		// }
		const createOrder = (data, actions) => {
			ReactGA.event({
				category: "Calculator",
				action: "Order Started"
			});
			return actions.order.create({
				intent: "CAPTURE",
				purchase_units: [{
					items: paypalOrderItems,
					amount: {
						currency_code: "USD",
						value: total,
						breakdown: {
						 	item_total: {
								currency_code: "USD",
								value: total
							}
						}
					}
				}]
			});
		};

		const onApprove = (data, actions) => {
			ReactGA.event({
				category: "Calculator",
				action: "Payment Approved"
			});
			setCompletedOrder(true);
			return actions.order.capture();
		};

		const onBackToCalculator = () => {
			props.clearCart();
			setCompletedOrder(false);
		};
		const onGoHome = () => {
			history.push("/");
		};
	
		
		const paypalButtonStlye = {
			shape: 'pill',
			size: 'small'
		};
		let PayPalButton = paypal.Buttons.driver('react', { React, ReactDOM }); // eslint-disable-line no-undef

		const cartLink = "/Store?cart=" + serializer.serialize(deserializeCartToJson(props.cart)) + "#Cart";

		return (
			<div id="Cart">
				<Modal isOpen={completedOrder}>
					<ModalHeader>
						Thank you for your order.
					</ModalHeader>
					<ModalBody>

					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={onGoHome}>Go Home</Button>
						<Button color="secondary" onClick={onBackToCalculator}>Back to Calculator</Button>
					</ModalFooter>
				</Modal>
					<h2 className="mt-4 m-3" ><a href={cartLink} style={{ pointer: "cursor", textDecoration: "none"}}>Cart</a></h2>
					{Object.entries(props.cart).map((cartItem, i) => {
						return <CartItem key={i} title={cartItem[0]} skuQtys={cartItem[1]} addToCart={props.addToCart}  />
					})}
					{totalDiscount > 0 ?
						<Row className="my-3">
							<Col className="text-info"><h4>Total Savings<small> ({getCode()})</small></h4></Col>
							<Col><h4 className="text-info text-right"><strong>${totalDiscount.toFixed(2)}</strong></h4></Col>
						</Row>
					: ""}
					<Row className="my-3">
						<Col><h3>Total</h3></Col>
						<Col><h3 className="text-right"><strong>${total.toFixed(2)}</strong></h3></Col>
					</Row>
					<Row>
						<Col lg={{ size: 8, offset: 2 }}>
						<PayPalButton
							locale="en_US"
							style={paypalButtonStlye}
							createOrder={createOrder}
							onApprove={onApprove}
						/>
						</Col>
					</Row>
				</div>
		);
	}else{
		if(props.redirectIfEmpty !== undefined){
			history.push(props.redirectIfEmpty);
		}
		return null;
	}
}

const CartItem = (props) => {
	return (
		<ListGroupItem id={"recommend" + props.title}>
			<Row>
				<Col>
					{
						Object.entries(props.skuQtys).map((skuQty, i) => {
							const percentage = getPercentage(skuQty[0]);
							const unitPrice = (skuQty[1].price*(100-percentage)/100.0).toFixed(2);
							return (
								<Row key={i} className="mt-1">
									<Col className="pt-2" md={4}>{skuQty[1].title} <small>({skuQty[0]})</small></Col>
									<Col className="text-right"><Qty qty={skuQty[1].qty} setQty={(qty) => props.addToCart(props.title, skuQty[0], qty, skuQty[1].price, skuQty[1].title)} /></Col>
									<Col className="pt-2 text-right">
										{percentage > 0 ?
										 <span><small><s>{skuQty[1].price.toFixed(2)}</s></small> ${unitPrice}</span> :
										 skuQty[1].price.toFixed(2)}
									</Col>
									<Col className="pt-2 text-right">
										{percentage > 0 ?
										<span><small><s>${(skuQty[1].qty * skuQty[1].price).toFixed(2)}</s></small><strong> ${(skuQty[1].qty * unitPrice).toFixed(2)}</strong></span> :
										<span><strong>${(skuQty[1].qty * skuQty[1].price).toFixed(2)}</strong></span>
										}
									</Col>
								</Row>
							);
						})
					}
				</Col>
			</Row>
		</ListGroupItem>
	);
};


export default withRouter(View);