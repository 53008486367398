import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isNo, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './seropromote1.jpg';
//import doc1 from './seropromote1.pdf';

const getEvaluationWithoutContrainsdications = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(inputUser[UserInput.types.depression.key] === "moderate"
		|| inputUser[UserInput.types.depression.key] === "severe"){
		if(isNo(inputUser, UserInput.types.takeDepressionMedication.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.depression.key);
			toReturn.inputUserKeys.push(UserInput.types.takeDepressionMedication.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}
	return toReturn;
}
const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getEvaluationWithoutContrainsdications(inputUser, inputCalculated);
	if(toReturn.recommended
		&& isNo(inputUser,UserInput.types.takePhenprocoumonMedications.key)
		&& isNo(inputUser,UserInput.types.takeCalciumChannelBlockersBloodPressureRegulators.key)
		&& isNo(inputUser,UserInput.types.takeChemotherapeuticOtherMedications.key)
		&& isNo(inputUser,UserInput.types.takeCardiacGycosidesCardiovascularMedications.key)
		&& isNo(inputUser,UserInput.types.takeClozapineMedications.key)
		&& isNo(inputUser,UserInput.types.takeDocetaxelMedications.key)){
			toReturn.inputUserKeys.push(UserInput.types.takePhenprocoumonMedications.key);
		    toReturn.inputUserKeys.push(UserInput.types.takeCalciumChannelBlockersBloodPressureRegulators.key);
		    toReturn.inputUserKeys.push(UserInput.types.takeChemotherapeuticOtherMedications.key);
		    toReturn.inputUserKeys.push(UserInput.types.takeCardiacGycosidesCardiovascularMedications.key);
		    toReturn.inputUserKeys.push(UserInput.types.takeClozapineMedications.key);
		    toReturn.inputUserKeys.push(UserInput.types.takeDocetaxelMedications.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}else{
		toReturn = getDefaultEvaluation();
	}
	return toReturn;
};

/* if yes to depression and not on depression or anxiety meds
mild - increase value 100
moderate - incfrease value 250
Severe - rec consult with primary care phycisian and increase value 450*/
// Craig Todo: specific anxiety med question? Create talk to primary care physician?
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(isNo(inputUser, UserInput.types.takeDepressionMedication.key)){
		if(inputUser[UserInput.types.depression.key] === "mild"){
			sortValue -= 100;
		} else if (inputUser[UserInput.types.depression.key] === "moderate"){
			sortValue -= 250;
		} else if (inputUser[UserInput.types.depression.key] === "severe"){
			sortValue -= 450;
		}
	}		
		
	return sortValue;
};
const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.depression.key)){
        toReturn.push(UserInput.types.depression);
	}
	if(inputUser[UserInput.types.depression.key] === "moderate"
	|| inputUser[UserInput.types.depression.key] === "severe"){
		if(isUnanswered(inputUser,UserInput.types.takeDepressionMedication.key)){
			toReturn.push(UserInput.types.takeDepressionMedication);
		}
	}

	if(toReturn.length === 0 && getEvaluationWithoutContrainsdications(inputUser, inputCalculated).recommended){
		if(isUnanswered(inputUser,UserInput.types.takePhenprocoumonMedications.key)){
			toReturn.push(UserInput.types.takePhenprocoumonMedications);
		}
		if(isUnanswered(inputUser,UserInput.types.takeCalciumChannelBlockersBloodPressureRegulators.key)){
			toReturn.push(UserInput.types.takeCalciumChannelBlockersBloodPressureRegulators);
		}
		if(isUnanswered(inputUser,UserInput.types.takeChemotherapeuticOtherMedications.key)){
			toReturn.push(UserInput.types.takeChemotherapeuticOtherMedications);
		}
		if(isUnanswered(inputUser,UserInput.types.takeCardiacGycosidesCardiovascularMedications.key)){
			toReturn.push(UserInput.types.takeCardiacGycosidesCardiovascularMedications);
		}
		if(isUnanswered(inputUser,UserInput.types.takeClozapineMedications.key)){
			toReturn.push(UserInput.types.takeClozapineMedications);
		}
		if(isUnanswered(inputUser,UserInput.types.takeDocetaxelMedications.key)){
			toReturn.push(UserInput.types.takeDocetaxelMedications);
		}
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.depression.key] === "moderate"){
		return {
			description : "1 or 2 servings per day.",
			servingsPerDay : 1.5
		};
	}else if(inputUser[UserInput.types.depression.key] === "severe"){
		return {
			description : "1 to 3 servings per day.",
			servingsPerDay : 2
		};
	}

	return getDefaultDosage();
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Sero Promote Active helps to support the serotonergic system using nutrients, targeted amino acids, and cofactors.* 
	This formula includes high-potency vitamin B12 (1000 mcg per serving), vitamin B6, and niacin, and it is an excellent source 
	of folate. Key ingredients include SAMe, 5-HTP, and high-quality, standardized St. John's wort extract.

Benefits

*	Designed to support the serotonergic system*
*	Ingredients selected to support motivation*
*	Formulated to support a feeling of well-being*
*	Ingredients can aid in supporting healthy eating habits*`;
};
const title = "Sero Promote";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "SERO-PROMOTE", price: 57, servings: 90}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	//docs: [{ src: doc1 }]
	
};