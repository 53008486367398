import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isAboveOptimalButInStandard, isBelowOptimalButInStandard, isBelowOptimal, isBelowStandard, isAboveStandard, isYes, isNo, isOutsideOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './immunobase1.png';
//import doc1 from './allercalm1.pdf';

const getEvaluationWithoutContrainsdications = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.frequentlyGetSick.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.frequentlyGetSick.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isAboveOptimalButInStandard(inputCalculated,CalculatedInput.types.totalWBCsRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.totalWBCsRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isBelowOptimalButInStandard(inputCalculated,CalculatedInput.types.totalWBCsRange.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.frequentlyDehydrated.key);
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.totalWBCsRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isAboveStandard(inputCalculated,CalculatedInput.types.totalWBCsRange.key) && isNo(inputUser, UserInput.types.autoimmune.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.autoimmune.key);
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.totalWBCsRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isBelowStandard(inputCalculated,CalculatedInput.types.totalWBCsRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.totalWBCsRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.neutrophilsRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.neutrophilsRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.lymphocytesRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.lymphocytesRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getEvaluationWithoutContrainsdications(inputUser, inputCalculated);
	if(toReturn.recommended && isNo(inputUser, UserInput.types.takeImmunosuppressantMedications.key)){
		toReturn.inputUserKeys.push(UserInput.types.takeImmunosuppressantMedications.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}else{
		toReturn = getDefaultEvaluation();
	}
	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(isYes(inputUser, inputUser,UserInput.types.frequentlyGetSick.key)){
		sortValue -= 50;
	}/* is yes increase value by 50*/

	/* if WBC below optimal increase value by 50, if below standard increase value by 100*/
	if(isBelowStandard(inputCalculated,CalculatedInput.types.totalWBCsRange.key)){
        sortValue -= 100;
	}else if(isBelowOptimal(inputCalculated,CalculatedInput.types.totalWBCsRange.key)){
        sortValue -= 50;
	}


	if(isYes(inputUser, UserInput.types.autoimmune.key)){
		sortValue -= 50;
	}/* if yes on Automimmune increase value by 50*/

	return sortValue;
};
const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.totalWBCs.key)){
        toReturn.push(UserInput.types.totalWBCs);
	}
	if(isUnanswered(inputUser,UserInput.types.neutrophils.key)){
        toReturn.push(UserInput.types.neutrophils);
	}
	if(isUnanswered(inputUser,UserInput.types.lymphocytes.key)){
        toReturn.push(UserInput.types.lymphocytes);
	}
	if(isAboveStandard(inputCalculated, CalculatedInput.types.totalWBCsRange.key)){
		if(isUnanswered(inputUser,UserInput.types.autoimmune.key)){
			toReturn.push(UserInput.types.autoimmune);
		}	
	}
	if(isUnanswered(inputUser,UserInput.types.frequentlyGetSick.key)){
        toReturn.push(UserInput.types.frequentlyGetSick);
	}
	if(toReturn.length === 0 && getEvaluationWithoutContrainsdications(inputUser, inputCalculated).recommended){
		if(isUnanswered(inputUser,UserInput.types.takeImmunosuppressantMedications.key)){
			toReturn.push(UserInput.types.takeImmunosuppressantMedications);
		}
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(isBelowStandard(inputCalculated,CalculatedInput.types.totalWBCsRange.key)){
		return {
			description : "2 servings per day.",
			servingsPerDay : 2
		};
	}
	if(isAboveOptimalButInStandard(inputCalculated,CalculatedInput.types.totalWBCsRange.key) || isBelowOptimalButInStandard(inputCalculated,CalculatedInput.types.totalWBCsRange.key)){
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	}
	if(isAboveStandard(inputCalculated,CalculatedInput.types.totalWBCsRange.key) && isNo(inputUser, UserInput.types.autoimmune.key)){
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	}
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.neutrophilsRange.key) || isOutsideOptimal(inputCalculated, CalculatedInput.types.lymphocytesRange.key)){
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	}
	return getDefaultDosage();
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	Immuno Base is a herbal formula that is designed to support healthy immune system function during cold and flu season.* It 
	contains herbs that support normal natural killer (NK) cell activity and the balance of cytokines, which are the regulatory 
	proteins released by immune cells as part of a normal immune system response.* The standardized herbs in this formula contain 
	optimal and consistent amounts of the most active ingredients. Immuno Base is suitable for long term use and for all age 
	groups.*`;
};
const title = "Immuno Base";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "IMMUNOBASE", price: 37, servings: 120}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	getSortValue,
	category : categories.supplements,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	// docs: [{ src: doc1 }],
	
};