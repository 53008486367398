import UserInput from '../../inputs/user/index.js';
import { getUnknownKeys, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';
import { getEvaluation as getComprehensiveTestEvaluation } from './fullbloodpanel.js';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	
	if(getComprehensiveTestEvaluation(inputUser, inputCalculated).recommended){
		return toReturn;
	}
    
    const unknownKeys = getUnknownKeys(inputUser, [
		UserInput.types.totalWBCs.key,
		UserInput.types.neutrophils.key, 
		//UserInput.types.bands.key,
		UserInput.types.lymphocytes.key,
		UserInput.types.monocytes.key,
		UserInput.types.basophils.key,
		UserInput.types.eosinophils.key]);

    if(unknownKeys.length > 0){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(...unknownKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "White Blood Cell (WBC) Differential Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};