import adrenal from './adrenal.js';
import stoolanalysis from './stoolanalysis.js';
import adrenocortex from './adrenocortex.js';
import insulinlevel from './insulinlevel.js';
import retestprote from './retestprotein';
import ironpanel from './ironpanel.js';
import lipidspanel from './lipidspanel';
import thyroid from './thyroid.js';
import toxicity from './toxicity.js';
import allergy from './allergy.js';
import foodsensitivity from './foodsensitivity.js';
import kidneypanel from './kidneypanel.js';
import electrolytepanel from './electrolytepanel.js';
import liverfunctionpanel from './liverfunctionpanel.js';
import autoimmunethyroid from './autoimmunethyroid.js';
import inflammation from './inflammation';
import completebloodcount from './completebloodcount.js';
import whitebloodcell from './whitebloodcell.js';
import infection from './infection.js';
import mthfr from './mthfr.js';
import dhea from './dhea.js';
import sibo from './sibo.js';
import vitamind from './vitamind.js';
import organicacidstest from './organicacidstest.js';
import autoimmuneprofile from './autoimmuneprofile.js';
import autoimmunepanel from './autoimmunepanel.js';
import fullbloodpanel from './fullbloodpanel.js';
import yeastcandida from './yeastcandida.js';

//import  from './.js';


export default [
	stoolanalysis,
	adrenocortex,
	insulinlevel,
	retestprote,
	ironpanel,
	lipidspanel,
	thyroid,
	adrenal,
	toxicity,
	allergy,
	foodsensitivity,
	kidneypanel,
	electrolytepanel,
	liverfunctionpanel,
	autoimmunethyroid,
	inflammation,
	completebloodcount,
	whitebloodcell,
	infection,
	mthfr,
	dhea,
	sibo,
	vitamind,
	organicacidstest,
	autoimmuneprofile,
	autoimmunepanel,
	fullbloodpanel,
	yeastcandida
];