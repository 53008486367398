import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './fungalformula1.jpg';
//import doc1 from './fungalformula1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "mild"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.yeastInfectionSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}else if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "moderate"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.yeastInfectionSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}else if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.yeastInfectionSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if(isYes(inputUser, UserInput.types.nailFungus.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nailFungus.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	return toReturn;
};/* if yes to yeast infections
mild - increase value 200
moderate - increase value 300
severe - increase value 400 */
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "mild"){
		sortValue -= 200;
	}else if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "moderate"){
		sortValue -= 300;
	}else if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"){
		sortValue -= 400;
	}

	if(isYes(inputUser, UserInput.types.whiteTongueCoating.key)){
		sortValue -= 100;
	}
	if(isYes(inputUser, UserInput.types.nailFungus.key)){
		sortValue -= 200;
	}
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.yeastInfectionSeverity.key)){
        toReturn.push(UserInput.types.yeastInfectionSeverity);
	}
	if(isUnanswered(inputUser, UserInput.types.whiteTongueCoating.key)){
		toReturn.push(UserInput.types.whiteTongueCoating);
	}	
	if(isUnanswered(inputUser, UserInput.types.nailFungus.key)){ toReturn.push(UserInput.types.nailFungus); }	
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "mild"){
		return getDefaultDosage();
	}
	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "moderate"){
		return {
			description : "1 serving, twice per day.",
			servingsPerDay : 2
		};
	}
	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"){
		return {
			description : "1 serving, twice per day.",
			servingsPerDay : 2
		};
	}
	return getDefaultDosage();
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Fungal Formula is intended to support a healthy intestinal environment.* This formula combines pau d'arco with high-quality, 
	standardized extracts of uva ursi and cat's claw to support the intestinal mucosal barrier and a healthy gut microbial balance.* Other key ingredients include undecylenic acid and caprylic acid.
 

Benefits

*	Supports a healthy intestinal environment, the intestinal mucosal barrier, and a healthy gut-microbial balance.*
*	This formula combines pau d'arco with high-quality, standardized extracts of uva ursi and cat's claw.*
*	Includes undecylenic acid and caprylic acid.*`;
};
const title = "Fungal Formula";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "FUNGAL-FORMULA", price: 26, servings: 90}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	//docs: [{ src: doc1 }]
	
};