import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isBelowStandard, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isBelowOptimal, getDefaultDosage} from '../utilities';

import image1 from './ironglycinate1.jpg';
import doc1 from './ironglycinate1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();
    
    if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
        if(isBelowOptimal(inputCalculated, CalculatedInput.types.transferrinSaturationRange.key)
        ){
            toReturn.recommended = true;
            toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.transferrinSaturationRange.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
        }
    }
	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
    if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
        if(isBelowStandard(inputCalculated, CalculatedInput.types.transferrinSaturationRange.key)
        ){
           sortValue -= 400;
        } else if(isBelowOptimal(inputCalculated, CalculatedInput.types.transferrinSaturationRange.key)
        ){
           sortValue -= 200;
        }/* if transferritin sat range is below optimal increase value 200
        is below standard range increase value 400 */
    }
	return sortValue;
};


/* Change name to iron Glycinate */
const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.maleOrFemale.key)){
        toReturn.push(UserInput.types.maleOrFemale);
    }else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
        if(isUnanswered(inputUser, UserInput.types.transferrinSaturation.key)){
            toReturn.push(UserInput.types.transferrinSaturation);
        }
    }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
    return {
		description : "1 serving, 3 times per week for 1 month, then retest iron levels.  Consider consulting with your doctor.",
		servingsPerDay : .43
	};
};

const getDescription = (inputUser, inputCalculated) => {
	return `
    Iron bis-glycinate is a well-studied, 100% fully-reacted, patented form of iron exclusively 
    from Albion Laboratories®. The amino acid glycine is actually one of the two starting materials 
    the body uses to synthesize hemoglobin. Therefore, Iron Glycinate contributes two key factors. 
    This form of iron has higher bioavailability, lower toxicity, less food reactivity, less food 
    interactions and has a longer shelf life than any other common form of iron.

    All Functional Formulas Formulas Meet or Exceed cGMP Quality Standards.`;
};
const title = "Iron Glycinate";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00034", price: 16, servings: 120}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
    getSortValue,
    getDescription,
    getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};