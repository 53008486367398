import UserInput from '../../inputs/user/index.js';
import { isUnanswered, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isYes, getDefaultDosage} from '../utilities';


import image1 from './neurostream1.jpg';
//import doc1 from './neurostream1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(inputUser[UserInput.types.brainFog.key] === "yes"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.brainFog.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(inputUser[UserInput.types.migraines.key] === "yes"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.migraines.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	// Craig Todo: Make BrainFog severity
	if(isYes(inputUser,UserInput.types.brainFog.key)){
		sortValue -= 300;
	}/* if yes to brain fog
	mild - increase value 150
	moderate - increase value 300
	Severe - increase value 450*/
	if(inputUser[UserInput.types.migraines.key] === "yes"){
		sortValue -= 300;
	}/* if yes to migraines increase value 300 */

	return sortValue;
};
const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.brainFog.key)){
		toReturn.push(UserInput.types.brainFog);
	}
	if(isUnanswered(inputUser, UserInput.types.migraines.key)){
		toReturn.push(UserInput.types.migraines);
	}
	return toReturn;
};
const getDosage = getDefaultDosage;
const getDescription = (inputUser, inputCalculated) => {
	return `
	Neuro Stream is uniquely designed and mechanistically balanced to support the cerebral microvasculature for healthy blood flow to 
	the brain.* By using high quality, standardized extracts with targeted “brain nutrients” such as vinpocetine at clinically 
	studied levels, this formula can be useful to those seeking to counter the mild effects of aging on brain health and brain 
	function.*`;
};
const title = "Nuero Stream";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "NEUROSTREAM", price: 43, servings: 90}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	category : categories.supplements,
	getSortValue,
	getDosage,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	//docs: [{ src: doc1 }]
	
};