import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './vitaminc1.jpg';
import doc1 from './vitaminc1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.bleedingGums.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bleedingGums.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.bleedingGums.key)){
		toReturn.push(UserInput.types.bleedingGums);
	}
	return toReturn;
};


const getDosage = getDefaultDosage;

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(isYes(inputUser, UserInput.types.bleedingGums.key)){
		sortValue -= 100;
	}
	return sortValue;
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	C Extreme is a high-potency vitamin C formula with the addition of 7.5 mg of BioPerine® per capsule.
	BioPerine, a proprietary black pepper extract, is present to promote absorption and bioavailability of vitamin
	C. Vitamin C provides valuable antioxidant protection and is necessary for the production of collagen, an
	integral component of blood vessels, tendons, ligaments, and bone. This essential water-soluble vitamin is
	required for the synthesis of neurotransmitters and carnitine as well. C Extreme contains buffering minerals
	to help prevent potential stomach upset.*`;
};
const title = "C Extreme";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, /* sku: "00048",*/ price: 30, servings: 120}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};