import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isYes, isUnanswered, isAboveStandard, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(isAboveStandard(inputCalculated, CalculatedInput.types.neutrophilsRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.neutrophilsRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		/* if above or below optimal or standard range invcreae value by 50 */
	}
	if(isAboveStandard(inputCalculated, CalculatedInput.types.lymphocytesRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.lymphocytesRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if above or below optimal or standard range invcreae value by 50 */
	if(isYes(inputUser, UserInput.types.candidaHistory.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.candidaHistory.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if yes increase value by 50*/
	if(isYes(inputUser, UserInput.types.utiHistory.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.utiHistory.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if yes increase value by 50 */
	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "mild" || inputUser[UserInput.types.yeastInfectionSeverity.key] === "moderate" || inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.yeastInfectionSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if yes
	mild - increase by 50
	moderate - increase by 75
	severe - increase by 100*/
	if(isYes(inputUser, UserInput.types.bladderInfections.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bladderInfections.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if yes
	mild - increase by 50
	moderate - increase by 75
	severe - increase by 100*/
	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(isAboveStandard(inputCalculated, CalculatedInput.types.neutrophilsRange.key)){
		sortValue -= 50;
		/* if above or below optimal or standard range invcreae value by 50 */
	}
	if(isAboveStandard(inputCalculated, CalculatedInput.types.lymphocytesRange.key)){
		sortValue -= 50;
	}/* if above or below optimal or standard range invcreae value by 50 */
	if(isYes(inputUser, UserInput.types.candidaHistory.key)){
		sortValue -= 50;
	}/* if yes increase value by 50*/
	if(isYes(inputUser, UserInput.types.utiHistory.key)){
		sortValue -= 50;
	}/* if yes increase value by 50 */
	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "mild"){
		sortValue -= 50;
	}else if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "moderate"){
		sortValue -= 75;
	}else if (inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"){
		sortValue -= 100;
	}/* if yes
	mild - increase by 50
	moderate - increase by 75
	severe - increase by 100*/
	if(isYes(inputUser, UserInput.types.bladderInfections.key)){
		sortValue -= 75;
	}/* if yes
	mild - increase by 50
	moderate - increase by 75
	severe - increase by 100*/
	// Craig Todo: Convert bladder infections to severity?
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.neutrophils.key)){ toReturn.push(UserInput.types.neutrophils); }
	if(isUnanswered(inputUser,UserInput.types.lymphocytes.key)){ toReturn.push(UserInput.types.lymphocytes); }
	if(isUnanswered(inputUser,UserInput.types.candidaHistory.key)){ toReturn.push(UserInput.types.candidaHistory); }
	if(isUnanswered(inputUser,UserInput.types.utiHistory.key)){ toReturn.push(UserInput.types.utiHistory); }
	if(isUnanswered(inputUser,UserInput.types.yeastInfectionSeverity.key)){ toReturn.push(UserInput.types.yeastInfectionSeverity); }
	if(isUnanswered(inputUser,UserInput.types.bladderInfections.key)){ toReturn.push(UserInput.types.bladderInfections); }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(isYes(inputUser, UserInput.types.candidaHistory.key)){
		return {
			description : "1 serving, twice per day.",
			servingsPerDay : 2
		};
	}
	if(isYes(inputUser, UserInput.types.utiHistory.key)
		|| inputUser[UserInput.types.yeastInfectionSeverity.key] === "mild" 
		|| inputUser[UserInput.types.yeastInfectionSeverity.key] === "moderate" 
		|| inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"
		|| isYes(inputUser, UserInput.types.bladderInfections.key)){
			return {
				description : "1 serving, twice per day.",
				servingsPerDay : 2
			};
	}
	if(isAboveStandard(inputCalculated, CalculatedInput.types.neutrophilsRange.key) || isAboveStandard(inputCalculated, CalculatedInput.types.lymphocytesRange.key)){
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	}
	return getDefaultDosage();
};

/* Add in
if patient has or has a history of candida recommend 1 garlix 2 times per day. Add in another product - Candida formula. 
If patient has a hisotry or current UTI, yeast infection or bladder infection also recommend Candida Formula 1 cap 2 times per day
and also recommend patient get a stool analysis test and possible Organic Acids Test (OATS) */

const getDescription = (inputUser, inputCalculated) => {
	return `
	Garlic is a standardized extract of garlic (Allium sativum). This concentrated source provides a high yield of the 
	sulfur-containing bioactive compounds allicin and alliin. Research suggests that garlic supports immune function, 
	antioxidant activity, and the cardiovascular system. Garlic may also support healthy blood pressure and cholesterol 
	levels already within the normal range.*`;
};
const title = "Garlic";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "GARLIC", price: 20}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices
	
};