import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './neurosupport1.jpg';
import doc1 from './neurosupport1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.significantVisionChanges.key)
		&& isYes(inputUser, UserInput.types.troubleFocusingEyesBrainFog.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.significantVisionChanges.key);
		toReturn.inputUserKeys.push(UserInput.types.troubleFocusingEyesBrainFog.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.significantVisionChanges.key)){
		toReturn.push(UserInput.types.significantVisionChanges);
	}else if(isYes(inputUser, UserInput.types.significantVisionChanges.key) 
		&& isUnanswered(inputUser, UserInput.types.troubleFocusingEyesBrainFog.key)){
			toReturn.push(UserInput.types.troubleFocusingEyesBrainFog);
	}
	return toReturn;
};


const getDosage = getDefaultDosage;

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	sortValue -= 350;
	return sortValue;
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	This unique encapsulated formula is designed to enhance brain performance and promote brain health. A
	key component of a complete program to ensure optimal brain function, Neuro Support improves the energy
	production of brain mitochondria and provides generous antioxidant support to combat the damaging effects
	of excess free radicals in the brain.*
	`;
};
const title = "Neuro Support";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "NEURO-SUPPORT", price: 83, servings: 120}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};