import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isYes, isAboveOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isAboveStandard, getDefaultDosage} from '../utilities';

import image1 from './allercalm1.jpg';
import doc1 from './allercalm1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.eosinophilsRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.eosinophilsRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	} 
	
	if(inputUser[UserInput.types.allergies.key] === "mild"
		|| inputUser[UserInput.types.allergies.key] === "moderate"
		|| inputUser[UserInput.types.allergies.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.allergies.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(inputUser[UserInput.types.dryItchyRedEyes.key] === "mild" || inputUser[UserInput.types.dryItchyRedEyes.key] === "moderate" || inputUser[UserInput.types.dryItchyRedEyes.key] === "severe"
	){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.dryItchyRedEyes.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.eosinophils.key)){
        toReturn.push(UserInput.types.eosinophils);
	}
	//if(isAboveOptimal(inputCalculated, CalculatedInput.types.eosinophilsRange.key)){
		if(isUnanswered(inputUser,UserInput.types.allergies.key)){
			toReturn.push(UserInput.types.allergies);
		} else if (isYes(inputUser, UserInput.types.allergies.key) && isUnanswered(inputUser, UserInput.types.pluggedEars.key)){
			toReturn.push(UserInput.types.pluggedEars);
		}
	//}
	
    if(isUnanswered(inputUser, UserInput.types.dryItchyRedEyes.key)){
		toReturn.push(UserInput.types.dryItchyRedEyes);
	}
	if(isUnanswered(inputUser, UserInput.types.itchyEars.key)){
		toReturn.push(UserInput.types.itchyEars);
	}
	if(isUnanswered(inputUser, UserInput.types.postNasalDrip.key)){
		toReturn.push(UserInput.types.postNasalDrip);
	}
	if(isUnanswered(inputUser, UserInput.types.noseStuffyness.key)){
		toReturn.push(UserInput.types.noseStuffyness);
	}
    return toReturn;
};

/* Change name to Aller Calm. other changes - 
if eosinophils are between 2-4 dosage is 1 capsule 2 times per day. (Above Optimal)
if between 4-6 dosage is 2 capsules 2 times per day (Above Standard)
If the patient says on questionaire they have allergies =
Mild - dosage 1 capsule 2 times per day
Moderate - dosage 1 capsule 3 times per day
Severe - dosgae 2 capsules 2 times per day */

const getDosage = (inputUser, inputCalculated) => {
	if(isAboveStandard(inputCalculated, CalculatedInput.types.eosinophilsRange.key) 
		|| inputUser[UserInput.types.allergies.key] === "severe"){
			return {
				description : "2 servings, 2 times per day.",
				servingsPerDay : 4
			};
	}else if(inputUser[UserInput.types.allergies.key] === "moderate"){
		return {
			description : "1 serving, 3 times per day.",
			servingsPerDay : 3
		};
	} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.eosinophilsRange.key)
		|| inputUser[UserInput.types.allergies.key] === "mild"){
			return {
				description : "1 serving, 2 times per day.",
				servingsPerDay : 2
			};
	} 
	return getDefaultDosage();
};
/* input symptoms
mild - increase by 100
Moderate - indrease by 200 over base line
Severe increase by 300 over baseline
If lab value above optimal increase by 100
if lab value is above standard increase by 100
 */
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(inputUser[UserInput.types.allergies.key] === "mild"){
		sortValue -= 100;
	}else if(inputUser[UserInput.types.allergies.key] === "moderate"){
		sortValue -= 200;
	}else if(inputUser[UserInput.types.allergies.key] === "severe"){
		sortValue -= 300;
	}

	if(isYes(inputUser, UserInput.types.pluggedEars.key)){
		sortValue -= 50;
	}
	if(isYes(inputUser, UserInput.types.postNasalDrip.key)){
		sortValue -= 50;
	}
	if(isYes(inputUser, UserInput.types.noseStuffyness.key)){
		sortValue -= 50;
	}
	if(isYes(inputUser, UserInput.types.itchyEars.key)){
		sortValue -= 50;
	}

	if(inputUser[UserInput.types.dryItchyRedEyes.key] === "mild"){
		sortValue -= 50;
	}else if(inputUser[UserInput.types.dryItchyRedEyes.key] === "moderate"){
		sortValue -= 100;
	}else if(inputUser[UserInput.types.dryItchyRedEyes.key] === "severe"){
		sortValue -= 150;
	}

	if(isAboveStandard(inputCalculated, CalculatedInput.types.eosinophilsRange.key)){
		sortValue -= 100;
	} else if(isAboveOptimal(inputCalculated, CalculatedInput.types.eosinophilsRange.key)){
		sortValue -= 100;
	}
	return sortValue;
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	Aller-Calm incorporates bioflavonoids, micronutrients, proteolytic enzymes, and herbs 
	into a comprehensive formula that provides multifaceted support for individuals with 
	immune imbalances. Dihydroquercetin (DHQvital™), a key component in Aller-Calm, inhibits 
	oxidation, is bioactive, and is highly absorbable. Aller-Calm supports the body’s regulating 
	function in addressing an overactive or distressed histamine response that are sometimes 
	brought on by the environment.*`;
};
const title = "Aller Calm";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00048", price: 33, servings: 60}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};