import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isOutsideOptimal, isOutsideStandard, getDefaultDosage} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isYes(inputUser, UserInput.types.pmsSymptoms.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputUserKeys.push(UserInput.types.pmsSymptoms.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
		
		if(isYes(inputUser, UserInput.types.hotFlashes.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputUserKeys.push(UserInput.types.hotFlashes.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}

		if(inputUser[UserInput.types.hormoneProblems.key] === "severe" || inputUser[UserInput.types.hormoneProblems.key] === "extremely severe"){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputUserKeys.push(UserInput.types.hormoneProblems.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}

		if(isOutsideOptimal(inputCalculated, CalculatedInput.types.estradiolFemaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.estradiolFemaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
		if(isOutsideOptimal(inputCalculated, CalculatedInput.types.progesteroneFemaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.progesteroneFemaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}
	return toReturn;
};
const getPrompts = (inputUser, inputCalculated) => {
	var toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.maleOrFemale.key)){
		toReturn.push(UserInput.types.maleOrFemale);
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isUnanswered(inputUser, UserInput.types.hormoneProblems.key)){
			toReturn.push(UserInput.types.hormoneProblems);
		}
		if(isUnanswered(inputUser, UserInput.types.pmsSymptoms.key)){
			toReturn.push(UserInput.types.pmsSymptoms);
		}
		if(isUnanswered(inputUser, UserInput.types.hotFlashes.key)){
			toReturn.push(UserInput.types.hotFlashes);
		}
		if(isUnanswered(inputUser, UserInput.types.estradiolFemale.key)){
			toReturn.push(UserInput.types.estradiolFemale);
		}
		if(isUnanswered(inputUser, UserInput.types.progesteroneFemale.key)){
			toReturn.push(UserInput.types.progesteroneFemale);
		}
	}
	return toReturn;
};

// Craig Todo: Expand dosage logic for estradiol and progesterone

/* For pms symptom dosaage change to "5 mL the last 2 weeks of cycle or begin 5 days prior to time of cycle
	that pms symptoms are the most severe and continue for 2 days after pms symptoms subside" */
const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.hormoneProblems.key] === "severe"){
		return {
			description : "1 serving (1/2 tsp or 250 mL), 2 times per day.",
			servingsPerDay : 2
		};
	} else if(inputUser[UserInput.types.hormoneProblems.key] === "extremely severe" || isYes(inputUser, UserInput.types.hotFlashes.key)){
		return {
			description : "2 servings (1 tsp or 500 mL), 2 times per day.",
			servingsPerDay : 4
		};
	}else if(isYes(inputUser, UserInput.types.pmsSymptoms.key)){
		return {
			description : "2 servings (1 tsp or 500 mL), 2 times per day the last 2 weeks of cycle or begin 5 days prior to time of cycle that pms symptoms are the most severe.  Continue for 2 days after pms symptoms subside.",
			servingsPerDay : 2
		};
	}
	return getDefaultDosage();
};

/* input symptoms of hot flashes
mild increase by 50
moderate increase by 150
severe increase by 250

PMS symptoms
mild increase 50
moderate increase by 100
severe increase by 150

Hormone problems
mild increase by 50
moderate increase by 100
severe increase by 150

If estrogen or progesterone labs are above or below optimal range increase value by 25
if estrogen or progesterone are above or below standard increase value by 75

If any of the above symptoms are complaints and estrogen or progesterone are above or below optimal range increase value by 150
If any of the above symptoms are complaints and estrogen or progesterone are above or below standard range increase value by 350
*/

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	let otherFactor = false;
	
	if(isYes(inputUser, UserInput.types.pmsSymptoms.key)){
		sortValue -= 100;
		otherFactor = true;
	}
		
	if(isYes(inputUser, UserInput.types.hotFlashes.key)){
		sortValue -= 100;
		otherFactor = true;
	}

	if(inputUser[UserInput.types.hormoneProblems.key] === "mild"){
		sortValue -= 50;
		otherFactor = true;
	}else if(inputUser[UserInput.types.hormoneProblems.key] === "moderate"){
		sortValue -= 100;
		otherFactor = true;
	}else if(inputUser[UserInput.types.hormoneProblems.key] === "severe"){
		sortValue -= 150;
		otherFactor = true;
	}else if(inputUser[UserInput.types.hormoneProblems.key] === "extremely severe"){
		sortValue -= 150;
		otherFactor = true;
	}

	if(isOutsideStandard(inputCalculated, CalculatedInput.types.estradiolFemaleRange.key)){
		sortValue -= 75;
	}else if(isOutsideOptimal(inputCalculated, CalculatedInput.types.estradiolFemaleRange.key)){
		sortValue -= 25;
	}

	if(isOutsideStandard(inputCalculated, CalculatedInput.types.progesteroneFemaleRange.key)){
		sortValue -= 75;
	}else if(isOutsideOptimal(inputCalculated, CalculatedInput.types.progesteroneFemaleRange.key)){
		sortValue -= 25;
	}

	if(otherFactor && (isOutsideStandard(inputCalculated, CalculatedInput.types.estradiolFemaleRange.key) || isOutsideStandard(inputCalculated, CalculatedInput.types.progesteroneFemaleRange.key))){
		sortValue -= 350;
	}else if(otherFactor && (isOutsideOptimal(inputCalculated, CalculatedInput.types.estradiolFemaleRange.key) || isOutsideOptimal(inputCalculated, CalculatedInput.types.progesteroneFemaleRange.key))){
		sortValue -= 150;
	}
	return sortValue;
};

// const getDescription = (inputUser, inputCalculated) => {
// 	return `
// 	Aller-Calm incorporates bioflavonoids, micronutrients, proteolytic enzymes, and herbs 
// 	into a comprehensive formula that provides multifaceted support for individuals with 
// 	immune imbalances. Dihydroquercetin (DHQvital™), a key component in Aller-Calm, inhibits 
// 	oxidation, is bioactive, and is highly absorbable. Aller-Calm supports the body’s regulating 
// 	function in addressing an overactive or distressed histamine response that are sometimes 
// 	brought on by the environment.*`;
// };
const title = "Chaste Tree";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "CHASTETREE", price: 61}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getPrices
	
};