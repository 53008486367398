import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(inputUser[UserInput.types.maleOrFemale.key] === "female" &&
		isYes(inputUser, UserInput.types.pregnantOrNursing.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
		toReturn.inputUserKeys.push(UserInput.types.pregnantOrNursing.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.maleOrFemale.key)){
        toReturn.push(UserInput.types.maleOrFemale);
	}else if (inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isUnanswered(inputUser,UserInput.types.pregnantOrNursing.key)){
			toReturn.push(UserInput.types.pregnantOrNursing);
		}
	}
    return toReturn;
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	If you are pregnant and/or nursing, please consult with a licensed physician prior to commencing any supplement regimen. 
	While certain vitamin, supplements and herbs are necessary and can be helpful for a healthy pregnancy and while 
	breastfeeding, some should not be taken.`;
};
const title = "Pregnant/Nursing Warning";

export default {
	getEvaluation,
	getPrompts,
	title,
	category : categories.notifications,
	getDescription
};