import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import thyroid from '../helpers/thyroid.js';
import { getUnknownKeys, isUnanswered, isYes, isAboveStandard, isBelowStandard, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';
import { getEvaluation as getComprehensiveTestEvaluation } from './fullbloodpanel.js';

const getBelowStandardKeys = (inputCalculated, inputCalculatedKey) => {
	if(isBelowStandard(inputCalculated, inputCalculatedKey)){
		return [inputCalculatedKey];
	}else{
		return [];
	}
};
const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();
    
    if(getComprehensiveTestEvaluation(inputUser, inputCalculated).recommended){
		return toReturn;
	}

    if(thyroid.isHashimotos(inputUser, inputCalculated)){
        toReturn.recommended = true;
		toReturn.reasons = ["Hashimotos : true"];
    }
    if(thyroid.isNonHashimotos(inputUser, inputCalculated)){
        let belowStandardKeys = [];
        belowStandardKeys.push(...getBelowStandardKeys(inputCalculated, CalculatedInput.types.freeT3Range.key));
        belowStandardKeys.push(...getBelowStandardKeys(inputCalculated, CalculatedInput.types.totalT3Range.key));
        belowStandardKeys.push(...getBelowStandardKeys(inputCalculated, CalculatedInput.types.freeT4Range.key));
        belowStandardKeys.push(...getBelowStandardKeys(inputCalculated, CalculatedInput.types.totalT4Range.key));
        belowStandardKeys.push(...getBelowStandardKeys(inputCalculated, CalculatedInput.types.thyroidPeroxidaseTPOAbsLABCORPRange.key));
        belowStandardKeys.push(...getBelowStandardKeys(inputCalculated, CalculatedInput.types.thyroglobulinAbsRange.key));
        
        if(belowStandardKeys.length > 0){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(...belowStandardKeys);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat(["Hashimotos : false"]);
        }
    }

    const unknownKeys = getUnknownKeys(inputUser, [
        UserInput.types.tsh.key,
        UserInput.types.totalT4.key,
        UserInput.types.freeT4.key,
        UserInput.types.totalT3.key,
        UserInput.types.freeT3.key,
        UserInput.types.reverseT3.key,
        UserInput.types.t3Uptake.key,
        UserInput.types.freeThyroxineIndexT7.key,
        UserInput.types.thyroidPeroxidaseTPOAbsLABCORP.key,
        UserInput.types.thyroglobulinAbs.key]);

	if(unknownKeys.length > 0){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(...unknownKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
    
    if(isYes(inputUser, UserInput.types.difficultySwallowing.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.difficultySwallowing.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
    if(isYes(inputUser, UserInput.types.throatHoarsness.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.throatHoarsness.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    toReturn.push(...thyroid.getHashimotosPrompts(inputUser, inputCalculated));
    toReturn.push(...thyroid.getNonHashimotosPrompts(inputUser, inputCalculated));
    if(thyroid.isNonHashimotos(inputUser, inputCalculated)){
        if(isUnanswered(inputUser,UserInput.types.freeT3.key)){
            toReturn.push(UserInput.types.freeT3);
        }
        if(isUnanswered(inputUser,UserInput.types.totalT3.key)){
            toReturn.push(UserInput.types.totalT3);
        }
        if(isUnanswered(inputUser,UserInput.types.freeT4.key)){
            toReturn.push(UserInput.types.freeT4);
        }
        if(isUnanswered(inputUser,UserInput.types.totalT4.key)){
            toReturn.push(UserInput.types.totalT4);
        }
        if(isUnanswered(inputUser,UserInput.types.thyroidPeroxidaseTPOAbsLABCORP.key)){
            toReturn.push(UserInput.types.thyroidPeroxidaseTPOAbsLABCORP);
        }
        if(isUnanswered(inputUser,UserInput.types.thyroglobulinAbs.key)){
            toReturn.push(UserInput.types.thyroglobulinAbs);
        }
    }
    if(isUnanswered(inputUser, UserInput.types.difficultySwallowing.key)){
		toReturn.push(UserInput.types.difficultySwallowing);
    }
    if(isUnanswered(inputUser, UserInput.types.throatHoarsness.key)){
		toReturn.push(UserInput.types.throatHoarsness);
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
    if(thyroid.isNonHashimotos(inputUser, inputCalculated)){
        let count = 0;
        count += isBelowStandard(inputCalculated, CalculatedInput.types.freeT3Range.key) ? 1 : 0;
        count += isBelowStandard(inputCalculated, CalculatedInput.types.totalT3Range.key) ? 1 : 0;
        count += isBelowStandard(inputCalculated, CalculatedInput.types.freeT4Range.key) ? 1 : 0;
        count += isBelowStandard(inputCalculated, CalculatedInput.types.totalT4Range.key) ? 1 : 0;
        count += isAboveStandard(inputCalculated, CalculatedInput.types.thyroidPeroxidaseTPOAbsLABCORPRange.key) ? 1 : 0;
        count += isAboveStandard(inputCalculated, CalculatedInput.types.thyroglobulinAbsRange.key) ? 1 : 0;
        if(count >= 1){
            return "Test or retest Thyroid if labs are older than 2 months.";
        }
    }
};

export default {
	getEvaluation,
	getPrompts,
	title : "Thyroid Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};