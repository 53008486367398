import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isBelowStandard, isBelowOptimalButInStandard, isAboveOptimalButInStandard, isAboveStandard, isYes, isNo, isOutsideOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './pmsease1.png';
import doc1 from './pmsease1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(inputUser[UserInput.types.maleOrFemale.key] === "female" && inputUser[UserInput.types.age.key] >= 14){
		if(isOutsideOptimal(inputCalculated,CalculatedInput.types.estradiolFemaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputUserKeys.push(UserInput.types.age.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.estradiolFemaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
		if(isYes(inputUser, UserInput.types.dryPainfulIntercourse.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputUserKeys.push(UserInput.types.age.key);
			toReturn.inputUserKeys.push(UserInput.types.dryPainfulIntercourse.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/* if yes increase value 100*/
		if(inputUser[UserInput.types.hormoneProblems.key] === "mild" || inputUser[UserInput.types.hormoneProblems.key] === "moderate"){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputUserKeys.push(UserInput.types.age.key);
			toReturn.inputUserKeys.push(UserInput.types.hormoneProblems.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		} else if(
			isBelowStandard(inputCalculated, CalculatedInput.types.transferrinSaturationRange.key)
			&& isYes(inputUser,UserInput.types.irregularPeriods.key)
		){/* if hormone problems yes 
			mild - increase value 100
			moderate - increase value 200
			severe - increase value 300

			if irregular periods increase value 200

			if yes to any of the questions recommend additional testing of blood hormone testing and option of DUTCH (Dried Urine Testing for Comprehensive Hormones) test
			*/
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputUserKeys.push(UserInput.types.age.key);
			toReturn.inputUserKeys.push(UserInput.types.irregularPeriods.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.transferrinSaturationRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}
	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(inputUser[UserInput.types.maleOrFemale.key] === "female" && inputUser[UserInput.types.age.key] >= 14){
		if(isYes(inputUser, UserInput.types.dryPainfulIntercourse.key)){
			sortValue -= 100;
		}/* if yes increase value 100*/
		if(inputUser[UserInput.types.hormoneProblems.key] === "mild"){
			sortValue -= 100;
		} else if (inputUser[UserInput.types.hormoneProblems.key] === "moderate"){
			sortValue -= 200;
		} else if (inputUser[UserInput.types.hormoneProblems.key] === "severe"){
			sortValue -= 300;
		}
			
		if(isYes(inputUser,UserInput.types.irregularPeriods.key)
		){
			sortValue -= 200;	
		}
			/* if hormone problems yes 
			mild - increase value 100
			moderate - increase value 200
			severe - increase value 300

			if irregular periods increase value 200

			if yes to any of the questions recommend additional testing of blood hormone testing and option of DUTCH (Dried Urine Testing for Comprehensive Hormones) test
			*/
			// Craig Todo: define blood hormone testing and DUTCH test

		if(isYes(inputUser,UserInput.types.breastSwellingTendernessDischarge.key)){
			sortValue -= 250;	
		}
	}else if(isYes(inputUser,UserInput.types.breastSwellingTendernessDischarge.key)){
		sortValue -= 100;	
	}
	return sortValue;
};
const getPrompts = (inputUser, inputCalculated) => {
	var toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.maleOrFemale.key)){
		toReturn.push(UserInput.types.maleOrFemale);
	}else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isUnanswered(inputUser, UserInput.types.estradiolFemale.key)){
			toReturn.push(UserInput.types.estradiolFemale)
		}
		if(isUnanswered(inputUser, UserInput.types.dryPainfulIntercourse.key)){
			toReturn.push(UserInput.types.dryPainfulIntercourse)
		}
		if(isUnanswered(inputUser, UserInput.types.age.key)){
			toReturn.push(UserInput.types.age);
		}else if (inputUser[UserInput.types.age.key] >= 14){
			if(isUnanswered(inputUser, UserInput.types.hormoneProblems.key)){
				toReturn.push(UserInput.types.hormoneProblems);
			}else if(isNo(inputUser,UserInput.types.hormoneProblems.key)){
				if(isUnanswered(inputUser, UserInput.types.transferrinSaturation.key)){
					toReturn.push(UserInput.types.transferrinSaturation);
				}else if (isBelowStandard(inputCalculated, CalculatedInput.types.transferrinSaturationRange.key)){
					if(isUnanswered(inputUser, UserInput.types.irregularPeriods.key)){
						toReturn.push(UserInput.types.irregularPeriods);
					}
				}
			}
		}
	}
	if(isUnanswered(inputUser, UserInput.types.breastSwellingTendernessDischarge.key)){
		toReturn.push(UserInput.types.breastSwellingTendernessDischarge);
	}
	return toReturn;
};
const getDosage = (inputUser, inputCalculated) => {
	let dosage = "";

	if(inputUser[UserInput.types.age.key] > 16
		&& (isBelowStandard(inputCalculated, CalculatedInput.types.estradiolFemaleRange.key)
		|| isAboveStandard(inputCalculated, CalculatedInput.types.estradiolFemaleRange.key))
	){
		dosage = "1 serving, twice per day.";
		if(isBelowStandard(inputCalculated, CalculatedInput.types.transferrinSaturationRange.key) && isYes(inputUser,UserInput.types.irregularPeriods.key)){
			dosage = dosage + " (consider testing hormones)"
		}
		return {
			description : dosage,
			servingsPerDay : 2
		};
		return dosage;
	}else if(isYes(inputUser, UserInput.types.dryPainfulIntercourse.key)){
		return {
			description : "1 serving, twice per day.",
			servingsPerDay : 2
		};
	} else if((inputUser[UserInput.types.age.key] >= 14 && inputUser[UserInput.types.age.key]<=16) 
		|| isBelowOptimalButInStandard(inputCalculated, CalculatedInput.types.estradiolFemaleRange.key)
		|| isAboveOptimalButInStandard(inputCalculated, CalculatedInput.types.estradiolFemaleRange.key)
	) {
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	} else if(inputUser[UserInput.types.hormoneProblems.key] === "mild"){
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	} else if(inputUser[UserInput.types.hormoneProblems.key] === "moderate"){
		return {
			description : "2 servings per day.",
			servingsPerDay : 2
		};
	}
	return getDefaultDosage();
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	PMS Ease is a comprehensive blend of Native American and Chinese herbs traditionally used to provide
	balance and support for a healthy menstrual cycle.*`;
};
const title = "PMS Ease";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "PMS-EASE", price: 27, servings: 60}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	docs: [{ src: doc1 }],
	images: [{ src: image1 }]
	
};