import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isYes, isUnanswered, isAboveStandard, isAboveOptimal , getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './calciumdglucarate1.jpg';
import doc1 from './calciumdglucarate1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
		
		if(isAboveOptimal(inputCalculated,CalculatedInput.types.estradiolMaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.estradiolMaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
		if(isAboveOptimal(inputCalculated,CalculatedInput.types.progesteroneMaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.progesteroneMaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
		if(isAboveStandard(inputCalculated,CalculatedInput.types.sexHormoneBindingGlobulinMaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.sexHormoneBindingGlobulinMaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
		
		if(isAboveOptimal(inputCalculated,CalculatedInput.types.progesteroneFemaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.progesteroneFemaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
		if(isAboveStandard(inputCalculated,CalculatedInput.types.sexHormoneBindingGlobulinFemaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.sexHormoneBindingGlobulinFemaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}
	return toReturn;
};


const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(isYes(inputUser,UserInput.types.breastSwellingTendernessDischarge.key)){
		sortValue -= 300;	
	}
	return sortValue;
};



const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.maleOrFemale.key)){
        toReturn.push(UserInput.types.maleOrFemale);
    }else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isUnanswered(inputUser, UserInput.types.sexHormoneBindingGlobulinMale.key)){ toReturn.push(UserInput.types.sexHormoneBindingGlobulinMale); }
		if(isUnanswered(inputUser, UserInput.types.progesteroneMale.key)){ toReturn.push(UserInput.types.progesteroneMale); }
		if(isUnanswered(inputUser, UserInput.types.estradiolMale.key)){ toReturn.push(UserInput.types.estradiolMale); }
    }else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
        if(isUnanswered(inputUser, UserInput.types.sexHormoneBindingGlobulinFemale.key)){ toReturn.push(UserInput.types.sexHormoneBindingGlobulinFemale); }
		if(isUnanswered(inputUser, UserInput.types.progesteroneFemale.key)){ toReturn.push(UserInput.types.progesteroneFemale); }
	}
	if(isUnanswered(inputUser, UserInput.types.breastSwellingTendernessDischarge.key)){
		toReturn.push(UserInput.types.breastSwellingTendernessDischarge);
	}
	
	return toReturn;
};
const getDosage = getDefaultDosage;
const getDescription = (inputUser, inputCalculated) => {
	return `
	Ca D-Glucarate is the supplemental, patented calcium salt form of D-glucaric acid—a substance produced 
	naturally in the body and obtained through consumption of certain fruits and vegetables. Ca D-glucarate 
	has been extensively studied by researchers at the MD Anderson Cancer Center, and its health benefits 
	are largely attributed to inhibition of beta-glucuronidase; this activity supports the body’s ability 
	to detoxify estrogens, xenobiotics, and fat-soluble toxins.*`;
};
const title = "CA D-Glucarate Detox & Hormone Support";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00053", price: 63, servings: 90}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]	
};