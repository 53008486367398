import React, { useState } from 'react';
import './Prompt.css';
import { isUnansweredRaw } from './outputs/utilities.js';
import { Col, Button, UncontrolledDropdown, ButtonGroup, DropdownToggle, DropdownItem, DropdownMenu, FormGroup, Label, Input, InputGroup, InputGroupAddon, Badge, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import {ImageViewer} from './outputs/View.js';
import {ReactComponent as InfoIcon} from './info-icon.svg';


const Prompt = (props) => {
	const [imagesPopoverOpen, setImagesPopoverOpen] = useState(false);
	// const getRadioGroup = () =>{
	// 	return <span>{props.possible.map((possibleValue) => 
	// 	<span key={possibleValue}>
	// 		<input 
	// 			key="possibleValue"
	// 			type="radio" 
	// 			value={props.inputUserKey}
	// 			checked={props.value === possibleValue}
	// 			onChange={() => props.setInputUser(props.inputUserKey, possibleValue)}
	// 			 />{possibleValue}</span>)}</span>;
	// }
	const getButtonGroup = () => {
		return <ButtonGroup>{props.possible.map((possibleValue) => 
		<Button
			key={possibleValue}
			color="primary"
			onClick={() => props.setInputUser(props.inputUserKey, possibleValue)}
			active={props.value === possibleValue}>
			{possibleValue}</Button>)}</ButtonGroup>;
	}
	// const getButtonDropDown = () => {
	// 	return <ButtonDropdown><DropdownToggle color="primary">test</DropdownToggle><DropdownMenu>{props.possible.map((possibleValue) => 
	// 	<DropdownItem
	// 		key={possibleValue}
	// 		color="primary"
	// 		onClick={() => props.setInputUser(props.inputUserKey, possibleValue)}
	// 		active={props.value === possibleValue}>
	// 		{possibleValue}</DropdownItem>)}</DropdownMenu></ButtonDropdown>;
	// }
	const getUncontrolledDropDown = () => {
		return <UncontrolledDropdown style={{ display: "inline-flex"}}><DropdownToggle caret  color="primary" active={!isUnansweredRaw(props.value)}>{props.value || "select"}</DropdownToggle><DropdownMenu>{props.possible.map((possibleValue) => 
		<DropdownItem
			key={possibleValue}
			color="primary"
			onClick={() => props.setInputUser(props.inputUserKey, possibleValue)}
			active={props.value === possibleValue}>
			{possibleValue}</DropdownItem>)}</DropdownMenu></UncontrolledDropdown>;
	}
	// const getSelect = () => {
	// 	return <Input 
	// 		type="select" 
	// 		value={props.value} 
	// 		placeholder="grams"
	// 		onChange={(event) => props.setInputUser(props.inputUserKey, event.target.value)} >
	// 			{props.possible.map((possibleValue) => 
	// 			<option>{possibleValue}</option>)}
	// 		</Input>;
	// }

	// const getText = () => {
	// 	return <Input 
	// 			type="textbox" 
	// 			value={props.value} 
	// 			placeholder={props.units}
	// 			onChange={(event) => props.setInputUser(props.inputUserKey, event.target.value)} 
	// 		/>;
	// }
	const getTextGroup = (type) => {
		return <InputGroup>
				<Input
				type={type} 
				value={props.value}
				onChange={(event) => props.setInputUser(props.inputUserKey, event.target.value)}/>
				<InputGroupAddon addonType="append">{props.units}</InputGroupAddon>
				<InputGroupAddon addonType="append">
					<Button 
					color={props.value === "unknown" ? "secondary" : "primary"}
					onClick={() => props.setInputUser(props.inputUserKey, "unknown")}>Unknown</Button>
				</InputGroupAddon>
			</InputGroup>;
	}

	const getInput = () => {
		if(props.possible !== undefined && props.possible !== null && props.possible.length > 0){
			if(props.possible.length < 3 || props.possible.join('').length < 24){
				return getButtonGroup();
			}else{
				return getUncontrolledDropDown();
			}
			
		}else{
			return getTextGroup(props.type || "text");
		}
	}
	const getRangeBadge = () => {
		if(props.range === "Below Standard" || props.range === "Above Standard"){
			return <Badge color="danger">{props.range}</Badge>;
		}else if(props.range === "Below Optimal" || props.range === "Above Optimal"){
			return <Badge color="warning">{props.range}</Badge>;
		}else if(props.range === "Optimal"){
			return <Badge color="info">{props.range}</Badge>;
		}else if(props.range === "Unknown"){
			return <Badge color="light">{props.range}</Badge>;
		}else{
			return "";
		}
	}

	const images = props.images !== undefined && props.images.length > 0 ?
			<span>
				<svg id={props.inputUserKey + "Camera"} style={{ cursor: 'pointer' }} width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-camera" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" d="M15 12V6a1 1 0 0 0-1-1h-1.172a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 9.173 3H6.828a1 1 0 0 0-.707.293l-.828.828A3 3 0 0 1 3.172 5H2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
					<path fill-rule="evenodd" d="M8 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
					<path d="M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
				</svg>
				<Popover placement="bottom" isOpen={imagesPopoverOpen} target={props.inputUserKey + "Camera"} toggle={() => setImagesPopoverOpen(!imagesPopoverOpen)} >
					<PopoverBody>
						<ImageViewer images={props.images} />
					</PopoverBody>
				</Popover>
			</span>
			: null;
    
	const input = getInput();

	return (
		<FormGroup row>
			<Label htmlFor={props.inputUserKey} md={6} sm={12}>{props.prompt} {images} {getRangeBadge()}</Label>
			
			<Col md={6} sm={12}>
				{input}
				<Info inputUserKey={props.inputUserKey} choiceDescription={props.choiceDescription} />
			</Col>
		</FormGroup>
	);
}
const Info = (props) => {
	const [infoPopoverOpen, setInfoPopoverOpen] = useState(false);
	if(props.choiceDescription !== undefined){
		return (
			<span>
				<InfoIcon id={props.inputUserKey + "Info"} style={{ cursor: "pointer", width : "30px" }} />
				<Popover trigger="hover" isOpen={infoPopoverOpen} target={props.inputUserKey + "Info"} toggle={() => setInfoPopoverOpen(!infoPopoverOpen)} >
					<PopoverBody>
						{props.choiceDescription}
					</PopoverBody>
				</Popover>
			</span>);
	}else{
		return null;
	}
};
export default Prompt;