import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isYes, isUnanswered, isBelowStandard, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isBelowOptimal, getDefaultDosage} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isBelowStandard(inputCalculated, CalculatedInput.types.dheasMaleRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.dheasMaleRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if dhea for male or female below optimal range increase value by 75
	if dhe for male or female below standard range increase value by 150 */
    if(isBelowStandard(inputCalculated, CalculatedInput.types.dheasFemaleRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.dheasFemaleRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if((isBelowOptimal(inputCalculated, CalculatedInput.types.dheasMaleRange.key) || isBelowOptimal(inputCalculated, CalculatedInput.types.dheasFemaleRange.key))
		&& inputUser[UserInput.types.age.key] >= 30){
		toReturn.inputUserKeys.push(UserInput.types.age.key);
		if(isBelowOptimal(inputCalculated, CalculatedInput.types.dheasMaleRange.key)){
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.dheasMaleRange.key);
		}
		if(isBelowOptimal(inputCalculated, CalculatedInput.types.dheasFemaleRange.key)){
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.dheasFemaleRange.key);
		}
		if(inputUser[UserInput.types.fatigue.key] === "mild" || inputUser[UserInput.types.fatigue.key] === "moderate" || inputUser[UserInput.types.fatigue.key] === "severe"){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.fatigue.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/* If fatigue
		mild - increase value by 50
		moderate - increase value by 75
		severe - increase value by 100 */
		if(isYes(inputUser, UserInput.types.brainFog.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.brainFog.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/* brain fog increase value by 25 */
		if(isYes(inputUser, UserInput.types.lackOfSexDrive.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.lackOfSexDrive.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}


	return toReturn;
}; 

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	/* if dhea for male or female below optimal range increase value by 75
	if dhe for male or female below standard range increase value by 150 */
	if(isBelowStandard(inputCalculated, CalculatedInput.types.dheasMaleRange.key)){
		sortValue -= 150;
	}else if(isBelowOptimal(inputCalculated, CalculatedInput.types.dheasMaleRange.key)){
		sortValue -= 75;
	}else if(isBelowStandard(inputCalculated, CalculatedInput.types.dheasFemaleRange.key)){
		sortValue -= 150;
	}else if(isBelowOptimal(inputCalculated, CalculatedInput.types.dheasFemaleRange.key)){
		sortValue -= 75;
	}

	/* If fatigue
	mild - increase value by 50
	moderate - increase value by 75
	severe - increase value by 100 */
	if(inputUser[UserInput.types.fatigue.key] === "mild"){
		sortValue -= 50;
	} else if(inputUser[UserInput.types.fatigue.key] === "moderate"){
		sortValue -= 75;
	} else if(inputUser[UserInput.types.fatigue.key] === "severe"){
		sortValue -= 100;
	}
	/* brain fog increase value by 25 */
	if(isYes(inputUser, UserInput.types.brainFog.key)){
		sortValue -= 25;
	}
	/* if low sex drive increase value by 75 */
	if(isYes(inputUser, UserInput.types.lackOfSexDrive.key)){
		sortValue -= 75;
	}

	return sortValue;
};

/* Looks like there are no labs that are triggering this to come up. Add in the following. 
If male or female and has any of the following problems or copmlaints - low energy, brain fog, concentration issues, must be over 
30 for either sex, low sex drive, and blood levels are below optimal = 1 capsule per day in am. Consult with functional medicine 
practitioner prior to using higher doses and consider a consult just to make sure this supplement is indicated. */

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.maleOrFemale.key)){
        toReturn.push(UserInput.types.maleOrFemale);
    }else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
        if(isUnanswered(inputUser,UserInput.types.dheasMale.key)){
            toReturn.push(UserInput.types.dheasMale);
        }
    }else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
        if(isUnanswered(inputUser,UserInput.types.dheasFemale.key)){
            toReturn.push(UserInput.types.dheasFemale);
        }
	}
	if((isBelowOptimal(inputCalculated, CalculatedInput.types.dheasMaleRange.key) || isBelowOptimal(inputCalculated, CalculatedInput.types.dheasFemaleRange.key))){
		if(isUnanswered(inputUser, UserInput.types.age.key)){
			toReturn.push(UserInput.types.age);
		}else if (inputUser[UserInput.types.age.key] >= 30){
			if(isUnanswered(inputUser, UserInput.types.fatigue.key)){ toReturn.push(UserInput.types.fatigue); }
			if(isUnanswered(inputUser, UserInput.types.brainFog.key)){ toReturn.push(UserInput.types.brainFog); }
			if(isUnanswered(inputUser, UserInput.types.lackOfSexDrive.key)){ toReturn.push(UserInput.types.lackOfSexDrive); }
		}
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	return {
		description : "1 serving per day in am. Consult with functional medicine practitioner prior to using higher doses and consider a consult just to make sure this supplement is indicated.",
		servingsPerDay : 1
	};
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	DHEA is a hormone secreted primarily by the adrenal gland. It acts as a substance in the synthesis of other important hormones 
	and plays a key role in a variety of physiological responses.*`;
};
const title = "DHEA 5mg";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "DHEA-5MG", price: 21}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices
	
};