import Calculated from '../Calculated.js';          

export default class Range extends Calculated {
    constructor(inputUserKey,prompt,standardMin,optimalMin,optimalMax,standardMax,getinputUserValue){
        super();
        this.key = inputUserKey + "Range";
        this.prompt = prompt;
        this.standardMin = standardMin;
        this.optimalMin = optimalMin;
        this.optimalMax = optimalMax;
        this.standardMax = standardMax;
        this.getinputUserValue = getinputUserValue;
    }
    static Values = {
        aboveStandard: "Above Standard",
        aboveOptimal: "Above Optimal",
        optimal: "Optimal",
        belowOptimal: "Below Optimal",
        belowStandard: "Below Standard",
        unknown: "Unknown",
        noResponse: "No Response"
    };
    getValue = (inputUser) => {
        var value = this.getinputUserValue(inputUser);

        var absoluteMax = this.optimalMax > this.standardMax ? this.optimalMax : this.standardMax;
        //var absoluteMin = this.optimalMin < this.standardMin ? this.optimalMin : this.standardMin;
        if(value === "Unknown"){
            return Range.Values.unknown;
        }else if(value === "No Response" || value === "" || value === undefined || value === null){
            return Range.Values.noResponse;
        }else if(value > absoluteMax){
            return Range.Values.aboveStandard;
        }else if(value > this.optimalMax){
            return Range.Values.aboveOptimal;
        }else if(value > this.optimalMin){
            return Range.Values.optimal;
        }else if(value > this.standardMin){
            return Range.Values.belowOptimal;
        }else if(value <= this.standardMin){
            return Range.Values.belowStandard;
        }else{
            return Range.Values.unknown;
        }
    };
};