import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './utiflowclear1.jpg';
import doc1 from './utiflowclear1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.bladderInfections.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bladderInfections.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};
/* if yes to bladder infections, UTI (urinary tract infections) or yeast infections
1-2 infections per month increase value 450
1-2 infections every 2-3 months increase value 400
1-2 infections every 4-5 months increase value 300 
1-2 infections every 6 months or more increase value 200 */
// Craig Todo: Bladder infections, UTI History, Yeast Infections convert to frequency?
const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.bladderInfections.key)){
        toReturn.push(UserInput.types.bladderInfections);
	}
    return toReturn;
};

const getDosage = getDefaultDosage;
/* Change name to UTI flow Clear */
const getDescription = (inputUser, inputCalculated) => {
	return `
	UTI Flo-Clear features D-mannose, a simple sugar that occurs naturally in cranberries and 
	pineapple. Research suggests that D-mannose interferes with lectin adhesion to the bladder 
	wall, thereby supporting a healthy urinary tract.*`;
};
const title = "UTI Flow Clear";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00039", price: 41, servings: 50}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue : getDefaultSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};