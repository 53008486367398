import UserInput from '../../inputs/user/index.js';
import { getUnknownKeys, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';
import { getEvaluation as getComprehensiveTestEvaluation } from './fullbloodpanel.js';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(getComprehensiveTestEvaluation(inputUser, inputCalculated).recommended){
		return toReturn;
	}
	
	const unknownKeys = getUnknownKeys(inputUser, [
		UserInput.types.sodium.key,
		UserInput.types.potassium.key,
		UserInput.types.chloride.key,
		UserInput.types.cO2.key]);

	if(unknownKeys.length > 0){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(...unknownKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "Electrolyte Panel Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};