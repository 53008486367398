import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, isNo, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './girenew1.png';
//import doc1 from './girenew1.pdf';

const getEvaluationWithoutContrainsdications = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(isYes(inputUser, UserInput.types.nausea.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nausea.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if yes increase value 50*/
	if(isYes(inputUser, UserInput.types.vomiting.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.vomiting.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if yes increase value 25 */
	if(isYes(inputUser, UserInput.types.digestiveProblems.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.digestiveProblems.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if yes increase value 75 */
	return toReturn;
}
const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getEvaluationWithoutContrainsdications(inputUser, inputCalculated);
	if(toReturn.recommended
		&& isNo(inputUser,UserInput.types.takeAntihypertensiveMedications.key)
		&& isNo(inputUser,UserInput.types.takeCardiacGycosidesCardiovascularMedications.key)
		&& isNo(inputUser,UserInput.types.takeHydralazineDiureticsBloodPressureRegulators.key)
		&& isNo(inputUser,UserInput.types.takeLoopDiureticsBloodPressureRegulators.key)
		&& isNo(inputUser,UserInput.types.takeThiazideDiureticsBloodPressureRegulators.key)
		&& isNo(inputUser,UserInput.types.takePotassiumSparingDiureticsBloodPressureRegulators.key)){
		toReturn.inputUserKeys.push(UserInput.types.takeAntihypertensiveMedications.key);
		toReturn.inputUserKeys.push(UserInput.types.takeCardiacGycosidesCardiovascularMedications.key);
		toReturn.inputUserKeys.push(UserInput.types.takeHydralazineDiureticsBloodPressureRegulators.key);
		toReturn.inputUserKeys.push(UserInput.types.takeLoopDiureticsBloodPressureRegulators.key);
		toReturn.inputUserKeys.push(UserInput.types.takeThiazideDiureticsBloodPressureRegulators.key);
		toReturn.inputUserKeys.push(UserInput.types.takePotassiumSparingDiureticsBloodPressureRegulators.key);

		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}else{
		toReturn = getDefaultEvaluation();
	}
	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(isYes(inputUser, UserInput.types.nausea.key)){
		sortValue -= 50;
	}/* if yes increase value 50*/
	if(isYes(inputUser, UserInput.types.vomiting.key)){
		sortValue -= 25;
	}/* if yes increase value 25 */
	if(isYes(inputUser, UserInput.types.digestiveProblems.key)){
		sortValue -= 75;
	}/* if yes increase value 75 */
	return sortValue;
};

/* Change name to GI renew */

/* if patient answers yes to nausea, vomiting, leaky gut or other any other digestive issue recommed the GI renew and Stool analysis testing */

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.nausea.key)){ toReturn.push(UserInput.types.nausea); }
	if(isUnanswered(inputUser, UserInput.types.vomiting.key)){ toReturn.push(UserInput.types.vomiting); }
	if(isUnanswered(inputUser, UserInput.types.digestiveProblems.key)){ toReturn.push(UserInput.types.digestiveProblems); }
	
	if(toReturn.length === 0 && getEvaluationWithoutContrainsdications(inputUser, inputCalculated).recommended){
		if(isUnanswered(inputUser,UserInput.types.takeAntihypertensiveMedications.key)){
			toReturn.push(UserInput.types.takeAntihypertensiveMedications);
		}
		if(isUnanswered(inputUser,UserInput.types.takeCardiacGycosidesCardiovascularMedications.key)){
			toReturn.push(UserInput.types.takeCardiacGycosidesCardiovascularMedications);
		}
		if(isUnanswered(inputUser,UserInput.types.takeHydralazineDiureticsBloodPressureRegulators.key)){
			toReturn.push(UserInput.types.takeHydralazineDiureticsBloodPressureRegulators);
		}
		if(isUnanswered(inputUser,UserInput.types.takeLoopDiureticsBloodPressureRegulators.key)){
			toReturn.push(UserInput.types.takeLoopDiureticsBloodPressureRegulators);
		}
		if(isUnanswered(inputUser,UserInput.types.takeThiazideDiureticsBloodPressureRegulators.key)){
			toReturn.push(UserInput.types.takeThiazideDiureticsBloodPressureRegulators);
		}
		if(isUnanswered(inputUser,UserInput.types.takePotassiumSparingDiureticsBloodPressureRegulators.key)){
			toReturn.push(UserInput.types.takePotassiumSparingDiureticsBloodPressureRegulators);
		}
	}
	return toReturn;
};
const getDosage = (inputUser, inputCalculated) => {
	if(isYes(inputUser, UserInput.types.nausea.key)){
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	}
	if(isYes(inputUser, UserInput.types.vomiting.key)){
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	}
	return getDefaultDosage();
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	GI Renew offers comprehensive support for optimum gastrointestinal health and function. 
	The lining of the gut must have proper permeability and integrity so it can not only absorb 
	nutrients, but also prevent toxins, allergens and microbes from gaining access to the blood 
	stream. Maintaining gut health is the key to maintaining overall wellness.

	Made with non-GMO ingredients.`;
};
const title = "GI Renew";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00031", price: 61}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	//docs: [{ src: doc1 }]		
};