import UserInput from '../../inputs/user/index.js';
import { getUnknownKeys,getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';
import thyroid from './thyroid.js';
import { getEvaluation as getComprehensiveTestEvaluation } from './fullbloodpanel.js';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(getComprehensiveTestEvaluation(inputUser, inputCalculated).recommended){
		return toReturn;
	}

	const thyroidEvaluation = thyroid.getEvaluation(inputUser, inputCalculated);
	if(!thyroidEvaluation.recommended){
		const unknownKeys = getUnknownKeys(inputUser, [
			UserInput.types.hsCRPMale.key,
			//UserInput.types.hsCRPFemale.key,
			//UserInput.types.cReactiveProtein.key,
			//UserInput.types.esrFemale.key,
			UserInput.types.homocysteine.key,
			UserInput.types.fibrinogen.key]);
		if(unknownKeys.length > 0){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(...thyroidEvaluation.inputUserKeys);
			toReturn.inputUserKeys.push(...unknownKeys);
			toReturn.inputCalculatedKeys.push(...thyroidEvaluation.inputCalculatedKeys);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "Inflammation Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};