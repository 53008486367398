import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isYes, isAboveOptimal , getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.potassiumRange.key) && isYes(inputUser, UserInput.types.takePotassium.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.takePotassium.key);
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.potassiumRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.potassium.key)){
		toReturn.push(UserInput.types.potassium);
	} else if (isAboveOptimal(inputCalculated, CalculatedInput.types.potassiumRange.key) && isUnanswered(inputUser, UserInput.types.takePotassium.key)){
		toReturn.push(UserInput.types.takePotassium);
	}
	return toReturn;
};
export default {
	getEvaluation,
	getPrompts,
	title : "Talk to your doctor about your potassium dosage.",
	category : categories.behaviors,
	getSortValue : getDefaultSortValue
};