import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(isYes(inputUser, UserInput.types.nailFungus.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nailFungus.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	
	// if(isYes(inputUser, UserInput.types.nailFungus.key)){
	// 	sortValue -= 200;
	// }
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	
	if(isUnanswered(inputUser, UserInput.types.nailFungus.key)){ toReturn.push(UserInput.types.nailFungus); }	
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	return "3 times per day";
};

export default {
	getEvaluation,
	getPrompts,
	title : "Tea Tree Oil On Toes",
	getDosage,
	category : categories.supplements,
	getSortValue
	
};