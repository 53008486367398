import React from 'react';
import {  } from 'reactstrap';
import './About.css';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Button, Col, Row, Card, CardText, CardBody, CardTitle, CardSubtitle, CardFooter, CardImg, CardImgOverlay, CardLink, CardGroup, Container } from 'reactstrap';
import { useHistory } from "react-router-dom";
import LayoutConstants from '../LayoutConstants.js';


const Home = (props) => {
	let history = useHistory();
	return(
        <Container>
            <h2 className="mt-4 m-3">About Us</h2>
            <h3>Dr. Craig Mortensen</h3>
            <h4>My Story</h4>
            <p>I was born and raised as a California boy. Growing up my father was a Doctor of Chiropractic, so I always knew I wanted to go into alternative healthcare. It was part of who our family was. I just never knew the extent I would get into it.</p>
            <p>Let’s fast forward a few years, around the age of 13 or 14 I began to experience these random very severe stomach pains. They would get so bad that I would be doubled over in pain for sometimes a couples days at a time. I would throw up and dry heave uncontrollably until I would get so dehydrated from it that my entire body and muscles would go into spasms and I couldn’t move.</p>
            <p>Stacks Image 2921I would then be rushed to the Emergency Room where I would be pumped full of fluids through IV and given all kinds of pain killers. The first time this happened, we thought it was just a fluke. The doctors couldn’t find anything wrong. Must have eaten something bad they said.</p>
            <p>Low and behold, six months later it happened again, then again. See a pattern?</p>
            <p>Long story short, I went to every type of medical doctor, internist, GI doctor, and specialist that there was and had all kinds of different tests in which I was poked, prodded, or violated. It was a terrible experience.</p>
            <p>The end result was that they told my mother that my intestines were inflamed.</p>
            <p>NO $#!t Sherlock! was all I could think. I was about 14 or 15 at this time and I was terrified I would have to deal with these “episodes” for the rest of my life. Let me tell you, I have never experienced pain like that before and I never want to again. I would not wish that on my worst enemy.</p>
            <p>Stacks Image 2924Being fed up with the medical system and being that we were an “alternative” family, my mom took me to see a natural doctor. Thus began my long journey into what would become Functional Medicine.</p>
            <p>The doctor that I saw put me on a long and hard program that eventually FIXED my intestinal problems and restored me to health. I can’t remember the last time I have had a stomach episode and I don’t want to.</p>
            <p>Good riddance!</p>
            <h4>My education and journey into Integrative and Functional Medicine</h4>
            <p>Immediately after graduating from Valencia High School I began attending college. While going through school I worked fast and furious on getting all my prerequisite classes done so I could become a Chiropractic Physician and practice alternative medicine. I was never good with needles anyway.</p>
            <p>During college I majored in pre-med and biochemistry. I received a scholarship from Fullerton College for my academics, I also received an award and scholarship awarded by Chapman University and the American Chemical Society for my work in Organic Chemistry.</p>
            <p>When I transferred to Southern California university of Health Sciences I had a cumulative GPA of 3.98 (I got a C in pottery class!). While at SCUHS I excelled in all my classes, and particularly enjoyed all the classes in alternative medicine and natural healing (I received almost 5,000 hours of alternative healing training in school). I graduated in 2001 as the youngest doctor in the school and was my class Valedictorian with a GPA of 3.98. My Valedictorian speech was a tear jerker! ;-) I also received 3 scholarships from the Chiropractic Physician Board for my academic achievements.</p>
            <p>After graduating I immediately began practicing with my father. My practice was a combination of Alternative and Natural Medicine mixed in with some Chiropractic. After about 10-12 years of practice I suffered a shoulder injury and had to have surgery. Around this same time I discovered and began studying Functional Medicine.</p>
            <p>I have solely dedicated myself to helping patients and anyone I can, achieve better health through the natural healing methods of Functional Healthcare.</p>
            <p>I have completed countless hours of training and certification courses. I am certified by the IFM (Institute of Functional Medicine) and FMU (Functional Medicine University). I have completed many other training courses and I am always looking to learn more. I have honestly lost count of how many courses and classes I have done.</p>
        </Container>
	);
};
export default Home;