import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './sleepez1.png';
import doc1 from './sleepez1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.cantFallAsleep.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.cantFallAsleep.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.cantStayAsleep.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.cantStayAsleep.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.cantFallAsleep.key)){
        toReturn.push(UserInput.types.cantFallAsleep);
	}
	if(isUnanswered(inputUser,UserInput.types.cantStayAsleep.key)){
        toReturn.push(UserInput.types.cantStayAsleep);
	}
    return toReturn;
};

/* Dosage instructions. If trouble falling asleep - Dosage instructions. Start with 1 capsule 30 minutes prior to bed. Continue for 
3 nights. If dosage is not sufficient increase dosage to 2 capsules 30 minutes prior to bed. */

const getDosage = (inputUser, inputCalculated) => {
	return {
		description : "Start with 1 serving 30 minutes before bed. Continue for 3 nights. If dosage is not sufficient increase dosage to 2 servings, 30 minutes before bed.",
		servingsPerDay : 1
	};
};
/* cant fall asleep increase by 100
cant stay asleep increase by additional 100
 */
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(isYes(inputUser, UserInput.types.cantFallAsleep.key)){
		sortValue -= 100
	}
	if(isYes(inputUser, UserInput.types.cantStayAsleep.key)){
		sortValue -= 100
	}

	return sortValue;
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Sleep EZ is designed to help relax tight/spastic muscles and ease occasional discomfort from overworked
	muscles. It features botanical extracts traditionally used to calm nerves and muscles and address
	occasional sleeplessness plus minerals to support healthy muscular contraction and relaxation.*`;
};
const title = "Sleep EZ";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "SLEEP-EZ", price: 22, servings: 60}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
	
};