import React, { useState } from 'react';
import { ListGroup, Container, Card, CardBody, Link, CardText, CardTitle, CardImg, CardSubtitle,Row, Col, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import {ImageViewer, Prices} from './View.js';
import Disclaimer from './Disclaimer.js';




const OutputCategoryStoreView = (props) => {
	if(props.outputCategoryItems !== undefined && props.outputCategoryItems.length > 0){
		return(
			<div>
						<h2 className="mt-4 m-3">{props.title}</h2>
				<Row>
					
				{props.outputCategoryItems.map((output, i) => <OutputItemStoreView
				key={i}
				title={output.title}
				description={output.getDescription === undefined ? "" : output.getDescription()}
				prices={output.getPrices === undefined ? [] : output.getPrices()}
				images={output.images}
				docs={output.docs}
				cart={props.cart}
				getQtyAndPrice={props.getQtyAndPrice}
				addToCart={props.addToCart}
				showDetails={props.showDetails} />)}
					
				</Row>
			</div>
		);
	}else{
		return(
			<ListGroup className="mb-5">
				<h2>{props.title}</h2>
				<ListGroupItem>No Recommendations.</ListGroupItem>
			</ListGroup>
		);
	}
};



const OutputItemStoreView = (props) => {
	const [showDetails, setShowDetails] = useState(props.showDetails);
	return (
		<Col xs="12" sm="6" xl="4" className="p-3">
			<Card id={props.title} className="h-100">
				<CardTitle className="mt-3"><h3><center>{props.title}</center></h3></CardTitle>
				{/* {props.images !== undefined && props.images.length > 0 ? <CardImg variant="top" src={props.images[0].src} /> : "" } */}
				{props.images !== undefined && props.images.length > 0 ? <CardBody className="p-0"><Col xs={{ size: 6, offset: 3}}><ImageViewer images={props.images} /></Col></CardBody> : "" }
				<CardBody>
					<CardSubtitle>{props.subtitle}</CardSubtitle>
					{showDetails ? <CardText onClick={() => setShowDetails(!showDetails)}>{props.description}</CardText> : "" }
					{/* <CardText>{props.text}</CardText> */}
					{showDetails && props.description !== undefined && props.description.length > 0 ? <CardText><Disclaimer></Disclaimer></CardText> : null}
					{ (showDetails && props.docs !== undefined && props.docs.length > 0) ? <CardText><a href={props.docs[0].src}>Download Information</a></CardText> : null }
					<CardText onClick={() => setShowDetails(!showDetails)} style={{ cursor: 'pointer' }} className="text-info, text-center">{ showDetails ? " - Hide Details" : " + Show Details" }</CardText>
					<Prices title={props.title} prices={props.prices} cart={props.cart} getQtyAndPrice={props.getQtyAndPrice} addToCart={props.addToCart} />
				</CardBody>
			</Card>
		</Col>
	);
};

const OutputItemStoreView2 = (props) => {
	return (
		<ListGroupItem id={props.title}>
			<ListGroupItemHeading tag="h3">{props.title}</ListGroupItemHeading>
			<Row>
			<Col xs="12" md="9">
				<Card style={{borderColor: '#FFFFFF'}}>
					<CardBody>
						<CardSubtitle>{props.subtitle}</CardSubtitle>
						<CardText>{props.description}</CardText>
						{/* <CardText>{props.text}</CardText> */}
						{props.description !== undefined && props.description.length > 0 ? <CardText><Disclaimer></Disclaimer></CardText> : null}
						<Prices title={props.title} prices={props.prices} cart={props.cart} getQtyAndPrice={props.getQtyAndPrice} addToCart={props.addToCart} />
						{ (props.docs !== undefined && props.docs.length > 0) ? <CardText><a href={props.docs[0].src}>Download Information</a></CardText> : null }
					</CardBody>
				</Card>
			</Col>
			<Col xs={{ size: 6, offset: 3}}  md={{ size: 3, offset: 0}}>
				<ImageViewer images={props.images} />
			</Col>
			</Row>
		</ListGroupItem>
	);
};
const OutputCategoryStoreView2 = (props) => {
	if(props.outputCategoryItems !== undefined && props.outputCategoryItems.length > 0){
		return(
			<ListGroup className="mb-5">
				<h2>{props.title}</h2>
				{props.outputCategoryItems.map((output, i) => <OutputItemStoreView
				key={i}
				title={output.title}
				description={output.getDescription === undefined ? "" : output.getDescription()}
				prices={output.getPrices === undefined ? [] : output.getPrices()}
				images={output.images}
				docs={output.docs}
				cart={props.cart}
				getQtyAndPrice={props.getQtyAndPrice}
				addToCart={props.addToCart} />)}
			</ListGroup>
		);
	}else{
		return(
			<ListGroup className="mb-5">
				<h2>{props.title}</h2>
				<ListGroupItem>No Recommendations.</ListGroupItem>
			</ListGroup>
		);
	}
};

export default OutputCategoryStoreView;