import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import thyroid from '../helpers/thyroid.js';
import { isUnanswered, isYes, isAboveOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(thyroid.isHashimotos(inputUser, inputCalculated) && isAboveOptimal(inputCalculated,CalculatedInput.types.reverseT3Range.key)){
        toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.reverseT3Range.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
    } else if(thyroid.isNonHashimotos(inputUser, inputCalculated) && isAboveOptimal(inputCalculated,CalculatedInput.types.reverseT3Range.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.reverseT3Range.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : false");
	}
	if(isYes(inputUser, UserInput.types.backOfArmBumps.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.backOfArmBumps.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.eczemaPsoriasisRosacea.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.nailPitting.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nailPitting.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    toReturn.push(...thyroid.getHashimotosPrompts(inputUser, inputCalculated));
    toReturn.push(...thyroid.getNonHashimotosPrompts(inputUser, inputCalculated));
    if(isUnanswered(inputUser,UserInput.types.reverseT3.key)){
        toReturn.push(UserInput.types.reverseT3);
	}
	if(isUnanswered(inputUser, UserInput.types.backOfArmBumps.key)){ toReturn.push(UserInput.types.backOfArmBumps); }
	if(isUnanswered(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){ toReturn.push(UserInput.types.eczemaPsoriasisRosacea); }
	if(isUnanswered(inputUser, UserInput.types.nailPitting.key)){ toReturn.push(UserInput.types.nailPitting); }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	sortValue -= 150;	
	return sortValue;
};

export default {
	getEvaluation,
	getPrompts,
	title : "Toxicity Test",
	getDosage,
	category : categories.tests,
	getSortValue
	
};