import React from 'react';
import './PrivacyPolicy.css';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Button, Col, Row, Card, CardText, CardBody, CardTitle, CardSubtitle, CardFooter, CardImg, CardImgOverlay, CardLink, CardGroup, Container } from 'reactstrap';
import { useHistory } from "react-router-dom";

const Home = (props) => {
	let history = useHistory();
	return(
		<Container>
            <h2 className="mt-4 m-3">Privacy Policy</h2>
			<p>We do not sell or share information about our users.</p>
			<p>Order information is stored entirely in <a href="//www.paypal.com">Paypal</a> systems and is only accessed by the order fulfillment team.</p>
			<p>Recommendations pass through our systems (<a href="//aws.amazon.com">AWS</a> is our cloud provider) to send an email, but are not saved.</p>
			<p>We do save email addresses and latest results in a <a href="//mailchimp.com">mailchimp</a> audience. An opportunity to unsubscribe will be available inside emails distributed to that audience.</p>
			<p>If you accept, we do store user activity information with Google to better understand the activity occuring on our website.</p>
        </Container>
	);
};
export default Home;