import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import thyroid from '../helpers/thyroid.js';
import { isUnanswered, isYes, isOutsideOptimal, isAboveOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isAboveStandard, isBelowOptimal, getDefaultDosage} from '../utilities';
import image1 from './cortisolbalancer1.jpg';
import doc1 from './cortisolbalancer1.pdf';

const getRangeNotOptimalKeys = (inputCalculated) => {
	let rangeNotOptimalKeys = [];
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.sodiumRange.key)){
		rangeNotOptimalKeys.push(CalculatedInput.types.sodiumRange.key);
		
		/* increase value by 50 */

		
	}
	if(isOutsideOptimal(inputCalculated, CalculatedInput.types.potassiumRange.key)){
		rangeNotOptimalKeys.push(CalculatedInput.types.potassiumRange.key);
	}
	return rangeNotOptimalKeys;
};
const getExtraFactorYesKeys = (inputUser) => {
	let extraFactorYesKeys = [];
	if(inputUser[UserInput.types.craveSalt.key] === "yes"){
		extraFactorYesKeys.push(UserInput.types.craveSalt.key);
		/* increase value by 100 */
	}
	if(inputUser[UserInput.types.fatigue.key] === "mild" || inputUser[UserInput.types.fatigue.key] === "moderate" || inputUser[UserInput.types.fatigue.key] === "severe"){
		extraFactorYesKeys.push(UserInput.types.fatigue.key);
		/* increase value by 100 */
	}
	if(inputUser[UserInput.types.stress.key] === "yes"){
		extraFactorYesKeys.push(UserInput.types.stress.key);
		/* mild increase value by 50 
		moderate increase value by 150
		severe increase value by 250*/
	}
	if(inputUser[UserInput.types.eatALotOfSalt.key] === "yes"){
		extraFactorYesKeys.push(UserInput.types.eatALotOfSalt.key);
		/* increase value by 50 */
	}
	return extraFactorYesKeys;
};
/* Change name to Cortisol Balancer */

/* add in the following. 
If wake between 1am -3am - start with 1 capsule at night for 3 nights. If sleep does not improve take 1 capsule 2 times per day. 
One at lunch and one at bed. 
If yes answer to fatigue:
Mild = 1 capsule at bed
Moderate or Severe = 1 capsule 2 times per day
*/

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(isYes(inputUser, UserInput.types.wakeUpBetween1And3.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.wakeUpBetween1And3.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		/* increase value by 150 */
	}

	if(inputUser[UserInput.types.fatigue.key] === "mild" || inputUser[UserInput.types.fatigue.key] === "moderate" || inputUser[UserInput.types.fatigue.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.fatigue.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* fatigue mild - increase value by 150
	moderate increase by 200
	severe increase value by 300 */


	const rangeNotOptimalKeys = getRangeNotOptimalKeys(inputCalculated); /* increase value by 50 */
	const extraFactorYesKeys = getExtraFactorYesKeys(inputUser);
	if(rangeNotOptimalKeys.length && extraFactorYesKeys.length){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(...extraFactorYesKeys);
		toReturn.inputCalculatedKeys.push(...rangeNotOptimalKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.potassiumRange.key) && inputUser[UserInput.types.takePotassium.key] === "no"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.takePotassium.key);
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.potassiumRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	} else if(thyroid.isHashimotos(inputUser, inputCalculated) && isAboveOptimal(inputCalculated,CalculatedInput.types.reverseT3Range.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.reverseT3Range.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
	} else if(thyroid.isNonHashimotos(inputUser, inputCalculated) && isAboveOptimal(inputCalculated,CalculatedInput.types.reverseT3Range.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.reverseT3Range.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
	}
	return toReturn;
};
/* if hashimotos increase value by 100
is sodium above optimal increase value by 50
if sodium above standard increase value by 100
if potassium below optimal or standard increase by 50
If T3 or Total T3 below optimal increase by 50
if T4 or Total T4 below optimal increase by 25
if T3 or Total T3 below standard increase by 150
if T4 or Total T4 below standard increase value by 100

If reverse T3 above optimal increase value by 200
if Reverese T3 above standard increase value by 400
 */
// Craig Todo: Clarity on T3 & T4 recommendation
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(thyroid.isHashimotos(inputUser, inputCalculated)){
		sortValue -= 100;
	}
	if(isAboveStandard(inputCalculated, CalculatedInput.types.sodiumRange.key)){
		sortValue -= 100;
	}else if(isAboveOptimal(inputCalculated, CalculatedInput.types.sodiumRange.key)){
		sortValue -= 50;
	}

	if(isBelowOptimal(inputCalculated, CalculatedInput.types.potassiumRange.key)){
		sortValue -= 50;
	}

	if(isAboveStandard(inputCalculated,CalculatedInput.types.reverseT3Range.key)){
		sortValue -= 400;
	}else if(isAboveOptimal(inputCalculated,CalculatedInput.types.reverseT3Range.key)){
		sortValue -= 200;
	}

	if(inputUser[UserInput.types.fatigue.key] === "mild"){
		sortValue -= 150;
	}else if(inputUser[UserInput.types.fatigue.key] === "moderate"){
		sortValue -= 200;
	}else if(inputUser[UserInput.types.fatigue.key] === "severe"){
		sortValue -= 300;
	}

	if(inputUser[UserInput.types.craveSalt.key] === "yes"){
		sortValue -= 100;
	}
	if(inputUser[UserInput.types.stress.key] === "yes"){
		sortValue -= 150;
		// Craig Todo: Need to convert stress to mild/moderate/severe
		/* mild increase value by 50 
		moderate increase value by 150
		severe increase value by 250*/
	}
	if(inputUser[UserInput.types.eatALotOfSalt.key] === "yes"){
		sortValue -= 50;
	}
	if(isYes(inputUser, UserInput.types.wakeUpBetween1And3.key)){
		sortValue -= 150;
	}
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];

	if(isUnanswered(inputUser,UserInput.types.wakeUpBetween1And3.key)){
        toReturn.push(UserInput.types.wakeUpBetween1And3);
	}

	if(isUnanswered(inputUser, UserInput.types.potassium.key)){ toReturn.push(UserInput.types.potassium); }
	if(isUnanswered(inputUser, UserInput.types.fatigue.key)){ toReturn.push(UserInput.types.fatigue); } 
	if(isUnanswered(inputUser, UserInput.types.sodium.key)){ toReturn.push(UserInput.types.sodium); } 
	if(isUnanswered(inputUser, UserInput.types.potassium.key)){ toReturn.push(UserInput.types.potassium); } 

	if(isAboveOptimal(inputCalculated, CalculatedInput.types.potassiumRange.key)){
		if(isUnanswered(inputUser, UserInput.types.takePotassium.key)){
			toReturn.push(UserInput.types.takePotassium);
		}
	}
	
	const rangeNotOptimalKeys = getRangeNotOptimalKeys(inputCalculated);
	if(rangeNotOptimalKeys.length > 0){
		if(isUnanswered(inputUser, UserInput.types.craveSalt.key)){
			toReturn.push(UserInput.types.craveSalt)
		}
		if(isUnanswered(inputUser, UserInput.types.fatigue.key)){
			toReturn.push(UserInput.types.fatigue);
		}
		if(isUnanswered(inputUser, UserInput.types.stress.key)){
			toReturn.push(UserInput.types.stress);
		}
		if(isUnanswered(inputUser, UserInput.types.eatALotOfSalt.key)){
			toReturn.push(UserInput.types.eatALotOfSalt);
		}
	}

	toReturn.push(...thyroid.getHashimotosPrompts(inputUser, inputCalculated));
	toReturn.push(...thyroid.getNonHashimotosPrompts(inputUser, inputCalculated));
	if(isUnanswered(inputUser,UserInput.types.reverseT3.key)){
		toReturn.push(UserInput.types.reverseT3);
	}
	return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(isYes(inputUser, UserInput.types.wakeUpBetween1And3.key)){
		return {
			description : "Start with 1 at night for 3 nights.  If sleep does not improve, take 1 serving at lunch and 1 before bed.",
			servingsPerDay : 1
		};
	}
	if(inputUser[UserInput.types.fatigue.key] === "mild"){
		return {
			description : "1 serving before bed.",
			servingsPerDay : 1
		};
	}
	if(inputUser[UserInput.types.fatigue.key] === "moderate" || inputUser[UserInput.types.fatigue.key] === "severe"){
		return {
			description : "1 serving at lunch, 1 serving before bed.",
			servingsPerDay : 2
		};
	}
	
	return {
		description : "1 serving before bed.",
		servingsPerDay : 1
	};
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	Cortisol Correct helps your body resist and recover from the effects of everyday stress. 
	Components of this multifunctional formula have been shown to support healthy cortisol 
	levels, help alleviate occasional fatigue, promote mental clarity, and support relaxation 
	and restful sleep.*`;
};
const title = "Cortisol Balancer";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00006", price: 42, servings: 60}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};