import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import thyroid from '../helpers/thyroid.js';
import { isUnanswered, isAboveStandard, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(thyroid.isNonHashimotos(inputUser, inputCalculated)){
		let keysAboveStandard = [];
        if (isAboveStandard(inputCalculated, CalculatedInput.types.thyroidPeroxidaseTPOAbsLABCORPRange.key)){
            keysAboveStandard.push(CalculatedInput.types.thyroidPeroxidaseTPOAbsLABCORPRange.key);
        }
        if (isAboveStandard(inputCalculated, CalculatedInput.types.thyroglobulinAbsRange.key)){
            keysAboveStandard.push(CalculatedInput.types.thyroglobulinAbsRange.key);
        }
        if(keysAboveStandard.length >= 1){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(...keysAboveStandard);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat(["Hashimotos: false"]);
        }
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    toReturn.push(...thyroid.getNonHashimotosPrompts(inputUser, inputCalculated));
    if(thyroid.isNonHashimotos(inputUser, inputCalculated)) {
        if(isUnanswered(inputUser,UserInput.types.thyroidPeroxidaseTPOAbsLABCORP.key)){
            toReturn.push(UserInput.types.thyroidPeroxidaseTPOAbsLABCORP);
        }
        if(isUnanswered(inputUser,UserInput.types.thyroglobulinAbs.key)){
            toReturn.push(UserInput.types.thyroglobulinAbs);
        }
    }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "Talk To Your Doctor About Thyroid Test Results",
	getDosage,
	category : categories.behaviors,
	getSortValue : getDefaultSortValue
};