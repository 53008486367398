import React from 'react';
import './FAQ.css';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Button, Col, Row, Card, CardText, CardBody, CardTitle, CardSubtitle, CardFooter, CardImg, CardImgOverlay, CardLink, CardGroup, Container } from 'reactstrap';
import { useHistory } from "react-router-dom";
import LayoutConstants from '../LayoutConstants.js';


const Home = (props) => {
	let history = useHistory();
	return(
        <Container>
            <h2 className="mt-4 m-3">Frequently Asked Questions</h2>
        
		<ListGroup>
        <ListGroupItem id="WhyFunctionalyMedicineCalculator">
                <ListGroupItemHeading>Why should you use our Calculator?</ListGroupItemHeading>
                <ListGroupItemText><p>How do you know which supplements to take? How much do you take, when do you take
them, or for how long?</p>
<p>Are you a symptom chaser, choosing only based off of what your current symptoms are?</p>
<p>Are you a random pill picker, not really sure what to do but wanting to do something for your
health?</p>
<p>Maybe you are a “my friend told me about it” picker, or you are newsletter picker. Buying
random things because you read an article about how good a certain supplement or product
was.</p>
<p>How do you know if those supplements are doing you any good. Or maybe they are actually
doing you harm, or you just have expensive toilet water?</p>
<p>Whatever your current method of picking supplements, odds are it’s not the best way for you.
What if there was a better way. A way that took all the confusion out of trying to decide what
supplements you should take, when, how much, and for how long?</p>
<p>What if there was a way that would take into account not just your symptoms but also your
health history, your goals and sometimes most importantly your blood work, urine testing,
stool testing, hair analysis, etc and combine it and calculate exactly what you should be taking
for optimal health?</p>
<p>Well now there is, and it’s called Functional Medicine Calculator.</p>
<p>Functional Medicine Calculator is the most comprehensive questionnaire and lab analyzer that
takes all of your information and uses a complex algorithm to calculate specifically for you and
you alone what supplements you should be taking.</p>
<p>So, whether your goal is to get back to health from a chronic illness, stay healthy, active, fit,
lose weight, or to just feel good, we have you covered. Functional medicine calculator will do
all the work for you.</p>
<p>Functional Medicine Calculator is taking the guess work out of being healthy.</p></ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem id="MostImportant">
                <ListGroupItemHeading>How do I know which supplements are the most important?</ListGroupItemHeading>
                <ListGroupItemText><p>Based off of years of experience and learning, each product and lab recommendation is
associated with certain aspects of your questionnaire and lab results. Based on those inputs a
certain value is assigned and the importance of each recommendation is calculated and
adjusted with each variable. You may notice the numbers next to each supplement. Based on
all of your inputs those are your “scores” each recommendation obtained. You can compare
and contrast the value given to each one and even why each one was recommended.
</p>
<p>
Our usual recommendation is that if you are looking for a base program, start with the top 5 on
the list. If you are looking to obtain optimal health, the top 10 or even 12 might be better.</p></ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem id="WhyLabs">
                <ListGroupItemHeading>Why should I get lab testing done?</ListGroupItemHeading>
                <ListGroupItemText>While lab testing is not mandatory, it is strongly recommended. The basis of health and
functional medicine is that we want to know how your body is functioning, not just what your
symptoms are. Symptoms are important but more often than not they are an external or visible
sign of something internally wrong or off. By looking at your labs and combining those with your
symptoms the functional medicine calculator can more accurately determine what would be
optimal for you. Recommendations will be generated based off of your questionnaire; they will
just be that more accurate and specific to you if labs are available.</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem id="WhyOurSupplements">
                <ListGroupItemHeading>Why are functional formula supplements better and why should I use them?</ListGroupItemHeading>
                <ListGroupItemText><p>Even though it's not a requirement to use functional medicine supplements, these are the supplements I have used and seen work with hundreds of patients over the years.
                    Research has shown me these supplements have a very high quality and consistency, unlike some products.
                    Also very important is the fact that many supplements have too many ingredients in them, and it is easy to overlap and get too much of something and not enough of another.
                    Balance is the key here.
                    Because of this, we use our own line that works synergistically with each other.
                    What I have found with new patients is often they would be on something like 1000% of a certain ingredient or something in one product would be counteracting something in another product.
                    "Too much of a good thing" can become a bad thing.
                    Our goal is to avoid any overlap and give you the highest quality so you can get the biggest bang for your buck and your health.
                    </p></ListGroupItemText>

            </ListGroupItem>
            <ListGroupItem>
                <ListGroupItemHeading id="OptimalRange">Why do I still feel so bad when all of my labs look normal? Optimal labs vs Normal.</ListGroupItemHeading>
                <ListGroupItemText><p>The problem with normal blood test ranges is that they are very broad. Here is how I explain
this to my clients in my office. If we look at a bell curve, and then we cut off the ends of that
curve, the ends are where people are sick. In the standard reference range (i.e. “normal”) is what
is left over. In the conventional medical world it tends to be very black or white. So if you are
not “sick”, by default it means you are healthy. This is not a good way of looking at health. For
example, if we look at scurvy. The RDA for vitamin C is between 65-90 mg per day, which is
barely above the 10mg necessary to just not get scurvy and have your teeth fall out. This is in
contrast to the often recommended vitamin C dosage of many Natural doctors of 2-3 grams per
day, which is 2,000-3,000 grams per day.</p>
<p>
So the optimal range we are looking for is a much narrower range. We want to avoid the gray
areas. The gray area tends to be a slippery slope. If we have one or 2 markers in that area it's
probably not too big of a deal. But, if we start getting 5, 6, 7 or more markers in those areas it
can often create a situation in which you can have the symptoms of being sick or not feeling
well and not actually be labeled as being sick. You are told “everything is normal, it's all in your
head.”</p>
<p>
The goal is to get as many of the basic blood markers into the optimal range so that your body
has the basic building blocks it needs to get and stay healthy. Without a good foundation,
nothing else matters. You have to have good building blocks to build a good temple. Your body
is the most precious thing you will ever own. Better take care of it.</p></ListGroupItemText>
            </ListGroupItem>
        </ListGroup>
        </Container>
	);
};
export default Home;