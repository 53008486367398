import React, { useState } from 'react';
export default {
	hashimotos : {
		key: "hashimotos",
		prompt : "Do you have Hashimotos?",
		possible: ["no","yes", "unknown"],
		group : 10, 
		priority : 10
	},
	autoimmune : {
		key: "autoimmune",
		prompt : "Do you have (or suspect/undiagnosed) any Autoimmune disease?",
		possible: ["no","yes", "unknown"],
		group : 10, 
		priority : 10
	},
	allergies : {
		key: "allergies",
		prompt : "Do you have allergies (environment or food)?",
		possible: ["no","mild","moderate","severe"],
		group : 2, 
		priority : 10
	},
	anxiety : {
		key: "anxiety",
		prompt : "Do you have anxiety/hyperactivity on a weekly basis?",
		possible: ["no","yes"],
		group : 4, 
		priority : 10
	},
	depression : {
		key: "depression",
		prompt : "Do you suffer from depression?",
		possible: ["no","mild","moderate","severe"],
		group : 0, 
		priority : 10,
		choiceDescription: <span><b>mild:</b> 0-30% of the time<br/>
								<b>moderate:</b> 31-70% of the time<br/>
								<b>severe:</b> 71-100% of the time</span>
	},
	benignProstateHypertrophy : {
		key: "benignProstateHypertrophy",
		prompt : "Do you have Benign Prostate Hypertrophy (BPH)?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	heartDisease : {
		key: "heartDisease",
		prompt : "Do you have heart disease?",
		possible: ["no","yes"],
		group : 3, 
		priority : 10
	},
	colorectalCancerHistory : {
		key: "colorectalCancerHistory",
		prompt : "Do you have a history of Colorectal Cancer?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	breastCancerHistory : {
		key: "breastCancerHistory",
		prompt : "Do you have a history of Breast Cancer?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	uterineCancerHistory : {
		key: "uterineCancerHistory",
		prompt : "Do you have a history of Uterine Cancer?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	candidaHistory : {
		key: "candidaHistory",
		prompt : "Do you have a history of Candida within the last year?",
		possible: ["no","yes"],
		group : 2, 
		priority : 10
	},
	eczemaPsoriasisRosacea : {
		key: "eczemaPsoriasisRosacea",
		prompt : "Do you have Eczema, Psoriasis, or Rosacea?",
		possible: ["no","yes"],
		group : 6, 
		priority : 11
	},
	pregnantOrNursing : {
		key: "pregnantOrNursing",
		prompt : "Are you pregnant or nursing?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
};