import React, { useState } from 'react';
import { Container, Col, Button, UncontrolledDropdown, ButtonGroup, DropdownToggle, DropdownItem, DropdownMenu, FormGroup, Label, Input, InputGroup, InputGroupAddon, Badge, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import ToastBody from 'reactstrap/lib/ToastBody';
/* 
    code & user expiration stored in local storage, obfuscated
    get json for code from server eventually, get it from local code for now
    getPercentage returns discount percentage (15 not .15)
    items in cart should be saved with full amount, discount added as a negative line item at checkout
*/

import currentActiveEncoded from "./active.js";
const currentActive = JSON.parse(Buffer.from(currentActiveEncoded, "base64").toString());

const MILLISECONDS_IN_A_DAY = 86400000;

export const saveDC = (dc, created = Date.now()) => {
    localStorage.setItem("disc", JSON.stringify({
        dc,
        created
    }));
};

export const getDC = (code, expiration) => {
    return Buffer
            .from(code + "|" + expiration.valueOf(), "utf-8")
            .toString('base64');
};
export const decodeDC = (dc) => {
    const splitDc = Buffer.from(dc,'base64').toString("utf-8").split("|");
    return {
        code : splitDc[0],
        expiration : new Date(parseInt(splitDc[1]))
    };
}
export const getPercentage = (sku, activeEncoded) => {
    const active = activeEncoded !== undefined ? JSON.parse(Buffer.from(activeEncoded, "base64").toString()) : currentActive;
    const savedDisc = JSON.parse(localStorage.getItem("disc"));
    if(savedDisc !== undefined && savedDisc !== null && savedDisc.created > 0){
        if((Date.now() - savedDisc.created) < 30 * MILLISECONDS_IN_A_DAY ){ // 30 days
            const decodedDC = decodeDC(savedDisc.dc);
            if(decodedDC.expiration >= Date.now()){
                if(active[decodedDC.code] !== undefined){
                    if(active[decodedDC.code].skus !== undefined
                        && active[decodedDC.code].skus[sku] !== undefined
                        && active[decodedDC.code].skus[sku] > 0 ){
                            return active[decodedDC.code].skus[sku];
                        }
                    return active[decodedDC.code].totalPercentage;
                }
            }
        }
    }
    return 0;
};
export const getCode = (activeEncoded) => {
    const active = activeEncoded !== undefined ? JSON.parse(Buffer.from(activeEncoded, "base64").toString()) : currentActive;
    const savedDisc = JSON.parse(localStorage.getItem("disc"));
    if(savedDisc !== undefined && savedDisc !== null && savedDisc.created > 0){
        if((Date.now() - savedDisc.created) < 30 * MILLISECONDS_IN_A_DAY ){ // 30 days
            const decodedDC = decodeDC(savedDisc.dc);
            if(decodedDC.expiration >= Date.now()){
                if(active[decodedDC.code] !== undefined){
                    return decodedDC.code;
                }
            }
        }
    }
    return "";
};

export const DCCreator = (props) => {

    const [code, setCode] = useState(props.code || "");
    const [expirationInput, setExpirationInput] = useState((new Date(Date.now() + (30 * MILLISECONDS_IN_A_DAY))).toISOString().substring(0,10));
    const [expiration, setExpiration] = useState((new Date(expirationInput)).valueOf());

    const [baseUrl, setBaseUrl] = useState("https://www.functionalmedicinecalculator.com/calculator/questionaire");

    const getUrl = (base, code, expiration) => {
        let divider = "?";
        if(base.indexOf("?") >= 0){
            divider = "&";
        }
        const splitBase = base.split("#");
        return splitBase[0] + divider + "dc=" + getDC(code, expiration) + (splitBase.length > 1 ? "#" + splitBase[1] : "");
    };

    if(props.debug){
        return(
            <Container>
                <h2 className="mt-4 m-3">DC Creator</h2>
                <FormGroup row>
                    <Label htmlFor="code" md={3} sm={6} xs={12}>Code</Label>
                    <Col md={3} sm={6} xs={12}>
                        <InputGroup>
                            <Input
                            id="code"
                            type="text" 
                            value={code}
                            onChange={(event) => setCode(event.target.value)}/>
                        </InputGroup>
                    </Col>
                    <Label htmlFor="expirationInput"  md={3} sm={6} xs={12}>Expiration</Label>
                    <Col  md={3} sm={6} xs={12}>
                        <InputGroup>
                            <Input
                            id="expirationInput"
                            type="date" 
                            value={expirationInput}
                            min={(new Date()).toISOString().substring(0,10)}
                            onChange={(event) => setExpirationInput(event.target.value)}/>
                        </InputGroup>
                    </Col>
                </FormGroup>
                <p>Add dc={getDC(code,expiration)} to the query string of urls when sharing/emailing links for users to receive a discount.</p>
                <p>The code is the named discount code.  It will only work if the website has the code programmed so it must be coordinated with developer.</p>
                <p>The expiration date is the date that the code will no longer be active when users click the link.</p>
                <p>When the user clicks the link, the discount code is saved in their browser for 30 days.  So they can come back to the website, without clicking the link and the discount will apply.  After 30 days, if the expiration date is beyond that, the user must use the link given to them again.</p>
                <h4>For example</h4>
                <p>https://www.functionalmedicinecalculator.com?somethingElse=foo<strong><big>&amp;</big>dc={getDC(code,expiration)}</strong>
                </p>
                <h4>Add dc to existing url like a pre-filled cart</h4>
                <FormGroup row>
                    <Label htmlFor="baseUrl" md={3} sm={6} xs={12}>Base URL</Label>
                    <Col md={9} sm={6} xs={12}>
                        <InputGroup>
                            <Input
                            id="baseUrl"
                            type="text" 
                            value={baseUrl}
                            onChange={(event) => setBaseUrl(event.target.value)}/>
                        </InputGroup>
                    </Col>
                </FormGroup>
                <p><a href={getUrl(baseUrl, code, expiration)}>{getUrl(baseUrl, code, expiration)}</a></p>
            </Container>
        );
    }else{
        return "";
    }
};
export default {
    saveCode: saveDC, 
    getDC,
    decodeDC,
    getPercentage,
    getCode,
    DCCreator
};
