import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './d350001.jpg';
import doc1 from './d350001.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(!isUnanswered(inputUser, UserInput.types.vitaminD25OH.key) && inputUser[UserInput.types.vitaminD25OH.key] <= 30){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.vitaminD25OH.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.eczemaPsoriasisRosacea.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(isYes(inputUser,UserInput.types.eczemaPsoriasisRosacea.key)){
		sortValue -= 100;
	}
	return sortValue;
};
const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.vitaminD25OH.key)){
        toReturn.push(UserInput.types.vitaminD25OH);
	}
	if(isUnanswered(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){
		toReturn.push(UserInput.types.eczemaPsoriasisRosacea);
	}
    return toReturn;
};

const getDosage = getDefaultDosage;
const getDescription = (inputUser, inputCalculated) => {
	return `
	Vitamin D3 5000 is cholecalciferol provided in convenient softgels. Vitamin D3 Liquid
	is cholecalciferol derived from lanolin and provided in a liquid base of sunflower oil and purified water. In
	this liquid formula, vitamin D and sunflower oil are combined using a special micro-emulsification process
	designed to create a natural micellized matrix which, when coated by the stomach bile, will encourage
	absorption.*`;
};
const title = "D3 5000";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "D3-5000", price: 14, servings: 60}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
	
};