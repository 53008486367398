import products from './products/index.js';
import tests from './tests/index.js';
import behaviors from './behaviors/index.js';
import notifications from './notifications/index.js';
import View from './View';
import OutputCategoryView from './OutputCategoryView';
import { isUnanswered, categories } from './utilities.js';

const sortByTitle = (a, b) => {
	if(a.title < b.title){
		return -1;
	}else if(a.title > b.title){
		return 1;
	}else{
		return 0;
	}
};
export const allOutputs = [
	...products.sort(sortByTitle),
	...tests.sort(sortByTitle),
	...behaviors.sort(sortByTitle),
	...notifications.sort(sortByTitle)
];

const getPrompts = (inputUser, inputCalculated, selectedOutputs) => {
	let prompts = allOutputs
		// If it is already recommended / part of outputs, no need to use the outputs prompts
		.filter(output => (selectedOutputs === undefined || selectedOutputs.indexOf(output.title) > -1) && !output.getEvaluation(inputUser, inputCalculated).recommended)
		// Get the prompts
		.map(output => output.getPrompts(inputUser, inputCalculated))
		// flatten array of arrays
		.flat()
		// dedupe prompts
		.filter((x,i,a) => a.indexOf(x) === i);
	
	prompts.sort((a,b) => {
		if(a.group === b.group){
			return a.priority - b.priority;
		}else{
			return a.group - b.group;
		}});

	return prompts;
};

const getRecommended = (inputUser, inputCalculated, selectedOutputs) => {
	
	return allOutputs
		.filter(output => selectedOutputs === undefined || selectedOutputs.indexOf(output.title) > -1)
		.map((output) => {return { output, evaluation : output.getEvaluation(inputUser, inputCalculated)};})
		.filter(output => output.evaluation.recommended);
};
export default {
	getPrompts,
	getRecommended,
	View,
	OutputCategoryView,
	allOutputs,
	isUnanswered,
	categories
};