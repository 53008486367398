import UserInput from '../../inputs/user/index.js';
//import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './zinc1.jpg';
import doc1 from './zinc1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.nailPitting.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nailPitting.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(isYes(inputUser, UserInput.types.nailPitting.key)){
		sortValue -= 150;
	}
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.nailPitting.key)){ toReturn.push(UserInput.types.nailPitting); }
    return toReturn;
};

const getDosage = getDefaultDosage;
const getDescription = (inputUser, inputCalculated) => {
	return `
	Zinc is a fully reacted, proprietary TRAACS® amino acid chelate formulated for enhanced absorption. 
	As an essential mineral, zinc serves catalytic, structural, and regulatory functions in the body. 
	Zinc ultimately supports immune and neurological function, growth, taste acuity, nutrient metabolism, 
	and reproductive health.*`;
};
const title = "Zinc";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00047", price: 19, servings: 120}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};