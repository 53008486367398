import UserInput from '../inputs/user/index.js';
import CalculatedInput from '../inputs/calculated/index.js';

export const isUnanswered = (inputUser, key) => {
	return isUnansweredRaw(inputUser[key]);
};
export const isUnansweredRaw = (value) => {
	return value === undefined || value === "";
};


export const isOutsideOptimal = (inputCalculated, key) => {
	return isAboveOptimal(inputCalculated, key) || isBelowOptimal(inputCalculated, key);
};
export const isOutsideStandard = (inputCalculated, key) => {
	return isAboveStandard(inputCalculated, key) || isBelowStandard(inputCalculated, key);
};

export const isAboveStandard = (inputCalculated, key) => {
	return inputCalculated[key] === "Above Standard";
};
export const isBelowStandard = (inputCalculated, key) => {
	return inputCalculated[key] === "Below Standard";
};
export const isAboveOptimalButInStandard = (inputCalculated, key) => {
	return inputCalculated[key] === "Above Optimal";
};
export const isBelowOptimalButInStandard = (inputCalculated, key) => {
	return inputCalculated[key] === "Below Optimal";
};
export const isAboveOptimal = (inputCalculated, key) => {
	return isAboveStandard(inputCalculated, key) || isAboveOptimalButInStandard(inputCalculated, key);
};
export const isBelowOptimal = (inputCalculated, key) => {
	return isBelowStandard(inputCalculated, key) || isBelowOptimalButInStandard(inputCalculated, key);
};
export const isOptimalOrBelow = (inputCalculated, key) => {
	return isOptimal(inputCalculated, key) || isBelowOptimal(inputCalculated, key);
};
export const isOptimalOrAbove = (inputCalculated, key) => {
	return isOptimal(inputCalculated, key) || isAboveOptimal(inputCalculated, key);
};
export const isOptimal = (inputCalculated, key) => {
	return inputCalculated[key] === "Optimal";
};
export const isYes = (inputUser, key) => {
	return inputUser[key] === "yes";
};
export const isNo = (inputUser, key) => {
	return inputUser[key] === "no";
};
export const isUnknown = (inputUser, key) => {
	return inputUser[key] === "unknown";
};
export const getDefaultSortValue = () => {
	return 500;
};
export const getDefaultEvaluation = () => {
	return {
		recommended : false,
		inputUserKeys : [],
		inputCalculatedKeys : [],
		reasons: []
	};
};
export const getDefaultDosage = () => {
	return {
		description : "1 serving per day.",
		servingsPerDay : 1
	};
};
export const getReasons = (inputUserKeys, inputCalculatedKeys, inputUser, inputCalculated) => {
	const invalidUserKeys = inputUserKeys.filter(key => {
		if(UserInput.types[key] === undefined){ return key; }
	});
	if(invalidUserKeys.length > 0){
		console.log(invalidUserKeys.length + ":" + invalidUserKeys.join());
	}
	inputUserKeys = [...new Set(inputUserKeys)];
	inputCalculatedKeys = [...new Set(inputCalculatedKeys)];
	return inputUserKeys
		.filter((key) => UserInput.types[key] !== undefined && key !== "maleOrFemale")
		.map((key) => getReason(UserInput.types[key].prompt, inputUser[key]))
		.concat(inputCalculatedKeys.map((key) => getReason(CalculatedInput.types[key].prompt, CalculatedInput.types[key].getValue(inputUser))))
};
const getReason = (prompt, answer) => {
	return {
		prompt,
		answer
	}
};
export const getUnknownKeys = (inputUser, inputUserKeys) => {
	if(inputUserKeys !== undefined && inputUserKeys.length > 0){
		return inputUserKeys
			.filter(inputUserKey => isUnknown(inputUser, inputUserKey));
	}else{
		return [];
	}
};
export const getUnknownOrUnansweredKeys = (inputUser, inputUserKeys) => {
	if(inputUserKeys !== undefined && inputUserKeys.length > 0){
		return inputUserKeys
			.filter(inputUserKey => isUnknown(inputUser, inputUserKey) || isUnanswered(inputUser, inputUserKeys));
	}else{
		return [];
	}
};
export const categories = {
	supplements : "Supplements",
	behaviors : "Behaviors",
	tests : "Tests",
	notifications : "Notifications"
};
export default {
	isUnanswered,
	isAboveOptimalButInStandard,
	isBelowOptimalButInStandard,
	isAboveOptimal,
	isBelowOptimal,
	isOptimalOrAbove,
	isOptimalOrBelow,
	isOptimal,
	isOutsideOptimal,
	isOutsideStandard,
	isYes,
	isNo,
	isUnknown,
	categories,
	getDefaultSortValue,
	getDefaultEvaluation,
	getReasons,
	getUnknownKeys,
	getUnknownOrUnansweredKeys,
	getDefaultDosage
};