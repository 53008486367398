import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isAboveStandard, isNo, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isAboveStandard(inputCalculated, CalculatedInput.types.transferrinSaturationRange.key)){
        if(isNo(inputUser,UserInput.types.takeIron.key)){
            toReturn.recommended = true;
            toReturn.inputUserKeys.push(UserInput.types.takeIron.key);
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.transferrinSaturationRange.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
        }
	}
	return toReturn;
}

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.transferrinSaturation.key)){
        toReturn.push(UserInput.types.transferrinSaturation);
    }else if(isAboveStandard(inputCalculated, CalculatedInput.types.transferrinSaturationRange.key)){
        if(isUnanswered(inputUser, UserInput.types.takeIron.key)){
            toReturn.push(UserInput.types.takeIron);
        }
    }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
    return "1) Stop/decrease intake of red meat. 2) Don't use iron skillets. 3) Don't eat citrus or vitamin C with iron containing meals. 4) Possible test for Hemochromatosis."
};

export default {
	getEvaluation,
	getPrompts,
	title : "Reduce Iron Intake",
	getDosage,
	category : categories.behaviors,
	getSortValue : getDefaultSortValue
};