// import UserInput from '../../inputs/user/index.js';
// import CalculatedInput from '../../inputs/calculated/index.js';
import { getDefaultSortValue, getDefaultEvaluation, categories, getDefaultDosage} from '../utilities';

import image1 from './probio1001.jpg';
import doc1 from './probio1001.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	return toReturn;
};


const getDosage = getDefaultDosage;

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	return sortValue;
};

const getDescription = (inputUser, inputCalculated) => {
	return `Probio-100 DF is a vegetarian, dairy- and gluten-free, four-strain probiotic 
	totaling 100 billion CFU† per capsule. Each vegetarian capsule is sealed in 
	nitrogen-purged aluminum blister packs to serve as protection from factors proven 
	to compromise the stability of probiotics such as heat, moisture, and oxygen. 
	Probio-100 DF provides four researched strains of beneficial bacteria, including the 
	extensively studied HN019® strain of Bifidobacterium lactis. These live microorganisms 
	have proven health benefits and well-established safety, and have been tested for 
	epithelial cell adhesion and/or resistance to low pH.*

	To further support resistance to low pH and the delivery of microorganisms to the 
	small intestines, Premier IFM employs DRcaps™ gastro-resistant capsules. These 
	specially designed, innovative capsules help slow exposure of actives to stomach acid 
	and ensure more targeted release.*`;
};
const title = "Probio-100 DF";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00045", price: 73, servings: 30}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};