import UserInput from '../../inputs/user/index.js';
import { isUnknown, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getUnknownKeys = (inputUser, inputUserKey) => {
	if(isUnknown(inputUser, inputUserKey)){
		return [inputUserKey];
	}else{
		return [];
	}
};

const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();
    
	let unknownKeys = [];
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.alkPhos.key));
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.astSGOT.key));
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.altSGPT.key));
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.ggt.key));
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.ldh.key));
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.bilirubinTotal.key));
	
    if(unknownKeys.length > 0){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(...unknownKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "Hepatic (Liver) Panel Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};