import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import outputs from '../Recommender/outputs/index.js';
import { Row, Col, Container, Button, Label, Input, InputGroup, InputGroupAddon, FormGroup } from 'reactstrap';
import OutputCategoryStoreView from '../Recommender/outputs/OutputCategoryStoreView.js';
import Cart from '../Cart/index.js';
//import serializer from './persister/serializer.js';
import queryString from 'query-string';
import LayoutConstants from '../LayoutConstants.js';

const Store = (props) => {
	let history = useHistory();
	const queryStringParams = queryString.parse(props.location.search);
	// const getCartFromQueryString = () => {
	// 	//debugger;
	// 	return getDefaultInputUser(serializer.deserialize(queryStringParams.inputUser));
	// };


	let [q, setQ] = useState(queryStringParams["q"]);
	const setSearch = (query) => {
		history.push(query.length > 0 ? "?q="+query : "?")
		setQ(query);
	};
	const outputCategoryItems = outputs.allOutputs
		.filter(out => 
			out.getPrices !== undefined 
			&& out.getPrices().length > 0
			&& (q === undefined || q.length === 0 || out.title.toLowerCase().includes(q.toLowerCase())));

	return (
		<Container>
			<h2 className="mt-4 m-3">Store</h2>
				<Col lg="6">
					<label>Search</label>
					<Input
						type="text"
						value={q}
						onChange={(event) => setSearch(event.target.value)}
						/>
				</Col>
					<OutputCategoryStoreView debug={props.debug} outputCategoryItems={outputCategoryItems} cart={props.cart} getQtyAndPrice={props.getQtyAndPrice} addToCart={props.addToCart} />
					<Cart.View cart={props.cart} getQtyAndPrice={props.getQtyAndPrice} addToCart={props.addToCart} clearCart={props.clearCart} />
		</Container>
	);
};
export default withRouter(Store);