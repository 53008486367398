import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isBelowOptimal, isOptimal, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if(isBelowOptimal(inputCalculated,CalculatedInput.types.cholesterolTotalRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.cholesterolTotalRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		} else if (isOptimal(inputCalculated,CalculatedInput.types.cholesterolTotalRange.key)){
			if(isYes(inputUser, UserInput.types.preferStoppingCholesterolLoweringMeds.key)){
				toReturn.recommended = true;
				toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
				toReturn.inputUserKeys.push(UserInput.types.preferStoppingCholesterolLoweringMeds.key);
				toReturn.inputCalculatedKeys.push(CalculatedInput.types.cholesterolTotalRange.key);
				toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
			}
		}
	}
	return toReturn;
}

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.takeCholesterolLoweringMeds.key)){
        toReturn.push(UserInput.types.takeCholesterolLoweringMeds);
    }else if(isYes(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if(isUnanswered(inputUser, UserInput.types.cholesterolTotal.key)){
			toReturn.push(UserInput.types.cholesterolTotal);
		}else if(isOptimal(inputCalculated, CalculatedInput.types.cholesterolTotalRange.key)){
			if(isUnanswered(inputUser, UserInput.types.preferStoppingCholesterolLoweringMeds.key)){
				toReturn.push(UserInput.types.preferStoppingCholesterolLoweringMeds);
			}
		}
    }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	return "";
};

export default {
	getEvaluation,
	getPrompts,
	title : "Talk To Your Doctor About Reducing Cholesterol Medication Dosage",
	getDosage,
	category : categories.behaviors,
	getSortValue : getDefaultSortValue
};