import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './prostateguard1.jpg';
import doc1 from './prostateguard1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isYes(inputUser, UserInput.types.benignProstateHypertrophy.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
			toReturn.inputUserKeys.push(UserInput.types.benignProstateHypertrophy.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}
	
	return toReturn;
};

/* if yes to benign prostate hypertrophy increase value 300
	if PSA on labs is above optimal rec prostate guard 1xd - increase value 150
	is PSA on labs is above standard rec prostate guard 1 capsule 2 times per day - increase value 450
	if male or female and complaint of hair loss rec 1 capsule per day increaes value 250
	if male complaint of loss of libido rec 1 capsule per day - increase value 250*/
// Craig Todo: What is PSO on labs?
// Craig Todo: Hair loss question?
// Craig Todo: Libido question?
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isYes(inputUser, UserInput.types.benignProstateHypertrophy.key)){
			sortValue -= 300;
		}
	}
	return sortValue;
};

/* Change name to Prostate Guard*/


const getPrompts = (inputUser, inputCalculated) => {
	var toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.maleOrFemale.key)){
		toReturn.push(UserInput.types.maleOrFemale);
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isUnanswered(inputUser, UserInput.types.benignProstateHypertrophy.key)){
			toReturn.push(UserInput.types.benignProstateHypertrophy);
		}
	}
	return toReturn;
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Prostate Guard supports normal male lower urinary tract function and prostate health. Clinically meaningful
	levels of key ingredients target urinary flow and frequency as well as prostate-related hormone metabolism.
	This formula features FLOWENS®—a full-spectrum cranberry powder optimized for men’s health—combined
	with beta-sitosterol, pyridoxal 5’-phosphate,TRAACS® zinc bisglycinate chelate, and highly concentrated,
	standardized extracts of saw palmetto and pygeum.*`;
};
const title = "Prostate Guard";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "PROSTATE-GUARD", price: 40, servings: 60}]
};
const getDosage = getDefaultDosage;

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};