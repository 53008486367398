import UserInput from '../../inputs/user/index.js';
import { isUnknown, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getUnknownKeys = (inputUser, inputUserKey) => {
	if(isUnknown(inputUser, inputUserKey)){
		return [inputUserKey];
	}else{
		return [];
	}
};
const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	let unknownKeys = [];
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.rbcMale.key));
	//unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.rbcFemale.key));
	//unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.reticulocyte.key));
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.hemoglobinMale.key));
	//unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.hemoglobinFemale.key));
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.hematocritMale.key));
	//unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.hematocritFemale.key));
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.mcv.key));
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.mch.key));
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.mchc.key));
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.rdw.key));
	unknownKeys.push(...getUnknownKeys(inputUser, UserInput.types.platelets.key))

	if(unknownKeys.length > 0){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(...unknownKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "Complete Blood Count (CBC) Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};