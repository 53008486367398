import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(isYes(inputUser, UserInput.types.utiHistory.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.utiHistory.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "mild" || inputUser[UserInput.types.yeastInfectionSeverity.key] === "moderate" || inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.yeastInfectionSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.bladderInfections.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bladderInfections.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	
	if(isUnanswered(inputUser,UserInput.types.utiHistory.key)){ toReturn.push(UserInput.types.utiHistory); }
	if(isUnanswered(inputUser,UserInput.types.yeastInfectionSeverity.key)){ toReturn.push(UserInput.types.yeastInfectionSeverity); }
	if(isUnanswered(inputUser,UserInput.types.bladderInfections.key)){ toReturn.push(UserInput.types.bladderInfections); }

	return toReturn;
};
const getDosage = (inputUser, inputCalculated) => {
	return "";
};
const title = "Organic Acids Test (OATS)"
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};