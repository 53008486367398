import UserInput from '../../inputs/user/index.js';
import { isYes, isUnanswered, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';
import image1 from './candidaformula1.jpg';
import doc1 from './candidaformula1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.yeastInfectionSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.utiHistory.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.utiHistory.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.bladderInfections.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bladderInfections.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.candidaHistory.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.candidaHistory.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.nailFungus.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nailFungus.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.yeastInfectionSeverity.key)){
        toReturn.push(UserInput.types.yeastInfectionSeverity);
	}
	if(isUnanswered(inputUser,UserInput.types.utiHistory.key)){
        toReturn.push(UserInput.types.utiHistory);
	}
	if(isUnanswered(inputUser,UserInput.types.bladderInfections.key)){
        toReturn.push(UserInput.types.bladderInfections);
	}
	if(isUnanswered(inputUser, UserInput.types.sinusInfections.key)){
		toReturn.push(UserInput.types.sinusInfections);
	}
	if(isUnanswered(inputUser, UserInput.types.badBreath.key)){
		toReturn.push(UserInput.types.badBreath);
	}
	if(isUnanswered(inputUser, UserInput.types.whiteTongueCoating.key)){
		toReturn.push(UserInput.types.whiteTongueCoating);
	}	
	if(isUnanswered(inputUser,UserInput.types.candidaHistory.key)){ 
		toReturn.push(UserInput.types.candidaHistory);
	}else if(isYes(inputUser, UserInput.types.candidaHistory.key) && isUnanswered(inputUser, UserInput.types.itchyEars.key)){
		toReturn.push(UserInput.types.itchyEars);
	}
	if(isUnanswered(inputUser, UserInput.types.nailFungus.key)){ toReturn.push(UserInput.types.nailFungus); }	
    return toReturn;
};
/* add symptoms to point to this supplement on questionaire of UTI's, Bladder infection, Candida
if symptoms of any of the previous increase value by 100
Mild increase by 100
moderate increase by 100
Severe 100 by 100*/

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "mild"){
		sortValue -= 100;
	}else if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "moderate"){
		sortValue -= 200;
	}else if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"){
		sortValue -= 300;
	}
	if(isYes(inputUser, UserInput.types.utiHistory.key)){
		sortValue -= 100;
	}
	if(isYes(inputUser, UserInput.types.bladderInfections.key)){
		sortValue -= 100;
	}
	if(isYes(inputUser, UserInput.types.candidaHistory.key)){
		sortValue -= 100;
	}
	if(isYes(inputUser, UserInput.types.itchyEars.key)){
		sortValue -= 100;
	}
	if(isYes(inputUser, UserInput.types.badBreath.key)){
		sortValue -= 50;
	}
	if(isYes(inputUser, UserInput.types.whiteTongueCoating.key)){
		sortValue -= 100;
	}
	if(inputUser[UserInput.types.sinusInfections.key] === "moderate"){
		sortValue -= 50;
	}else if(inputUser[UserInput.types.sinusInfections.key] === "severe"){
		sortValue -= 100;
	}

	if(isYes(inputUser, UserInput.types.nailFungus.key)){
		sortValue -= 200;
	}
	return sortValue;
};

/* Add to dosage instructions. "If recommended dosage does not improve symptoms within 1 week increase dosage by adding an additional 
capsule until reaching 3 caspules per day. If infection continues add in Candida Formula. Consider consulting with your doctor or contact 
a Functional Medicine Doctor" */
const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.yeastInfectionSeverity.key] === "severe"){
		return {
			description : "1 serving per day. If symptoms do not improve within 1 week, increase dosage by an additional serving up to 3. If infection continues add in Candida Formula. Consider consulting with your doctor or contact a Functional Medicine Doctor.",
			servingsPerDay : 1
		};
	}
	return getDefaultDosage();
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	Candida Formula offers a complementary blend of herbs, essential oils, and sodium caprylate, a naturally occurring
	fatty acid. Candida Formula is formulated to support the body’s immune system as well as a healthy gastrointestinal
	(GI) flora. This comprehensive formula contains Origanox™ WS—a GRAS, phenolic-rich ingredient extracted from the
	edible herb Origanum vulgare—as well as herbs to support digestion and a healthy GI system.*
	`;
};
const title = "Candida Formula";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "CANDIDA", price: 29, servings: 60}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};