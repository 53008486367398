import UserInput from '../../inputs/user/index.js';
import { getUnknownOrUnansweredKeys, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isUnanswered} from '../utilities';


export const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	const unknownOrUnansweredKeys = getUnknownOrUnansweredKeys(inputUser, [
	//lipids panel
		UserInput.types.cholesterolTotal.key,
		UserInput.types.triglycerides.key,
		UserInput.types.ldlCholesterol.key,
		UserInput.types.hdlCholesterol.key,
		UserInput.types.vldlCholesterol.key,
	//whitebloodcell
		UserInput.types.totalWBCs.key,
		UserInput.types.neutrophils.key,
	//UserInput.types.bands.key,
		UserInput.types.lymphocytes.key,
		UserInput.types.monocytes.key,
		UserInput.types.basophils.key,
		UserInput.types.eosinophils.key,
	//ironpaneltest
		UserInput.types.ironSerum.key,
		UserInput.types.ferritin.key,
		UserInput.types.tibc.key,
		UserInput.types.transferrinSaturation.key,
	//kidneypaneltest
		UserInput.types.bun.key,
		UserInput.types.creatinine.key,
		UserInput.types.eGFRNonAfricanAmerican.key,
		UserInput.types.eGFRAfricanAmerican.key,
	//insulinleveltest
		UserInput.types.hemoglobinA1C.key,
		UserInput.types.glucose.key,
	//electrolytepaneltest
		UserInput.types.sodium.key,
		UserInput.types.potassium.key,
		UserInput.types.chloride.key,
		UserInput.types.cO2.key,
	//thyroidtest
		UserInput.types.tsh.key,
		UserInput.types.totalT4.key,
		UserInput.types.freeT4.key,
		UserInput.types.totalT3.key,
		UserInput.types.freeT3.key,
		UserInput.types.reverseT3.key,
		UserInput.types.t3Uptake.key,
		UserInput.types.freeThyroxineIndexT7.key,
		UserInput.types.thyroidPeroxidaseTPOAbsLABCORP.key,
		UserInput.types.thyroglobulinAbs.key,
	//inflammationtest
		UserInput.types.hsCRPMale.ke,
	//UserInput.types.hsCRPFemale.key,
	//UserInput.types.cReactiveProtein.key,
	//UserInput.types.esrFemale.key,
		UserInput.types.homocysteine.key,
		UserInput.types.fibrinogen.key,
	//vitaminDTest
		UserInput.types.vitaminD25OH.key]);

    if(unknownOrUnansweredKeys.length > 0){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(...unknownOrUnansweredKeys);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}


	if(inputUser[UserInput.types.verticalNailRidges.key] === "mild"
	|| inputUser[UserInput.types.verticalNailRidges.key] === "moderate"
	|| inputUser[UserInput.types.verticalNailRidges.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.verticalNailRidges.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if(inputUser[UserInput.types.horizontalNailRidges.key] === "mild"
	|| inputUser[UserInput.types.horizontalNailRidges.key] === "moderate"
	|| inputUser[UserInput.types.horizontalNailRidges.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.horizontalNailRidges.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if(isYes(inputUser, UserInput.types.nailPitting.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nailPitting.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.nailFungus.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nailFungus.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	if(inputUser[UserInput.types.yellowEyesSeverity.key] === "moderate"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.yellowEyesSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.eczemaPsoriasisRosacea.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.verticalNailRidges.key)){ toReturn.push(UserInput.types.verticalNailRidges); }
	if(isUnanswered(inputUser, UserInput.types.horizontalNailRidges.key)){ toReturn.push(UserInput.types.horizontalNailRidges); }
	if(isUnanswered(inputUser, UserInput.types.nailPitting.key)){ toReturn.push(UserInput.types.nailPitting); }
	if(isUnanswered(inputUser, UserInput.types.nailFungus.key)){ toReturn.push(UserInput.types.nailFungus); }	
	if(isUnanswered(inputUser, UserInput.types.yellowEyesSeverity.key)){ toReturn.push(UserInput.types.yellowEyesSeverity); }
    if(isUnanswered(inputUser, UserInput.types.eczemaPsoriasisRosacea.key)){ toReturn.push(UserInput.types.eczemaPsoriasisRosacea); }
    return toReturn;
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Testing can be completed at any LabCorp location.  We cannot refer you to test locations in New York or New Jersey.  You must fast 
	for 8-12 hours before testing. We will contact you to arrange testing.
	`;
};
const getDosage = (inputUser, inputCalculated) => {
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	sortValue -= 1000;	
	return sortValue;
};
const title = "Comprehensive Initial Blood Panel Test";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "INIT_BLOOD", price: 368.62}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.tests,
	getSortValue,
	getDescription,
	getPrices,
	
};