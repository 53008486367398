import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isYes, isNo, isAboveStandard, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isAboveOptimal, getDefaultDosage} from '../utilities';

import image1 from './niacinsr1.jpg';
import doc1 from './niacinsr1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(isNo(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if(inputUser[UserInput.types.cholesterolTotal.key] >= 240){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
			toReturn.inputUserKeys.push(UserInput.types.cholesterolTotal.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}else if(isYes(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if(isAboveStandard(inputCalculated,CalculatedInput.types.cholesterolTotalRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.cholesterolTotalRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.ldlCholesterolRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.ldlCholesterolRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
		if(isAboveOptimal(inputCalculated, CalculatedInput.types.triglyceridesRange.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.takeCholesterolLoweringMeds.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.triglyceridesRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}

	return toReturn;
};

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(isNo(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if(inputUser[UserInput.types.cholesterolTotal.key] >= 240){
			sortValue -= 200;
		}/* 
			// Craig Todo: Need some clarity here.  What is 240 from the above?  Should we just use Range and is that defined?
		is yes to cholesterol meds and total choesterol or LDL or triglycerides above optimal increase value 150
		if yes to cholesterol meds and total cholesterol or LDL or triglycerides above standard invrease value 250
		add in trigger of
		if triglycerides or LDL above optimal or standard recommend 1 capsule at night*/
	}else if(isYes(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if(isAboveStandard(inputCalculated,CalculatedInput.types.cholesterolTotalRange.key) 
		|| isAboveStandard(inputCalculated, CalculatedInput.types.ldlCholesterolRange.key)
		|| isAboveStandard(inputCalculated, CalculatedInput.types.triglyceridesRange.key)){
			sortValue -= 250
		}else if(isAboveOptimal(inputCalculated,CalculatedInput.types.cholesterolTotalRange.key)
		|| isAboveOptimal(inputCalculated, CalculatedInput.types.ldlCholesterolRange.key)
		|| isAboveOptimal(inputCalculated, CalculatedInput.types.triglyceridesRange.key)){
			sortValue -= 150
		}
	}
	return sortValue;
};


/* Change name to Niacin SR*/
const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser,UserInput.types.takeCholesterolLoweringMeds.key)){
        toReturn.push(UserInput.types.takeCholesterolLoweringMeds);
	}else {	
		if(isUnanswered(inputUser, UserInput.types.cholesterolTotal.key)){
			toReturn.push(UserInput.types.cholesterolTotal);
		}
		if(isUnanswered(inputUser, UserInput.types.ldlCholesterol.key)){
			toReturn.push(UserInput.types.ldlCholesterol);
		}
		if(isUnanswered(inputUser, UserInput.types.triglycerides.key)){
			toReturn.push(UserInput.types.triglycerides);
		}
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(isNo(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if(inputUser[UserInput.types.cholesterolTotal.key] >= 240){
			return {
				description : "1 serving, twice per day.",
				servingsPerDay : 2
			};
		}
	}else if(isYes(inputUser, UserInput.types.takeCholesterolLoweringMeds.key)){
		if((inputUser[UserInput.types.cholesterolTotal.key] >= 200 && inputUser[UserInput.types.cholesterolTotal.key] < 220)
		|| isAboveOptimal(inputCalculated, CalculatedInput.types.ldlCholesterolRange.key)
		|| isAboveOptimal(inputCalculated, CalculatedInput.types.triglyceridesRange.key)){
			return {
				description : "1 serving per day in the evening.",
				servingsPerDay : 1
			};
		}else if(inputUser[UserInput.types.cholesterolTotal.key] >= 220){
			return {
				description : "1 serving in the morning and 1 in the evening.",
				servingsPerDay : 2
			};
		}
	}
	return getDefaultDosage();
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Niacin is one of the most studied and documented nutrients for support of lipid levels 
	already within the normal range, especially high-density lipoprotein cholesterol (HDL-C) levels. 
	Sustained-release niacin, as found in Niacin SR, has a lesser flushing effect compared with 
	instant-release niacin. Use of a proprietary, wax-coated technology permits release that is 
	complete in seven to eight hours, the time that is considered ideal for a time-release form of 
	niacin.*`;
};
const title = "Niacin SR";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00025", price: 30, servings: 120}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};