import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isAboveStandard, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';



const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isAboveStandard(inputCalculated, CalculatedInput.types.homocysteineRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.homocysteineRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.homocysteine.key)){
		toReturn.push(UserInput.types.homocysteine);
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "MTHFR Genetic Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};