import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import thyroid from '../helpers/thyroid.js';
import { isUnanswered, isBelowOptimalButInStandard, isBelowStandard, isBelowOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './thyroidbalance1.jpg';
import doc1 from './thyroidbalance1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();
    if(thyroid.isHashimotos(inputUser, inputCalculated)){
        let t4RangeBelowOptimalKeys = [];
        if(isBelowOptimal(inputCalculated,CalculatedInput.types.freeT4Range.key)){
            t4RangeBelowOptimalKeys.push(CalculatedInput.types.freeT4Range.key);
        }
        if(isBelowOptimal(inputCalculated,CalculatedInput.types.totalT4Range.key)){
            t4RangeBelowOptimalKeys.push(CalculatedInput.types.totalT4Range.key);
        }

        let t3RangeBelowOptimalKeys = [];
        if(isBelowOptimal(inputCalculated,CalculatedInput.types.freeT3Range.key)){
            t3RangeBelowOptimalKeys.push(CalculatedInput.types.freeT3Range.key);
        }
        if(isBelowOptimal(inputCalculated,CalculatedInput.types.totalT3Range.key)){
            t3RangeBelowOptimalKeys.push(CalculatedInput.types.totalT3Range.key);
        }

        if(t4RangeBelowOptimalKeys.length > 0 && t3RangeBelowOptimalKeys.length > 0){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(...t4RangeBelowOptimalKeys);
            toReturn.inputCalculatedKeys.push(...t3RangeBelowOptimalKeys);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
        }
    }
    if(thyroid.isNonHashimotos(inputUser, inputCalculated)){

        let tRangeBelowOptimalButInStandardKeys = [];
        if(isBelowOptimalButInStandard(inputCalculated,CalculatedInput.types.freeT3Range.key)){
            tRangeBelowOptimalButInStandardKeys.push(CalculatedInput.types.freeT3Range.key);
        }
        if(isBelowOptimalButInStandard(inputCalculated,CalculatedInput.types.totalT3Range.key)){
            tRangeBelowOptimalButInStandardKeys.push(CalculatedInput.types.totalT3Range.key);
        }
        if(isBelowOptimalButInStandard(inputCalculated,CalculatedInput.types.freeT4Range.key)){
            tRangeBelowOptimalButInStandardKeys.push(CalculatedInput.types.freeT4Range.key);
        }
        if(isBelowOptimalButInStandard(inputCalculated,CalculatedInput.types.totalT4Range.key)){
            tRangeBelowOptimalButInStandardKeys.push(CalculatedInput.types.totalT4Range.key);
        }

        if(tRangeBelowOptimalButInStandardKeys.length >= 2){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(...tRangeBelowOptimalButInStandardKeys);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
        }

        let tRangeBelowStandardKeys = [];
        if(isBelowStandard(inputCalculated,CalculatedInput.types.freeT3Range.key)){
            tRangeBelowStandardKeys.push(CalculatedInput.types.freeT3Range.key);
        }
        if(isBelowStandard(inputCalculated,CalculatedInput.types.totalT3Range.key)){
            tRangeBelowStandardKeys.push(CalculatedInput.types.totalT3Range.key);
        }
        if(isBelowStandard(inputCalculated,CalculatedInput.types.freeT4Range.key)){
            tRangeBelowStandardKeys.push(CalculatedInput.types.freeT4Range.key);
        }
        if(isBelowStandard(inputCalculated,CalculatedInput.types.totalT4Range.key)){
            tRangeBelowStandardKeys.push(CalculatedInput.types.totalT4Range.key);
        }

        if(tRangeBelowStandardKeys.length >= 1){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(...tRangeBelowStandardKeys);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
        }
    }

	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	/*  if symptoms of complaints of energy issues, brain fog or skin problems increase value 25
    if diagnosis of thyroid problems (other than Graves disease) or Hashimotos increase value 75
    if T4 or Total T4 below optimal increase value 100, if below standard increase value 200
    if T3 or Total T3 below optimal increase value 200, if below standard increase value 400 and refer to primary dr. or 
    functional dr. 
    
    If Reverse T3 above optimal increase value 75, if above standard increase value 150
    
    If TPO antibodies above optimal or standard increase value 300 and recommend follow up testing in 1 month and 
    refer to primary or Functional medicine dr if they dont already have one. 
    
    If thyroglobulin antibodies above optimal or standard increase value 300 and recommend follow up testing in 1 month and 
    refer to primary or Functional medicine dr if they dont already have one.
    
    if complaints of hair loss increase value 150
    if compaint of symptoms of trouble losing weight increase value 100*/

    // Craig Todo: I'll need to sit with you on this one

    // if(thyroid.isHashimotos(inputUser, inputCalculated)){
    //     let t4RangeBelowOptimalKeys = [];
    //     if(isBelowOptimal(inputCalculated,CalculatedInput.types.freeT4Range.key)){
    //         t4RangeBelowOptimalKeys.push(CalculatedInput.types.freeT4Range.key);
    //     }
    //     if(isBelowOptimal(inputCalculated,CalculatedInput.types.totalT4Range.key)){
    //         t4RangeBelowOptimalKeys.push(CalculatedInput.types.totalT4Range.key);
    //     }

    //     let t3RangeBelowOptimalKeys = [];
    //     if(isBelowOptimal(inputCalculated,CalculatedInput.types.freeT3Range.key)){
    //         t3RangeBelowOptimalKeys.push(CalculatedInput.types.freeT3Range.key);
    //     }
    //     if(isBelowOptimal(inputCalculated,CalculatedInput.types.totalT3Range.key)){
    //         t3RangeBelowOptimalKeys.push(CalculatedInput.types.totalT3Range.key);
    //     }

    //     if(t4RangeBelowOptimalKeys.length > 0 && t3RangeBelowOptimalKeys.length > 0){
    //         toReturn.recommended = true;
    //         toReturn.inputCalculatedKeys.push(...t4RangeBelowOptimalKeys);
    //         toReturn.inputCalculatedKeys.push(...t3RangeBelowOptimalKeys);
    //         toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
    //     }
    // }
    // if(thyroid.isNonHashimotos(inputUser, inputCalculated)){

    //     let tRangeBelowOptimalButInStandardKeys = [];
    //     if(isBelowOptimalButInStandard(inputCalculated,CalculatedInput.types.freeT3Range.key)){
    //         tRangeBelowOptimalButInStandardKeys.push(CalculatedInput.types.freeT3Range.key);
    //     }
    //     if(isBelowOptimalButInStandard(inputCalculated,CalculatedInput.types.totalT3Range.key)){
    //         tRangeBelowOptimalButInStandardKeys.push(CalculatedInput.types.totalT3Range.key);
    //     }
    //     if(isBelowOptimalButInStandard(inputCalculated,CalculatedInput.types.freeT4Range.key)){
    //         tRangeBelowOptimalButInStandardKeys.push(CalculatedInput.types.freeT4Range.key);
    //     }
    //     if(isBelowOptimalButInStandard(inputCalculated,CalculatedInput.types.totalT4Range.key)){
    //         tRangeBelowOptimalButInStandardKeys.push(CalculatedInput.types.totalT4Range.key);
    //     }

    //     if(tRangeBelowOptimalButInStandardKeys.length >= 2){
    //         toReturn.recommended = true;
    //         toReturn.inputCalculatedKeys.push(...tRangeBelowOptimalButInStandardKeys);
    //         toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
    //     }

    //     let tRangeBelowStandardKeys = [];
    //     if(isBelowStandard(inputCalculated,CalculatedInput.types.freeT3Range.key)){
    //         tRangeBelowStandardKeys.push(CalculatedInput.types.freeT3Range.key);
    //     }
    //     if(isBelowStandard(inputCalculated,CalculatedInput.types.totalT3Range.key)){
    //         tRangeBelowStandardKeys.push(CalculatedInput.types.totalT3Range.key);
    //     }
    //     if(isBelowStandard(inputCalculated,CalculatedInput.types.freeT4Range.key)){
    //         tRangeBelowStandardKeys.push(CalculatedInput.types.freeT4Range.key);
    //     }
    //     if(isBelowStandard(inputCalculated,CalculatedInput.types.totalT4Range.key)){
    //         tRangeBelowStandardKeys.push(CalculatedInput.types.totalT4Range.key);
    //     }

    //     if(tRangeBelowStandardKeys.length >= 1){
    //         toReturn.recommended = true;
    //         toReturn.inputCalculatedKeys.push(...tRangeBelowStandardKeys);
    //         toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
    //     }
    // }

	return sortValue;
};
const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    toReturn.push(...thyroid.getHashimotosPrompts(inputUser, inputCalculated));
    if(thyroid.isHashimotos(inputUser, inputCalculated)){
        if(isUnanswered(inputUser,UserInput.types.freeT3.key)){
            toReturn.push(UserInput.types.freeT3);
        }
        if(isUnanswered(inputUser,UserInput.types.totalT3.key)){
            toReturn.push(UserInput.types.totalT3);
        }
        if(isUnanswered(inputUser,UserInput.types.freeT4.key)){
            toReturn.push(UserInput.types.freeT4);
        }
        if(isUnanswered(inputUser,UserInput.types.totalT4.key)){
            toReturn.push(UserInput.types.totalT4);
        }
    }
    
    toReturn.push(...thyroid.getNonHashimotosPrompts(inputUser, inputCalculated));
    if(thyroid.isNonHashimotos(inputUser, inputCalculated)) {
        if(isUnanswered(inputUser,UserInput.types.freeT3.key)){
            toReturn.push(UserInput.types.freeT3);
        }
        if(isUnanswered(inputUser,UserInput.types.totalT3.key)){
            toReturn.push(UserInput.types.totalT3);
        }
        if(isUnanswered(inputUser,UserInput.types.freeT4.key)){
            toReturn.push(UserInput.types.freeT4);
        }
        if(isUnanswered(inputUser,UserInput.types.totalT4.key)){
            toReturn.push(UserInput.types.totalT4);
        }
    }
    return toReturn;
};

const getDosage = getDefaultDosage;

const getDescription = (inputUser, inputCalculated) => {
	return `
    Thyroid Balance is a comprehensive BSE-free, bovine, multi-glandular, 
    mineral and herbal formula to support healthy thyroid function.*`;
};
const title = "Thyroid Balance";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00018", price: 23, servings: 60}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
    getSortValue,
    getDescription,
    getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};