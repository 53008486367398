import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './dailycalm1.jpg';
import image2 from './dailycalm2.jpg';
import doc1 from './dailycalmcherry1.pdf';
import doc2 from './dailycalmunflavored1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.cantTurnBrainOff.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.cantTurnBrainOff.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if cant turn off brain increase value by 75 */
	if(isYes(inputUser, UserInput.types.anxiety.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.anxiety.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* If anxeity increase value by 75 */
	return toReturn;
};

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(isYes(inputUser, UserInput.types.cantTurnBrainOff.key)){
		sortValue -= 75;
	}/* if cant turn off brain increase value by 75 */
	if(isYes(inputUser, UserInput.types.anxiety.key)){
		sortValue -= 75;
	}/* If anxeity increase value by 75 */
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.cantTurnBrainOff.key)){ toReturn.push(UserInput.types.cantTurnBrainOff); }
	if(isUnanswered(inputUser,UserInput.types.anxiety.key)){ toReturn.push(UserInput.types.anxiety); }
    return toReturn;
};

/* add in a symptom for this one. If they answer yes to having anxiety during the day, recommend 1 scoop of daily calm 
in a water bottle during the day to help ease symtpoms and lessen the severity and frequency. */

const getDosage = (inputUser, inputCalculated) => {
	if(isYes(inputUser, UserInput.types.anxiety.key)){
		return {
			description : "1 serving of daily calm in a water bottle during the day to help lessen severity and frequency of anxiety symtpoms.",
			servingsPerDay : 1
		};
	}
	return {
		description : "1 serving 30 minutes before bed.",
		servingsPerDay : 1
	};
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Daily Calm is an innovative powdered drink mix without flavoring components. 
	It contains a blend of ingredients that supports the body's natural synthesis of catecholamines, 
	the inhibitory neurotransmitter GABA, hormonal balance, and healthy glucose metabolism. 
	Daily Calm aims to promote a calm, relaxed, well-balanced emotional and physiological state.* 

	Daily Calm comes in Wild Cherry or Unflavored.`;
};
const title = "Daily Calm";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00026", price: 38, servings: 60},
	{title: title + " (Wild Cherry)", sku: "00027", price: 44, servings: 60}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }, { src: image2 }],
	docs: [{ src: doc1 }, { src: doc2 }]
};