import React, { useState } from 'react';
import horizontalNailRidges1 from './horizontalNailRidges1.jpg';
import verticalNailRidges1 from './verticalNailRidges1.jpg';
import yellowEyesSeverity1 from './yellowEyesSeverity1.jpg';

export default {
	fatigue : {
		key: "fatigue",
		prompt : "Do you suffer from fatigue?",
		possible: ["no","mild","moderate","severe"],
		group : 4, 
		priority : 10,
		choiceDescription: <span><b>mild:</b> 1-3 days per week<br/><b>moderate:</b> 3-4 day per week, 50-70% of the day<br/><b>severe:</b> Every day/all day</span>
	},
	brainFog : {
		key: "brainFog",
		prompt : "Do you suffer from brain fog?",
		possible: ["no","yes"],
		group : 0, 
		priority : 10
	},
	hormoneProblems : {
		key: "hormoneProblems",
		prompt : "Do you have hormone problems?",
		possible: ["no","mild","moderate","severe","extremely severe"],
		group : 10, 
		priority : 10,
		choiceDescription: <span>pms/period irregularity, erectile dysfunction, trouble building muscle, hair loss, abnormal hair growth</span>
	},
	kidneyProblems : {
		key: "kidneyProblems",
		prompt : "Do you have kidney problems?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	urinateFrequently : {
		key: "urinateFrequently",
		prompt : "Do you urinate frequently?",
		possible: ["no","yes"],
		group : 5, 
		priority : 10
	},
	utiHistory : {
		key: "utiHistory",
		prompt : "Do you have a history of UTIs, bladder infections, or yeast infections?",
		possible: ["no","yes"],
		group : 2, 
		priority : 10
	},
	digestiveProblems : {
		key: "digestiveProblems",
		prompt : "Do you have digestive problems (bloating, upset stomach, nausea, diarrhea, constipation, leaky gut, undigested food in stool, etc.) on a weekly basis?",
		possible: ["no","yes"],
		group : 1, 
		priority : 10
	},
	frequentlyDehydrated : {
		key: "frequentlyDehydrated",
		prompt : "Are you frequently dehydrated?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	craveSalt : {
		key: "craveSalt",
		prompt : "Do you crave salt?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	bodyPain : {
		/* for the trigger of body pain put in parenthesis (including but not limited to - headaches, Rheumatoid arthritis, PMS, 
	back and neck pain, headaches, muscle tenstion, etc.) */
		key: "bodyPain",
		prompt : "Do you have body pain (headaches, Rheumatoid arthritis, PMS, back and neck pain, muscle tension, etc.)?",
		possible: ["no","mild","moderate","severe"],
		group : 2, 
		priority : 10
	},
	swellingInflammation : {
		key: "swellingInflammation",
		prompt : "Do you have a feeling of swelling and/or inflammation in either an area of the body or all over?",
		possible: ["no","mild","moderate","severe"],
		group : 2, 
		priority : 10,
		choiceDescription: <span><b>mild:</b> 30% of body or 1-3 of 10 on pain scale<br/>
								<b>moderate:</b> 31-70% of body or 4-7 of 10 on pain scale<br/>
								<b>severe:</b> 71-100% of body or 8-10 of 10 on pain scale</span>
	},
	irregularPeriods : {
		key: "irregularPeriods",
		prompt : "Do you have irregular periods/frequent bleeding or irregular time between periods?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	lowTemperature : {
		key: "lowTemperature",
		prompt : "Do you have a low body temperature (below 98.6) on a regular basis?",
		possible: ["no","yes"],
		group : 4, 
		priority : 10
	},
	lowThyroid : {
		key: "lowThyroid",
		prompt : "Do you have low thyroid or been diagnosed with hypothyroid or Hashimotos?",
		possible: ["no","yes", "unknown"],
		group : 10, 
		priority : 10
	},
	memoryProblems : {
		key: "memoryProblems",
		prompt : "Do you have memory problems? (Trouble with recall, remembering names, things, places, events)",
		possible: ["no","mild","moderate","severe"],
		group : 0, 
		priority : 10,
		choiceDescription: <span><b>mild:</b> 1-2 times per week<br/>
								<b>moderate:</b> 4-7 times per week<br/>
								<b>severe:</b> 2-3 times per day</span>
	},
	lackOfJoy : {
		key: "lackOfJoy",
		prompt : "Do you have a lack of joy on a weekly basis?",
		possible: ["no","yes"],
		group : 0, 
		priority : 10,
		choiceDescription: <span>Disinterest in social contact and a lack of pleasure in social situations or from things that you believe should or previously provided you with joy.</span>
	},
	migraines : {
		key: "migraines",
		prompt : "Do you get migraines on a monthly basis?",
		possible: ["no","yes"],
		group : 0, 
		priority : 10
	},
	frequentlyGetSick : {
		key: "frequentlyGetSick",
		prompt : "Do you get sick more than once every 3-4 months?",
		possible: ["no","yes"],
		group : 2, 
		priority : 10
	},
	coldSoreFrequency : {
		key: "coldSoreFrequency",
		prompt : "How often do you get cold sores/herpes?",
		possible: [
			"never",
			"couple of times per year", 
			"every couple of months", 
			"about once a month", 
			"more than once a month",
			"have a current outbreak"],
		group : 2, 
		priority : 10
	},
	bladderInfections : {
		key: "bladderInfections",
		prompt : "Do you get bladder infections more than once every 3-4 months?",
		possible: ["no","yes"],
		group : 2, 
		priority : 10
	},
	yeastInfectionSeverity : {
		key: "yeastInfectionSeverity",
		prompt : "If you have yeast infections, how severe?",
		possible: ["never","mild","moderate","severe"],
		group : 2, 
		priority : 10,
		choiceDescription: <span><b>mild:</b> 1-2 times every 6 months or longer<br/>
								<b>moderate:</b> 1-2 times every 4-6 months<br/>
								<b>severe:</b> 1-2 times in less than 4 months</span>
	},
	lackOfSexDrive : {
		key: "lackOfSexDrive",
		prompt : "Do you have a lack of sex drive?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	troubleGainingMuscle : {
		key: "troubleGainingMuscle",
		prompt : "Do you have trouble gaining muscle?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	troubleLosingWeight : {
		key: "troubleLosingWeight",
		prompt : "Do you have trouble losing weight?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	maleBreasts : {
		key: "maleBreasts",
		prompt : "Do you have male breasts?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	dryPainfulIntercourse : {
		key: "dryPainfulIntercourse",
		prompt : "Is intercourse dry/painful?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	pmsSymptoms : {
		key: "pmsSymptoms",
		prompt : "Do you have PMS symptoms?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	hotFlashes : {
		key: "hotFlashes",
		prompt : "Do you have hot flashes?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10
	},
	highBloodPressure : {
		key: "highBloodPressure",
		prompt : "Do you have high blood pressure?",
		possible: ["no","yes"],
		group : 3, 
		priority : 10
	},
	cantFallAsleep : {
		key: "cantFallAsleep",
		prompt : "Do you have trouble falling asleep?",
		possible: ["no","yes"],
		group : 4, 
		priority : 10
	},
	cantStayAsleep : {
		key: "cantStayAsleep",
		prompt : "Do you have trouble staying asleep (other than waking up to urinate)?",
		possible: ["no","yes"],
		group : 4, 
		priority : 10
	},
	cantTurnBrainOff : {
		key: "cantTurnBrainOff",
		prompt : "Do you have trouble turning your brain off before bed?",
		possible: ["no","yes"],
		group : 0, 
		priority : 10
	},
	wakeUpBetween1And3 : {
		key: "wakeUpBetween1And3",
		prompt : "Do you wake up between 1 and 3 am?",
		possible: ["no","yes"],
		group : 10, 
		priority : 10,
		choiceDescription: <span>Not due to having to urinate and not able to immediately go back to sleep.</span>
	},
	diarrheaSeverity : {
		key: "diarrheaSeverity",
		prompt : "Do you have diarrhea?",
		possible: ["no","mild","moderate","severe"],
		group : 1, 
		priority : 10,
		choiceDescription: <span><b>mild:</b> 1-2 times per month<br/>
								<b>moderate:</b> 3-4 times per month<br/>
								<b>severe:</b> More than once per week</span>
	},
	bowelMovementFrequency : {
		key: "bowelMovementFrequency",
		prompt : "How often do you have a bowel (healthy looking and feeling) movement?",
		possible: ["every day", "every day but doesn't feel complete", "every 2-3 days", "every 3-4 days", "every 4-6 days"],
		group : 1, 
		priority : 10
	},
	nausea : {
		key: "nausea",
		prompt : "Do you suffer from Nausea more than once per month?",
		possible: ["no","yes"],
		group : 1, 
		priority : 10
	},
	vomiting : {
		key: "vomiting",
		prompt : "Do you suffer from Vomiting more than once every 2-3 months?",
		possible: ["no","yes"],
		group : 1, 
		priority : 10
	},
	upsetStomachWithMeals : {
		key: "upsetStomachWithMeals",
		prompt : "Do you have an upset stomach with meals on a regular basis?",
		possible: ["no","yes"],
		group : 1, 
		priority : 10
	},
	bloatingAfterMeals : {
		key: "bloatingAfterMeals",
		prompt : "Do you suffer from bloating after meals?",
		possible: ["no","after all meals","after mostly sugar or carbohydrate meals"],
		group : 1, 
		priority : 10
	},
	burpingFartingGas : {
		key: "burpingFartingGas",
		prompt : "Do you suffer from excessive burping/farting/gas on a regular basis?",
		possible: ["no","yes"],
		group : 1, 
		priority : 10
	},
	dryThinningBaldingBrittleHair : {
		key : "dryThinningBaldingBrittleHair",
		prompt : "Do you have dry, thinning, balding, or brittle hair?",
		possible: ["no","yes"],
		group : 8, 
		priority : 10
	},
	significantVisionChanges : {
		key : "significantVisionChanges",
		prompt : "Have you had significant vision changes in the last 6 months?",
		possible: ["no","yes"],
		group : 8, 
		priority : 11
	},
	troubleFocusingEyesBrainFog : {
		key : "troubleFocusingEyesBrainFog",
		prompt : "Do you have trouble focusing your eyes/blurry vision as a result of brain fog on a weekly basis?",
		possible: ["no","yes"],
		group : 8, 
		priority : 11,
		choiceDescription: <span>Lack of focus, trouble concentrating, lack of clarity, feeling of disconnect.</span>
	},
	yellowEyesSeverity : {
		key: "yellowEyesSeverity",
		prompt : "Do the whites around your eyes have a yellow tint to them?",
		possible: ["no","mild","moderate","severe"],
		group : 8, 
		priority : 11,
		images: [{src: yellowEyesSeverity1 }]
	},
	dryItchyRedEyes : {
		key: "dryItchyRedEyes",
		prompt : "Do you have dry, itchy, red eyes?",
		possible: ["no","mild","moderate","severe"],
		group : 8, 
		priority : 11
	},
	itchyEars : {
		key: "itchyEars",
		prompt : "Do your ears itch on a regular basis?",
		possible: ["no","yes"],
		group : 8, 
		priority : 12
	},
	pluggedEars : {
		key: "pluggedEars",
		prompt : "Do you have plugged ears or pressure in ears more than 2 times per month?",
		possible: ["no","yes"],
		group : 8, 
		priority : 12
	},
	postNasalDrip : {
		key: "postNasalDrip",
		prompt : "Do you have post nasal drip more than once per week?",
		possible: ["no","yes"],
		group : 8, 
		priority : 13
	},
	noseStuffyness : {
		key: "noseStuffyness",
		prompt : "Do you have nose stuffyness more than once per week?",
		possible: ["no","yes"],
		group : 8, 
		priority : 13
	},
	sinusPressure : {
		key: "sinusPressure",
		prompt : "Do you have sinus pressure more than once per week?",
		possible: ["no","yes"],
		group : 8, 
		priority : 14
	},
	sinusInfections : {
		key: "sinusInfections",
		prompt : "Do you get sinus infections?",
		possible: ["no","mild","moderate","severe"],
		group : 8, 
		priority : 14,
		choiceDescription: <span><b>mild:</b> 1-2 times per year<br/>
								<b>moderate:</b> 3-4 times per year<br/>
								<b>severe:</b> more than 4 times per year</span>
	},
	cavities : {
		key: "cavities",
		prompt : "Do you get cavities?",
		possible: ["no","yes"],
		group : 8, 
		priority : 15
	},
	bleedingGums : {
		key: "bleedingGums",
		prompt : "Do you have bleeding gums?",
		possible: ["no","yes"],
		group : 8, 
		priority : 15
	},
	badBreath : {
		key: "badBreath",
		prompt : "Do you have bad breath?",
		possible: ["no","yes"],
		group : 8, 
		priority : 15
	},
	whiteTongueCoating : {
		key: "whiteTongueCoating",
		prompt : "Do you have a white coating on your tongue?",
		possible: ["no","yes"],
		group : 8, 
		priority : 15
	},
	difficultySwallowing : {
		key: "difficultySwallowing",
		prompt : "Do you have difficulty swallowing?",
		possible: ["no","yes"],
		group : 9, 
		priority : 15
	},
	throatHoarsness : {
		key: "throatHoarsness",
		prompt : "Do you have throat hoarsness (longer than 3 weeks)?",
		possible: ["no","yes"],
		group : 9, 
		priority : 15
	},
	breastSwellingTendernessDischarge : {
		key: "breastSwellingTendernessDischarge",
		prompt : "Do you have breast swelling, tenderness, or discharge not associated with breast feeding?",
		possible: ["no","yes"],
		group : 9, 
		priority : 16
	},
	skinIssues : {
		key: "skinIssues",
		prompt : "Do you have skin issues?",
		possible: ["no","yes"],
		group : 6, 
		priority : 10,
		choiceDescription: <span>dry, itchy, discoloration, eczema, prosiasis, thinning</span>
	},
	acne : {
		key: "acne",
		prompt : "Do you have acne more than the occasional pimple?",
		possible: ["no","yes"],
		group : 6, 
		priority : 11
	},
	backOfArmBumps : {
		key: "backOfArmBumps",
		prompt : "Do you have bumps on the back of your arms?",
		possible: ["no","yes"],
		group : 6, 
		priority : 11,
		choiceDescription: <span>Hyperkeratosis - i.e. chicken skin</span>
	},
	dryCrackedSkin : {
		key: "dryCrackedSkin",
		prompt : "Do you have dry/cracked skin?",
		possible: ["no","yes"],
		group : 6, 
		priority : 11,
		choiceDescription: <span>Anywhere on you body, more predominantly on the elbows, knees, or heels.</span>
	},
	verticalNailRidges : {
		key: "verticalNailRidges",
		prompt : "Do your nails have vertical (lengthwise) ridges?",
		possible: ["no","mild","moderate","severe"],
		group : 9, 
		priority : 17,
		images: [{src: verticalNailRidges1 }],
		choiceDescription: <span><b>mild:</b> 1-2 fingers/toes<br/>
								<b>moderate:</b> 3-4 fingers/toes<br/>
								<b>severe:</b> 5 or more fingers/toes</span>
	},
	horizontalNailRidges : {
		key: "horizontalNailRidges",
		prompt : "Do your nails have horizontal lines or ridges?",
		possible: ["no","mild","moderate","severe"],
		group : 9, 
		priority : 17,
		images: [{src: horizontalNailRidges1 }],
		choiceDescription: <span><b>mild:</b> 1-2 fingers/toes<br/>
								<b>moderate:</b> 3-4 fingers/toes<br/>
								<b>severe:</b> 5 or more fingers/toes</span>
	},
	nailPitting : {
		key: "nailPitting",
		prompt : "Do you have nail pitting? (Little depression in nails)",
		possible: ["no","yes"],
		group : 9, 
		priority : 17
	},
	nailFungus : {
		key: "nailFungus",
		prompt : "Do you have nail fungus on hands or feet?",
		possible: ["no","yes"],
		group : 9, 
		priority : 17
	},
}