import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isAboveOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isAboveOptimal(inputCalculated, CalculatedInput.types.proteinTotalRange.key) && inputUser[UserInput.types.frequentlyDehydrated.key] === "yes"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.frequentlyDehydrated.key);
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.proteinTotalRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.proteinTotal.key)){
		toReturn.push(UserInput.types.proteinTotal);
	} else if (isAboveOptimal(inputCalculated, CalculatedInput.types.proteinTotalRange.key) && isUnanswered(inputUser, UserInput.types.frequentlyDehydrated.key)){
		toReturn.push(UserInput.types.frequentlyDehydrated);
	}
	return toReturn;
};
export default {
	getEvaluation,
	getPrompts,
	title : "Drink More Water",
	category : categories.behaviors,
	getSortValue : getDefaultSortValue
};