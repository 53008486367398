import UserInput from '../../inputs/user/index.js';
import { isUnanswered, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';
import image1 from './bmassist1.jpg';
import doc1 from './bmassist1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 2-3 days"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bowelMovementFrequency.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 3-4 days"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bowelMovementFrequency.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 4-6 days"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bowelMovementFrequency.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

/* if digestive issues of any type on questionaire increase value by 250
BM frequency of 2-3 days increase by 200
3-4 days increase by 300
4-6 days increase by 400 */
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 2-3 days"){
		sortValue -= 200;
	}
	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 3-4 days"){
		sortValue -= 300;
	}
	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 4-6 days"){
		sortValue -= 400;
	}
	return sortValue;
};

/* Change name to BM Assist */

/* add in dosage information. 
if BM every 2-3 days = 1 capsule at bed
if BM every 3-4 days = 2 capsules at bed
if BM every 4-6 days = 2 capsules at bed for 1 week, if consistency does not improve add in 
Natural calm - 1 scoop at night for 2 weeks or until bowels are normal. */

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.bowelMovementFrequency.key)){
		toReturn.push(UserInput.types.bowelMovementFrequency);
	}
	return toReturn;
};
const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 2-3 days"){
		return {
			description : "1 serving before bed.",
			servingsPerDay : 1
		};
	}
	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 3-4 days"){
		return {
			description : "2 servings before bed.",
			servingsPerDay : 2
		};
	}
	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 4-6 days"){
		return {
			description : "2 servings before bed.",
			servingsPerDay : 2
		};
	}
	return getDefaultDosage();
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	BM Assist is designed to support gastrointestinal (GI) regularity and complement dietary 
	fiber intake. Magnesium citrate is present to support muscle relaxation and bowel elimination. 
	Cape Aloe is added to support normal GI transit time and stool bulk. Triphala, a balanced blend 
	of astringent fruits used extensively in Ayurveda, is present to support all phases of digestion, 
	assimilation, and elimination. Gastrointestinal regularity in turn plays a fundamental role in 
	detoxification, providing a major route for elimination of toxins.*

Clinical Applications:
- Supports Digestion, Assimilation, and Elimination*
- Promotes Gastrointestinal Motility and Stool Bulk*
- Supports Final Phases of Detoxification*`;
};
const title = "BM Assist";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00051", price: 15, servings: 60}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
};