import UserInput from '../../inputs/user/index.js';
import { isUnanswered, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './activatedcharcoal1.jpg';
//import doc1 from './activatedcharcoal1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(inputUser[UserInput.types.diarrheaSeverity.key] === "mild" || inputUser[UserInput.types.diarrheaSeverity.key] === "moderate" || inputUser[UserInput.types.diarrheaSeverity.key] === "severe"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.diarrheaSeverity.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.diarrheaSeverity.key)){
        toReturn.push(UserInput.types.diarrheaSeverity);
	}
    return toReturn;
};

/* Diarrhea symptoms
mild increase by 50
moderate increase by 150
Severe increase by 300 */

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(inputUser[UserInput.types.diarrheaSeverity.key] === "mild"){
		sortValue -= 50;
	}else if(inputUser[UserInput.types.diarrheaSeverity.key] === "moderate"){
		sortValue -= 150;
	}else if(inputUser[UserInput.types.diarrheaSeverity.key] === "severe"){
		sortValue -= 300;
	}
	return sortValue;
};

const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.diarrheaSeverity.key] === "severe"){
		return {
			description : "2 servings before bed.",
			servingsPerDay : 2
		};
	}
	return getDefaultDosage();
};

const getDescription = (inputUser, inputCalculated) => {
	return `
	Activated Charcoal is highly porous and can adsorb unwanted substances, carrying them out of the digestive system.*`;
};

const title = "Activated Charcoal";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "ACT-CHAR", price: 10, servings: 100}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	images: [{ src: image1 }],
	//docs: [{ src: doc1 }],
	getPrices,
	getDescription
	
};