import UserInput from '../../inputs/user/index.js';
import { isUnanswered, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(inputUser[UserInput.types.bloatingAfterMeals.key] === "after mostly sugar or carbohydrate meals"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bloatingAfterMeals.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.bloatingAfterMeals.key)){
		toReturn.push(UserInput.types.bloatingAfterMeals);
	}	
	return toReturn;
};
const getDosage = (inputUser, inputCalculated) => {
	return "";
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	sortValue -= 180;	
	return sortValue;
};
export default {
	getEvaluation,
	getPrompts,
	title : "SIBO Test",
	getDosage,
	category : categories.tests,
	getSortValue
	
};