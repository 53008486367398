import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isBelowOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isAboveOptimal, isBelowStandard, getDefaultDosage} from '../utilities';

import image1 from './magneuro31.png';
import image2 from './magneuro32.png';
import doc1 from './magneuro31.pdf';
import doc2 from './magneuro32.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	

	if(isBelowOptimal(inputCalculated, CalculatedInput.types.magnesiumRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.magnesiumRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}else if(!isUnanswered(inputUser, UserInput.types.magnesium.key) && !isAboveOptimal(inputCalculated, CalculatedInput.types.magnesiumRange.key)){
		if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every day but doesn't feel complete"){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.bowelMovementFrequency.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.magnesiumRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		} else  if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 2-3 days"){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.bowelMovementFrequency.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.magnesiumRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}else if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 3-4 days"){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.bowelMovementFrequency.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.magnesiumRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		} else if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 4-6 days"){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.bowelMovementFrequency.key);
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.magnesiumRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}

	}

	return toReturn;
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(isBelowStandard(inputCalculated, CalculatedInput.types.magnesiumRange.key)){
		sortValue -= 450;
	} else if(isBelowOptimal(inputCalculated, CalculatedInput.types.magnesiumRange.key)){
		sortValue -= 300;
	}/* if magnesium serum or RBC is below optimal range increase value 300
	// Craig Todo: Clarify RBC - is that rbcMale and rbcFemale?
	if magnesium below standard range increase value 450
	add in - if magnesium is above optimal range recommend stop taking magnesium and retest in 1 month
	// Craig Todo: what test exactly?  Do we need test results in order to recommend?
	if magnesium is above standard range recommend stop taking magnesium, retest in 1 month and follow up with promary care phycisian or 
	functional medicine dr*/



	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every day but doesn't feel complete"){
		sortValue -= 100;
	}/* if yes increase value 100 */
	else if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 2-3 days"){
		sortValue -= 150;
	}/* if yes increase value 150 */
	else if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 3-4 days"){
		sortValue -= 200;
	}/* if yes increase value 200 */
	else if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 4-6 days"){
		sortValue -= 300;
	}/* if yes increase value 300 */

	return sortValue;
};

/* Change name to Magneuro 3 */
const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.bowelMovementFrequency.key)){
		toReturn.push(UserInput.types.bowelMovementFrequency);
	}
	if(isUnanswered(inputUser, UserInput.types.magnesium.key)){
		toReturn.push(UserInput.types.magnesium);
	}
	return toReturn;
};
const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every day but doesn't feel complete"){
		return {
			description : "1 serving per day before bed.",
			servingsPerDay : 1
		};
	}
	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 2-3 days"){
		return {
			description : "1 serving per day before bed.",
			servingsPerDay : 1
		};
	}
	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 3-4 days"){
		return {
			description : "1 serving per day before bed.",
			servingsPerDay : 1
		};
	}
	if(inputUser[UserInput.types.bowelMovementFrequency.key] === "every 4-6 days"){
		return {
			description : "1 serving per day before bed.",
			servingsPerDay : 1
		};
	}
	return {
		description : "1 serving per day before bed.",
		servingsPerDay : 1
	};
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Mag Neuro-3 features Albion chelated magnesium plus MagteinTM (magnesium L-threonate), 
	the only form of magnesium proven in animal studies to cross the blood-brain barrier. 
	Boosting the brain’s magnesium level is vital to healthy cognition, which includes long- 
	and short-term memory, learning, stress management, and sleep.* 
	
	All Functional Formulas Formulas Meet or Exceed cGMP Quality Standards.`;
};
const title = "Magneuro 3";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "00019", price: 44},
		{title: title + " (Mixed Berry)", sku: "00019MB", price: 52, servings: 60}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 },{ src: image2 }],
	docs: [{ src: doc1 },{ src: doc2 }]
};