import React, { useState, useLayoutEffect } from 'react';
import queryString from 'query-string';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  //useHistory,
  // Link,
  // Redirect,
  // withRouter
} from "react-router-dom";
import LayoutConstants from './LayoutConstants.js';
import Recommender from './Recommender/Recommender.js';
import Home from './Home/Home.js';
import FAQ from './FAQ/FAQ.js';
import About from './About/About.js';
import Contact from './Contact/Contact.js';
import Cart from './Cart/index.js';
import { Container, Nav, Row, Col, Navbar, NavLink, NavbarBrand, NavItem, NavbarToggler, Collapse } from 'reactstrap';
import Tracking from './Privacy/Tracking.js';
import PrivacyPolicy from './Privacy/PrivacyPolicy.js';
import Store from './Store/Store.js';
import {ReactComponent as Logo} from './fmbell-Horizontal-ondark.svg';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { deserializeCartToJson, serializeJsonToCart } from './Cart/Cart.js'
import serializer from './Recommender/persister/serializer.js';


const App = (props) => {

  //usePageTracking();
  
  const [isNavbarHidden, setIsNavbarHidden] = useState(false)
  const [isNavbarTransparent, setIsNavbarTransparent] = useState(true);

  
  const queryStringParams = queryString.parse(window.location.search);
  const [debug] = useState(queryStringParams.debug !== undefined);

  const [cart, setCart] = useState({});
	if(queryStringParams.cart !== undefined){
    const newCart = serializeJsonToCart(serializer.deserialize(queryStringParams.cart));
    if(Object.keys(newCart).length !== Object.keys(cart).length){
		  setCart({ ...cart, ...newCart });
    }
	}

  if(queryStringParams.dc !== undefined){
    Cart.saveDC(queryStringParams.dc);
  }

  const [collapsed, setCollapsed] = useState(true);

  const cartCount = Cart.getCount(cart); 
  const cartLink =  cartCount > 0 ? 
      <NavItem className="m-2 mr-4">
        <Link to="/cart">Cart ({Object.keys(cart).length})</Link>
      </NavItem> : null;

  useScrollPosition(
    ({ prevPos, currPos}) => {
      // console.log(prevPos.y + "|" + currPos.y);
      if(currPos.y >= 0 && !isNavbarTransparent){
        // console.log("setIsNavbarTransparent=true");
        setIsNavbarTransparent(true);
      }else if(currPos.y < 0 && isNavbarTransparent){
        // console.log("setIsNavbarTransparent=false");
        setIsNavbarTransparent(false);
      }
      const isScrollingUp = currPos.y >= prevPos.y || currPos.y >= 0;

      // console.log(isScrollingUp + "|" + isNavbarHidden);
      if (isScrollingUp === isNavbarHidden) {
        if(!collapsed){
          setCollapsed(true);
        }
        setIsNavbarHidden(!isScrollingUp);
      }
    },
    [isNavbarHidden],
    null,
    false,
    200
    );
  
  const navigationBar = 
  <Navbar color="primary" dark expand="md" fixed="top" style={{ height: LayoutConstants.headerHeight + "px"}} className={ isNavbarTransparent ? "transparent" : "" } >
    <Container>
      <NavbarBrand href="/" className="ml-4 top-logo-navbar"><Logo height="4em" /></NavbarBrand>
      <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-4" />
      <Collapse isOpen={!collapsed} navbar className="justify-content-end">
        <Nav className="mr-4">
          <NavItem className="m-2 mr-4">
            <Link onClick={() => setCollapsed(true)} to="/" >Home</Link>
          </NavItem>
          <NavItem onClick={() => setCollapsed(true)} className="m-2 mr-4">
            <Link to="/calculator/questionaire" >Calculator</Link>
          </NavItem>
          <NavItem className="m-2 mr-4">
            <Link onClick={() => setCollapsed(true)} to="/store" >Store</Link>
          </NavItem>
          {cartLink}
        </Nav>
        </Collapse>
      </Container>
  </Navbar>;


  return (
    
      <Container style={{ backgroundColor:'#FFFFFF', paddingLeft: "0px", paddingRight: "0px" }}>
        {isNavbarHidden ? "" : navigationBar }
        <Switch>
          <Route exact={true} path="/">
            <Home/>
          </Route> 
          <Route exact={true} path="/store" debug={debug}>
            <div style={{ paddingTop: LayoutConstants.headerHeight + "px" }}>
              <Store
                cart={cart}
                debug={debug}
                getQtyAndPrice={(key,sku) => Cart.getQtyAndPrice(cart,key,sku)} 
                addToCart={(key,sku,qty,price,title) => setCart(Cart.addToCart(cart, key, sku, qty, price, title))} 
                clearCart={() => setCart({})}
              />
            </div>
          </Route>
          <Route exact={true} path="/privacypolicy" debug={debug}>
            <div style={{ paddingTop: LayoutConstants.headerHeight + "px" }}>
              <PrivacyPolicy
                debug={debug}
              />
            </div>
          </Route>
          <Route exact={true} path="/about" debug={debug}>
            <div style={{ paddingTop: LayoutConstants.headerHeight + "px" }}>
              <About
                debug={debug}
              />
            </div>
          </Route> 
          <Route exact={true} path="/contact" debug={debug}>
            <div style={{ paddingTop: LayoutConstants.headerHeight + "px" }}>
              <Contact
                debug={debug}
              />
            </div>
          </Route> 
          <Route exact={true} path="/faq" debug={debug}>
            <div style={{ paddingTop: LayoutConstants.headerHeight + "px" }}>
              <FAQ
                cart={cart}
                debug={debug}
                getQtyAndPrice={(key,sku) => Cart.getQtyAndPrice(cart,key,sku)} 
                addToCart={(key,sku,qty,price,title) => setCart(Cart.addToCart(cart, key, sku, qty, price, title))} 
                clearCart={() => setCart({})}
              />
            </div>
          </Route>  
          <Route path="/cart">
            <div style={{ paddingTop: LayoutConstants.headerHeight + "px" }}>
              <Container>
              <Cart.View
                redirectIfEmpty="store" 
                cart={cart}
                debug={debug}
                getQtyAndPrice={(key,sku) => Cart.getQtyAndPrice(cart,key,sku)} 
                addToCart={(key,sku,qty,price,title) => setCart(Cart.addToCart(cart, key, sku, qty, price, title))} 
                clearCart={() => setCart({})}
                setCart={(cart) => setCart(cart)}
              />
              </Container>
            </div>
          </Route>
          <Route path="/dccreator">
            <div style={{ paddingTop: LayoutConstants.headerHeight + "px" }}>
              <Container>
              <Cart.DCCreator debug={debug} code={queryStringParams.code} />
              </Container>
            </div>
          </Route>
          <Route path="/calculator/:tab?">
            <div style={{ paddingTop: LayoutConstants.headerHeight + "px" }}>
              <Recommender 
                cart={cart}
                debug={debug}
                getQtyAndPrice={(key,sku) => Cart.getQtyAndPrice(cart,key,sku)} 
                addToCart={(key,sku,qty,price,title) => setCart(Cart.addToCart(cart, key, sku, qty, price, title))} 
                clearCart={() => setCart({})} />
              </div>
          </Route>
          
        </Switch>
        <Tracking />
        <hr className="mt-5" />
        <footer className="pt-3 pb-5">
          <center>
              <NavLink href="/FAQ">FAQ</NavLink>
              <NavLink href="/About">About</NavLink>
              <NavLink href="/Contact">Contact</NavLink>
              <NavLink href="/PrivacyPolicy">Privacy</NavLink>
          </center>
        </footer>
      </Container>
  );
}

export default () => {
  return <Router keyLength={10000} >
    <App />
  </Router>
};
