import React, { useState } from 'react';
import { InputGroup, InputGroupAddon, Table, Button, Form, FormGroup, Input } from 'reactstrap';
import localStorage from './localStorage.js';
import { getDateString } from './utilities.js';
import './Browser.css';

const Browser = (props) => {
	const [persisterName, setPersisterName] = useState(getDateString());
	
	const localStorageKeys = [...localStorage.getKeys("inputUser")].sort(function (a, b) {
		return a.toLowerCase().localeCompare(b.toLowerCase());
	  });

	const saveToLocalStorage = () => {
		localStorage.saveToLocalStorage( "inputUser", persisterName, props.inputUser);
		props.onStorageUpdated(persisterName);
	}
	const loadFromLocalStorage = (keyToLoad) => {
		const loadedInputUser = localStorage.loadFromLocalStorage("inputUser", keyToLoad);
		if(loadedInputUser){
			props.updateInputUser(loadedInputUser);
		}
	}
	const deleteFromLocalStorage = (keyToDelete) => {
		localStorage.deleteItemFromLocalStorage("inputUser", keyToDelete);
		setPersisterName(persisterName);
		props.onStorageUpdated(persisterName);
	}
	
		
	return (
			<div>	
				{ props.inputUser !== undefined ?
				<Form className="mb-3">
					<h4>Name and save this checkpoint in your browser:</h4>
				<FormGroup>
					<InputGroup>
						<Input value={persisterName } onChange={(event) => setPersisterName(event.target.value)} />
						<InputGroupAddon addonType="append">
							<Button color="primary" onClick={() => saveToLocalStorage()}>Save progress in browser</Button>
						</InputGroupAddon>
					</InputGroup>
				</FormGroup>
				</Form> : null}
				<Form>
					<h4>Select a progress checkpoint to continue where you left off:</h4>
					<Table striped style={{ width: "auto"}}>
						<tbody>
						{ localStorageKeys.map((inputUserKey) => 
							<tr key={inputUserKey}>
								<td>
									<Button color="link" onClick={() => loadFromLocalStorage(inputUserKey)}>{inputUserKey}</Button>
								</td>
								<td>
									<Button color="primary" className="rawText" onClick={() => loadFromLocalStorage(inputUserKey)}>Load</Button>
									<Button color="secondary" onClick={() => deleteFromLocalStorage(inputUserKey)}>Delete</Button>
								</td>
							</tr>)}
						</tbody>
					</Table>
				</Form>
			</div>
	);
};
export default Browser;