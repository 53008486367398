import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, isNo, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './doparefresh1.png';
//import doc1 from './doparefresh1.pdf';

const getEvaluationWithoutContrainsdications = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.lackOfJoy.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.lackOfJoy.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
}
const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getEvaluationWithoutContrainsdications(inputUser, inputCalculated);
	if(toReturn.recommended
		&& isNo(inputUser, UserInput.types.takeBoronicAcidBasedProteaseInhibitorMedications.key)
		&& isNo(inputUser, UserInput.types.takeSunitinibMedications.key)){
		toReturn.inputUserKeys.push(UserInput.types.takeBoronicAcidBasedProteaseInhibitorMedications.key);
		toReturn.inputUserKeys.push(UserInput.types.takeSunitinibMedications.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}else{
		toReturn = getDefaultEvaluation();
	}
	return toReturn;
};
/* if lack of joy
mild - increase value 100
moderate - increase 150
severe - increae 200 */
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(isYes(inputUser, UserInput.types.lackOfJoy.key)){
		sortValue -= 150;
		// Craig Todo: Convert lack of joy to severity
	}
	return sortValue;
};
const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.lackOfJoy.key)){
        toReturn.push(UserInput.types.lackOfJoy);
	}

	if(toReturn.length === 0 && getEvaluationWithoutContrainsdications(inputUser, inputCalculated).recommended){
		if(isUnanswered(inputUser,UserInput.types.takeBoronicAcidBasedProteaseInhibitorMedications.key)){
			toReturn.push(UserInput.types.takeBoronicAcidBasedProteaseInhibitorMedications);
		}
		if(isUnanswered(inputUser,UserInput.types.takeSunitinibMedications.key)){
			toReturn.push(UserInput.types.takeSunitinibMedications);
		}
	}

    return toReturn;
};

const getDosage = getDefaultDosage;

const getDescription = (inputUser, inputCalculated) => {
	return `
	Dopa Refresh is designed to support the body’s natural production of dopamine, one of the body’s primary catecholamines. 
	Dopamine is essential for the normal functioning of the central nervous system and is associated with attention, learning, 
	movement, balance, and mood regulation.* The primary ingredient in Dopa Refresh is Mucuna pruriens, a botanical which 
	naturally contains L-Dopa, the metabolic precursor to dopamine which can cross the blood-brain barrier. Additional 
	ingredients include EGCg (epigallocatechin gallate), acetyl-L-tyrosine, quercetin and vitamin B6, all selected for their 
	synergistic roles in the production and regulation of dopamine.*`;
};
const title = "Dopa Refresh";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "DOPA-REFRESH", price: 29, servings: 60}]
};

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	images: [{ src: image1 }],
	//docs: [{ src: doc1 }]	,
	getDescription,
	getPrices
};