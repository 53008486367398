import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isAboveStandard, isOutsideOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();
    
    if(isAboveStandard(inputCalculated, CalculatedInput.types.neutrophilsRange.key)){
        toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.neutrophilsRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
    if(isAboveStandard(inputCalculated, CalculatedInput.types.lymphocytesRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.lymphocytesRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }
    if(isOutsideOptimal(inputCalculated, CalculatedInput.types.monocytesRange.key)){
        toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.monocytesRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
    }

	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.neutrophils.key)){
        toReturn.push(UserInput.types.neutrophils);
	}
	if(isUnanswered(inputUser,UserInput.types.lymphocytes.key)){
        toReturn.push(UserInput.types.lymphocytes);
    }
    if(isUnanswered(inputUser,UserInput.types.monocytes.key)){
        toReturn.push(UserInput.types.monocytes);
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(isAboveStandard(inputCalculated, CalculatedInput.types.neutrophilsRange.key) || isAboveStandard(inputCalculated, CalculatedInput.types.lymphocytesRange.key)){
		return "Consult with doctor";
    }
    if(isOutsideOptimal(inputCalculated, CalculatedInput.types.monocytesRange.key)){
        return "Possible chronic bacterial/viral infection, consult with doctor."
    }
	return "";
};

export default {
	getEvaluation,
	getPrompts,
	title : "Infection Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};