import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import thyroid from '../helpers/thyroid.js';
import { isUnanswered, isAboveOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();
    
    if(thyroid.isHashimotos(inputUser, inputCalculated)){
        if(isAboveOptimal(inputCalculated,CalculatedInput.types.thyroidPeroxidaseTPOAbsLABCORPRange.key)){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.thyroidPeroxidaseTPOAbsLABCORPRange.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
        }else if(isAboveOptimal(inputCalculated,CalculatedInput.types.thyroglobulinAbsRange.key)){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.thyroglobulinAbsRange.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
        }
    }

	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    toReturn.push(...thyroid.getHashimotosPrompts(inputUser, inputCalculated));
    if(thyroid.isHashimotos(inputUser, inputCalculated)){
        if(isUnanswered(inputUser,UserInput.types.thyroidPeroxidaseTPOAbsLABCORP.key)){
            toReturn.push(UserInput.types.thyroidPeroxidaseTPOAbsLABCORP);
        }
        if(isUnanswered(inputUser,UserInput.types.thyroglobulinAbs.key)){
            toReturn.push(UserInput.types.thyroglobulinAbs);
        }
    }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};
const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	sortValue -= 170;	
	return sortValue;
};
export default {
	getEvaluation,
	getPrompts,
	title : "Food Sensitivity Test",
	getDosage,
	category : categories.tests,
	getSortValue
	
};