import UserInput from '../../inputs/user/index.js';
//import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.nailPitting.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nailPitting.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    if(isUnanswered(inputUser, UserInput.types.nailPitting.key)){ toReturn.push(UserInput.types.nailPitting); }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "Autoimmune Panel Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};