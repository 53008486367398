import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isNo, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isNo(inputUser, UserInput.types.allergies.key) && isYes(inputUser, UserInput.types.pluggedEars.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.allergies.key);
		toReturn.inputUserKeys.push(UserInput.types.pluggedEars.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.allergies.key)){
		toReturn.push(UserInput.types.allergies);
	}else if(isNo(inputUser, UserInput.types.allergies.key) && isUnanswered(inputUser, UserInput.types.pluggedEars.key)){
		toReturn.push(UserInput.types.pluggedEars);
	}
	return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "Talk To An Ear, Nose, Throat (ENT) Doctor",
	getDosage,
	category : categories.behaviors,
	getSortValue : getDefaultSortValue
};