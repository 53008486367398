import Browser from './Browser.js';

export const serialize = (jsonObject) => {
	return JSON.stringify(jsonObject)
		.replace(/=/g,"~~")
		.replace(/":"/g,"~-")
		.replace(/","/g,"~_")
		.replace(/"/g,"~DQT")
		.replace(/{/g,"~OPAR")
		.replace(/}/g,"~CPAR")
		.replace(/&/g,"~AMP");
};
export const deserialize = (jsonString) => {
	return JSON.parse(jsonString ? 
			jsonString
			.replace(/~AMP/g,"&")
			.replace(/~CPAR/g,"}")
			.replace(/~OPAR/g,"{")
			.replace(/~DQT/g,"\"")
			.replace(/~_/g,"\",\"")
			.replace(/~-/g,"\":\"")
			.replace(/~~/g,"=") 
			: null);
};
export default {
	serialize,
	deserialize,
	Browser
};