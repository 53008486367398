import UserInput from '../../inputs/user/index.js';
import { isUnanswered, isYes, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';

// import image1 from './allercalm1.jpg';
// import doc1 from './allercalm1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();
	if(isYes(inputUser, UserInput.types.bleedingGums.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bleedingGums.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.bleedingGums.key)){
		toReturn.push(UserInput.types.bleedingGums);
	}
	return toReturn;
};


const getDosage = (inputUser, inputCalculated) => {
	return "";
};

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	return sortValue;
};

const getDescription = (inputUser, inputCalculated) => {
	return ``;
};
const title = "Dental Check-up";
// const getPrices = (inputUser, inputCalculated) => {
// 	return [{title, sku: "00048", price: 33}]
// };

export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.behaviors,
	getSortValue,
	getDescription,
	// getPrices,
	// images: [{ src: image1 }],
	// docs: [{ src: doc1 }]
};