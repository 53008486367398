import React, { useState } from 'react';
import { Card, CardBody, CardText, CardSubtitle, UncontrolledCarousel,Row, Col, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import {Qty} from '../../Cart/View.js';
import Disclaimer from './Disclaimer.js';
import {ReactComponent as ButtonCaratExpand} from '../../Components/button-carat-expand.svg';
import {ReactComponent as ButtonCaratCollapse} from '../../Components/button-carat-collapse.svg';
import { getPercentage } from '../../Cart/disc.js';
import './View.css';
import Container from 'reactstrap/lib/Container';

const View = (props) => {
	const [minimized, setMinimized] = useState(props.minimized);
	return (
		 <ListGroupItem id={props.title} 
		 	//style={{ border: "0px none #00000000" }}  
		 	//style={ props.priority !== undefined && props.priority < -10 ? { borderLeft: "5px solid #93C54B" } : props.priority > 499 ? { borderLeft: "5px solid #C0C0C0" } : {}}
			 >
		    <ListGroupItemHeading className="text-info border-info" tag="h3" style={{ cursor: "pointer", marginTop: ".5em"}} alt={ minimized ?  "Expand details for " + props.title : "Collapse details for " + props.title } onClick={() => setMinimized(!minimized)}>{props.title}{props.priority !== undefined && props.debug ? " (" + (500 - props.priority) + ")" : ""}
				{ minimized ? <ButtonCaratExpand style={{float:"right"}} width="24px" /> : <ButtonCaratCollapse style={{float:"right"}} width="24px" />}
			</ListGroupItemHeading>
			{ minimized ? 
			<Prices title={props.title} prices={props.prices} cart={props.cart} getQtyAndPrice={props.getQtyAndPrice} addToCart={props.addToCart}/>
			: 
			<Row>
			<Col xs="12" md="9">
				<Card style={{borderColor: '#FFFFFF'}}>
					<CardBody>
						<Reasons reasons={props.reasons} />
						<CardText>{props.description}</CardText>
						{/* <CardText>{props.text}</CardText> */}
						{props.description !== undefined && props.description.length > 0 ? <CardText><Disclaimer></Disclaimer></CardText> : null}
						<CardSubtitle info className="mb-3"><strong>{props.subtitle}</strong></CardSubtitle>
						<Prices title={props.title} prices={props.prices} cart={props.cart} getQtyAndPrice={props.getQtyAndPrice} addToCart={props.addToCart} servingsPerDay={props.servingsPerDay} />
						{ (props.docs !== undefined && props.docs.length > 0) ? <CardText><a href={props.docs[0].src}>Download Information</a></CardText> : null }
						<Related related={props.related} />
					</CardBody>
				</Card>
			</Col>
			<Col xs={{ size: 6, offset: 3}}  md={{ size: 3, offset: 0}}>
				<ImageViewer images={props.images} />
			</Col>
			</Row>
			}
		</ListGroupItem>
	);
};

export const Prices = (props) => {
	if(props.prices !== undefined && props.prices.length > 0){
		return (
			<span>{props.prices.map((price, i) => <Price price={price} key={i} qtyAndPrice={props.getQtyAndPrice(props.title, price.sku)} setQty={(qty) => props.addToCart(props.title, price.sku, qty, price.price, price.title, price.servings, props.servingsPerDay)} discount={getPercentage(price.sku)} servings={price.servings} servingsPerDay={props.servingsPerDay} />)}</span>
		);
	}else{
		return null;
	}
};
export const Price = (props) => {
	let price = props.price.price;
	let oldPrice = 0;
	if(props.discount > 0){
		oldPrice = price;
		price = props.price.price*(100-props.discount)/100.0
	}
	let perDayCost = 0;
	if(props.servingsPerDay > 0 && props.servings > 0){
		perDayCost = (price * props.servingsPerDay / props.servings).toFixed(2);
	}
	return( 
			<Row className="mb-2">
				<Col xs="12">
					{props.price.title} <small>{ props.price.sku !== undefined ? "(" + props.price.sku + ")" : null}</small>
				</Col>
				<Col className="text-right">
					<table align="right">
						<tr>
							<td>
								<span>
									{oldPrice > 0 ? <small><s>{oldPrice.toFixed(2)}&nbsp;</s></small> : ""}
									<strong>${(price).toFixed(2)}</strong>
									{perDayCost > 0 ? <small><br/>${perDayCost} per day</small> : ""}
								</span>
							</td>
							<td>
								{props.qtyAndPrice !== undefined ? <Qty qty={props.qtyAndPrice.qty} setQty={props.setQty} /> : null }
							</td>
						</tr>
					</table>
				</Col>
			</Row>
	);
};

export const ImageViewer = (props) => {
	if(props.images !== undefined && props.images.length > 0){
		// return (<Img width="100px" src={props.images[0].src} />);
		const images = props.images.map((img,key) => {
			return {
				key : img.key,
				src : img.src,
				caption: "", 
				altText : img.altText || img.title || props.title};});
		return (<UncontrolledCarousel 
			items={images}
			autoplay={false}
			className="m-3" />);
	}else{
		return null;
	}
};
const Reasons = (props) => {
	if(props.reasons !== undefined && props.reasons.length > 0){
		return(
			<span>
				<h4>Reasons for Recommendation:</h4>
				<ul style={{maxHeight:'10em',overflowY:'auto',marginBottom:'1em'}}>
					{props.reasons.map((reason, i) => <li key={i}>{reason.prompt} <strong>{reason.answer}</strong></li>)}
				</ul>
			</span>
		);
	}else{
		return null;
	}
};
const Related = (props) => {

	const getRelationText = (relation) => {
		switch (relation) {
			case "EQUAL":
				return "(same reasons)";
			case "MORE":
				return "(additional reasons)";
			case "LESS":
				return "(less reasons)";
			case "SOME":
				return "(similar reasons)"
			default:
				console.warn("Shouldn't be other relations");
				return "(other)";
		}	
	};

	if(props.related !== undefined && props.related.length > 0){
		return(
			<CardText key="related"><strong>Related Recommendations:&nbsp;</strong>
				{props.related.map((rel,i) => <a href={"#" + rel.output.title} key={i} className="m-2" alt={rel.output.title + " (" + rel.relation + ")"}>{rel.output.title + " " + getRelationText(rel.relation)}</a> )}
			</CardText>
		);
	}else{
		return null;
	}
};

export default View;