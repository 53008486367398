import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker, { unregister } from './registerServiceWorker';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import './sandstonebootstrap.min.css';
import './flatlybootstrap.min.css';
import './index.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
//unregister();
