import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import thyroid from '../helpers/thyroid.js';
import { isUnanswered, isAboveOptimal, isNo, isUnknown, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();
    
    if(thyroid.isHashimotos(inputUser, inputCalculated)){
        if(isAboveOptimal(inputCalculated,CalculatedInput.types.thyroidPeroxidaseTPOAbsLABCORPRange.key)){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.thyroidPeroxidaseTPOAbsLABCORPRange.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
        }else if(isAboveOptimal(inputCalculated,CalculatedInput.types.thyroglobulinAbsRange.key)){
            toReturn.recommended = true;
            toReturn.inputCalculatedKeys.push(CalculatedInput.types.thyroglobulinAbsRange.key);
            toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
        }
    }
    if(isAboveOptimal(inputCalculated, CalculatedInput.types.eosinophilsRange.key) 
    && (isNo(inputUser, UserInput.types.allergies.key) || isUnknown(inputUser, UserInput.types.allergies.key))){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.allergies.key);
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.eosinophilsRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

    if(inputUser[UserInput.types.dryItchyRedEyes.key] === "mild" || inputUser[UserInput.types.dryItchyRedEyes.key] === "moderate" 
	//|| inputUser[UserInput.types.dryItchyRedEyes.key] === "severe"
	){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.dryItchyRedEyes.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    toReturn.push(...thyroid.getHashimotosPrompts(inputUser, inputCalculated));
    if(thyroid.isHashimotos(inputUser, inputCalculated)){
        if(isUnanswered(inputUser,UserInput.types.thyroidPeroxidaseTPOAbsLABCORP.key)){
            toReturn.push(UserInput.types.thyroidPeroxidaseTPOAbsLABCORP);
        }
        if(isUnanswered(inputUser,UserInput.types.thyroglobulinAbs.key)){
            toReturn.push(UserInput.types.thyroglobulinAbs);
        }
    }
    if(isUnanswered(inputUser,UserInput.types.eosinophils.key)){
        toReturn.push(UserInput.types.eosinophils);
	} else  if(isAboveOptimal(inputCalculated, CalculatedInput.types.eosinophilsRange.key)){
		if(isUnanswered(inputUser,UserInput.types.allergies.key)){
			toReturn.push(UserInput.types.allergies);
		}
    }
    if(isUnanswered(inputUser, UserInput.types.dryItchyRedEyes.key)){
		toReturn.push(UserInput.types.dryItchyRedEyes);
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "Allergy Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};