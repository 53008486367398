import UserInput from '../../inputs/user/index.js';
import { isUnanswered, getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './llysine1.jpg';
import doc1 from './llysine1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(inputUser[UserInput.types.coldSoreFrequency.key] === "every couple of months"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.coldSoreFrequency.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	} else if(inputUser[UserInput.types.coldSoreFrequency.key] === "about once a month"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.coldSoreFrequency.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	} else if(inputUser[UserInput.types.coldSoreFrequency.key] === "have a current outbreak"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.coldSoreFrequency.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	} else if(inputUser[UserInput.types.coldSoreFrequency.key] === "more than once a month"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.coldSoreFrequency.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	return toReturn;
};

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();

	if(inputUser[UserInput.types.coldSoreFrequency.key] === "every couple of months"){
		sortValue -= 50;
	}/* if every couple of months increase value 50 */
	else if(inputUser[UserInput.types.coldSoreFrequency.key] === "about once a month"){
		sortValue -= 300;
	}/* if once per month increase value 300 */
	else if(inputUser[UserInput.types.coldSoreFrequency.key] === "have a current outbreak"){
		sortValue -= 400;
	}/* If have a current outbreak increase value 400 */
	else if(inputUser[UserInput.types.coldSoreFrequency.key] === "more than once a month"){
		sortValue -= 450;
	}/* if yes to more than once per month increase value 450 */

	return sortValue;
};


const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
	if(isUnanswered(inputUser,UserInput.types.coldSoreFrequency.key)){
        toReturn.push(UserInput.types.coldSoreFrequency);
	}
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.coldSoreFrequency.key] === "every couple of months"){
		return {
			description : "1 serving per day.",
			servingsPerDay : 1
		};
	}
	if(inputUser[UserInput.types.coldSoreFrequency.key] === "about once a month"){
		return {
			description : "2 servings per day.",
			servingsPerDay : 1
		};
	}
	if(inputUser[UserInput.types.coldSoreFrequency.key] === "have a current outbreak"){
		return {
			description : "3 servings per day.",
			servingsPerDay : 1
		};
	}
	return getDefaultDosage();
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	L-Lysine is an amino acid essential in human physiology. Lysine is vital for normal growth and for the manufacture of 
	collagen, a substance important for healthy skin, bones, tendons, and cartilage. Supplemental lysine may be appropriate for 
	individuals on a high-arginine diet.*`;
};
const title = "L-Lysine";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "L-Lysine", price: 14, servings: 90}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]
	
};