import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isUnanswered, isBelowOptimal, isYes , getDefaultSortValue, getDefaultEvaluation, getReasons, categories, isBelowStandard, getDefaultDosage} from '../utilities';

import image1 from './digestright1.png';
import image2 from './digestright2.png';
import doc1 from './digestright1.pdf';
import doc2 from './digestright2.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(isBelowOptimal(inputCalculated, CalculatedInput.types.proteinTotalRange.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.proteinTotalRange.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if protein below optimal range increase value by 75
	if protein below standard range increase value by 150 */
	if(isYes(inputUser, UserInput.types.nausea.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.nausea.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/* if nausea increase value by 25 */
	if(isYes(inputUser, UserInput.types.vomiting.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.vomiting.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}/*  if vomitting increase value by 200*/

	if(isYes(inputUser, UserInput.types.upsetStomachWithMeals.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.upsetStomachWithMeals.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(inputUser[UserInput.types.bloatingAfterMeals.key] === "after all meals"){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.bloatingAfterMeals.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}
	if(isYes(inputUser, UserInput.types.burpingFartingGas.key)){
		toReturn.recommended = true;
		toReturn.inputUserKeys.push(UserInput.types.burpingFartingGas.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
	}

	return toReturn;
};

const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	/* if protein below optimal range increase value by 75
	if protein below standard range increase value by 150 */
	if(isBelowStandard(inputCalculated, CalculatedInput.types.proteinTotalRange.key)){
		sortValue -= 150;
	} else if(isBelowOptimal(inputCalculated, CalculatedInput.types.proteinTotalRange.key)){
		sortValue -= 75;
	}

	if(isYes(inputUser, UserInput.types.nausea.key)){
		sortValue -= 25;
	}/* if nausea increase value by 25 */
	if(isYes(inputUser, UserInput.types.vomiting.key)){
		sortValue -= 200;
	}/*  if vomitting increase value by 200*/
	return sortValue;
};

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.proteinTotal.key)){
		toReturn.push(UserInput.types.proteinTotal);
	}
	if(isUnanswered(inputUser, UserInput.types.nausea.key)){
		toReturn.push(UserInput.types.nausea);
	}
	if(isUnanswered(inputUser, UserInput.types.vomiting.key)){
		toReturn.push(UserInput.types.vomiting);
	}
	if(isUnanswered(inputUser, UserInput.types.upsetStomachWithMeals.key)){
		toReturn.push(UserInput.types.upsetStomachWithMeals);
	}
	if(isUnanswered(inputUser, UserInput.types.bloatingAfterMeals.key)){
		toReturn.push(UserInput.types.bloatingAfterMeals);
	}
	if(isUnanswered(inputUser, UserInput.types.burpingFartingGas.key)){
		toReturn.push(UserInput.types.burpingFartingGas);
	}	
	return toReturn;
};
const getDosage = (inputUser, inputCalculated) => {
	if(isYes(inputUser, UserInput.types.nausea.key)){
		return {
			description : "1 serving with meals.",
			servingsPerDay : 3
		};
	}
	if(isYes(inputUser, UserInput.types.vomiting.key)){
		return {
			description : "1 serving with meals.",
			servingsPerDay : 3
		};
	}
	if(isYes(inputUser, UserInput.types.nausea.key)){
		return {
			description : "1 serving with meals.",
			servingsPerDay : 3
		};
	}
	if(inputUser[UserInput.types.bloatingAfterMeals.key] === "after all meals"){
		return {
			description : "1 serving with meals.",
			servingsPerDay : 3
		};
	}
	if(isYes(inputUser, UserInput.types.burpingFartingGas.key)){
		return {
			description : "1 serving with meals.",
			servingsPerDay : 3
		};
	}
	return {
		description : "1 serving with meals.",
		servingsPerDay : 3
	};	
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	Digest Right is a blend of digestive enzymes derived from the fermentation action of fungi 
	such as Aspergillus niger and Rhizopus niveus, microorganisms safely used in fermenting foods, 
	including cheese, soy sauce, and yogurt. These non-animal derived enzymes support the assimilation 
	of nutrients in foods and the digestion of proteins, carbohydrates, fats, and vegetable matter. 
	Clinical trials suggest that Digest Right is effective across a broad spectrum of pH ranges.*`;
};
const title = "Digest Right";
const getPrices = (inputUser, inputCalculated) => {
	return [{title: title + " (180c)", sku: "00010", price: 46},
	{title: title + " (90c)", sku: "00009", price: 28, servings: 180}]
};
export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }, { src: image2 }],
	docs: [{ src: doc1 }, { src: doc2 }]
};