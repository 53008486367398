import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import { isYes, isUnanswered, isAboveStandard, isAboveOptimal , getDefaultSortValue, getDefaultEvaluation, getReasons, categories, getDefaultDosage} from '../utilities';

import image1 from './dim3supreme1.jpg';
import doc1 from './dim3supreme1.pdf';

const getEvaluation = (inputUser, inputCalculated) => {
	let toReturn = getDefaultEvaluation();

	if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
		if(isYes(inputUser, UserInput.types.maleBreasts.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.maleBreasts.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/* if male breast increase value by 400 */
		if(isYes(inputUser, UserInput.types.benignProstateHypertrophy.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.benignProstateHypertrophy.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/*  if begign prostate hypertorphy increase value by 200*/
		if(isYes(inputUser, UserInput.types.colorectalCancerHistory.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.colorectalCancerHistory.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/* if hx of colon cancer increaes value by 350 */
		if(isAboveOptimal(inputCalculated,CalculatedInput.types.estradiolMaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.estradiolMaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/* if male and estradiol or estrogen above optimal range increase value by 200
		if above standard increase value by 400 */
		/*for males
		if above optimal increase value by 150
		if above standard increase value by 250*/
		if(isAboveOptimal(inputCalculated,CalculatedInput.types.progesteroneMaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.progesteroneMaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
		if(isAboveStandard(inputCalculated,CalculatedInput.types.sexHormoneBindingGlobulinMaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.sexHormoneBindingGlobulinMaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/* for male and female if sexhormone binding above optimal range increase value by 75
		is above standard increase b y 150 */
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		toReturn.inputUserKeys.push(UserInput.types.maleOrFemale.key);
		if(isYes(inputUser, UserInput.types.pmsSymptoms.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.pmsSymptoms.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/* if pms symptoms increase value by 150 */
		if(isYes(inputUser, UserInput.types.breastCancerHistory.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.breastCancerHistory.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/* if breast cancer history increase value by 250 */
		if(isYes(inputUser, UserInput.types.uterineCancerHistory.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.uterineCancerHistory.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/* If uterine cancer history increase value by 250 */
		if(isYes(inputUser, UserInput.types.colorectalCancerHistory.key)){
			toReturn.recommended = true;
			toReturn.inputUserKeys.push(UserInput.types.colorectalCancerHistory.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/* if colorectal history increase value by 250 */
		if(isAboveOptimal(inputCalculated,CalculatedInput.types.progesteroneFemaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.progesteroneFemaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}/*  progesterone for females
		if above optimal increase value by 50
		if above standard increase by 100*/
		if(isAboveStandard(inputCalculated,CalculatedInput.types.sexHormoneBindingGlobulinFemaleRange.key)){
			toReturn.recommended = true;
			toReturn.inputCalculatedKeys.push(CalculatedInput.types.sexHormoneBindingGlobulinFemaleRange.key);
			toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated);
		}
	}

	
	
	
	
	

	return toReturn;
};


const getSortValue = (inputUser, inputCalculated) => {
	let sortValue = getDefaultSortValue();
	if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isYes(inputUser, UserInput.types.maleBreasts.key)){
			sortValue -= 400;
		}/* if male breast increase value by 400 */
		if(isYes(inputUser, UserInput.types.benignProstateHypertrophy.key)){
			sortValue -= 200;
		}/*  if begign prostate hypertorphy increase value by 200*/
		if(isYes(inputUser, UserInput.types.colorectalCancerHistory.key)){
			sortValue -= 350;
		}/* if hx of colon cancer increaes value by 350 */
		if(isAboveStandard(inputCalculated,CalculatedInput.types.estradiolMaleRange.key)){
			sortValue -= 200;
		}else if(isAboveOptimal(inputCalculated,CalculatedInput.types.estradiolMaleRange.key)){
			sortValue -= 200;
		}/* if male and estradiol or estrogen above optimal range increase value by 200
		if above standard increase value by 400 */
		// Craig Todo: We don't have estrogen
		/*
		for males
		if above optimal increase value by 150
		if above standard increase value by 250*/
		if(isAboveStandard(inputCalculated,CalculatedInput.types.progesteroneMaleRange.key)){
			sortValue -= 250;
		} else if(isAboveOptimal(inputCalculated,CalculatedInput.types.progesteroneMaleRange.key)){
			sortValue -= 150;
		}
		if(isAboveStandard(inputCalculated,CalculatedInput.types.sexHormoneBindingGlobulinMaleRange.key)){
			sortValue -= 150;
		} else if(isAboveOptimal(inputCalculated,CalculatedInput.types.sexHormoneBindingGlobulinMaleRange.key)){
			sortValue -= 75;
		}/* for male and female if sexhormone binding above optimal range increase value by 75
		is above standard increase b y 150 */
	} else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isYes(inputUser, UserInput.types.pmsSymptoms.key)){
			sortValue -= 150;
		}/* if pms symptoms increase value by 150 */
		if(isYes(inputUser, UserInput.types.breastCancerHistory.key)){
			sortValue -= 250;
		}/* if breast cancer history increase value by 250 */
		if(isYes(inputUser, UserInput.types.uterineCancerHistory.key)){
			sortValue -= 250;
		}/* If uterine cancer history increase value by 250 */
		if(isYes(inputUser, UserInput.types.colorectalCancerHistory.key)){
			sortValue -= 250;
		}/* if colorectal history increase value by 250 */
		if(isAboveStandard(inputCalculated,CalculatedInput.types.progesteroneFemaleRange.key)){
			sortValue -= 100;
		} else if(isAboveOptimal(inputCalculated,CalculatedInput.types.progesteroneFemaleRange.key)){
			sortValue -= 50;
		}/*  progesterone for females
		if above optimal increase value by 50
		if above standard increase by 100*/
		if(isAboveStandard(inputCalculated,CalculatedInput.types.sexHormoneBindingGlobulinFemaleRange.key)){
			sortValue -= 150;
		} else if(isAboveOptimal(inputCalculated,CalculatedInput.types.sexHormoneBindingGlobulinFemaleRange.key)){
			sortValue -= 75;
		}/* for male and female if sexhormone binding above optimal range increase value by 75
		is above standard increase b y 150 */
	}

	if(isYes(inputUser,UserInput.types.breastSwellingTendernessDischarge.key)){
		sortValue -= 300;	
	}
	return sortValue;
};

/* Change name to DIM 3 supreme. 
add in the following for symptoms. If male and has symptoms of - Benign prostatic hypertrophy (BPH) or colorectal cancer 
= 1 capsule 2 times per day. If Male and Lab value for estrogen above optimal range = 1 capsule 2 times per day
If female and has symptoms of PMS or breast pain related to cycle or history of breast, uterine or colorectal cancer = 
1 capsule 2 times per day

DIM and dimension3 are the same thing. They are now DIM 3 supreme*/

const getPrompts = (inputUser, inputCalculated) => {
	let toReturn = [];
	if(isUnanswered(inputUser, UserInput.types.maleOrFemale.key)){
        toReturn.push(UserInput.types.maleOrFemale);
    }else if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isUnanswered(inputUser, UserInput.types.maleBreasts.key)){ toReturn.push(UserInput.types.maleBreasts); }
		if(isUnanswered(inputUser, UserInput.types.benignProstateHypertrophy.key)){ toReturn.push(UserInput.types.benignProstateHypertrophy); }
		if(isUnanswered(inputUser, UserInput.types.colorectalCancerHistory.key)){ toReturn.push(UserInput.types.colorectalCancerHistory); }
        if(isUnanswered(inputUser, UserInput.types.sexHormoneBindingGlobulinMale.key)){ toReturn.push(UserInput.types.sexHormoneBindingGlobulinMale); }
		if(isUnanswered(inputUser, UserInput.types.progesteroneMale.key)){ toReturn.push(UserInput.types.progesteroneMale); }
		if(isUnanswered(inputUser, UserInput.types.estradiolMale.key)){ toReturn.push(UserInput.types.estradiolMale); }
    }else if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
        if(isUnanswered(inputUser, UserInput.types.sexHormoneBindingGlobulinFemale.key)){ toReturn.push(UserInput.types.sexHormoneBindingGlobulinFemale); }
		if(isUnanswered(inputUser, UserInput.types.progesteroneFemale.key)){ toReturn.push(UserInput.types.progesteroneFemale); }
		if(isUnanswered(inputUser, UserInput.types.pmsSymptoms.key)){ toReturn.push(UserInput.types.pmsSymptoms); }
		if(isUnanswered(inputUser, UserInput.types.breastCancerHistory.key)){ toReturn.push(UserInput.types.breastCancerHistory); }
		if(isUnanswered(inputUser, UserInput.types.uterineCancerHistory.key)){ toReturn.push(UserInput.types.uterineCancerHistory); }
		if(isUnanswered(inputUser, UserInput.types.colorectalCancerHistory.key)){ toReturn.push(UserInput.types.colorectalCancerHistory); }
	}
	if(isUnanswered(inputUser, UserInput.types.breastSwellingTendernessDischarge.key)){
		toReturn.push(UserInput.types.breastSwellingTendernessDischarge);
	}
	
	return toReturn;
};
const getDosage = (inputUser, inputCalculated) => {
	if(inputUser[UserInput.types.maleOrFemale.key] === "male"){
		if(isYes(inputUser, UserInput.types.maleBreasts.key)
			|| isYes(inputUser, UserInput.types.benignProstateHypertrophy.key)
			|| isYes(inputUser, UserInput.types.colorectalCancerHistory.key)){
				return {
					description : "1 serving, 2 times per day.",
					servingsPerDay : 2
				};
		}
		if(isAboveOptimal(inputCalculated,CalculatedInput.types.progesteroneMaleRange.key)){
			return {
				description : "1 serving, 2 times per day.",
				servingsPerDay : 2
			};
		}
	} 
	if(inputUser[UserInput.types.maleOrFemale.key] === "female"){
		if(isYes(inputUser, UserInput.types.pmsSymptoms.key)
			|| isYes(inputUser, UserInput.types.breastCancerHistory.key)
			|| isYes(inputUser, UserInput.types.uterineCancerHistory.key)
			|| isYes(inputUser, UserInput.types.colorectalCancerHistory.key)){
				return {
					description : "1 serving, 2 times per day.",
					servingsPerDay : 2
				};
		}

	}
	return {
		description : "1 serving per day.",
		servingsPerDay : 1
	};
};
const getDescription = (inputUser, inputCalculated) => {
	return `
	DIM 3 Supreme represents a three-dimensional approach to supporting healthy estrogen metabolism.
	Research suggests that diindolylmethane (DIM), curcumin (from turmeric extract), and the patented black
	pepper extract BioPerine® support balanced estrogen metabolism.*`;
};
const title = "DIM 3 Supreme";
const getPrices = (inputUser, inputCalculated) => {
	return [{title, sku: "DIM-3-SUPREME", price: 43, servings: 120}]
};



export default {
	getEvaluation,
	getPrompts,
	title,
	getDosage,
	category : categories.supplements,
	getSortValue,
	getDescription,
	getPrices,
	images: [{ src: image1 }],
	docs: [{ src: doc1 }]	
};