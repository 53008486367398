import { addToCart, getQtyAndPrice, getCount } from './Cart.js';
import View from './View.js';
import { saveDC, getPercentage, DCCreator, getCode } from './disc.js';

export default {
	addToCart,
	getQtyAndPrice,
	getCount,
	View,
	saveDC,
	getPercentage,
	DCCreator,
	getCode
};