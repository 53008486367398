import React from 'react';
import {  } from 'reactstrap';
import './Contact.css';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Button, Col, Row, Card, CardText, CardBody, CardTitle, CardSubtitle, CardFooter, CardImg, CardImgOverlay, CardLink, CardGroup, Container } from 'reactstrap';
import { useHistory } from "react-router-dom";
import LayoutConstants from '../LayoutConstants.js';


const Home = (props) => {
	let history = useHistory();
	return(
        <Container>
            <h2 className="mt-4 m-3">Contact Us</h2>
            <h3>General Email</h3>
            <p><a href="mailto:info@fmcalculator.com">info@fmcalculator.com</a></p>
            <h4>For Technical Issues</h4>
            <p>Call (714) 357-8886</p>
            <h4>For Order Issues</h4>
            <p>Call (833) 372-7244</p>
            <h4>To make a personal appointment with Dr. Craig</h4>
            <p>Call (833) DrCraig</p>
            <h4>For your health needs in Southern California</h4>
            <a href="https://premierifm.com/">Premier IFM - Integrative Functional Medicine Center</a>
        </Container>
	);
};
export default Home;