export default {
    glucose : { key : "glucose", prompt : "Glucose:", units : "mg/dL", type : "number" , group : 100, priority : 10001 },
    hemoglobinA1C : { key : "hemoglobinA1C", prompt : "Hemoglobin A1C:", units : "%", type : "number" , group : 100, priority : 10002 },
    insulinFasting : { key : "insulinFasting", prompt : "Insulin - Fasting:", units : "µIU/ml", type : "number" , group : 100, priority : 10003 },
    fructosamine : { key : "fructosamine", prompt : "Fructosamine:", units : "µmol/Ll", type : "number" , group : 100, priority : 10004 },
    cPeptide : { key : "cPeptide", prompt : "C-Peptide:", units : "ng/ml", type : "number" , group : 100, priority : 10005 },
    bun : { key : "bun", prompt : "BUN:", units : "mg/dL", type : "number" , group : 100, priority : 10006 },
    creatinine : { key : "creatinine", prompt : "Creatinine:", units : "mg/dL", type : "number" , group : 100, priority : 10007 },
    eGFRNonAfricanAmerican : { key : "eGFRNonAfricanAmerican", prompt : "eGFR Non-African American:", units : "mL/min/1.73m2", type : "number" , group : 100, priority : 10008 },
    eGFRAfricanAmerican : { key : "eGFRAfricanAmerican", prompt : "eGFR African American:", units : "mL/min/1.73m2", type : "number" , group : 100, priority : 10009 },
    sodium : { key : "sodium", prompt : "Sodium:", units : "mEq/L", type : "number" , group : 100, priority : 10010 },
    potassium : { key : "potassium", prompt : "Potassium:", units : "mEq/L", type : "number" , group : 100, priority : 10011 },
    chloride : { key : "chloride", prompt : "Chloride:", units : "mEq/L", type : "number" , group : 100, priority : 10012 },
    cO2 : { key : "cO2", prompt : "CO2:", units : "mEq/L", type : "number" , group : 100, priority : 10013 },
    anionGap : { key : "anionGap", prompt : "Anion Gap:", units : "mEq/L", type : "number" , group : 100, priority : 10014 },
    uricAcidMale : { key : "uricAcidMale", prompt : "Uric Acid - Male:", units : "mg/dL", type : "number" , group : 100, priority : 10015 },
    uricAcidFemale : { key : "uricAcidFemale", prompt : "Uric Acid - Female:", units : "mg/dL", type : "number" , group : 100, priority : 10016 },
    proteinTotal : { key : "proteinTotal", prompt : "Protein Total:", units : "g/dL", type : "number" , group : 100, priority : 10017 },
    albumin : { key : "albumin", prompt : "Albumin:", units : "g/dL", type : "number" , group : 100, priority : 10018 },
    globulinTotal : { key : "globulinTotal", prompt : "Globulin - Total:", units : "g/dL", type : "number" , group : 100, priority : 10019 },
    calcium : { key : "calcium", prompt : "Calcium:", units : "mg/dL", type : "number" , group : 100, priority : 10020 },
    phosphorus : { key : "phosphorus", prompt : "Phosphorus:", units : "mg/dL", type : "number" , group : 100, priority : 10021 },
    magnesium : { key : "magnesium", prompt : "Magnesium:", units : "mg/dL", type : "number" , group : 100, priority : 10022 },
    alkPhos : { key : "alkPhos", prompt : "Alk Phos:", units : "IU/L", type : "number" , group : 100, priority : 10023 },
    astSGOT : { key : "astSGOT", prompt : "AST (SGOT):", units : "IU/L", type : "number" , group : 100, priority : 10024 },
    altSGPT : { key : "altSGPT", prompt : "ALT (SGPT):", units : "IU/L", type : "number" , group : 100, priority : 10025 },
    ldh : { key : "ldh", prompt : "LDH:", units : "IU/L", type : "number" , group : 100, priority : 10026 },
    bilirubinTotal : { key : "bilirubinTotal", prompt : "Bilirubin - Total:", units : "mg/dL", type : "number" , group : 100, priority : 10027 },
    bilirubinDirect : { key : "bilirubinDirect", prompt : "Bilirubin - Direct:", units : "mg/dL", type : "number" , group : 100, priority : 10028 },
    bilirubinIndirect : { key : "bilirubinIndirect", prompt : "Bilirubin - Indirect:", units : "mg/dL", type : "number" , group : 100, priority : 10029 },
    ggt : { key : "ggt", prompt : "GGT:", units : "IU/L", type : "number" , group : 100, priority : 10030 },
    ironSerum : { key : "ironSerum", prompt : "Iron Serum:", units : "µg/dL", type : "number" , group : 100, priority : 10031 },
    ferritin : { key : "ferritin", prompt : "Ferritin:", units : "ng/mL", type : "number" , group : 100, priority : 10032 },
    tibc : { key : "tibc", prompt : "TIBC:", units : "µg/dL", type : "number" , group : 100, priority : 10033 },
    transferrinSaturation : { key : "transferrinSaturation", prompt : "% Transferrin Saturation:", units : "%", type : "number" , group : 100, priority : 10034 },
    cholesterolTotal : { key : "cholesterolTotal", prompt : "Cholesterol - Total:", units : "mg/dL", type : "number" , group : 100, priority : 10035 },
    triglycerides : { key : "triglycerides", prompt : "Triglycerides:", units : "mg/dL", type : "number" , group : 100, priority : 10036 },
    ldlCholesterol : { key : "ldlCholesterol", prompt : "LDL Cholesterol:", units : "mg/dL", type : "number" , group : 100, priority : 10037 },
    hdlCholesterol : { key : "hdlCholesterol", prompt : "HDL Cholesterol:", units : "mg/dL", type : "number" , group : 100, priority : 10038 },
    vldlCholesterol : { key : "vldlCholesterol", prompt : "VLDL Cholesterol:", units : "mg/dL", type : "number" , group : 100, priority : 10039 },
    tsh : { key : "tsh", prompt : "TSH:", units : "µU/mL", type : "number" , group : 100, priority : 10040 },
    freeT3 : { key : "freeT3", prompt : "Free T3:", units : "pg/mL", type : "number" , group : 100, priority : 10041 },
    totalT3 : { key : "totalT3", prompt : "Total T3:", units : "ng/dL", type : "number" , group : 100, priority : 10042 },
    freeT4 : { key : "freeT4", prompt : "Free T4:", units : "ng/dL", type : "number" , group : 100, priority : 10043 },
    totalT4 : { key : "totalT4", prompt : "Total T4:", units : "µg/dL", type : "number" , group : 100, priority : 10044 },
    t3Uptake : { key : "t3Uptake", prompt : "T3 Uptake:", units : "%", type : "number" , group : 100, priority : 10045 },
    freeThyroxineIndexT7 : { key : "freeThyroxineIndexT7", prompt : "Free Thyroxine Index (T7):", units : "Index", type : "number" , group : 100, priority : 10046 },
    reverseT3 : { key : "reverseT3", prompt : "Reverse T3:", units : "ng/dL", type : "number" , group : 100, priority : 10047 },
    thyroidPeroxidaseTPOAbs : { key : "thyroidPeroxidaseTPOAbs", prompt : "Thyroid Peroxidase (TPO) Abs:", units : "IU/mL", type : "number" , group : 100, priority : 10048 },
    thyroidPeroxidaseTPOAbsLABCORP : { key : "thyroidPeroxidaseTPOAbsLABCORP", prompt : "Thyroid Peroxidase (TPO) Abs LABCORP:", units : "IU/mL", type : "number" , group : 100, priority : 10049 },
    thyroglobulinAbs : { key : "thyroglobulinAbs", prompt : "Thyroglobulin Abs:", units : "IU/mL", type : "number" , group : 100, priority : 10050 },
    hsCRPMale : { key : "hsCRPMale", prompt : "Hs CRP - Male:", units : "mg/L", type : "number" , group : 100, priority : 10051 },
    hsCRPFemale : { key : "hsCRPFemale", prompt : "Hs CRP - Female:", units : "mg/L", type : "number" , group : 100, priority : 10052 },
    cReactiveProtein : { key : "cReactiveProtein", prompt : "C-Reactive Protein:", units : "mg/L", type : "number" , group : 100, priority : 10053 },
    esrMale : { key : "esrMale", prompt : "ESR - Male:", units : "mm/hr", type : "number" , group : 100, priority : 10054 },        
    esrFemale : { key : "esrFemale", prompt : "ESR - Female:", units : "mm/hr", type : "number" , group : 100, priority : 10055 },        
    homocysteine : { key : "homocysteine", prompt : "Homocysteine:", units : "µmol/L", type : "number" , group : 100, priority : 10056 },
    fibrinogen : { key : "fibrinogen", prompt : "Fibrinogen:", units : "mg/dL", type : "number" , group : 100, priority : 10057 },
    creatineKinase : { key : "creatineKinase", prompt : "Creatine Kinase:", units : "u/l", type : "number" , group : 100, priority : 10058 },
    vitaminD25OH : { key : "vitaminD25OH", prompt : "Vitamin D (25-OH):", units : "ng/mL", type : "number" , group : 100, priority : 10059 },
    vitaminB12 : { key : "vitaminB12", prompt : "Vitamin B12:", units : "pg/mL", type : "number" , group : 100, priority : 10060 },
    folate : { key : "folate", prompt : "Folate:", units : "ng/mL", type : "number" , group : 100, priority : 10061 },
    dheasMale : { key : "dheasMale", prompt : "DHEA-S - Male:", units : "mcg/dL", type : "number" , group : 100, priority : 10062 },
    dheasFemale : { key : "dheasFemale", prompt : "DHEA-S - Female:", units : "µg/dl", type : "number" , group : 100, priority : 10063 },
    testosteroneTotalMale : { key : "testosteroneTotalMale", prompt : "Testosterone Total - Male:", units : "ng/dL", type : "number" , group : 100, priority : 10064 },
    testosteroneTotalFemale : { key : "testosteroneTotalFemale", prompt : "Testosterone Total - Female:", units : "ng/dL", type : "number" , group : 100, priority : 10065 },
    testosteroneFreeMale : { key : "testosteroneFreeMale", prompt : "Testosterone Free - Male:", units : "pg/dL", type : "number" , group : 100, priority : 10066 },
    testosteroneFreeFemale : { key : "testosteroneFreeFemale", prompt : "Testosterone Free - Female:", units : "pg/dL", type : "number" , group : 100, priority : 10067 },
    testosteroneBioavailableMale : { key : "testosteroneBioavailableMale", prompt : "Testosterone Bioavailable - Male:", units : "ng/dL", type : "number" , group : 100, priority : 10068 },
    testosteroneBioavailableFemale : { key : "testosteroneBioavailableFemale", prompt : "Testosterone Bioavailable - Female:", units : "ng/dL", type : "number" , group : 100, priority : 10069 },
    testosteroneFreeMaleLABCORP : { key : "testosteroneFreeMaleLABCORP", prompt : "Testosterone Free - Male LABCORP:", units : "pg/mL", type : "number" , group : 100, priority : 10070 },
    sexHormoneBindingGlobulinMale : { key : "sexHormoneBindingGlobulinMale", prompt : "Sex Hormone Binding Globulin - Male:", units : "mmol/L", type : "number" , group : 100, priority : 10071 },
    sexHormoneBindingGlobulinFemale : { key : "sexHormoneBindingGlobulinFemale", prompt : "Sex Hormone Binding Globulin - Female:", units : "mmol/L", type : "number" , group : 100, priority : 10072 },
    estradiolMale : { key : "estradiolMale", prompt : "Estradiol - Male:", units : "mg/dL", type : "number" , group : 100, priority : 10073 },
    estradiolFemale : { key : "estradiolFemale", prompt : "Estradiol - Female:", units : "pg/mL", type : "number" , group : 100, priority : 10074 },
    progesteroneMale : { key : "progesteroneMale", prompt : "Progesterone - Male:", units : "ng/mL", type : "number" , group : 100, priority : 10075 },
    progesteroneFemale : { key : "progesteroneFemale", prompt : "Progesterone - Female:", units : "ng/mL", type : "number" , group : 100, priority : 10076 },
    totalWBCs : { key : "totalWBCs", prompt : "Total WBCs:", units : "k/cumm", type : "number" , group : 100, priority : 10077 },
    rbcMale : { key : "rbcMale", prompt : "RBC - Male:", units : "m/cumm", type : "number" , group : 100, priority : 10078 },
    rbcFemale : { key : "rbcFemale", prompt : "RBC - Female:", units : "m/cumm", type : "number" , group : 100, priority : 10079 },
    hemoglobinMale : { key : "hemoglobinMale", prompt : "Hemoglobin - Male:", units : "g/dL", type : "number" , group : 100, priority : 10080 },
    hemoglobinFemale : { key : "hemoglobinFemale", prompt : "Hemoglobin - Female:", units : "g/dL", type : "number" , group : 100, priority : 10081 },
    hematocritMale : { key : "hematocritMale", prompt : "Hematocrit - Male:", units : "%", type : "number" , group : 100, priority : 10082 },
    hematocritFemale : { key : "hematocritFemale", prompt : "Hematocrit - Female:", units : "%", type : "number" , group : 100, priority : 10083 },
    mcv : { key : "mcv", prompt : "MCV:", units : "fL", type : "number" , group : 100, priority : 10084 },
    mch : { key : "mch", prompt : "MCH:", units : "pg", type : "number" , group : 100, priority : 10085 },
    mchc : { key : "mchc", prompt : "MCHC:", units : "g/dL", type : "number" , group : 100, priority : 10086 },
    platelets : { key : "platelets", prompt : "Platelets:", units : "k/cumm", type : "number" , group : 100, priority : 10087 },
    rdw : { key : "rdw", prompt : "RDW:", units : "%", type : "number" , group : 100, priority : 10088 },
    neutrophils : { key : "neutrophils", prompt : "Neutrophils:", units : "%", type : "number" , group : 100, priority : 10089 },
    bands : { key : "bands", prompt : "Bands:", units : "%", type : "number" , group : 100, priority : 10090 },
    lymphocytes : { key : "lymphocytes", prompt : "Lymphocytes:", units : "%", type : "number" , group : 100, priority : 10091 },
    monocytes : { key : "monocytes", prompt : "Monocyces:", units : "%", type : "number" , group : 100, priority : 10092 },
    eosinophils : { key : "eosinophils", prompt : "Eosinophils:", units : "%", type : "number" , group : 100, priority : 10093 },
    basophils : { key : "basophils", prompt : "Basophils:", units : "%", type : "number" , group : 100, priority : 10094 },
    collagenCrossLinkedNTx : { key : "collagenCrossLinkedNTx", prompt : "Collagen Cross-Linked NTx:", units : "nmol BCE/mmol creat", type : "number" , group : 100, priority : 10095 },
    cortisolAM : { key : "cortisolAM", prompt : "Cortisol - AM:", units : "µg/dL", type : "number", group : 100, priority : 10096 },
    cortisolPM : { key : "cortisolPM", prompt : "Cortisol - PM:", units : "µg/dL", type : "number", group : 100, priority : 10097 },
    creatinineClearance : { key : "creatinineClearance", prompt : "Creatinine Clearance:", units : "g/24 hours", type : "number", group : 100, priority : 10098 },
    leptinMale : { key : "leptinMale", prompt : "Leptin - Male:", units : "ng/mL", type : "number", group : 100, priority : 10099 },
    leptinFemale : { key : "leptinFemale", prompt : "Leptin - Female:", units : "ng/mL", type : "number", group : 100, priority : 100100 },
    gastrin : { key : "gastrin", prompt : "Gastrin:", units : "pg/mL", type : "number", group : 100, priority : 100101 },
    reticulocyteCount : { key : "reticulocyteCount", prompt : "Reticulocyte count:", units : "%", type : "number", group : 100, priority : 100102 },
    lithium : { key : "lithium", prompt : "Lithium:", units : "mEq/L", type : "number", group : 100, priority : 100103 },

};