import UserInput from '../../inputs/user/index.js';
import CalculatedInput from '../../inputs/calculated/index.js';
import thyroid from '../helpers/thyroid.js';
import { isUnanswered, isAboveOptimal, getDefaultSortValue, getDefaultEvaluation, getReasons, categories} from '../utilities';


const getEvaluation = (inputUser, inputCalculated) => {
    let toReturn = getDefaultEvaluation();
    
    if(thyroid.isHashimotos(inputUser, inputCalculated) && isAboveOptimal(inputCalculated,CalculatedInput.types.reverseT3Range.key)){
        toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.reverseT3Range.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : true");
    } else if(thyroid.isNonHashimotos(inputUser, inputCalculated) && isAboveOptimal(inputCalculated,CalculatedInput.types.reverseT3Range.key)){
		toReturn.recommended = true;
		toReturn.inputCalculatedKeys.push(CalculatedInput.types.reverseT3Range.key);
		toReturn.reasons = getReasons(toReturn.inputUserKeys, toReturn.inputCalculatedKeys, inputUser, inputCalculated).concat("Hashimotos : false");
	}

    return toReturn;
};

const getPrompts = (inputUser, inputCalculated) => {
    let toReturn = [];
    toReturn.push(...thyroid.getHashimotosPrompts(inputUser, inputCalculated));
    if(thyroid.isHashimotos(inputUser, inputCalculated)) {
        if(isUnanswered(inputUser,UserInput.types.reverseT3.key)){
            toReturn.push(UserInput.types.reverseT3);
        }
    }
    
    toReturn.push(...thyroid.getNonHashimotosPrompts(inputUser, inputCalculated));
    if(thyroid.isNonHashimotos(inputUser, inputCalculated)) {
        if(isUnanswered(inputUser,UserInput.types.reverseT3.key)){
            toReturn.push(UserInput.types.reverseT3);
        }
    }
    return toReturn;
};

const getDosage = (inputUser, inputCalculated) => {
};

export default {
	getEvaluation,
	getPrompts,
	title : "Adrenal Test",
	getDosage,
	category : categories.tests,
	getSortValue : getDefaultSortValue
	
};